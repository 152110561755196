export const analyticsTranslation = {
  scenarioID: 'Scenario ID',
  employeeChurn: 'Employee Churn',
  budgetForecasting: 'Budget Forecasting',
  processByCriticality: 'Process by Criticality',
  analyticsOverview: 'Analytics Overview',
  overallEffectiveness: 'Overall Effectiveness',
  insights: 'Insights',
  editScenario: 'Edit Scenario',
  scenarioDetails: 'Scenario Details',
  analyticsEffectiveness: 'Analytics Effectiveness',
  scenarioCriticalityRating: 'Scenario Criticality Rating',
  analyticsManager: 'Analytics Manager',
  eventType: 'Event Type',
  dataSourceDetails: 'Data Source Details',
  recordOverview: 'Record Overview',
  newControlRating: 'New Control Rating',
  newDataSource: 'New Data Source',
  analyticsProcess: 'Analytics Process',
  selectFields: 'Select Fields',
  submitForMR: 'Submit for MR',
  records: 'Records',
  lastRunDate: 'Last Run Date',
  dataSource: 'Data Source',
  newRecords: 'New Records',
  dataRefreshHistory: 'Data Refresh History',
  statusDescription: 'Status Description',
  scenarioBuilder: 'Scenario Builder',
  newScenario: 'New Scenario',
  connectToData: 'Connect to Data',
  createAnalyticsScenario: 'Create Analytics Scenario',
  fields: 'Fields',
  expressions: 'Expressions',
  expressionLabel: 'Some features of query builder is not supported by the browser,choose other browser',
  clear: 'Clear',
  calculation: 'Calculation',
  typeHere: 'Type here...',
  lastExtract: 'Last Extract',
  executionTime: 'Execution Time',
  numberOfRecords: 'Number of Records',
  dataFlow: 'Data Flow',
  intelligentDataForm: 'Intelligent Data Form',
  dataLoadType: 'Data load type',
  newRowsColumns: 'New rows using columns',
  frequencyOfDataRefresh: 'Frequency of data refresh',
  dataSourceNotScheduled: 'Data source not scheduled',
  editSubProcess: 'Edit Sub-Process',
  addSubProcess: 'Add Sub-Process',
  newSubProcess: 'New Sub-Process',
  analyticsSubProcess: 'Analytics Sub-Process',
  scenario: 'Scenario',
  falsePositive: 'False Positive',
  exception: 'Exceptions',
  selectTable: 'Select table',
  validTime: 'Please enter a valid time',
  connectionType: 'Connection Type',
  existingTemplate: 'Existing Template',
  selectExistingTemplate: 'Please select an existing template',
  allScenarios: 'All Scenarios',
  scenariosBySubProcess: 'Scenarios by Sub Process',
  scenariosCriticality: 'Scenarios by Criticality',
  scenariosProcess: 'Scenarios by Process',
  tcpIPport: 'TCP / IP port',
  schema: 'Schema',
  sid: 'SID',
  descriptionConnection: 'Description for the connection',
  sytemIdentifier: 'System Identifier (SID) is a unique name for an Oracle database instance',
  defaultSchema: 'The schema to use as default schema',
  userToConnect: 'Name of the user to connect with',
  addressOfServerHost: 'Name or IP address of the server host',
  nthgMoreShw: 'Add a new connection',
  addNewConnection: 'add new connection',
  editConnection: 'Edit Connection',
  connectionName: 'Connection name',
  nameForConnection: 'Type a name for the connection',
  hostName: 'Host name',
  frqOfDataRefresh: 'Please select the frequency of data refresh',
  identifyNewRows: 'Identify new rows using column',
  forFullLoadDataRefresh: 'Full load will replace the existing data with the new dataset.',
  appendLoadNewData: 'Append load will add new data to the existing dataset without updating the existing content.',
  incrementalLoadExistingData: 'Incremental load selectively loads only the new or modified records.',
  incrementalLoad: 'Incremental load',
  appendLoad: 'Append Load',
  fullLoad: 'Full load',
  selectDataLoad: 'Select the data load type',
  refreshDataPeriodic: 'Do you want to automatically refresh the data on a periodic basis',
  intelligentDataLoader: 'Intelligent Data Loader',
  editLogic: 'Edit Logic',
  calculationLogic: 'Calculation Logic',
  exceptionCriteria: 'Exception Criteria',
  createScenario: 'Create Scenario',
  tables: 'Tables',
  queryBuilder: 'Query builder',
  executeQuery: 'Execute Query',
  tableData: 'Table data',
  fieldNmaeExist: 'Field name exists',
  clickAddNewColumn: 'Click to Add New Custom Column',
  fieldNameExists: 'Field name exists',
  datatype: 'Datatype',
  fieldsConnectedDataSource: 'Fields from Connected data source',
  rename: 'Rename',
  dataType: 'Data type',
  fieldsFromDocument: 'Fields from the Document',
  mapFields: 'Map fields',
  mapData: 'Map Data',
  validateData: 'Validate Data',
  dataSourceName: 'Data Source Name',
  detailedOverview: 'Detailed Overview',
  markAsOpen: 'Mark as Open',
  markAsException: 'Mark as Exception',
  markAsFalsePositive: 'Mark as False Positive',
  insightInProgress: 'AI Insight in Progress',
  reGenerateAI: 'Generate AI Insight',
  markAsClosed: 'Mark As Closed',
  importData: 'Import Data',
  possibleException: 'Possible Exceptions',
  population: 'Population',
  gettingStarted: 'Getting started',
  clickAddColumn: 'Add Column',
  effectivenessByProcess: 'Effectiveness by Processes',
  effectivenessBySubProcess: 'Effectiveness by Sub-Processes',
  fieldMapping: 'Field Mapping',
  sendForMr: 'Submit for Management Response',
  selectExceptions: 'Select Exceptions',
  fieldDocument: 'Fields from the Document',
  markAsUnreviewed: 'Mark as Unreviewed',
  markAsReviewed: 'Mark as Reviewed',
  score: 'Score',
  calculatedFieldLogic: 'Calculated Field Logic',
  holdForMoment: 'Hold on for a moment!',
  processingData: 'Processing data...',
  processingDataWithoutDot: 'Processing data',
  modelInsights: 'Model Insight',
  lastGeneratedOn: 'Last generated on',
  model: 'Model',
  exceptionTrend: 'Exception Trend',
  insightAvailable: 'Insights will be available after the data is processed.',
  calculatedTimestampParse: 'Can only be used inside if, timestamp format and case when functions.',
  fieldShouldBeString: 'The field should be string.',
  fieldShouldBeStringOrInteger: 'The field should be integer or string.',
  fieldShouldBeDate: 'The field should be date.',
  mrCreateFieldSelectionMessage: 'Only the selected fields will be displayed on the exception table in the management response.',
  mrCreateFieldSelectionValidation: 'Please select atleast one field',
  editField: 'Edit Field',
  scoreRepresents: 'A score represents the probability of a record being an exception.',
  Scenariosby: 'Scenarios by',
  Overall: 'Over All',
  overalls: 'Overall',
  sameCalculatedField: 'The expressions cannot use the same field.',
  generateAIDependencyTagged: 'There have been updates on the scenario.',
  generateAIDependencyExamine: 'Generate new AI insights.',
  isGeneratingNotify: 'Regenerating AI insight.',
  isGeneratingNotifyParagraph: 'You will be notified once the process is complete.',
  aboutAurexGenerateAI: 'About Aurex Generative AI',
  aboutAurexGenerateAIParagrpagh: 'Generate effortless summaries and insights with Aurex Generative AI. Get concise and tailored reports and recommendations for the scenario.',
  generateAIDisableHelpText: 'Process data to generate insights',
  aiGenerateAiInProgreess: 'AI insights generation in progress',
  mrDashboard: 'Management Response Dashboard',
  responseByOwner: 'Response By',
  listOf: 'List of ',
  scenarioWithexception: 'Scenarios with Exceptions',
  mrDashboardTableHead: 'All Management Responses',
  scheduledScenarios: 'Scheduled Scenarios',
  totalExceptions: 'Scenarios with Exceptions',
  dormantScenarios: 'Dormant Scenarios',
  tableView: 'Table View',
  lineChartView: 'Line Chart View',
  expandTableView: 'Expand Table View',
  processHeatmap: 'Process Heatmap',
  heatmap: 'Heatmap',
  loadingData: 'Fetching data, please wait',
  selectDelimiter: 'Select Delimiter',
  dormantScenario: 'Scenarios that have been inactive for more than the past 3 months',
  scenarioCount: 'Scenario Count',
  fieldShouldBeInteger: 'The field should be integer',
  fieldShouldBeStringOrDate: 'The field should be string or date.',
  fieldsShouldBeString: 'The fields should be string.',
  scenariosByCriticality: 'Scenarios By Criticality',
  controlsEffectiveness: 'Controls Effectiveness',
  exceptionsStatus: 'Exceptions Status',
  taggedControls: 'Tagged Controls',
  taggedRisk: 'Tagged Risk',
  scenarioByRiskRating: 'Risk Rating',
  maximumNumberOfFieldTwo: 'The maximum number of fields should be two.',
  sameFieldNotExpected: 'Same field not expected.',
  connectionSource: 'Connection Source',
  activatedBy: 'Activity by',
  scheduledFrequency: 'Schedule Frequency',
  scheduleType: 'Schedule Type',
  scheduleTime: 'Schedule Time',
  liveOrLastExtract: 'Live/Last Extract',
  excel: 'Excel',
  textData: 'Text Data',
  mysql: 'MySQL',
  oracle: 'Oracle',
  mssql: 'Microsoft SQL Server',
  loadMore: 'Load More',
  schedule: 'Schedule',
  dataFlush: 'Data Flush',
  intelligentDataLoaderSubText: 'SET UP AUTOMATIC DATA REFRESH AT REGULAR INTERVALS?',
  pleaseSelectDataLoadType: 'SELECT THE DATA LOAD TYPE',
  fullLoadAppendLoadText: 'Full load will replace the existing data with the new dataset.',
  pleaseSelectFrequencyOfDataRefresh: 'SELECT THE FREQUENCY FOR DATA REFRESH',
  selectDate: 'Select Date',
  time: 'Time',
  allDataSource: 'All Data Sources',
  connectToTextData: 'Connect to text data',
  notExpectingField: 'The field cannot be in the ',
  notExpectinValue: 'The value cannot be in the ',
  position: ' position of the expression.',
  first: 'first',
  second: 'second',
  third: 'third',
  risksByRating: 'Risks by Rating',
  minimumShouldTwo: 'At least two fields are required.',
  notExpectingValue: 'Values are not allowed in the expression.',
  initialResponseby: 'Initial Response by',
  initialResponseDate: 'Initial Response Date',
  lastModifiedBy: 'Last Modified by',
  currentRating: 'Current Rating',
  aIExceptionScore: 'AI Exception Score',
  newExceptions: 'New Exceptions',
  exceptionConfirmation: 'Exception Confirmation',
  controlType: 'Type',
  flowBuilder: 'Flow Builder',
  responseID: 'Response ID',
  responseText: 'Response Text',
  responseExpectedby: 'Response Expected by',
  implementationStatus: 'Implementation Status',
  implementationDueDate: 'Implementation Due Date',
  initialDueDate: 'Initial Due Date',
  scenarioName: 'Scenario Name',
  scenarioDescription: 'Scenario Description',
  exceptionsCount: 'Exceptions Count',
  reviewedExceptionsCount: 'Reviewed Exceptions Count',
  department1: 'Department Level1',
  department2: 'Department Level2',
  managementUser: 'Management User',
  managementReviewer: 'Management Reviewer',
  lastModifiedDate: 'Last Modified Date',
  assignedDate: 'Assigned Date',
  assignedBy: 'Assigned by',
  scenarioSubprocess: 'Scenario Subprocess',
  scenarioProcess: 'Scenario Process',
  scenarioRating: 'Scenario Rating',
  refreshHistory: 'Refresh History',
  workingHourvalidationFirst: 'The first field should be a string or a date.',
  workingHourvalidationSecond: 'The second and third fields should be integers.',
  maximumNumberOne: 'The maximum number of fields should be one.',
  analyticsCriticalityRating: 'Analytics Criticality Rating',
  preview: 'Preview',
  uploadedFile: 'Uploaded File',
  selectSheet: 'SELECT SHEET',
  back: 'Back',
  cancel: 'Cancel',
  previous: 'Previous',
  next: 'Next',
  finish: 'Finish',
  uploadedDocumentPreview: 'Uploaded Document Preview',
  mapFieldsInfo: 'Ensure the accuracy of the data types for the field mapping to avoid errors.',
  verifyAndFinish: 'Verify data and finish',
  fieldShouldbeDate: 'The field must be a date.',
  fieldCannotbeDate: 'The field cannot be a date.',
  lastFiledOnlyNumeric: 'The last field must contain only numeric values.',
  fieldContainNumericValue: 'The field must contain only numeric values.',
  noFieldsallowed: 'No input values or fields are allowed',
  dashboards: 'Dashboards',
  totalJobs: 'Total',
  completedJobs: 'Completed',
  inprogressJobs: 'In Progress',
  failedJobs: 'Failed',
  scheduleByDataSource: 'Schedule by Data Source',
  scheduleByFrequency: 'Schedule by Frequency',
  scheduleByType: 'Schedule by Type',
  fieldNameAlreadyExists: 'Field name already exists.',
  datasourceName: 'Data Source Name',
  selectFile: 'Select file to upload data',
  dataLoading: 'Data Loading...',
  selectTables: 'Select table',
  chooseTable: 'Choose your table',
  fetchingData: 'Fetching data. Please wait...',
  atLeastOneColumnRequired: 'At least one column is required.',
  fileRequired: 'Data fetching is in progress.',
  maximumFileSize: 'The maximum file size of a single file should be 800MB',
  fileSizeExceeds: 'The maximum file size exceeds the limit, file should be less than 2 GB',
  incrementLoadDescription: 'This field contains the list of date columns from the connected data source.',
  addingColumn: 'Adding Column',
  updateStatus: 'Update status',
  cloud: 'Cloud',
  functions: 'Functions',
  syntax: 'Syntax',
  functionHelptext: 'The available functions for performing calculations.',
  fieldHelptext: 'Columns from the data source.',
  expressionHelptext: 'Formulas combining values and operations to create new data.',
  functionsGuide: 'Functions Guide',
  example: 'Example',
  addPath: 'Add path',
  enterPath: 'Enter the path of the file',
  validPath: 'Enter a file path',
  xlsType: 'xls',
  xlsxType: 'xlsx',
  csvType: 'csv',
  supportdFormats: 'Supported file formats: xls, xlsx, csv',
  selectedFile: 'SELECTED FILE',
  cloudType: 'cloud',
  viewScenario: 'View Scenario',
  cannotDeleteCalculated: 'Unable to delete the calculated field',
  whileEditing: 'as it is currently being edited.',
  selectAfunction: 'Select a function.',
  requires: 'function requires',
  valueInPostion: 'field in position',
  requiresFieldPosition: 'function requires a field in position',
  requiresValuePosition: 'function requires a value in position',
  functionExpects: 'function expects at least',
  functionExpectsOnly: 'function expects only',
  DuplicateFieldNotAllowed: 'function does not accept duplicate fields.',
  minimize: 'Minimize',
  expand: 'Expand',
  argument: 'argument',
  arguments: 'arguments',
  calculatingFields: 'Applying logic',
  processingCompleted: 'Processing completed',
  appendLoadSchedule: 'Append load',
  incrementLoadSchedule: 'Incremental load',
  fullLoadSchedule: 'Full load',
  selectFunction: 'Select a function.',
  dataDoesNotContain: 'The data does not contain the field ',
  expressionInvalid: 'The expression is invalid.',
  absAffectArthimetic: 'The ABS() function requires arithmetic operations between parameters.',
  dataDetails: 'Data details - (All data)',
  functionAllows: 'function only allows the',
  functionsDuplicate: 'functions',
  functionDuplicate: 'function',
  functionDoesNotAny: 'function does not allow any other functions',
  guide: 'Guide',
  notClassified: 'Not Classified',
  exceptions: 'Exception',
  management_response: 'management response',
  report: 'report',
  connection: 'connection',
  control: 'Remove control ?',
  dashboard: 'dashboard',
  item: 'item',
  selectAColumnIdentify: 'Select a column to identify new rows',
  comment: 'comment',
  grid: 'Remove grid ?',
  document: 'document',
  Scenarios: 'Scenarios',
  assistedModelling: 'Assisted Modeling',
  folder: 'Delete folder ?',
  folderSub: 'This will permanently delete the folder and dashboard contained within it.',
  card: 'card',
  controlSub: 'This will remove control from the scenario.',
  gridSub: 'This will remove the grid and its configurations.',
  dataSourceCancel: 'You will lose the information entered.',
  calculatedFields: 'calculated fields',
  calculatedField: 'calculated field',
  reports: 'reports',
  sampleInfoContentForModelMonitoring: 'This is a sample help text',
  dataProcess: 'Data Process',
  dateAndTime: 'Date & Time',
  precision: 'Precision',
  precisionChange: 'Precision Change',
  populationStability: 'Population Stability',
  processedBy: 'Processed by',
  modelIterations: 'Model Iterations',
  changeVs: 'Change vs',
  initial: 'Initial',
  changeVsInitial: 'Change vs Initial',
  allModeliterations: 'All Model Iterations',
  informationValue: 'Information Value',
  psi: 'PSI',
  modelIterationsByOwner: 'Model Iterations by Owner',
  PrecisionAndPsi: 'Precision & PSI',
  totalModelIterations: 'Total Model Iterations',
  populationStabilityIndex: 'Population Stability Index',
  averagePrecision: 'Average Precision',
  initialPrecision: 'Initial Precision',
  averagepsi: 'Average PSI',
  vs: 'vs',
  latest: 'Latest',
  dataProcessingProgress: 'Data processing in progress.',
  disableAssistedModelling: 'Process data to generate model insights.',
  modelInsightMainHeader: 'Model monitoring enables tracking changes in data distribution over time by observing the Population Stability Index (PSI) and precision fluctuations.',
  precsionHelpText: 'Precision means how close data points are to each other. If data points are very close together, we say the data has high precision.',
  psiHelpText: 'The Population Stability Index (PSI) measures changes in data distribution over time to maintain the accuracy of predictive models.',
  modelInformationHelText: 'Information Value quantifies how each feature contributes to predictions.',
  modelMonitoringHelpText: 'The number of times the model has been trained or updated.',
  feature: 'Feature',
  iterations: 'Iterations',
  featureContribution: 'Feature Contribution',
  featureSelection: 'Feature Selection',
  modelSelection: 'Model Selection',
  preceedToNext: 'Proceed to Data Processing',
  viewProcessInstruction: 'View Process Instruction',
  recommendedText: 'We recommend using',
  recommendedText1: 'based on the characteristics of the data for better exceptions, precision, and accuracy scores. Other models are listed below.',
  otherModel: 'Other models are listed below.',
  assistedDataProcess: 'Assisted Data Process',
  processingDatas: 'Processing Data',
  plaseWaitMinute: 'This may take a while. You will be notified once the process is complete.',
  assistedDataProcessText: 'Allows active participation in the data processing workflow by selecting features and models based on insights and preferences.',
  featureSelectionText: 'Identify the most relevant variables from the dataset for the predictive modeling task.',
  modelSelectionText: 'Choose the best machine learning model for the dataset.',
  modelInsightText: 'Gain insights into how the selected model interacts with the dataset to make informed decisions and improve model performance.',
  dontShowagain: 'Don\'t show again',
  step1: 'Step 1',
  step2: 'Step 2',
  step3: 'Step 3',
  step4: 'Step 4',
  recommendedModelText: 'Optimizing model recommendations based on the selected features. This may take a moment.',
  recommended: 'Recommended',
  exceptionFlag: 'Exception flag',
  recordId: 'Record Id',
  sendforMR: 'Send for MR',
  assistedModeling: 'Assisted Modeling',
  proceeToDataProcess: 'Proceed To Data Process',
  dataProcessext: 'Participate actively in data processing by selecting features and models based on insights and preferences.',
  exit: 'Exit',
  randomForest: 'RANDOM FOREST',
  randomForestDesc: 'Random forest uses bagging to combine multiple decision tree models and reduce errors, making it a popular machine learning algorithm.',
  anomalyDetection: 'ANOMALY DETECTION',
  anomalyDetectionDesc: 'Anomaly detection is used to identify rare events, items, or observations that are suspicious because they significantly differ from standard behaviors or patterns.',
  autoMl: 'AUTOML',
  autoMlDesc: 'AutoML automates machine learning for real-world problems by finding the best model and parameters using the training dataset and evaluation techniques.',
  accuracy: 'Accuracy',
  exceptionStatus: 'Exception Status',
  taggedDate: 'Tagged Date',
  auditTitle: 'Audit Title',
  tableName: 'Table Name',
  cloudPath: 'Cloud Path',
  query: 'Query',
  summary: 'Summary',
  editScheduleTooltip: 'Modify schedule',
  conditionaImport: 'Conditional Import',
  conditionalImportHint: 'Import new or updated records based on a specified column.',
  conditionalFieldLabel: 'Select a column to identify new rows',
  select_one_scenario: 'Please select atleast one scenario',
  select_one_exception: 'Please select atleast one exception',
  exception_flag: 'Exception Flag',
  exception_count: 'Exception Count',
  failed_generate_obs: 'Failed to generate observation',
  a: 'a',
  reviewed: 'Reviewed',
  overview: 'Overview',
  autoInsights: 'Auto Insights',
  oracleInfo: 'For precise results, consider using double quotes around table names and column names to ensure exact casing where necessary',
  showButton: 'showButton',
  selective_Load: 'Selective Load',
  loadData: 'Data Load',
  dataFlushProgress: 'Data flush in progress',
  dataCopyProgress: 'Data copy in progress',
  everyweek: 'Every week on',
  at: 'at',
  everyMonth: 'Every month on the',
  daily: 'Daily at',
  delemiter: 'Delimiter',
  closeWindow: 'Close',
  deleteScenario: 'Delete Scenario?',
  deleteScenarios: 'Delete Scenarios?',
  permantentlyDelete: 'This will permanently delete the scenario.',
  permantentlyDeletes: 'This will permanently delete the scenarios.',
  intelligentLoaderSubTxt: 'Automates the data refresh process to ensure the data source remains current and accurate with scheduled updates.',
  queryBuilderNote: 'Use custom SQL queries to filter and select data. Enclose table and column names within double quotes for accurate query execution. Incorrect queries may result in errors or unexpected results.',
  note: 'Note',
  selectiveLoad: 'Selective Load',
  selectivelyLoad: 'Incremental load selectively loads new or updated records',
  fieldsFromSource: 'Fields from the source',
  function: 'Function',
  field: 'Field',
  fieldFromSource: 'Fields from the source',
  dataSourceField: 'Data source Field',
  configureFields: 'Configure fields',
  noModulesSelectedToast: 'Select at least one module.',
  modulesWithoutConnectionToast: 'Connect the modules to proceed.',
  noFieldSelectedToast: 'Select at least one field in each module.',
  newTemplate: 'New Template',
  template: 'Template.?',
  multipleInputConnection: 'The module cannot be connected as it conflicts with an existing connection.',
  circularNodeConnectionError: 'Circular connections are not allowed between modules.',
  reportBuilder: 'Report Builder',
  reportBuilderTemplates: 'Report Builder Templates',
  totalRecords: 'Total Records'
};
