export const policyTranslation = {
  policyMngment: 'Policy Management',
  allPolicy: 'All Policies',
  designPolicy: 'Design Policy',
  policyOverview: 'Policy Overview',
  compliance: 'Compliance',
  policy: 'POLICY  ',
  salDocument: 'DOCUMENT ',
  policyComplete: 'Is the policy draft complete? Get review from stakeholders, the legal panel, or the expert panel',
  finalPolicy: 'Do you have a finalized version of the policy? Publish here',
  livePolicy: 'View Live Policy',
  policyRev: 'Get Policy Review',
  docReview: 'Get Document Review',
  addCmpl: 'Add Compliance',
  addBsp: 'Add Business Process',
  reportAsMail: 'Send Report as Email',
  policyMngObj: 'Policy Management - Object Editor',
  editPolicy: 'Edit a Policy',
  areYouSure: 'Are you sure you want to edit the policy?',
  createPolicy: 'Are you sure you want to create a new policy?',
  policyCreate: 'Create a New Policy',
  noDataAvailable: 'No data available',
  policySectionWarning: 'Please provide the policy details',
  editTemplate: 'Edit a Template',
  areYouSureTemplate: 'Are you sure you want to edit the template?',
  authorization: 'Authorization',
  distributionList: 'Distribution List',
  addSectionSubHead: 'From here, start the new section. You can import a template from this page.',
  startFrom: 'START FROM',
  createNew: 'Create New',
  importFromTemplate: 'Import from template',
  parentSection: 'Parent Section (Optional)',
  excludeFromIndex: 'Exclude from index and numbering',
  numberingLevel: 'Numbering for this level',
  numberLevelBelow: 'Numbering for the level below',
  chooseTemplate: 'Choose Template',
  editSection: 'Edit Section',
  addSubSection: 'Add subsection',
  editSubSection: 'Edit subsection',
  saveNewVersion: 'New version',
  saveVersionText: 'Version numbers are unique identifiers of policy versions, allowing users to keep track of modifications and roll back to previous versions if needed.',
  majorUpdate: 'Major version',
  minorUpdate: 'Minor version',
  currentVersion: 'Current version',
  newVersion: 'New version',
  changesComment: 'Comments',
  addSection: 'Add Section',
  add: 'Add',
  cancel: 'Cancel',
  hidesectionfrompolicy: 'Hide section from policy',
  showsectionfrompolicy: 'Show section header in policy',
  showthesectiononanewpage: 'Show the section on a new page',
  edit: 'Edit',
  delete: 'Delete',
  sectionsPreview: 'Sections Preview',
  discardChanges: 'Discard unsaved changes',
  loseChanges: 'You will lose all unsaved changes',
  excludeInfoIconTxt: 'Check this to exclude this section from appearing in the table of contents.',
  parentSubSection: 'Parent Section',
  addSubSectionSubHead: 'From here, start the new sub-section. You can import a template from this page.',
  sectionNewVersionWarning: 'You have unsaved changes.',
  loadingData: 'Loading',
  maxFieldLent: 'The maximum length of this field is 255 characters',
  version: 'version',
  policies: 'policy',
  createdBy: 'Created by',
  status: 'Status',
  policyInfoHeader: 'Policy Information',
  createdDate: 'Created Date',
  valditityDate: 'Valid Till',
  lastUpdatedDate: 'Last Updated on',
  policyInfoheader: 'Policy Information',
  unableToCreateSection: 'Unable to create the section as the maximum of 6 subsection levels have been reached.',
  publishedDate: 'Published Date',
  allUsers: 'Users',
  userGrp: 'User Group',
  addUsers: 'Add Users',
  addUserGroups: 'Add User Groups',
  departmentEntity: 'Department-entity',
  emailId: 'Email',
  acknowledgementStatus: 'Acknowledgement By Status',
  acknowledgementDepartment: 'Acknowledgement By Department',
  acknowledgementResponseTime: 'Acknowledgement Response Time',
  acknowledgement: 'acknowledgement',
  sectionVisibleInPolicy: 'This section is visible in the policy',
  sectionHiddenFromPolicy: 'This section is hidden from the policy',
  restoreVersion: 'Restore this version',
  sectionVersion: 'Section version',
  sectionTemplate: 'Section Template',
  sectionCoverpage: 'Section Coverpage',
  documentControl: 'Document Control',
  generateDocument: 'Generate Document',
  versionHistory: 'Version History',
  depL1: 'Department L1',
  remType: 'Reminder Type',
  addPolicy: 'Add Policies',
  acknowledgementOn: 'Acknowledged on',
  acknowledgmentReq: 'Acknowledgement Requests',
  policyName: 'Policy Name',
  policyUpdatedOn: 'Updated On',
  policyVersion: 'Version',
  policyAcknowledgment: 'Policy Acknowledgment',
  downloadCopy: 'Download Copy',
  acceptedOn: 'Accepted On',
  acknowledge: 'Acknowledge',
  acknowledgmentSettings: 'Acknowledgment Settings',
  latestVersionPublished: 'Require acknowledgement for all latest version published',
  leaveToggle: 'Leave unchecked to request acknowledgment only for the current latest version.',
  makeItMandatory: 'Make it mandatory for users to accept the terms and condition statement before acknowledgement.',
  recipientsaAcknowledged: 'Recipients who have not acknowledged will recieve a cyclic reminder.',
  days: 'Days',
  weekly: 'Weekly',
  monthly: 'Monthly',
  recipientsReAcknowledge: 'Recipients must re-acknowledge the policy upon reaching the specified time interval.',
  enableMandatoryRead: 'Enable mandatory read',
  mandatesRecipients: 'The mandates recipients to scroll through the content before the acknowledgement',
  yearly: 'Yearly',
  acknowledgeExpiry: 'Acknowledgement Expire',
  readPolicy: 'Read the entire policy before acknowledgment',
  policyname: 'Policy name',
  policyversion: 'Version',
  policyupdatedOn: 'Updated on',
  acceptedBy: 'Accepted by',
  acceptedon: 'Accepted on',
  ackExpiry: 'Acknowledgement expiry',
  ackStatus: 'Policy status',
  responseTime: 'Response Time',
  notAccepted: 'Not accepted',
  userGroupName: 'User Group',
  members: 'Members',
  averageResponeTime: 'Average Response Time',
  ackGroupName: 'Group Name',
  restored: 'restored',
  sectionDeleted: 'Section Deleted',
  policysignoff: 'Policy Sign Off',
  Policy: 'Policy',
  untitledHeader: 'Untitled Header',
  addYourDesHere: 'Add your description here',
  pendingApprovBy: 'Pending for Approval by',
  relatedPolicyId: 'Related Policy Id',
  relatedPolicy: 'Related Policy',
  policyNameVer: 'Policy Name - Policy Version',
  approvalReqBy: 'Approval Requested By',
  mediaManAttach: 'Media Manager | Media Attachment',
  policyMonthV1: 'For the months does not have',
  ackGroupAdd: 'Please select at least one item',
  addTerms: 'Please enter terms and conditions text.',
  user: 'Users',
  export: 'export',
  selectEnds: 'selectAllEnds',
  latest: 'latest',
  day: 'day',
  everyDay: 'Occurs on every',
  everyWeek: 'Occurs every',
  everyMonth: 'Occurs every month on',
  th: 'th',
  accepted: 'Accepted',
  expired: 'Expired',
  unnumbered: 'Unnumbered',
  st: 'st',
  nd: 'nd',
  rd: 'rd',
  comments: 'Comments',
  policyVersions: 'Versions',
  sectionnotAdded: 'Sections not added',
  createVersion: 'Create new version',
  current: 'Current',
  restore: 'Restore',
  trackChange: 'Track Changes',
  publish: 'Publish',
  sendApproval: 'Send for Approval',
  compare: 'Compare',
  sendForSignOff: 'Send for Sign off',
  document: 'document',
  advance_stage: 'Advance Stage',
  policyStage: 'Policy Stage',
  next_stage: 'Next stage',
  untitled: 'Untitled',
  all: 'All',
  userGroup: 'User Groups',
  acknowledgementBy: 'Acknowledgement By',
  allacknowledgement: 'All acknowledgements',
  age: 'Age',
  userId: 'User ID',
  userName: 'User Name',
  source: 'Source',
  acknowledgedOn: 'Acknowleged On',
  requestedBy: 'Requested By',
  of: 'of',
  addUser: 'Add User',
  crtPolicy: 'Create Policy',
  ackversionPublished: 'Require acknowledgment for the latest version published',
  ackversionPublishedSubText: 'Leave unchecked to request acknowledgment only for the current latest version.',
  termsAndConditionAcknowledgement: 'Enable terms & conditions',
  termsAndConditionAcknowledgementSubText: 'Make it mandatory for users to accept the terms and conditions prior to acknowledgment.',
  remindRecipients: 'Remind recipients',
  acknowledgeReset: 'Send automatic reminders on acknowledgment reset.',
  everyYear: 'Occurs every year on',
  resetAcknowledgement: 'Reset acknowledgment',
  policyMonthV2: ', last date of the month will be taken.',
  compareVersion: 'Compare Versions',
  urlCopied: 'Public URL copied to clipboard.',
  effectiveUser: 'Effective Users',
  week: 'Week',
  month: 'Month',
  year: 'Year',
  addbsp: 'Add business process',
  latestVersion: 'Latest',
  lockStage: 'Unable to update policy stage as the policy is locked.',
  day_settings: 'Day',
  policyPublished: 'Policy not published yet',
  policyPublishedSubtex: 'To publish the policy, follow these steps. Here’s what you need to do:',
  createNewVersion: 'Create Version',
  enableClosure: 'Enable conflict disclosure',
  enableClosureDesc: 'Allow users to disclose any conflicts of interest when acknowledging policies.',
  discloseConflict: 'Disclose conflict',
  conflictDesription: 'Conflict Description',
  conflictDisclosedon: 'Conflict Disclosed on',
  fileValidationAck: 'Supported files: pdf, docx, ppt, pptx, xls, xlsx, tsv, csv, psv, odt, scsv, ssv, txt, mp4, avi, mkv, mov, wmv, web, flv, jpeg, jpg, png, vsd, vsdx, msg.',
  conflictdisclosed: 'Conflict disclosed',
  requiredValidator: 'This field is required',
  deleteDocument: 'Delete Document',
  downloadDocument: 'Download Document',
  discloseInfo: 'Declare any conflicts of interest associated with this policy.',
  editConflict: 'Edit conflict',
  attachFiles: 'Attach files',
  removeConflict: 'Remove Conflict'
};
