import { environment } from "src/environments/environment";
import { mfaAPI } from "src/app/core/constant/mfa.constant";
import { complianceTitles } from "src/app/core/constant/compliance.constant";
import { commonLanguage } from 'src/app/core/constant/language-translation/common-language';
import { HttpContextToken } from "@angular/common/http";
import { TimeEntryQueryParams } from "src/app/core/interface/audit-management.interface";
import { riskArabicTranslation } from "./constant/language-translation/risk/risk-language";

export const showToastrContext = new HttpContextToken(() => true);

export enum ApiEndpoints {
  EntityHierarchy= 'entity/hierarchy-list',
  organizationCardFilter = 'filter-charts/',
  organizationLevelFilter = 'get_filters/?request_for=',
  Login = 'mfa-token/',
  Logout = 'auth/logout',
  loginSaml = 'auth/login-saml',
  samlLoginInfo = 'saml-loginhide',
  loginInfo = 'v1/api/v6/login-loginfo/?user_id=',
  AllRiskGrisList = 'risk-assessment/header/list/',
  AllStrategyRiskGridList = 'business-strategy/tagged-risk/list/',
  getPublishedPageFilterFieldsForReport = 'dashboard_builder/page-filter/fields/list/',
  RiskUniverseGraph = 'filter-charts/?request_from=app&request_for=risk_assessment',
  RiskUniverseLandingGraph = 'filter-charts/?request_from=app&request_for=risk_assessment&request_for_risk=risk_assessment',
  StrategyRiskUniverseGraph = 'filter-charts/?request_from=app&request_for=risk_assessment_business_strategy',
  LatestUpdate = 'latest-update/',
  RiskAggregationGraph = 'risk-aggregation/dataset?request_from=app',
  RiskControlGridList = 'risk-assessment/details/list/',
  RiskControl = 'risk-assessment/details/',
  DashBoardAllChart = 'dashboard/dataset/?request_from=app&multi_values=true',
  RiskControlGraph = 'filter-charts/?request_for=risk_assessment_v1_details&request_from=app&platform=web',
  RiskControlDetail = 'risk-assessment/details/',
  RiskControlTypeDropDown = 'dropdown-list/risk-assessment-v1-details/control_type/',
  RiskControlFrequencyDropDown = 'dropdown-list/risk-assessment-v1-details/control_frequency/',
  RiskControlAutomationDropDown = 'dropdown-list/risk-assessment-v1-details/control_automation/',
  RiskControlRatingDropDown = 'dropdown-list/risk-assessment-v1-details/control_rating/',
  RiskControlManagerDropDown = 'user-list/',
  licenseList = 'admin-licencetype-list/',
  filterApiForRiskDashboard = 'get_filters/?request_for=risk-dashboard-new&dynamic_fields=true',
  permissionAccess = 'list-Usermodulepermissions/',
  permissionforAllActions = 'list-collective-actions-permissions/',
  permissionforAllComplianceActions = 'list-collective-compliance-actions-permissions/',
  adminPermissionAccess = 'list-usermodule-administrator-permissions/',
  licenseDetails = 'license-permission-list/',
  adminLicenseDetails = 'license-administrator-permission-list/',
  saveLicense = 'license-permission-update/',
  adminSaveLicense = 'license-administrator-permission-update/',
  controlFilter = 'get_filters/?request_for=risk-assessment-v1-details',
  bcmFilter = 'get_filters/',
  RiskControlOwnerDropDown = 'risk-assessment/risk_owner/list',
  EntityDropDown = 'entity/list/',
  filterErmApi = 'get-filters-erm-assessment/',
  publicEntityDropDown = 'entity/list/public',
  auditModuleList= 'list/',
  RiskControlProcessDropDown = 'dropdown-list/risk-assessment-v1-details/process/',
  RiskControlSubProcessDropDown = 'dropdown-child-list/risk-assessment-v1-details/sub_process/',
  RiskKeyControlDropDown = 'dropdown-list/risk-assessment-v1-details/key_control/',
  RiskControlImplementationEaseDropDown = 'dropdown-list/risk-assessment-v1-details/ease_of_implementation/',
  RiskControlMaintainEaseDropDown = 'dropdown-list/risk-assessment-v1-details/ease_of_maintain/',
  RiskControlImplementMaintainCostDropDown = 'dropdown-list/risk-assessment-v1-details/cost_to_implement_maintain/',
  RiskControlReduceImpactRiskDropDown = 'dropdown-list/risk-assessment-v1-details/reduces_impact/',
  RiskControlReduceLikelihoodRiskDropDown = 'dropdown-list/risk-assessment-v1-details/reduces_likelihood/',
  RiskControlDesignDropDown = 'dropdown-list/risk-assessment-v1-details/control_design_evaluation/',
  RiskCategoryDropDown = 'dropdown-list/risk-assessment/risk_category/',
  RiskSubCategoryDropDown = 'dropdown-list/risk-assessment/subcategory/',
  RiskTreatmentDropDown = 'dropdown-list/risk-assessment/treatment/',
  RiskStatusDropDown = 'dropdown-list/risk-assessment/status/',
  RequestStatusDropDown = 'dropdown-list/request/status/',
  RiskVelocityDropDown = 'dropdown-list/risk-assessment/risk_velocity/',
  RiskVulnerabilityDropDown = 'dropdown-list/risk-assessment/vulnerability/',
  RiskLikelihoodDropDown = 'dropdown-list/risk-assessment/inherent_likelihood/',
  RiskMegaProcessDropdown = 'dropdown-list/risk-assessment/mega_process/',
  AuditMegaProcessDropdown = 'audit/mega-process/list/',
  MegaProcessDropdownForPageLevel = 'mega-major-process/detail_list/',
  riskDepartmentLevels = 'department-level1-level2/list/',
  RiskMajorProcessDropdown = 'dropdown-child-list/risk-assessment/major_process/',
  RiskSubcategoryDropdown = 'dropdown-child-list/risk-assessment/subcategory/',
  GeneratePlanRisksList = 'generate-plan/risk/list/',
  GeneratePlanDepartmentList = 'generate-plan/department/list',
  MegaProcessAudits = 'audit-generate-plan/audit/list',
  DepartmentAudits = 'audit-generate-plan/department/list',
  AuditsStatusSummary = 'audit-generate-plan/summary/list',
  DepartmentAuditsStatusSummary = 'audit-generate-plan/summary/department/list',
  riskProgramTimeEntriesList = 'risk-program/',
  riskProgramTImeEntries = 'filter-charts/',
  // GenerateAuditPlan = 'audit-generate-plan/create?request_for=create-approval',
  GenerateAuditPlan = 'audit-generate-plan/create',
  generateAuditplan = 'audit-universe/model_plan/create/',
  generatePlanAudits = 'generate-audit/universe-audits',
  MegaProcessChart = 'filter-charts/?request_from=app&request_for=risk_assessment&multi_values=false&',
  AuditsListInPlan = 'audit-plan/audits/list',
  RiskProgramListInPlan = 'risk-plan/risk-program/list/',
  AuditsListInPlanApproval = 'audit-plan/audits/list',
  AssessmentTagRiskList = 'assessment/risk/list/',
  AssessmentTagControlList = 'assessment/control/list/',
  AssessmentTagPolicyList = 'assessment/policy/list/',
  AssessmentTagDocsList = 'assessment/',
  riskDashboardList = 'risk-dashboard/risk/list',
  RiskImpactDropDown = 'dropdown-list/risk-assessment/inherent_impact/',
  RiskTypeDropDown = 'dropdown-list/risk-assessment/risk_type/',
  RiskAssessmentDropDown = 'risk-assessment/Survey/tagginglist/',
  RiskScoringQuestions = 'criterias/',
  RiskManager = 'user-list/?request_for=professional',
  ContributorUsers = 'user-list/?request_for=contributor',
  stakeholderUsers = 'stake-holder-list/',
  allStakeholderList = 'stake-holder-list/?request_for=prof_contri_expl',
  entityListForAssessment = 'audit/enity-list/',
  RisktaggedControls = 'risk-assessment/controls/',
  RiskProgramIssue = 'issue-parent/',
  RiskOwner = 'risk-assessment/risk_owner/list',
  RiskIdentifier = 'dropdown-list/risk-assessment/risk_identifier/',
  RiskEdit = 'risk-assessment/header/',
  assessmentPlan = 'erm-assessment-plans/create/',
  EditAssessmentPlan = 'erm-assessment-plans/',
  GetAssessmentPlanDetailsByID = 'erm-assessment-plans/list/',
  policyApproval= 'approval-process/from-definition/create',
  IssueDelete = 'audit-planning/',
  RiskIssues = 'issues-list/',
  RiskControlRating = 'dropdown-list/audit-reporting/rating/',
  RiskRating = 'risk-assessment/rating/',
  Risk = 'risk/',
  ERM= 'erm/',
  RiskDetail = 'risk-assessment/header/detail/',
  RiskDetailUploadList = 'risk-assessment-upload-list/',
  RiskErmDetailUploadList = 'erm-upload-list/',
  RiskMovement = 'risk-movement-chart/',
  ControlMovement = 'control-movement-chart/',
  InherentLikelihoodDropDown = 'dropdown-list/risk-assessment/inherent_likelihood/',
  entityTierList = 'dropdown-list/entities/entity_additional_dropdown_1/',
  ResidualLikelihoodDropDown = 'dropdown-list/risk-assessment/residual_likelihood/',
  DepartmentRatingList = 'dropdown-list/accounts/department_rating',
  InherentImpactDropDown = 'dropdown-list/risk-assessment/inherent_impact/',
  InherentRatingDropDown = 'dropdown-list/risk-assessment/inherent_rating/',
  ResidualRatingDropDown = 'dropdown-list/risk-assessment/residual_rating/',
  EntityRatingDropDown = 'dropdown-list/entities/entity_rating',
  ResidualImpactDropDown = 'dropdown-list/risk-assessment/residual_impact/',
  ActionDetails = 'risk-assessment/tagged-action/',
  riskProgramActivityActionDetails = 'risk-program/activity/action/',
  riskProgramActionDetails = 'risk-program/action/',
  ActionDetailsDynamic = 'audit-planning/action/dynamic-field-list/',
  AuditDetails = 'risk-assessment/tagged_auditobservation/',
  ObservationsMR= 'observation/management-response/',
  ObservationMRTaggedResponse = '/tagged-items-count/',
  FileUploader = 'file-uploader/',
  newFileUpload= 'audit-plan/media/',
  FileUploaderIncident = 'audit-plan/media/',
  FileDelete = 'file-uploader/',
  FileDeleteImprovement = 'file-uploader/',
  AuditPlaningGrid = 'audit-planning/list/',
  riskProgramList = 'risk-program/list/',
  professionalUsers = 'user-list/?request_for=professional',
  professionalContUsers = 'user-list/?request_for=prof_contributor',
  approveAuditCreate = 'approval-system/request-approve/',
  approveAuditEdit = 'approval-system/change-request-approve/',
  adminAuditRequestData = 'approval/request-list/?slug=audit-planning&request_for=audit_planning',
  AuditPendingRequestData = 'approval/rejected/request-list/?slug=audit-planning&request_for=audit_planning',
  AuditPlaningGraph = 'filter-charts/?request_for=audit_planning&request_from=app&platform=web',
  riskProgramPlaningGraph = 'filter-charts/?request_for=risk_program&request_from=app&platform=web&chart_type=risk-program-plan',
  RiskProgramLandingPageGraph = 'filter-charts/?request_for=risk_program&request_from=app&platform=web',
  kriDetailsPageGraph = 'filter-charts/?request_for=kriscore',
  AuditPlaningDetailGraph = 'filter-charts/?request_for=audit_planning&request_from=app&platform=web',
  riskProgramDetailGraph = 'filter-charts/?request_for=risk_program&request_from=app&platform=web&users=otherusers&chart_type=risk-program-detail',
  AuditReviewDetailGraph = 'filter-charts/?request_for=audit_reporting&request_from=app',
  AuditPlanStages = 'audit-planning/detail/',
  AuditPlaningStages = 'audit-schedule-activity-detail/',
  AuditPlaningStagesTimeLog = 'audit-planning-stages/detail/',
  riskProgramStages = 'risk-program-stages/list/',
  complianceStages = 'compliance-stages/list/',
  AuditPlaningApprovalStages = 'audit-planning-stages-v2/detail/',
  BudgetGridDetails = 'audit-budget/list/',
  BudgetGrid = 'audit-budget/activity/list/',
  BudgetGraph = 'filter-charts/?request_for=budget_activity&request_from=app&platform=web',
  getAllObservation = 'audit-reporting/observation/',
  timeSheetGrid = 'time-sheet/grid',
  timeSheetGraph = 'filter-charts/?request_for=time-sheet&request_from=app&platform=web',
  timeSheetDetail = 'time-sheet/list',
  timeSheetCreate = 'time-sheet/create/',
  timeSheet = 'time-sheet/',
  bulkDeleteProject = 'timelog-project/bulk-delete/',
  timeSheetProjectTypeDropdown = 'dropdown-list/time-sheet/project_type/',
  BudgetCategoryDropDown = 'audit-budget/category/list/',
  BudgetSubCategoryDropDown = 'audit-budget/sub_category/',
  BudgetActivityCreate = 'audit-budget/create/',
  BudgetActivityEdit = 'audit-budget/',
  UsersList = 'user-list/',
  assesmentReassign ="re-assign/assessments/",
  designationList = 'designation/list/',
  getAdditionalDropDownV1 = 'dropdown-list/audit-planning/audit_additional_dropdown_v1/',
  getRiskProgramAdditionalDropDownV1 = 'dropdown-list/risk-program/risk_program_dropdown_v1/',
  getAdditionalDropDownV2 = 'dropdown-list/audit-planning/audit_additional_dropdown_v2/',
  getRiskProgramAdditionalDropDownV2 = 'dropdown-list/risk-program/risk_program_dropdown_v2/',
  getAdditionalDropDownV3 = 'dropdown-list/audit-planning/audit_additional_dropdown_v3/',
  getRiskProgramAdditionalDropDownV3 = 'dropdown-list/risk-program/risk_program_dropdown_v3/',
  getAdditionalDropDownV4 = 'dropdown-list/audit-planning/audit_additional_dropdown_v4/',
  getRiskProgramAdditionalDropDownV4 = 'dropdown-list/risk-program/risk_program_dropdown_v4/',
  getAdditionalMultiDropDownV1 = 'dropdown-list/audit-planning/audit_additional_multi_dropdown_v1/',
  getRiskProgramAdditionalMultiDropDownV1 = 'dropdown-list/risk-program/risk_program_multi_dropdown_v1/',
  getAdditionalMultiDropDownV2 = 'dropdown-list/audit-planning/audit_additional_multi_dropdown_v2/',
  getRiskProgramAdditionalMultiDropDownV2 = 'dropdown-list/risk-program/risk_program_multi_dropdown_v2/',
  getAdditionalMultiDropDownV3 = 'dropdown-list/audit-planning/audit_additional_multi_dropdown_v3/',
  getRiskProgramAdditionalMultiDropDownV3 = 'dropdown-list/risk-program/risk_program_multi_dropdown_v3/',
  getAdditionalMultiDropDownV4 = 'dropdown-list/audit-planning/audit_additional_multi_dropdown_v4/',
  getRiskProgramAdditionalMultiDropDownV4 = 'dropdown-list/risk-program/risk_program_multi_dropdown_v4/',
  UserGroupList = 'permission-usergroup-list/',
  adminUserGroupList = 'administrator-permission-usergroup-list/',
  permissionUserList = 'permission-users-list/',
  adminPermissionUserList = 'administrator-permission-users-list/',
  exportBudget_csv = 'audit-budget/budget_details_download_csv',
  CategoryDropDown = 'audit-budget/category/list/',
  countryDropDown = 'dropdown-country/list/',
  budgetBalance = 'audit-budget-category/total-budget/',
  subcategoryBudgetBalance = 'audit-budget-sub-category/total-budget/',
  languageDropdown = 'lookups/language-list/',
  GlobalFilter = 'apply-global-filter/',
  languageselector = 'userprofile-language-update/',
  CreateDropdownItem = 'dropdown/create/',
  GetGlobalFilter = 'applied-global-filter/list/',
  GetApproval = 'approval-process/approver/request-list',
  GetApprovalRequestor = 'approval-process/requestor/request-list',
  GetGroupApproval = 'approval-process/group/approver/request-list',
  GetGroupApprovalRequestor = 'approval-process/group/requestor/request-list',
  CreateApprovalRequest = 'approval-process/from-definition/create',
  Notifications = 'notification/dropdown/list/',
  Notification = 'notification/',
  NotificationMultiple = 'notifications/selected/',
  multipleNotification = 'notifications/',
  NotificationDetail = 'notification-details/',
  ViewNotifications = 'notification/details/',
  UnreadNotifications = 'notification/count/',
  ApprovalNotificationsCount = 'approval-process/count',
  UnreadCount = 'notifications/count/',
  auditReportingGraph = 'filter-charts/?request_for=audit_reporting&request_from=app&users=otherusers&platform=web',
  auditReportingGrid = 'audit-reporting/list/',
  submitRemark = 'action-on-approvals/',
  issueTrackerIssueChartsForRiskProgram = 'filter-charts/?request_for=issue_tracker&request_from=app&chart_type=risk_program_issue_dashboard&platform=web&',
  riskProgramIssueTrackerGraph = 'filter-charts/?request_for=issue_tracker&request_from=app&chart_type=risk_issue_tracker&platform=web',
  auditIssueTrackerGraph = 'filter-charts/?request_for=issue_tracker&request_from=app&chart_type=audit_issue_tracker&platform=web',
  auditIssueTrackerGrid = 'audit-reporting/audit/action/list/',
  assessmentCharts = 'filter-charts/?request_for=survey_v2&request_from=app',
  assessmentRequestCharts = 'filter-charts/?request_for=assessment_request&request_from=app',
  complianceActionIssueTracker = 'filter-charts/?request_for=compliance_audit&request_from=app&chart_type=compliance_action_issue_tracker&platform=web&multi_values=false',
  complianceObservationActionIssueTracker = 'filter-charts/?request_for=compliance_audit&request_from=app&chart_type=compliance_issue_tracker&&platform=web',
  assessmentTemplates = 'assessment-overview/',
  assessmentCreate = 'assessment-template/create/',
  assessmentApproval = 'assessments/approval_process/create/',
  assessmentTemplate = 'assessment-template/',
  assessmentSingleTemplate = 'assessment/template/',
  assessmentTemplateByType = 'survey-type-count/',
  assessmentTemplateTypes = 'surveytype-list/',
  assessmentTemplateView = 'assessment-published-list/',
  assessmentTemplateCategories = 'dropdown-list/survey/survey_category/',
  assessmentlist = 'risk-assessment/tagged_assessment/',
  assessmentDelete = 'risk-assessment/tagged-assessment/',
  assessmentDocumentDelete = 'assessment/document/',
  assessmentSavedDocDelete = 'audit-plan/media/',
  assessmentImportFields = 'get/import-fields/survey/',
  assessmentImport = 'import/survey/',
  riskUniverseImportFields = 'get/import-fields/risk-assessment/',
  risErmImportFields = 'get/import-fields/enterprise-risk/',
  riskUniverseImport = 'import/risk-assessment/',
  riskErmImport = 'import/enterprise-risk/',
  riskControlImportFields = 'get/import-fields/control',
  riskControlImport = 'import/risk-assessment-v1-details/',
  auditPlanningImportFields = 'get/import-fields/audit-planning/',
  auditPlanningImport = 'import/audit-planning/',
  auditPlanningActionImportFields = 'get/import-fields/audit-reporting-action/',
  auditPlanningActionImport = 'import/audit-reporting-action/',
  auditPlanningRecommendationImportFields = 'get/import-fields/audit-reporting-recommendation/',
  auditPlanningRecommendationImport = 'import/audit-reporting-recommendation/',
  auditPlanningObservationImportFields = 'get/import-fields/audit-reporting/',
  auditPlanningObservationImport = 'import/audit-reporting/',
  auditIssue = 'audit-planning/',
  auditIssueTrackerObservationDelete = 'audit-reporting/observation/',
  auditIssueList = 'issues-list/',
  complianceIssueList = 'compliance-audit/',
  complianceIssueEdit = '/issue/edit/',
  complianceIssueDelete = 'compliance-audit/issue/',
  issueListForRiskProgramWorkingPaper = 'risk-program/issues-list/',
  auditRelatedIssueList = 'related-issues-list/',
  issueActions = 'audit-planning/issue/tagged_action/',
  relatedActions = 'audit-reporting/issue/related-actions/',
  relatedRecommendations = 'audit-reporting/issue/related-recommendations/',
  issueControls = 'audit-planning/issue/tagged_control/',
  issueRisks = 'audit-planning/issue/tagged_risk/',
  issueObservations = 'audit-planning/issue/tagged_observation/',
  issueComplianceObservations = 'compliance-audit/issue/tagged_observation/',
  auditProgram = 'auditprogram/',
  auditProgramList = 'audit-program/',
  complianceAuditWorkingPaperList = 'compliance-audit/working-paper/',
  riskProgramWorkingPaperTabList = 'risk-program/working-paper/',
  budgetImportFields = 'get/import-fields/budget-activity/',
  bcmImportFields = 'get/import-fields/business-process/',
  araImportFields = 'audit_analytics/get/import-fields/',
  budgetImport = 'import/budget-activity/',
  araImportData = 'audit_analytics/import/',
  bcmImportData = 'import/business-process/',
  araMajorProcessDropDown = 'audit_analytics/major_process_list/',
  araModelDropDown = 'audit_analytics/major_process_model_list/',
  questionTypeList = 'questiontype-list/',
  userProfile = 'userprofile/',
  membersList = 'team-listing/',
  groupList = 'user-group-detail-list/',
  experienceList = 'experience/list/',
  addUserCertification = 'certification/create/',
  editExperience = 'experience/',
  editUser = 'userprofile/',
  editUserImage = 'userprofile-image/',
  editEducation = 'education/',
  editCertification = 'certification/',
  deleteExperience = 'experience/',
  deleteEducation = 'education/',
  employementType = 'dropdown-list/administrator/employment_type/',
  deleteCertification = 'certification/',
  educationList = 'education/list/',
  certificationList = 'certification/list/',
  membersData = 'userprofile/',
  membersExperience = 'experience/',
  membersEducation = 'education/',
  membersCertification = 'certification/',
  addUserExperience = 'experience/create/',
  addUserEducation = 'education/create/',
  changePassword = 'change-password',
  experienceDetails = 'experience/',
  educationDetails = 'education/',
  certificationDetails = 'certification/',
  AnalyticData = 'risk-assessment/tagged-audit-analytics/',
  observationAnalyticData = 'audit-reporting/tagged-audit-analytics/',
  auditData = 'risk-assessment/tagged-observation/',
  actionData = 'risk-assessment/tagged-action/',
  actionControlData = 'risk-assessmentdetails/tagged-action/',
  riskControlData = 'risk-assessment/controls/',
  controlTaggedData = 'controls/',
  ermControlData = 'erm/controls/',
  ermIncidentData = 'enterprise-risk/',
  riskIncidentData = 'risk-assessment/',
  riskRequestData = 'risk-assessment/',
  controlRequestData =  'control/',
  programRequestData =  'risk-program/',
  riskRequestListDelete = 'request/tagged-risk/',
  controlRequestListDelete = 'request/tagged-control/',
  auditRequestListDelete = 'request/tagged-audit/',
  riskProgramRequestListDelete = 'request/tagged-program/',
  complianceRequestListDelete = 'request/tagged-compliance/',
  policyRequestListDelete = 'request/tagged-policy/',
  bcmRequestListDelete = 'request/tagged-bcm/',
  riskRequestTagDelete = 'risk-assesment/tagged/request/',
  controlRequestTagDelete = 'control/tagged/request/',
  programRequestTagDelete = 'risk-program/tagged/request/',
  complianceRequestTagDelete = 'compliance/tagged/request/',
  policyRequestTagDelete = 'policy/tagged/request/',
  auditRequestTagDelete = 'audit/tagged/request/',
  bcmRequestTagDelete = 'bcm/tagged/request/',
  riskTaggedControl = 'risk-assessment/tagged-control/',
  ermTaggedControl = 'erm/tagged-control/',
  incidentTagged = 'enterprise-risk/tagged/incident/',
  incidentTag = 'risk-assesment/tagged/incident/',
  incidentmainTag = 'risk-assesment/tagged/incident/',
  riskTaggedAuditObservation = 'risk-assessment/tagged_auditobservation/',
  passwordReset = 'auth/password/reset/',
  notificationMarkRead = 'notification/mark_read/',
  historyTable = 'risk-assessment/history/',
  departmentDropdown = 'dropdown/department/list/',
  getBcmAdditionalDropDownV1 = 'dropdown-list/business-process/dropdown_1/',
  getBcmAdditionalDropDownV2 = 'dropdown-list/business-process/dropdown_2/',
  getBcmAdditionalDropDownV3 = 'dropdown-list/business-process/dropdown_3/',
  getBcmAdditionalDropDownV4 = 'dropdown-list/business-process/dropdown_4/',
  getBcmMultiDropDownV1 = 'dropdown-list/business-process/multi_dropdown_1/',
  getBcmMultiDropDownV2 = 'dropdown-list/business-process/multi_dropdown_2/',
  getBcmMultiDropDownV3 = 'dropdown-list/business-process/multi_dropdown_3/',
  getBcmMultiDropDownV4 = 'dropdown-list/business-process/multi_dropdown_4/',
  actionDepartmentDropdown = 'dropdown/department/list/',
  getBcmDisasterDropDownV1 = 'dropdown-list/business-process/recovery_dropdown_1/',
  getBcmDisasterDropDownV2 = 'dropdown-list/business-process/recovery_dropdown_2/',
  getBcmDisasterDropDownV3 = 'dropdown-list/business-process/recovery_dropdown_3/',
  getBcmDisasterDropDownV4 = 'dropdown-list/business-process/recovery_dropdown_4/',
  getBcmDisasterDropDownV5 = 'dropdown-list/business-process/recovery_dropdown_5/',
  getBcmMultiDisasterDropDownV1 = 'dropdown-list/business-process/recovery_multi_dropdown_1/',
  getBcmMultiDisasterDropDownV2 = 'dropdown-list/business-process/recovery_multi_dropdown_2/',
  getBcmMultiDisasterDropDownV3 = 'dropdown-list/business-process/recovery_multi_dropdown_3/',
  getBcmMultiDisasterDropDownV4 = 'dropdown-list/business-process/recovery_multi_dropdown_4/',
  getBcmMultiDisasterDropDownV5 = 'dropdown-list/business-process/recovery_multi_dropdown_5/',
  actionStatusDropdown = 'dropdown-list/audit-reporting-action/action_status/',
  actionPriorityDropdown = 'dropdown-list/audit-reporting-action/action_priority/',
  actionDetailsCreate = 'risk-assessment/tagged-action/',
  actionDetailsEdit = 'risk-assessment/tagged-action/',
  incidentActionDetailCreate = 'incident-reporting/tagged-action/',
  RiskCommentsList = 'risk/comments/',
  Comments = 'comments/',
  reviewComments = 'comments/',
  getRiskControlList = 'risk-assessment/tagging-control/add-list/',
  getRiskList = 'risk-assessment/details/tagging-risk/add-list/',
  getTagRiskList = 'audit-risk/tagging-list/?',
  getTagKriProcessList = 'kri/tagging_business_process/add-list/',
  fetchControlsForRiskKri = 'kri/risk-list-from-control/?risk_field=',
  fetchRisksForControlKri= 'kri/risk-list-from-control/?risk_field=&request_for=risk',
  fetchControlsForRisk = 'risk-assessment/control-list-from-risk/',
  fetchRisksForControl = 'risk-assessment/risk-list-from-control/',
  addTaggedIssueList = 'audit-reporting/auditobservation/issue/',
  addTaggedWpList = 'audit-reporting/auditobservation/working_paper/',
  tagWp = 'compliance-observation/working_paper/',
  deleteWp = 'compliance-observation/working_paper/',
  addTaggedControlList = 'audit-reporting/auditobservation/control/',
  addTaggedRiskList = 'audit-reporting/auditobservation/risk/',
  getTagControlList = 'audit-control/tagging-list/',
  getTagAssessmentList = 'risk-assessment/Survey/tagginglist/many-entity/?type=audit',
  getAuditTagAssessmentList = 'audit-assessment/Survey/tagginglist/many-entity/?type=audit',
  getRiskProgramTagAssessmentList = 'risk-program/assessment/tagginglist/many-entity/?type=risk program',
  getKriTagAssessmentList = 'kri/Survey/tagginglist/many-entity/?type=KRI',
  riskControlAssessmentList = 'risk-assessment/details/tagged_assessment/',
  riskAssessmentDelete = 'control/tagged-assessment/',
  kriAssessmentDelete = 'kri/tagging-assessments/',
  riskControlDocumentList = 'control-upload-list/',
  getKriAssessmentTagList = 'kri/Survey/tagginglist/many-entity/',
  riskControlList = 'risk-assessment/details/tagged-risk/',
  riskControlAdd = 'risk-assessment/',
  ermControlAdd = 'erm-risk-assessment/',
  IncidentAdd = 'enterprise-risk/',
  riskContolDelete = 'risk-assessment/tagged-risk/',
  actionControldata = 'risk-assessment/details/action/',
  createActionControl = 'risk-assessmentdetails/tagged-action/',
  createActionKri = 'kri/tagged-action/',
  auditControlData = 'risk-assessment/details/tagged_auditobservation/',
  auditControlDelete = 'control/tagged-observation/',
  auditTagAdd = 'risk-assessment/',
  auditTaggedData = 'controls/',
  riskNetwork = 'risk-assessment/',
  riskAssesment = 'risk-assessment/',
  tagRiskDependency = 'assessment/tagging-risk/',
  tagControlDependency = 'assessment/tagging-control/',
  tagPolicyDependency = 'assessment/tagging-policy/',
  tagDocumentDependency = 'assessment/document/',
  removeDocumentDependency = 'assessment/media/',
  editDocumentDependency = 'assessment/media/',
  riskControls = 'risk-assessment-details/',
  tagAssesment = '/tag/assessment',
  takeAssesment = 'assessment-task/attend/',
  tagAssessmentControl = 'risk-assessment/details/add/',
  tagAssessmentAuditObservation = 'audit-reporting/observation-assessment/',
  viewAssessment = 'assessment-published-list/',
  getAssessmentLiveScore = 'assessment-task/attend/live-score/',
  getAuditAssessmentLiveScore = 'assessment/',
  getAssessmentScore = 'assessment-task/attend/score/?',
  getConsolidatedObs = 'compliance-audit/',
  getConsolidatedObsAuditAssessment = 'working-paper/',
  getAuditTaggedList = 'risk-assessment/details/tagging-observation/add-list/',
  getRiskProgramObservation = 'risk-program/details/tagging-observation/add-list/',
  getRiskProgramObservationCompliance = 'compliance-audit/risk-program-observation/add-list/',
  riskErmExportCSV = '/enterprise-risk/erm_download_csv',
  riskExportCSV = '/risk-assessment/risk_download_csv',
  riskExportXLS = '/risk-assessment/risk_download_xls',
  riskErmExportXLS = '/enterprise-risk/erm_download_xls',
  auditReportPdf = '/audit-reporting/generate-report/',
  controlExportCSV = '/risk-assessment/download_csv_risk_control',
  controlExportXLS = '/risk-assessment/download_xls_risk_control',
  auditPlaningExportCSV = '/audit-planning/risk_download_csv',
  auditPlaningExportXLS = '/audit-planning/risk_download_xls',
  auditPlaningExport = 'audit-management/audit-export',
  editList = '/Edit-list/',
  analyticsBuilderTableList = 'analytics-builder/table/list/?object_id=',
  analyticsBuilderPreviewList = 'analytics-builder/preview/list/',
  analyticsBuilderFieldNameList = 'analytics-builder/field_name/list/',
  analyticsBuilderFieldNameListByQuery = 'analytics-builder/field_name/query/list/',
  analyticsBuilderValidateList = 'analytics-builder/validatelist/list/',
  analyticsBuilderDataCopy = 'analytics-builder/datacopy/',
  analyticsBuilderConnectionHistoryList = 'analytics-builder/druid/connection/list',
  analyticsSchedulecharts = 'analytics-builder/schedule-charts',
  analyticsBuilderProcurementList = 'analytics-builder/druid/table/list/',
  analyticsBuilderFieldList = 'analytics-builder/druid/selected_fields_list/?connection=',
  analyticsBuilderFunctionList = 'analytics-builder/calculated-field/functions/',
  analyticsBuilderSaveCalculatedFeild = 'analytics-builder/druid/create/calculated-field/',
  analyticsSummaryList = 'audit-analytics/scenario/',
  analyticsDataFlush = 'analytics-builder/',
  analyticsDruidDataFlush= 'analytics-builder/druid/datasource/delete/flush/view/',
  analyticsDataImportAfterFlush = 'analytics-builder/druid/dataimport/field-mapping/',
  analyticsDataUpdateAfterFlush = 'analytics-builder/druid/dataimport/',
  analyticsDynamicExceptionStatus = 'analytics-builder/dynamic-exception-status',
  createConnections = 'analytics-builder/create/connection/',
  listEstablishedConnections = 'analytics-builder/established/connection/list/?conn_type=',
  deleteConnection = 'analytics-builder/',
  editConnection = 'analytics-builder/connection/',
  saveScenario = 'analytics-builder/druid/save_scenario/',
  editScenario = 'analytics-builder/druid/',
  majorProcessList = 'analytics-builder/megaprocess/list/?process=',
  processList = 'audit_analytics/process_list/',
  subProcessList = 'audit_analytics/sub_process_list/',
  analyticsScheduleEdit = 'analytics-builder/schedule/',
  analyticsJobDetails = 'analytics-builder/job/log/list/',
  analyticsScenarioDetails = 'analytics-builder/scenario_list/',
  compilanceoverviewform = 'compliance-detail/',
  AllComplianGrisList = 'compliance-tagged-assesments/',
  compilanceFileUploader = 'file-uploader/',
  policyDocumentUploader = 'media/',
  documentEdit = 'audit-plan/media/',
  requestFileUploader = 'file-uploader/',
  requestNewFileUploader = 'media/',
  compilancecomments = 'compliance-comments/',
  compilancecommentsdelete = 'comments-compliance-delete/',
  compilancecommentsedit = 'comments-compliance-edit/',
  compliancedocument = 'compliance-document-upload-list/',
  compliancassessemtsdelete = 'compliance-tagged-assesments-delete/',
  complianceActionGrid = 'action-compliance/list/',
  complianceAuditGrid = 'compliance-audit/list/',
  complianceActionCreate = 'compliance-action-create/',
  complianceActionEdit = 'compliance-action-edit/',
  complianceActionStatusDropdown = 'dropdown-list/compliance/compliance_status/',
  complianceActionPriorityDropdown = 'dropdown-list/compliance-action/compliance_action_priority/',
  complianceActionStakeholderDropdown = 'user-list/?request_for=contributor',
  complianceExportCSV = '/compliance/compliance/csv_download',
  complianceExportXLS = '/compliance/compliance/xls_download',
  complianceActionStatus = 'dropdown-list/compliance-audit-action/compliance_audit_action_status/',
  compliancepagefilter = 'get_filters/?request_for=compliance',
  ContributorHomeCharts = 'filter-charts/',
  ContributorHomeCounts = 'contributor/observation/dashboard-count/',
  ContributorRecentActivities = 'contributor/recent-activities/list',
  ContributorAllObservations = 'contributor/management-response/list/',
  ContributorAllImprovements = 'contributor/improvement/list/',
  ContributorAllIssues = 'contributor/issues-list/',
  ContributorActionsTable = 'contributor/action/list/',
  ContributorAllRecommendations = 'contributor/recommendation/list/',
  ContributorActionsDeatils = 'contributor/action/',
  ContributorManagementStatus = 'contributor-management-response/list/',
  contributorImpMrList = 'contributor/improvement/management-response/list/',
  complianceManagementResponseList = 'compliance-management-response/all-list/',
  rpManagementResponseList = 'risk-contributor-management-response/list/',
  auditManagementResponseList = 'audit-contributor-management-response/list/',
  analyticsManagementResponseList = 'analytics-builder/scenario/management-response/contributor/all-list/',
  statusGraph = 'filter-charts/?request_from=app&request_for=audit_reporting&platform=web&users=contributor',
  ContributorHistoryTable = 'history/',
  ContributorObservationsDetails = 'contributor/observation/',
  ContributorIssuesDetails = 'contributor/issue/',
  ContributorRecommendationsDetails = 'contributor/recommendation/',
  ContributorDashboard = 'filter-dashboard/',
  IncidentDashboard = 'filter-dashboard/',
  ContributorComments = 'contributer-comments/',
  ContributorCommentsGet = 'contributor/comments/',
  auditActivityList = 'audit_activity_task/',
  riskProgramMailList = 'program-notification/email/',
  complianceMail = 'compliacne-audit-notification/email/',
  ContributorSendRemainder = 'contributor/action/sendremainder/',
  ContributorStatusDropdown = 'contributor/status-dropdown/',
  auditObservation = 'audit-reporting/observation/',
  complianceObservationMR = 'compliance-audit/observation/',
  riskObservationDetails = 'risk-program/observation/',
  taggableObservation = 'audit-reporting/',
  taggableObservationRiskProgram = 'risk-program/',
  taggableObservationCompliance ='compliance-management/',
  taggableObservationComplianceProgram ='compliance-management/',
  taggedObservationWorkingPaper = 'working-paper/observation/tagged-observation/',
  taggedObservationRiskProgramWorkingPaper = 'risk-program/working-paper/',
  auditObservationControlDynamic = 'audit/risk-control/dynamic-field/header/list/',
  riskProgramWorkingPaperDynamicHeader = 'audit/risk-control/dynamic-field/header/list/?request_for=risk-program-working-paper',
  auditObservationControlCreate = 'audit/risk-control/dynamic-field/header/list/',
  auditObservationEditDetail = 'approval-system/observation/',
  auditTimeEntriesList = 'audit/',
  timeGraph = 'filter-charts/?request_for=time-sheet&platform=web',
  auditTimeEntriesGraph = 'filter-charts/',
  auditActionList = 'audit-planning/action/',
  kriActionList = 'kri/details/action/',
  riskProgramActionList = 'risk-program/',
  auditReviewIssueList = 'issues-list/',
  auditActionDelete = 'audit/action/',
  observationActionCreate = 'audit-reporting/action/',
  improvementActionCreateAndList = 'audit-reporting/improvement/action/',
  riskProgramActionCreate = 'risk-program/observation/',
  observationActionData = 'audit-reporting/action/',
  AuditPlaningRecommendations = '',
  AuditObservationGrid = 'audit-planning/add-audit-observation/list',
  riskProgramObservationGrid  = 'observation-tagged/risk-program/list',
  auditOverview = 'audit-planning/detail/',
  riskProgramDetails = 'risk-program/detail/',
  auditAllCounts = 'approval-system/dependency/count/?slug=audit-planning&request_for=audit_planning',
  auditAllCountsDraft = 'global/draft-count/?slug=audit-planning',
  riskProgramDraftCounts = 'global/draft-count/?slug=risk-program',
  auditReviewOverview = 'audit-planning/detail/',
  auditeeList = '',
  auditDetailsData = 'audit-planning/audit-details/',
  auditOverviewEdit = 'audit-planning/audit-overview/',
  auditSheduleEdit = 'audit/overview-schedule/',
  auditAllTaggedList = 'audit/team/list/',
  auditOwningGroupList = 'audit/team/',
  auditOwningData = 'owning-group/',
  auditTaggedRiskList = 'audit/details/tagged-risk/',
  riskProgramTaggedRiskList = 'risk-program/details/tagged-risk/',
  taggedRiskKri = 'kri-risk/details/tagged-risk/',
  taggedControlKri = 'kri-control/details/tagged-control/',
  taggedKriProcess = 'kri/tagged-business-process/details/',
  auditApprovalTaggedRiskList = 'audit/details/tagged-risk/',
  auditApprovalTaggedControlList = 'audit/details/tagged-control/',
  auditApprovalTaggedProgramList = 'audit-program/',
  auditApprovalTaggedAssessmentList = 'audit-reporting/tagged_assessment/',
  // auditRiskLists='audit-planning/risk-tagging/',
  auditTaggedControlList = 'audit/details/tagged-control/',
  riskProgramTaggedControlList = 'risk-program/details/tagged-control/',
  auditRiskTag = 'audit-planning/',
  riskProgram = 'risk-program/',
  complianceAudit ='compliance-audit/',
  complianceRiskProgram='compliance-audit/',
  riskProgramTaggedAuditObservation = '',
  allCompliance = 'risk-program/compliance-audit/add-list/',
  allRiskProgramList = 'risk-program/compliance-audit/add-list/',
  allRiskProgramAuditList = 'audit-planning/add-audit-observation/list',
  auditRiskProgramTagList = 'risk-program/tagged_auditobservation/',
  allRiskProgram = 'observation-tagged/risk-program/list',
  auditTeamtag = 'audit/user-team/',
  auditTaggedRiskDelete = 'audit/tagged-risk/',
  auditControlList = 'audit-planning/tagging_control/',
  auditTaggedControlDelete = 'audit/tagged-control/',
  auditTeamDelete = 'audit/management-team/',
  auditObservationAssessmentDelete = 'audit-reporting/observation-tagged-assessment/',
  auditTaggedAssessmentList = 'audit-reporting/observation-tagged_assessment/',
  riskProgramObservationTaggedAssessmentList = 'risk-program/observation/tagged_assessment/',
  riskProgramObservationTaggedAssessment = 'risk-program/observation-tagged-assessment/',
  auditTaggedAssessmentListApproval = 'audit-reporting/observation/tagged_assessment/',
  history = 'history/',
  newHistory = 'history-changes/',
  requestNewHistory = 'request/history-changes/',
  incidentNewHistory = 'history-changes/',
  auditAssessmentsList = 'audit-reporting/tagged_assessment/',
  auditorsComment='observation/management-response/auditors-comment/',
  auditorComment='compliance-management/management-response/auditors-comment/',
  auditorCommentRisk='risk-program/management-response/auditors-comment/',
  auditorDropdown = 'dropdown-list/compliance-audit-management-response/auditors_comment_status/',
  auditorCommentCategory='dropdown-list/compliance-audit-management-response/auditors_comment_category/',
  AuditorsCommentType='dropdown-list/compliance-audit-management-response/auditors_comment_type/',
  auditRiskList = 'audit-reporting/tagged_risk/',
  planGraphs = 'filter-charts/?request_from=app&request_for=audit_planning&multi_values=false&user=other_users&platform=web&',
  auditPlanList = 'auditplanmaster/list',
  auditUniverseList = 'audit-universe/list',
  workflow = 'work-flow/',
  workflowList = 'work-flow/list',
  workflowHistory = 'work-flow-history/',
  riskPlanList = 'risk-program-plan/list/',
  auditRiskLists = 'audit-planning/risk-tagging/',
  auditPlan = 'auditplan-list/',
  auditUniverse = 'audit-universe/',
  auditUniverseV2 = 'audit-universe/universe-table/detail/',
  riskPlan = 'risk-program-plan/detail/',
  countData = 'audit-plan/',
  reportGenerate = '/audit-reporting/generate-report/',
  auditPlanDelete = 'audit-plan/',
  riskPlanDelete = 'risk-program-plan/',
  riskProgramPlanEdit = 'risk-program-plan/',
  auditPlanEditOpenEditApproval = 'approval/edit/reopen/V2/',
  auditPlanCreate = 'audit-plan/create/',
  auditCreate = 'audit/create/v5/',
  complianceCreate = 'compliance-management/compliance-audit/create/',
  complianceEdit = 'compliance-management/compliance-audit/edit/',
  riskProgramCreate = 'risk-program/create/',
  newriskProgramCreate = "risk-program/create/v6/",
  auditDelete = 'audit/',
  riskProgramDelete = 'risk-program/',
  auditDraftDelete = 'audit/',
  audit = 'audit-v2/',
  auditEdit = 'audit/',
  riskProgramEdit = 'risk-program/',
  riskProgramNewEdit = 'risk-program-v6/',
  approveOrReject = 'approval-system/request-approve/',
  auditRequestDelete = 'audit-v2/',
  auditRisksList = 'audit-reporting/tag_risk/',
  auditCreation = 'audit-v2/create/',
  auditClone = 'audit-clone/',
  compliancePlanClone = 'compliance-plan-clone/',
  complianceProgramClone = 'compliance-program-clone/',
  duplicateObservation = 'observation-clone/',
  strategyClone = 'business-strategy-clone/',
  riskClone = 'risk-clone/',
  controlClone = 'control-clone/',
  auditObservationControl = 'audit-reporting/tag-control/',
  auditReviewDelete = 'audit/',
  userDependency = 'auditstages/check-user-audit/',
  auditDocumentList = 'audit-report-upload-list/',
  auditExportCSV = '/audit-planing/audit_download_csv',
  auditExportXLS = '/audit-planing/audit_download_xls',
  auditReportExportCSV = '/audit-reporting/audit-reporting/csv_download',
  auditReportExportXLS = '/audit-reporting/audit-reporting/xls_download',
  consolidatedReportExportCSV = '/consolidated-dashboard/download/csv',
  consolidatedReportExportXLS = '/consolidated-dashboard/download/xls',
  auditIssueTrackerCSV = '/audit-reporting/issue-tracker/csv_download',
  auditIssueTrackerXLS = '/audit-reporting/issue-tracker/xls_download',
  tagedcomplaince = 'compliance/',
  complianceactiondetailsbyid = 'compliance/action/detail/',
  complianceactioncomments = 'comments-create-compliance-action/',
  complianceactioneditcomments = 'compliance-action-comments-edit/',
  complianceactiondeletecomments = 'comments-compliance-action-delete/',
  complianceactionlistcomments = 'compliance-action-comments-list/',
  auditStatus = 'dropdown-list/audit-planning/status/',
  riskFilterApi = 'get_filters/?request_for=risk-assessment',
  allListApi = 'risk-audit-data-list/',
  filterApi = 'get_filters/?request_for=audit-planning',
  filterApiComplaince = 'get_filters/?request_for=compliance-audit',
  filterApiRiskProgram = 'get_filters/?request_for=risk-program',
  auditAreas = 'dropdown-list/audit-planning/areas_audit/',
  auditManager = 'dropdown-list/audit-planning/audit_manager/',
  methodOfAudit = 'dropdown-list/audit-planning/method_of_audit/',
  methodOfAuditRiskProgram = 'dropdown-list/risk-program/method_of_risk_program/',
  universeDropdown = 'audit-universe/dropdown/list',
  universeModelDropdown = 'audit-plan-model/dropdown/list',
  sectorList = 'admin-sector-list/',
  requestedBy = 'dropdown-list/audit-planning/requested_by/',
  riskProgramRequestedBy = 'dropdown-list/risk-program/risk_program_requested_by/',
  teamAuditeeTaggedList = 'audit/auditee/list/',
  auditManagementTaggedList = 'audit/management-team/list/',
  auditTeamList = 'audit/user-add/list/',
  complianceCategoryDropdown = 'dropdown-list/compliance/compliance_category/',
  complianceOccuranceDropdown = 'dropdown-list/compliance/compliance_occurance/',
  complianceStakeholderDropdown = 'user-list/',
  complianceAssigneeDropdown = 'user-list/',
  createCompliance = 'compliance-create/',
  complianceDetails = 'compliance-detail/',
  editCompliance = 'compliance-edit/',
  complianceUploadDocument = 'file-uploader/',
  complianceDelete = 'compliance-delete/',
  compliance_chart = 'filter-charts/',
  auditRecommendationList = 'audit-reporting/recommendation/',
  riskProgramObservationRecommendationList = 'risk-program/recommendation/',
  editObservationAction = 'audit-reporting/action/',
  observationActionList = 'audit-reporting/action/',
  incidentTypeList = 'incident-reporting/type/list/',
  incidenPublicTypeList = 'incident-reporting/public/type/list',
  incidentTabList = 'incident-reporting/tab/list/',
  incidentSectionList = 'incident-reporting/section/',
  incidentFieldList = 'incident-reporting/field/',
  incidentTaggedRisk = 'incident-reporting/tagging-risk/',
  incidentTagRiskProgram = 'incident-reporting/tagging-risk-program/',
  incidentTaggedAudit = 'incident-reporting/tagging-audit/',
  incidentTaggedBCM = 'incident-reporting/tagged-business-process/',
  incidentTaggedControl = 'incident-reporting/tagging-control/',
  incidentTaggedAssessment = 'incident-reporting/tagging-assessment/',
  auditObservationGraph = 'filter-charts/',
  contributorCommentDelete = 'auidt-report/',
  allIncidentGrid = 'incident-reporting/incident/list/',
  AuditIssuetrackerSendRemainder = 'contributor/action/sendremainder/',
  auditAction = 'audit-reporting/action/',
  auditNetwork = 'audit/audit-network/',
  observationManager = 'user/user-group-list?request_for=contributor',
  observationArea = 'dropdown-list/audit-reporting/audit_area/',
  observationAreas = 'dropdown-list/audit-planning/areas_audit/',
  issueAreas = 'dropdown-list/audit-planning/areas_audit/?request_for=issue&',
  observationStatus = 'dropdown-list/audit-reporting/observation_status/',
  observationCompStatus = 'dropdown-list/compliance-audit/compliance_observation_status/',
  issueNewFields = 'risk-assessment/data/',
  auditNewField = 'audit-issue/data/',
  complianceNewField = 'compliance-issue/data/',
  complianceEntityField = 'compliance-audit/entity/',
  issueNewFieldsForControl = 'risk-assessment/details/parent/',
  responseStatus = 'dropdown-list/management-response/audit-reporting/management_status/',
  responseStatusRiskProgram = 'dropdown-list/audit-reporting/management_status/',
  issueStatus = 'dropdown-list/audit-planning/issue_status/',
  issueRating = 'dropdown-list/audit-planning/issue_rating/',
  complianceIssueRating = 'dropdown-list/compliance-audit/compliance_audit_issue_rating/',
  complianceIssueStatus = 'dropdown-list/compliance-audit/compliance_audit_issue_status/',
  complianceIssueDropdown1 = 'dropdown-list/compliance-audit/compliance_issue_dropdown_v1/',
  complianceIssueDropdown2 = 'dropdown-list/compliance-audit/compliance_issue_dropdown_v2/',
  complianceIssueMultiDropdown1 = 'dropdown-list/compliance-audit/compliance_issue_multi_dropdown_v1/',
  complianceIssueMultiDropdown2 = 'dropdown-list/compliance-audit/compliance_issue_multi_dropdown_v2/',
  complianceWorkingPaper ='compliance-audit/working_paper/',
  issuesStatus = 'approval/',
  observationRating = 'dropdown-list/audit-reporting/rating/',
  ImprovementRating = 'dropdown-list/audit-reporting/improvement_rating',
  audditTeamList = 'audit/team/list/?request_for=',
  observationDepartment = 'dropdown/department/list/',
  observationReviewer = 'audit/team/list/',
  observationCreate = 'audit-reporting/observation/',
  riskProgramObservation = 'risk-program/observation/',
  riskProgramobservationCreate = 'risk-program/observation/',
  deleteObservationForWorkingPaper = 'observation/untag-observation/',
  workingPaperCreate = 'workingpaper/',
  complianceriskWorkingPaperCreate = 'compliance-audit/workingpaper/',
  riskProgramWorkingPaperCreate = 'risk-program/workingpaper/',
  riskWorkingPaperCreate = 'workingpaper/',
  auditCalendar = 'audit/calender/',
  riskProgramCalendar = 'risk-program/calender/list/',
  complianceassessmentTemplates = 'compliance-tagged-assesments/',
  complianceActionDelete = 'compliance/action/',
  recommendationDepartmentDropdown = 'dropdown/department/list/',
  activityStatusDropdown = 'dropdown-list/audit-planning/activity_status/',
  activityStakeholderDropdown = 'user-list/',
  recommendationStatusDropdown = 'dropdown-list/audit-reporting-recommendation/recommendation_status/',
  recommendationPriorityDropdown = 'dropdown-list/audit-reporting-recommendation/recommendation_priority/',
  recommendationstakeholderDropdown = 'user-list/',
  reviewerList = 'audit/team/list/',
  reviewerDropdown = 'audit/team/list/',
  activityList = 'audit-program-activity/list',
  activityListRiskProgram = 'risk-program-activity/list/',
  activitynewRiskProgramlist = 'risk-program-list-activity/list/',
  recommendationDetailsCreate = 'audit-reporting/recommendation/',
  recommendationRiskProgram = 'risk-program/recommendation/',
  observationRecommendationData = 'audit-reporting/recommendation/',
  observationMrTaggedRecommendationData ='observation/management-response/tagged-recommendation/',
  complianceMrTaggedRecommendationData ='compliance-observation/management-response/',
  editRecommendation = 'audit-reporting/recommendation/',
  recommendatioCreateReopen = 'audit-reporting/recommendation/',
  recommendationEditReopen = 'approval/edit/reopen/V2/',
  recommendatioEditReopenDetail = 'approval-system/observation-recommendation/',
  recommendationDetails = 'audit-reporting/recommendation/',
  observationControlsList = 'audit-reporting/tagged_control/',
  observationRiskListData = 'audit-reporting/tag_risk/',
  observationRiskList = 'audit-risk/tagging-list/',
  getObservationControl = 'audit-reporting/tagging_control/',
  getObservationControlApproval = 'audit-reporting/tagged_control/',
  getObservationRiskApproval = 'audit-reporting/tagged_risk/',
  observationTaggedIssueList = 'audit-reporting/tagged_issue/',
  observationTaggedIssueListApproval = 'audit-reporting/observation/tagged_issue/',
  workingPaperList = 'observation-tagged-working-paper/',
  workingPaperObsList = 'compliance-observation-tagged-working-paper/',
  workingPaperControlList = 'compliance-observation-tagged-control/',
  workingPaperRiskList = 'compliance-observation-tagged-risk/',
  relatedObservations = 'working-paper/observation/related-observation/',
  workingPaperListCompliance ='compliance-observation/',
  workingPaperTagList='compliance-observation/',
  taggedObservationComplianceProgramWorkingPaper='compliance-management/working-paper/',
  riskProgramTaggedWorkingPaperList = 'risk-program/observation-tagged-working-paper/',
  taggableWorkingPaper = 'audit-reporting/',
  recommendationTaggedIssueList = 'audit-reporting/recommendation/related_issue/',
  actionRelatedIssueList = 'audit-reporting/action/related_issue/',
  actionRelatedIssueListRiskProgram = 'risk-program/action/related_issue/',
  recommendationRelatedIssueListRiskProgram = 'risk-program/recommendation/related_issue/',
  sendActivityMail = 'audit_activity_email/',
  sendRiskProgramMail = 'program-notification/email/',
  sendReportEmail = 'digital-report/email/sent/',
  createActivityTask = 'audit_activity_task/',
  createActivityEvent = 'audit_activity_event/',
  mrAddModal='observation/management-response/',
  tagObservationControl = 'audit-reporting/',
  tagObservationIssueRiskProgram = '',
  auditAssessment = 'audit-reporting/',
  auditAssessmentSchedule = 'audit-schedule/',
  complianceAssessmentSchedule = 'compliance-audit-schedule/',
  kriTagAnAssessment = 'kri/tagging-assessments/',
  complianceAuditAssessmentSchedule = 'compliance-audit-schedule/',
  auditAssessmentDelete = 'audit-reporting/tagged-assessment/',
  auditAssessmentList = 'audit-reporting/tagged_assessment/',
  riskProgramAssessmentList  = 'risk-program/tagged_assessment/',
  kriAssessmentList  = 'kri/tagged_assessment/details/',
  riskProgramTaggedAssessmentList = 'risk-program/tagged-assessment/',
  analyticsBuilderCalculatedFieldsList = 'analytics-builder/druid/calculated_fields_list/?connection=',
  editAnalyticsBuilderCalculatedFieldsList = 'analytics-builder/save_calcfield/',
  editInherentScoring = 'scoring-model/',
  analyticsBuilderRelationInfoList = 'analytics-builder/relationinfo/list/',
  analyticsBuilderRelationGraph = 'analytics-builder/relation/list',
  analyticsBuilderRelationTableFieldList = 'analytics-builder/relationinfo/fields/list/?object_id=',
  analyticsBuilderCreateRelationTable = 'analytics-builder/create/relation/?relation_object_id=',
  analyticsBuilderCreateRelation = 'analytics-builder/create/relation/?relation_object_id=',
  analyticsBuilderProcurementWithConnectionFieldList = 'analytics-builder/dynamicwithconnection/field/list/?connection_history=',
  analyticsJobsList = 'analytics-builder/job/log/list/',
  analyticsWorkFlowList = 'analytics-builder/work-flow-builder/connection/list',
  analyticsScheduleList = 'analytics-builder/connectionhistory/',
  listAuditAnalytics = 'audit-analytic/exceptions/druid',
  analyticsRiskRating = 'dropdown-list/analytics-scenario/analytics_risk_rating/',
  analyticsCriticalityRating = 'dropdown-list/analytics-scenario/analytics_criticality_rating/',
  analyticsEffectiveness = 'dropdown-list/analytics-scenario/analytics_effectiveness/',
  analyticsManager = 'user-list/',
  analyticsScenarioList = 'analytics-builder/scenario_list/',
  analyticsScheduleDetails = 'analytics-builder/connectionhistory/',
  analyticsScenarioDetail = 'analytics-builder/scenario_edit_detail/',
  analyticsSearchFileCloud = 'analytics-builder/file/fetching',
  editExceptionStatus = 'audit-analytic/exceptions/',
  auditObservations = 'audit-reporting/observation/',
  auditObservationDynamic = 'audit-observation/',
  auditObservationsEditOpen = 'approval/edit/reopen/V2/',
  observation = 'observation/',
  improvementTagCount = 'audit-reporting/improvement/',
  recommendation = 'recommendation/',
  action = 'action/',
  auditActions = 'audit-reporting/action/',
  issue = 'issue/',
  issueCompliance='compliance-audit/issue/',
  activity = 'activity/',
  auditProgramWorkingPaper = 'auditprogram-workingpaper/',
  riskProgramWorkingPaperCount = 'risk-program/workingpaper/',
  auditProgramDependencyCount = 'audit-working-paper/assessment/',
  auditProgramDependencyCountMainTable = 'working-paper/assessment/',
  assessmentDependencyCount = 'assessment/question/',
  assessmentConsolidatedDependencyCount = 'assessment/',
  assessmentRequestExport = 'assessment-request/export/',
  assessmentDetailExport = 'assessment/details/export/',
  assessmentTemplateExport = 'assessment-template/export/',
  assessmentTableExport = 'assessment/table/export/',
  workingPaperIssue = 'working-paper-issue/',
  auditRecommendation = 'audit-planning/recommendation/',
  riskProgramRecommendation = 'risk-program/observation/recommendation/',
  editAuditAction = 'audit-reporting/action/',
  editAuditActivity = 'audit-program-activity/',
  getAuditscheduleDetails = 'audit-schedule-activity-detail/',
  getComplianceAuditscheduleDetails = 'compliance-audit-schedule-activity-detail/',
  getAuditscheduledynamicHeaders = 'audit-schedule-dynamic-fields/',
  getCompliancescheduledynamicHeaders = 'compliance-audit-schedule-dynamic-fields/',
  scheduleStatusChange = 'audit-planning-stages/status-update/',
  scheduleStatusChangeComplianceAudit = 'compliance-audit-stages/status-update/',
  scheduleStatusChangeRiskProgram = 'risk-program-stages/status-update/',
  tableAskAuraa = 'single-list-filter-api-list/',
  unassignedIncidentGrid = 'incident-reporting/incident/unassigned-list/',
  incidentOverviewList = 'incident-reporting/incident/details/',
  incidentSort = 'dropdown-list/incident-reporting/',
  bcpSort = 'dropdown-list/business-continuity-plan/',
  incidentRatingDropDown = 'dropdown-list/incident-reporting/incident_rating/',
  incidentRatingList = 'dropdown-list-v2/incident-reporting/incident_rating/',
  incidentOverViewGraph = 'filter-charts/?request_for=incident_reporting&request_from=app',
  incidentOverDeleteComments = 'incident-reporting/comments/',
  incidentOverViewAllComment = 'incident-reporting/contributor-comments/',
  incidentOverCreateComment = 'incident-reporting/comments/',
  incidentReportingGraph = 'filter-charts/',
  incidentCategoryDropDown = 'incident-reporting/type/list/',
  recommendationDropdowns = 'dropdown-list/',
  actionDropdowns = 'dropdown-list/',
  managementResponseCount = 'compliance/management-response-count/',
  actionStatusDrop = 'contributor/status-dropdown/audit-reporting-action/action_status/',
  recommendationStatusDrop = 'contributor/status-dropdown/audit-reporting-recommendation/recommendation_status/',
  observationExportCSV = '/audit-reporting/download-csv-observation/',
  observationExportXLS = '/audit-reporting/download-xls-observation/',
  observationCelleryExport = 'audit-observation-export/',
  auditaction = 'audit-reporting/action/',
  auditRecommendationDetails = 'audit-reporting/recommendation/',
  auditRecommendationtaggedData = 'observation/management-response/',
  riskRecommendationtaggedData = 'program-observation/management-response/',
  auditRecommendationtaggedAddData = 'observation/management-response/tagged-recommendation/',
  riskRecommendationtaggedAddData = 'program-observation/management-response/tagged-recommendation/',
  auditRecommendationDetailsDynamic = 'audit-reporting/recommendation/dynamic-field-list/',
  complianceAuditorDynamicFieldList = 'complainceobservations/dynamic-field/list/',
  riskProgramRecommendationDetailsDynamic = 'risk-program/recommendation/dynamic-field-list/',
  auditUniverseDynamic = 'audit-planning/audituniverse/dynamic-field-list/',
  universeDynamic = 'module-dynamic-fields/list/?request_for=audit-universe',
  riskProgramPlanDynamic = 'risk-program/plan/dynamic-field-list/',
  auditDynamicFields = 'audit-planning/audit/dynamic-field-list/',
  complianceIssueDynamicFields = 'compliance-audit/issue/dynamic-field-list/',
  complianceDynamicFields = 'compliance-audit/dynamic-field-list/',
  riskProgramDynamicfield = 'risk-program/dynamic-field-list/',
  auditRating = 'dropdown-list/audit-planning/department_rating',
  riskProgramRating = 'dropdown-list/risk-program/risk_program_department_rating/',
  workingPaperDynamic = 'audit-planning/workingpaper/dynamic-field-list/',
  workingPaperCompDynamic = 'compliance-audit/workingpaper/dynamic-field-list/',
  complianceAuditworkingPaperDynamic = 'compliance-audit/workingpaper/dynamic-field-list/',
  riskProgramWorkingPaperDynamic = 'risk-program/workingpaper/dynamic-field-list/',
  complianceAuditProgramWorkingPaperDynamic = 'compliance-audit/workingpaper/dynamic-field-list/',
  complianceWorkingPaperCreate = 'compliance-audit/workingpaper/',
  auditIssueDynamicList = 'audit-planning/issue/dynamic-field-list/',
  observationDynamicFields = 'auditobservations/dynamic-field/list/',
  observationCompDynamicFields = 'compliance-audit/dynamic-field-list/',
  observationComplianceDynamicFields = 'complainceobservations/dynamic-field/list/',
  riskProgramObservationDynamicList = 'risk-program/observation/dynamic-field/list/',
  auditDynamicScheduleFields = 'audit-planning/auditplandetail/dynamic-field-list/',
  riskProgramDynamicScheduleFields = 'risk-program/riskprogramdetail/dynamic-field-list/',
  auditAnalyticsComment = 'contributer-comments/',
  getCommentReplies = 'comment/',
  workingPaper = 'workingpaper/',
  issuesStatusDropdown = 'dropdown-list/risk-assessment/testing_status/',
  complianceIssuesStatusDropdown = 'dropdown-list/compliance-audit/compliance_testing_status/',
  issueDetail = 'issue-detail/',
  askAuraaCount = 'single-list-filter-nlp-count/',
  incidentStatusDropDown = 'dropdown-list/incident-reporting/incident_status/',
  incidentStatusList = 'dropdown-list-v2/incident-reporting/incident_status/',
  incidentActionStatusDropDown = 'dropdown-list/incident-action/incident_status',
  incidentCreateTab = 'incident-reporting/incident-create/tab/',
  incidentCreateData = 'incident-reporting/incidents/create/',
  incidentPublicCreateData = 'incident-reporting/public-incident/create/',
  IncidentsDetailEdit = 'incident-reporting/incidents/',
  getIncidentTabDetails = 'incident-reporting/incident/details/',
  incidentDelete = 'incident-reporting/incident/',
  incidentTabLists = 'incident-reporting/tab/',
  incidentPublicTabList = 'incident-reporting/public-tab/',
  incidentCount = 'incident-reporting/incident-count/',
  incidentAudit = 'incident-reporting/tagging-audit/',
  incidentBCM = 'incident-reporting/business-process/',
  incidentRisk = 'incident-reporting/tagging-risk/',
  incidentRiskProgramTagList = 'incident-reporting/tagging-risk-program/',
  incidentControl = 'incident-reporting/tagging-control/',
  incidentPublicDepartmentDropdown = 'public-department/list/',
  incidentTaggedRiskProgram = 'incident-reporting/tagging-risk-program/',
  incidentAssigneeDropdown = 'user-list/',
  incidentActionStakeholderDropdown = 'user-list/?request_for=contributor',
  incidentActionGrid = 'incident-reporting/incident-action/',
  incidentActionDetailsById = 'incident-reporting/incident-action/',
  incidentActionCreate = 'incident-reporting/incident-action/create/',
  incidentActionEdit = 'incident-reporting/incident-action/',
  incidentActionDelete = 'incident-reporting/incident-action/',
  incidentAllActionList = 'incident-reporting/incident-action/list/',
  incidentOverEditComment = 'incident-reporting/comments/',
  exceptionCriteria = 'analytics-builder/druid/calc_field_result/?object_id=',
  getChartData = 'dashboard_builder/get-chart-data/?slug=',
  getDashboardGridData = 'dashboard_builder/create_grid?limit=10',
  getReportGridData = 'dashboard_builder/create_grid/',
  dashboardBuilderPublishSave = 'dashboard_builder/publish/save/',
  getPublishedDashboards = 'dashboard_builder/published/dashboard/list/',
  dashboardBuilderChartData = 'dashboard_builder/charts/data/?chart_object_id=',
  dashboardBuilderSaveSnapshot = 'dashboard_builder/save-snapshot/',
  dashboardSavedDraft = 'dashboard_builder/drafts/dashboard/list/',
  dashboardModuleList = 'dashboard_builder/get-module-data/',
  dashboardModuleFieldList = 'dashboard_builder/get/fields/?module=',
  dashboardBuilder = 'dashboard_builder/',
  dashboardBuilderDelete = 'dashboard/delete/',
  incidentDashboardGraph = 'filter-dashboard/',
  incidentcomments = 'comments-list-incident-action/',
  incidentcommentsadd = 'comments-create-incident-action/',
  incidentcommentsedit = 'comments-edit-incident-action/',
  incidentcommentsdelete = 'comments-delete-incident-action/',
  auditIssuesList = 'issues-list/',
  ConsolidatedDashboardCharts = 'consolidated-dashboard-charts/?request_for=dashboard&request_from=app&multi_values=false',
  consolidatedReportList = 'user_dashboard/consolated_dashboard/list/',
  auditDocumentsList = 'audit-planning-upload-list/',
  auditImprovementDocumentsList = 'audit-report-upload-list/',
  auditReviewDocumentsList = 'audit-planning-upload-list/',
  existingTableTemplates = 'analytics-builder/user/table/list/',
  allIncidentObjectGrid = 'incident-reporting/admin/incident-reporting/list/',
  allBcmObjectGrid = 'incident-reporting/admin/business-process/list/',
  allBcpObjectGrid = 'incident-reporting/admin/business-continuity-plan/list/',
  incidentControlPanelDetail = 'incident-reporting/control-panel/form-data/',
  incidentFieldType = 'incident-reporting/data_type/list/',
  businessStrategy = 'business-strategy/list',
  businessStrategyEntity = 'entity-based-business-strategy/list',
  businessStrategyObjlist = 'business-strategy/objective-levels/list/',
  riskBusinessStrategy = 'business-strategy/risk-list/',
  businessStrategies = 'business-strategy/',
  createStrategy = 'business-strategy/create',
  controlpanelpriority = 'dropdown-list/incident-reporting/incident_rating/',
  controlPanelCreatedDropdown = 'dropdown/create/',
  incidentTypeCreate = 'incident-reporting/type/create/',
  requestTypeCreate = 'request/type/create/',
  requestControlPanelTypeEdit = 'request/type/',
  controlPanelPriorityDelete = 'dropdown/',
  controlpaneltype = 'incident-reporting/type/list/',
  controlpaneltypedelete = 'incident-reporting/type/',
  controlPanelAuditAreaDelete = 'dropdown/',
  manualWorkingPaperDelete = 'audit-program/',
  deleteComplainceWorkingPaper='compliance-audit-working-paper/',
  editComplianceWorkingPaper='compliance-audit/workingpaper/',
  manualRiskWorkingPaperDelete = 'risk-program/working-paper/',
  manualComplianceWorkingPaperDelete ='compliance-management/working-paper/',
  controlpaneltypeedit = 'incident-reporting/type/',
  controlpaneltypecreate = 'incident-reporting/type/create/',
  control = 'control/',
  incidentReportingControlPanelEdit = 'incident-reporting/control-panel/form-data/edit/',
  adminTabList = 'incident-reporting/module/incident-management/list/',
  allIncidentActionGrid = 'incident-reporting/admin/incident-action/list/',
  incidentpagefilter = 'get_filters/?request_for=incident-reporting',
  createIncident = 'incident-reporting/create-form/',
  publicCreateIncident = 'incident-reporting/public/create-form/',
  getIncidentTab = 'incident-reporting/incident/details/',
  getPublicIncidentTab = 'incident-reporting/public-incident/details/',
  improvementStatus = 'dropdown-list/audit-reporting/improvement_status/',
  documentArchive = 'document-list/',
  documentArchiveRecent = 'recent-document-list/',
  documentArchivedownload = 'document-download-link/',
  controlpanelstatus = 'dropdown-list/incident-reporting/incident_status/',
  controlpanelstatusdelete = 'dropdown/',
  controlpanelBcmTimeframe = 'bcmtimeframe/list/',
  controlpanelBcmTierList = 'tier/list/',
  createBcmTiers = 'tier/',
  bcmDeleteTier = 'tier/',
  editBcmTiers = 'bcm/tier/',
  controlpanelBcmCategoryList = 'bcmcategory/list/',
  createBcmCategory = 'bcmcategory/',
  bcmDeleteCategory = 'bcmcategory/',
  editBcmCategory = 'bcm/category/',
  incidentHidenonConfigurable = 'incident-reporting/admin/incident-reporting/list/?configurable=Yes&pagination_config=all',
  incidentHidenonConfigurableAction = 'incident-reporting/admin/incident-action/list/?configurable=Yes',
  bcmHidenonConfigurable = 'incident-reporting/admin/business-process/list/?configurable=Yes&pagination_config=all',
  bcpHideNonConfigurable = 'incident-reporting/admin/business-continuity-plan/list/?configurable=Yes&pagination_config=all',
  AuditPlanningNonConfigurable = 'incident-reporting/admin/audit-planning/list/?configurable=Yes&pagination_config=all',
  AuditParentConfigurable = 'incident-reporting/admin/',
  AuditReportingNonConfigurable = 'incident-reporting/admin/audit-reporting/list/?configurable=Yes&pagination_config=all',
  AuditRecommendationConfigurable = 'incident-reporting/admin/audit-reporting-recommendation/list/?configurable=Yes&pagination_config=all',
  AuditImprovrementConfigurable = 'incident-reporting/admin/improvement/list/?configurable=Yes&pagination_config=all',
  AuditActionConfigurable = 'incident-reporting/admin/audit-reporting-action/list/?configurable=Yes&pagination_config=all',
  AuditManagementConfigurable = 'incident-reporting/admin/management-response/list/?configurable=Yes&pagination_config=all',
  AuditProgramConfigurable = 'incident-reporting/admin/workingpaper/list/?configurable=yes&pagination_config=all',
  AuditActivityConfigurable = 'incident-reporting/admin/audit-program-activity/list/?configurable=yes&pagination_config=all',
  planConfigurable = 'incident-reporting/admin/audit-universe/list/?configurable=yes',
  ActionQuestion = 'action-assessment/list/',
  assessmentReviewerComment = 'assessment-reviewer-comment/',
  controlpaneldepartment = 'dropdown-list/incident-reporting/incident_department',
  araAnalyticsOverview = 'filter-charts/',
  araAnalyticsTabProcess = 'filter-charts/',
  adminUserGraph = 'filter-charts/?request_for=administrator&request_from=app&platform=web',
  adminUserGrid = 'all-users-list/',
  adminActiveUserGrid = 'user-list/?request_for=prof_contri_expl',
  allActiveUsersIncludeAllLicense = 'user-list/?request_for=all-user',
  adminUserGridNew = 'all-users-list/?exclude_client=false',
  adminLoginUser = 'user/type',
  adminUserDetails = 'admin/user/details',
  adminExperience = 'experience/',
  adminCertification = 'certification/',
  adminEducation = 'education/',
  adminRoleList = 'role-list/',
  adminRoleDelete = 'role-delete/',
  adminPolicyDelete = 'policy-delete/',
  adminUserDelete = 'user/',
  auditAnalyticsTaggedRisk = 'audit_analytics/tagged_risk_record_list/',
  auditAnalyticsTaggedControl = 'audit_analytics/tagged_control_record_list/',
  auditAnalyticsTaggedAudit = 'analytics-observation-list/',
  requestRiskList = 'request/risk/',
  requestControlList = 'request/control/',
  requestProgramList = 'request/program/',
  requestAuditList = 'request/audit/',
  requestComplianceList = 'request/compliance/',
  requestBcmList = 'request/bcm/',
  requestPolicyList = 'request/policy/',
  requestTaggedRisk = 'risk-assessment/',
  requestTaggedControl = 'control/',
  requestTaggedProgram = 'risk-program/',
  requestTaggedCompliance = 'compliance/',
  requestTaggedPolicy = 'policy/',
  requestTaggedAudit = 'audit/',
  requestTaggedBcm = 'bcm/',
  auditAnalyticsTaggedAssessment = 'audit_analytics/tagging-assessment/',
  araExceptionGrid = 'analytics-exception/',
  araScenarioDetailsView = 'audit-analytics/scenario_list/',
  araScenarioDetailsDependencyCount = 'analytics-builder/dependencies/count/',
  araScenarioDetails = 'audit-analytics/scenario/',
  araAnalyticsManagerDropDown = 'user-list/',
  araScenarioRatingDropDown = 'dropdown-list/analytics-scenario/analytics_risk_rating/',
  araControlRatingDropDown = 'dropdown-list/analytics-scenario/analytics_criticality_rating/',
  araEffectivenessDropDown = 'dropdown-list/analytics-scenario/analytics_effectiveness/',
  araScenarioDetailsEdit = 'audit_analytics/druid/edit/scenario/',
  araSummaryChart = 'filter-charts/',
  getUserCreatedAuditRiskAnalytics = 'audit_analytics/process_list/',
  araSubProcessList = 'audit_analytics/sub_process_list/',
  araDeleteException = 'audit_analytics/exception-datasource/',
  araDeleteExceptionData = 'audit_analytics/',
  adminUserGroup = 'group-list/',
  adminUserGroupModal = 'user-group-users/',
  adminUserGroupDelete = 'user-group/',
  adminAuditModeldelete = 'audit-universe/model/',
  adminUsergroupCreate = 'user-group/create/',
  adminUsergroupEdit = 'user-group/',
  adminAuditPlanningList = 'incident-reporting/admin/audit-planning/list/?pagination_config=all',
  adminAuditReportingList = 'incident-reporting/admin/audit-reporting/list/?pagination_config=all',
  adminAuditImprovementList = 'incident-reporting/admin/dynamicfields/list/?pagination_config=all',
  adminAuditRecommendationList = 'incident-reporting/admin/audit-reporting-recommendation/list/?pagination_config=all',
  adminAuditActionList = 'incident-reporting/admin/audit-reporting-action/list/?pagination_config=all',
  adminAuditManagementResponseList = 'incident-reporting/admin/management-response/list/?pagination_config=all',
  adminAuditProgramList = 'incident-reporting/admin/workingpaper/list/?pagination_config=all',
  adminAuditAreasList = 'dropdown-list/audit-planning/',
  adminAuditReportingDataList = 'dropdown-list/',
  bcmReportingDataList = 'dropdown-list/',
  adminAuditRecommendationDataList = 'dropdown-list/audit-reporting-recommendation/',
  adminAuditImprovementDataList = 'dropdown-list/audit-reporting/',
  adminAuditHeaderFieldName = 'audit/admin-dynamic-fields/list',
  adminAuditActionDataList = 'dropdown-list/audit-reporting-action/',
  adminAuditProgramDataList  = 'dropdown-list/risk-assessment/',
  adminmanagementResponseDataList  = 'dropdown-list/management-response/audit-reporting/',
  adminRiskProgramMRDataList  = 'dropdown-list/management-response/risk-program-management-response/',
  adminImprovementmanagementResponseDataList  = 'dropdown-list/management-response/improvement-management-response/',
  auditAreasSubValue = 'dropdown-list/',
  getValue = 'parent-condition/',
  adminPolicyList = 'policy-list/',
  adminRoleCreate = 'role-create/',
  adminPolicyCreate = 'policy-create/',
  importFile = 'analytics-builder/datacopy/excel/csv',
  FlowBuilderRunApi = 'analytics-builder/work-flow-builder/run/',
  FlowBuilderPublishApi = 'analytics-builder/work-flow-builder/publish/',
  FlowBuilderPreviewApi = 'analytics-builder/work-flow-builder/node-data/list/',
  analyticsDruid = 'analytics-builder/druid/',
  analyticsDruidException = 'analytics-builder/druid/exception_update/',
  druidDataCopy = 'analytics-builder/druid/datacopy-request/',
  issueDetails = 'issue-detail/',
  recommendationActionDetails = 'audit-reporting/recommendation/action/',
  userGroupdetails = 'user-group/',
  entityList = 'admin-entity-list/',
  entityDetail = 'admin-entity-detail/',
  entity = 'admin-entity/',
  entityEdit = 'admin-entity-edit/',
  entitySizeDropdown = 'dropdown-list/entity-management/entity_size/',
  regionDropdown = 'region-list/',
  stateDropdown = 'dropdown-state/',
  designationDropdown = 'risk-assessment/risk_owner/list',
  licenseDropdown = 'admin-licencetype-list/',
  adminUserscreate = 'user-create/',
  adminUsersedit = 'admin-user-edit/',
  roleDetails = 'role/',
  adminRolesEdit = 'role-update/',
  addNewDesignation = 'admin-designation-create/',
  policyDetails = 'policy/',
  policyEdit = 'policy-update/',
  policyAck = 'policy-management/acknowledgment/',
  adminRiskUniverse = 'incident-reporting/admin/risk-assessment/list/',
  adminKriList = 'incident-reporting/admin/',
  adminRiskProgram = 'incident-reporting/admin/risk-program/list/',
  riskProgramMrHidenonConfigurable = 'incident-reporting/admin/risk-program-management-response/list/?configurable=Yes&pagination_config=all',
  riskProgramManagementResponseList = 'incident-reporting/admin/risk-program-management-response/list/?pagination_config=all',
  riskUniverseHidenonConfigurable = 'incident-reporting/admin/risk-assessment/list/?configurable=Yes',
  riskHidenonConfigurable = 'incident-reporting/admin/risk-assessment/list/?configurable=Yes&pagination_config=all',
  kriHidenonConfigurable = 'incident-reporting/admin/',
  riskProgramHidenonConfigurable = 'incident-reporting/admin/risk-program/list/?configurable=Yes&pagination_config=all',
  adminRiskControl = 'incident-reporting/admin/risk-assessment-v1-details/list/',
  riskControlHidenonConfigurable = 'incident-reporting/admin/risk-assessment-v1-details/list/?configurable=Yes',
  controlHidenonConfigurable = 'incident-reporting/admin/risk-assessment-v1-details/list/?configurable=Yes&pagination_config=all',
  riskFieldGrid = 'dropdown-list/risk-assessment/',
  kriFieldGrid = 'dropdown-list/',
  riskFieldGridRiskProgram = 'dropdown-list/risk-program/',
  riskProgramFieldGrid = 'dropdown-list/risk-program/',
  controlFieldGrid = 'dropdown-list/risk-assessment-v1-details/',
  riskFieldDelete = 'dropdown/',
  riskFieldCreate = 'dropdown/create/',
  riskValueDropdown = 'dropdown-list/',
  riskParentDropdown = 'incident-reporting/admin/',
  auditUserList = 'user-list/?request_for=contributor',
  regionGrid = 'region-list/',
  reportGrid = 'report/list',
  departmentGrid = 'department/list/',
  userDepartmentGrid = 'user-department/list/',
  regionCreate = 'region-create/',
  reportCreate = 'report/create/',
  userPermissionAdd = 'add-user-permissions/',
  adminUserPermissionAdd = 'add-administrator-user-permissions/',
  userGroupPermissionAdd = 'add-group-permissions/',
  adminUserGroupPermissionAdd = 'add-administrator-group-permissions/',
  deleteUserPermission = 'remove-permissions/',
  adminDeleteUserPermission = 'remove-administrator-permissions/',
  permissionList = 'list-allusers-permissions/',
  adminPermissionList = 'list-allusers-administrator-permissions/',
  effectivePermission = 'effective-permissions/',
  adminEffectivePermission = 'effective-administrator-permissions/',
  allUserPermission = 'restrict-allusers-permissions/',
  adminAllUserPermission = 'restrict-allusers-administrator-permissions/',
  sidebarPermission = 'permission-module-submodule/',
  adminSidebarPermission = 'administrator-permission-module-submodule/',
  contributorSidebar = 'contributor-module-sidebar/',
  departmentCreate = 'department/create/',
  userdepartmentCreate = 'user-department/create/',
  regionDelete = 'region-delete/',
  reportDelete = 'report/',
  departmentDelete = 'department/',
  userdepartmentDelete = 'user-department/',
  userdepartmentEdit = 'user-department/',
  regionDetails = 'region-detail/',
  departmentDetails = 'department/list/',
  userdepartmentDetails = 'user-department/list/',
  regionEdit = 'region-update/',
  planningDetails = 'dropdown-list/',
  logoList = 'logo-config/list/',
  logoUpdate = 'logo-config/update/',
  loginUserType = 'user/type',
  uploadLogo = 'logo-config/upload/',
  countryDropdown = 'dropdown-country/list/',
  budgetCategoryGrid = 'audit-budget/category/list/',
  budgetSubCategoryGrid = 'audit-budget/sub_category/list/',
  addBudgetCategory = 'audit-budget/category/create/',
  addBudgetSubCategory = 'audit-budget/sub_category/create/',
  budgetCategory = 'audit-budget/category/',
  budgetSubCategory = 'audit-budget/sub_category/',
  categoryDetails = 'audit-budget-category/',
  subCategoryDetails = 'audit-budget-subcategory/',
  editCategory = 'audit-budget/category/',
  editSubCategory = 'audit-budget/sub_category/',
  activityStatus = 'dropdown-list/audit-planning/activity_status/',
  reportStatus = 'dropdown-list/audit-planning/report_state/',
  contributor = 'contributor/comments/',
  contributorCSV = '/api/v6/contributer_csv/contributor-management-response',
  contributorXLS = '/api/v6/contributer_excel/contributor-management-response',
  contributorComplianceCSV = '/api/v5/compliance-mgt-response/download_csv/',
  contributorComplianceXLS = '/api/v5/compliance-mgt-response/download_excel/',
  contributorActionCSV = '/audit-reporting/download-csv-contributor-action/csv_download',
  contributorActionXLS = '/audit-reporting/download-xls-contributor-action/xls_download',
  contributorMrImprovementCSV = '/audit-reporting/download-csv-contributor-improvement-management-response/csv_download',
  contributorMrImprovementXLS = '/audit-reporting/download-xls-contributor-improvement-management-response/xls_download',
  comment = 'comment/',
  contributercomments = 'contributer-comments/',
  auditapprovalDetails = 'audit-planning/detail/',
  contributerActioncomments = 'contributer-comments/',
  incidentExportCSV = '/incident-reporting/incident/csv_download',
  incidentExportXLS = '/incident-reporting/incident/xls_download',
  dashboardBuilderEdit = 'dashboard_builder/publish/save/update/dashboard/',
  digitalAuditReportGraph = 'filter-charts/?request_for=audit_planning&request_from=app&platform=web',
  digitalAuditDetail = 'audit-planning/detail/',
  digitalManagementTeam = 'audit/management-team/list/?object_id=',
  digitalAuditTeam = 'audit/auditee/list/?object_id=',
  digitalAuditObservation = 'audit-reporting/observation/',
  digitalAuditObservationDetails = 'audit-reporting/observation/',
  digitalAuditObservationRecommendationList = 'audit-reporting/recommendation/',
  digitalAuditObservationActionList = 'audit-reporting/action/',
  digitalAuditObservationControlList = 'audit-reporting/tagged_control/',
  digitalAuditObservationActionDetail = 'audit-reporting/action/',
  digitalAuditObservationControlDetail = 'risk-assessment/details/',
  digitalAuditObservationRecommendationDetail = 'audit-reporting/recommendation/',
  digitalAuditIssueList = 'issues-list/',
  digitalAuditIssueDetail = 'issue-detail/',
  activityDetail = 'audit-program-activity/list',
  activityDetailRiskProgram = 'risk-program-activity/list/',
  activityEditReqDetails = 'approval-system/activity/',
  activityCreateReqDetails = 'audit-program-activity/',
  activityActionCreate = 'audit-program-activity-tag-action/',
  riskProgramActivityActionCreate = 'risk-program/activity/',
  activityActionList = 'audit-program-activity-tagged-action/',
  riskProgramActivityActionDetail = 'risk-program/activity/action/',
  riskprogramActivityActionList = 'risk-program/activity/',
  issueActionList = 'issue-tagged-action/',
  editOpenActivityActionCreate = 'audit-program-activity-tag-action/',
  editOpenActivityActionEdit = 'approval/edit/reopen/V2/',
  activityEdit = 'audit-program-activity/',
  activityEditRiskProgram = 'risk-program-activity/',
  activityEditReqEdit = 'approval/edit/reopen/V2/',
  activityDocumentList = 'audit-planning-upload-list/',
  auditActivityDelete = 'audit-program-activity',
  activityDeleteRiskProgram = 'risk-program-activity/',
  activityReviewer = 'audit/team/list/?request_for=reviewer',
  activityDetails = 'audit-program-activity/list',
  workingPaperDetails = 'auditprogram-workingpaper/',
  complainceAuditworkingPaperDetails='compliance-audit-workingpaper/',
  riskProgramWorkingPaperDetails = 'risk-program/working-paper/',
  complianceAuditWorkingPaperDetails = 'compliance-audit-workingpaper/',
  workingPaperEdit = 'workingpaper/',
  riskWorkingPaperkingPaperEdit = 'risk-program/workingpaper/',
  complianceWorkingPaperEdit = 'compliance-audit/workingpaper/',
  workingPaperRating = 'dropdown-list/risk-assessment/working_paper_rating/',
  complianceAauditWorkingPaperRating ='dropdown-list/compliance-audit/compliance_audit_working_paper_rating/',
  deleteDocument = 'file-uploader/',
  auditProgramDelete = 'audit-program/',
  pendingEditRequest = 'audit/pending-edit-request/',
  analyticsBuilderEventLog = 'analytics-builder/joblog/info/?object_id=',
  actionDocumentList = 'audit-report-upload-list/',
  analyticsDataSourceDetails = 'analytics-builder/datasource-detail/summary',
  auditReportGrid = 'document-report-list/',
  auditDigitalReport = 'digital-report/',
  riskProgramDigitalReport = 'risk-program/',
  auditDigitalSignoff = 'digital-signoff/',
  auditSignersList = 'signers/user-list/',
  digitalReportSignoff = 'digital-report-signoff/',
  auditDigitalReportSelection = 'digital-report-selection/',
  auditReportDocFormatedPDF = 'pdf-report-generation/',
  complianceDigitalReportSelection = 'digital-report-selection/compliance-audit/',
  auditDigitalReportDoc = 'digital-report/',
  digitalReportDocRiskProgram = 'risk-program/',
  araDataSourceGrid = 'audit_analytics/scenario/data_source/',
  araMajorProcessDropdown = 'audit_analytics/major_process_list/',
  araModelDropdown = 'audit_analytics/major_process_model_list/',
  araDataFlushDetail = 'audit_analytics/dataflush/scenario',
  araDataProcess = 'analytics-builder/druid/scenario/data-process/',
  araProcessingData = 'analytics_builder/dataprocess/',
  araSingleDataProcess = 'audit_analytics/single/data-process',
  araSendReminder = 'analytics-builder/send_reminder/',
  araExceptionStatus = 'audit-analytic/exceptions/',
  araExceptionDelete = 'audit_analytics/',
  araExceptionChart = 'filter-charts/',
  araDataSourceDetails = 'audit_analytics/scenario/',
  araDataSourceDetailsPage = 'audit_analytics/scenario/data_source/exception',
  getScenarioTab = 'audit-analytics/druid/scenario_list/',
  araCpAnalyticsScenarioList = 'incident-reporting/admin/analytics-scenario/list/',
  cpAnalyticsScenarioList = 'incident-reporting/admin/analytics-scenario/list/?configurable=Yes',
  araCpProcessList = 'audit_analytics/process_list/',
  araCpProcessCreate = 'audit_analytics/process/create/',
  araCpCreateNewAraDropdown = 'dropdown/',
  araCpEditAraDropdown = 'dropdown/',
  araCpSubProcessCreate = 'audit_analytics/process/create/',
  araCpScenarioList = 'audit_analytics/control-panel/list/',
  araCpSubProcessList = 'audit_analytics/sub_process_list/?process_object_id=',
  araCpRisk = 'risk-assessment/header/list/',
  araCpControl = 'risk-assessment/details/list/',
  araCpAudit = 'audit-planning/list/',
  araCpScenarioCreate = 'audit_analytics/scenario/create/',
  araCpContributor = 'user-list/?request_for=contributor',
  araContentTypeList = 'audit_analytics/content_type_list/',
  araScenarioDelete = 'analytics-builder/scenario/',
  araCpScenarioEditList = 'audit_analytics/control-panel/scenario-edit/',
  araCpScenarioEdit = 'audit_analytics/control-panel/',
  araCpProcessDelete = 'audit_analytics/process/',
  araCpSubProcessDelete = 'audit_analytics/process/',
  araCpProcessEdit = 'audit_analytics/process/',
  araCpSubProcessEdit = 'audit_analytics/process/',
  araCpSubProcessGrid = 'audit_analytics/sub_process_list/',
  araCpAnalyticsCriticalityRatingGrid = 'dropdown-list/analytics-scenario/analytics_criticality_rating/',
  araCpAnalyticsEffectivenessGrid = 'dropdown-list/analytics-scenario/analytics_effectiveness/',
  araCpAnalyticsRiskRatingGrid = 'dropdown-list/analytics-scenario/analytics_risk_rating/',
  araControlpanelDelete = 'dropdown/',
  araCpSubProcessData = 'audit_analytics/sub_process_list/?object_id=',
  araSingleDataFlush = 'audit_analytics/delete_module/',
  PolicyModuleList = 'module/list',
  adminUserExportCSV = '/client/admin-panel/csv/download',
  adminUserExporXLS = '/client/admin-panel/xls/download',
  valueDropdown = 'parent-condition/',
  adminRiskDetails = 'dropdown-list/risk-assessment/',
  adminControlDetails = 'dropdown-list/risk-assessment-v1-details/',
  deleteAuditAnalytics = 'comment/',
  editAuditAnalytics = 'comment/',
  scoringFactorsLIst = 'scoring-model-factor/list',
  scoringModelsLIst = 'scoring-model/list',
  auditRpScoringModelList = 'observation-scoring-model/list',
  scoringModel = 'scoring-model/',
  scoringModelCreate = 'scoring-model/create',
  changeUserPassword = 'change-password/users-by-super_admin',
  activateUser = 'user/',
  adminSecurityList = 'security-control-list/',
  securityDelete = 'security/',
  securityList = 'security-control-list/',
  addColumnSecurity = 'security/add',
  editColumnSecuirty = 'security/',
  columnSecurityDetails = 'security-control-detail/',
  moduleDropdown = 'module/security/list',
  itemDropdown = 'admin/security/item/list',
  conditionDropdown = 'security/condition/list',
  featureList = 'admin/features/list',
  approvalList = 'approvalonoff/list/',
  departmentData  = 'audit-planning-department-rating/detail/',
  featureStatusEdit = 'admin/features/status/',
  featureClientStatusEdit = 'clientfeatures/status/',
  approvalStatus= 'approvalonoff/',
  multipleApproval = 'multiple-approval/',
  getCount = 'approvalonoff/approvalcount/',
  approvalPendingStatus = 'approvalonoff/approval-status/update',
  activateApproval = 'approvalonoff/approver-reviewer/update',
  featureUpdate = 'admin/features/',
  filter = 'filter',
  suggest = 'suggest',
  scenarioList = 'dropdown-scenario/list/',
  auditProgramChart = 'filter-charts/?request_for=audit_planning&platform=web&request_from=app&',
  riskProgramChart = 'filter-charts/?request_for=risk_program&request_from=app&users=otherusers&platform=web&chart_type=working-paper',
  scoringModelList = 'scoring-model/list',
  scoringFactorList = 'scoring-model-factor/list',
  scoringGradient = 'scoring-model/draw-graph',
  activityCharts = 'filter-charts/?request_for=audit_planning&platform=web&request_from=app&',
  activityChartsRiskProgram = 'filter-charts/?request_for=risk_program&platform=web&request_from=app&users=otherusers&',
  riskProgramDetailsIssueTab = 'filter-charts/?request_for=risk_program&request_from=app&users=otherusers&platform=web&chart_type=issue',
  issueTrackerIssueCharts = 'filter-charts/?request_for=issue_tracker&request_from=app&chart_type=audit_issue_dashboard&platform=web&',
  issueTrackerObservationCharts = 'filter-charts/?request_for=issue_tracker&request_from=app&chart_type=audit_observation_issue_tracker&platform=web&',
  complianceIssueTrackerIssueCharts = 'filter-charts/?request_for=compliance_audit&request_from=app&chart_type=compliance_issue_dashboard&platform=web&',
  complianceIssueTrackerObservationCharts = 'filter-charts/?request_for=compliance_audit&request_from=app&chart_type=compliance_observation_issue_tracker&platform=web&',
  complianceContributorObservationCharts = 'filter-charts/?request_for=compliance_audit&request_from=app&chart_type=contributor-compliance-observation-charts&platform=web&',
  auditContributorObservation = 'filter-charts/?request_for=audit_reporting&request_from=app&users=contributor&chart_type=contributor-audit-observation-charts&platform=web&',
  riskProgramContributorObservationCharts = 'filter-charts/?request_for=risk_program&request_from=app&chart_type=contributor-risk-observation-charts&platform=web&',
  issueTrackerIssueList = 'all-issues-list/',
  auditIssuetrackerObservationList ='audit-reporting/issue-tracker/observation/list/',
  issueTrackerIssueComplaince = 'compliance-audit/issue-tracker/issue-list/',
  issueTrackerComplianceObservation = 'compliance-audit/issue-tracker/observation/list/',
  auditContributorObservationList = 'contributor/management-response/list/',
  auditContributorRiskList = 'contributor/observations/list/',
  auditObservationContributorList = 'contributor/observations/list/',
  riskProgramExport = 'contributor/risk-program/observation/export',
  auditRiskAnalyticsExportCSV = '/audit_analytics/download/csv/',
  auditRiskAnalyticsExportXLS = '/audit_analytics/download/xls/',
  singleAuditRiskAnalyticsExportXLS = '/audit_analytics/download/bulk/xls/',
  singleAuditRiskAnalyticsExportCSV = '',
  AuditRiskAnalyticsAssessmentList = 'analytics-assessment-listing/',
  ExceptionRatingDropdown = 'audit_analytics/scenario_control_rating_list/',
  controlTagging = 'audit_analytics/scenario-control-threshold-set/',
  riskControlAddAnalytics = 'audit_analytics/scenario-control-tagging/',
  riskTaggedControlAraAnalytics = 'audit_analytics/scenario-control-untag/',
  auditAssessments = 'risk-assessment/Survey/tagginglist/many-entity/',
  araAssesmentGrid = 'audit-assessment/Survey/tagginglist/many-entity/',
  araAddAssessment = 'audit_analytics/tagging-assessment/',
  araTaggedAssessment = 'analytics-assessment-listing/',
  araDeleteAssessment = 'audit_analytics/tagged-assessment-delete/',
  getRiskControlListAraAnalytics = 'scenario/tagging-control/add-list/',
  getIncidentAraAnalytics = 'scenario/tagging-control/add-list/',
  calendarDefaultDate = 'audit/calender/date-range',
  bcmDuration = 'dropdown-list/business-process/business_process_duration/',
  bcmTier = 'tier/list/',
  bcmTimeframeEdit = 'bcm/timeframe/edit/',
  bcmTimeframeEditList = 'bcm/timeframe/edit-list/',
  businessProcessesGrid = 'bussinessprocess/list/',
  bcmAssetGrid = 'businessprocess/asset/list/',
  bcmPagefilter = 'filter-charts/',
  bcmBusinessChart = 'filter-charts/',
  bcmDeleteProcess = 'bussinessprocess-delete/',
  getTagUpstreamList = 'bussinessprocess-upstream/tagging-list/',
  getTagUpstreamListEdit = 'bussinessprocess-upstream/tagging-list/',
  getTagAssetList = 'bussinessprocess/asset/create/add-list/?',
  getTagPolicyList = 'bussinessprocess/tagging_policy/add-list/',
  bcmDeleteAsset = 'businessprocess/assetmanagement/',
  bcmProcessDocument = 'bussinessprocess-upload-list/',
  getTagDownstreamList = 'bussinessprocess-downstream/tagging-list/?',
  upstreamGrid = 'bussinessprocess/details/tagged-upstream/',
  bcmStatusDropdown = 'dropdown-list/business-process/business_process_status/',
  bcmRatingList = 'dropdown-list/business-process/business_process_rating/',
  bcmTimeFrameList = 'bcmtimeframe/list/',
  bcmCategoryList = 'bcmcategory/list/',
  bcmQuestianList = 'workaround/question/list/',
  bcmCreate = 'businessprocess/create/',
  createBcm = 'bcm/create/',
  bcmDetailbyId = 'bussinessprocess-edit-list/',
  bcmEdit = 'businessprocess/edit/',
  editBcm = 'bcm/edit/',
  downstreamGrid = 'bussinessprocess/details/tagged-downstream/',
  bcmWorkaroundView = 'bussinessprocessworkaround-detail/',
  bcmSummaryView = 'bussinessprocess-detail/',
  bcmApprovalDetail = 'bussinessprocess/approval-detail/',
  assetTitle = 'bussinessprocess/asset/type/list/',
  bcmImpactAssesmentView = 'bussinessprocess/impact-assessment/list/',
  bcmUpstreamList = 'bussinessprocess/details/tagged-upstream/',
  bcmDownstreamList = 'bussinessprocess/details/tagged-downstream/',
  bcmTaggedRisk = 'bussinessprocess/details/tagged-risk/',
  bcmTaggedControl = 'bussinessprocess/details/tagged-control/',
  bcmTaggedCount = 'bussinessprocess-dependecy-count/',
  addUpstream = 'bussinessprocess/upstream-tagging/',
  addAsset = 'bussinessprocess/asset/add-list/?',
  deleteUpstream = 'bussinessprocess/tagged-upstream/',
  deleteDownstream = 'bussinessprocess/tagged-downstream/',
  deleteTagAsset = 'bussinessprocess/tagged-asset/',
  deleteTagBcm = 'bussinessprocess/tagged-policy/',
  addDownstream = 'bussinessprocess/upstream-tagging/',
  addBCMRisk = 'businessprocess/tagging_risk/',
  tagBCMRIncident = 'bussinessprocess/',
  addBCMControl = 'businessprocess/tagging_control/',
  deleteBCMRisk = 'bussinessprocess/tagged-risk/',
  deleteBCMControl = 'bussinessprocess/tagged-control/',
  bcmassetcontrolpaneltype = 'bussinessprocess/asset/type/list/',
  bcmassetcontrolpaneltypecreate = 'bussinessprocess/asset/type/create/',
  bcmassetcontrolpaneltypeedit = 'bussinessprocess/asset/type/',
  bcmassetcontrolpaneltypedelete = 'bussinessprocess/asset/type/',
  bcmassetcontrolpaneldetails = 'asset-management/control-panel/form-data/',
  assetManagementControlPanelEdit = 'bussinessprocess/asset/control-panel/form-data/edit/',
  assetTypeDropdown = 'bussinessprocess/asset/type/list/',
  assetCreateData = 'businessprocess/assetmanagement/create/',
  bcmCriticality = 'dropdown-list/asset-management/asset_criticality_rating/',
  assetOverviewList = 'businessprocess/asset/details/',
  assetTabLists = 'businessprocess/assetmanagement/tab/',
  assetsDetailEdit = 'businessprocess/assetmanagement/',
  assetTabDetails = 'businessprocess/asset/details/',
  assetProcessTagged = 'bussinessprocess/asset/details/tagged-bussinessprocess/',
  assetTaggedProcess = 'bussinessprocess-asset-detail/',
  bcmDynamicFields = 'bussinessprocess/dynamic-field/list/',
  assetProcessTagDelete = 'bussinessprocess/asset/tagged-businessprocess/',
  assetTaggedProcessList = 'bussinessprocess/tagging/add-list/',
  assetCustomEdit = 'businessprocess/assetmanagement/',
  createAssetList = 'businessprocess/asset/create-form/',
  cpBcmAssetList = 'incident-reporting/admin/asset-management/list/',
  taggedAssetList = 'bussinessprocess/details/tagged-asset/',
  assetTagAddList = 'bussinessprocess/asset/add-list/',
  assetTagDelete = 'bussinessprocess/tagged-asset/',
  processTagDelete = 'business-process/up-down-stream/',
  tagAsset = 'bussinessprocess-detail/',
  businessProcessDurationDropdown = 'dropdown-list/business-process/business_process_duration/',
  auditIssueLists = 'issues-list/',
  riskProgramIssueList = 'risk-program/issues-list/',
  auditIssueListsForRiskProgramTab = 'risk-program/issues-list/',
  auditPlans = 'auditplan-dropdown-list/',
  tableauTicket = 'tableau-ticket/',
  checkDeletionStatus = 'audit-planning/tagged_risk_control/',
  checkControl = 'risk-program/tagged_risk_control/',
  checkRiskKriValidation = 'kri/tagged_risk_control/validation/?request_for=risk-assessment',
  bcmNetworkChartAsset = 'asset/network/chart/',
  bcmNetworkChartBusinessProcess = 'bussinessprocess/network/chart/',
  businessProcessActivityPeformedDropdowns = 'dropdown-list/business-process/activity_performed_by/',
  businessProcessFrequencyDropdowns = 'dropdown-list/business-process/frequency/',
  clientCreate = 'client/create/',
  clientInstance = 'client/create_instance/',
  clientEdit = 'client/',
  clientList = 'clients/list/',
  clientDelete = 'client/delete/',
  clientDetails = 'client/',
  clientFeaturesList = 'client-features/list',
  clientInstamce = 'client/create_instance/',
  clientAction = 'client/actions/',
  activateClientPassword = 'client/set_password_activate/',
  deploymentModes = 'client/deployment-modes/list',
  clientStagesList = 'client/stages/list',
  clientLicenseTypeList = 'client/license-type/list',
  issueTrackerDashboardGrid = 'user-dashboard/all-action-list',
  ObservationDashboardGrid = 'observation_list/',
  ComplianceObservationDashboardGrid='compliance-observation/dashboard/list/',
  AuditIssueTrackerSendRemainder = 'contributor/action/sendremainder/',
  issueDashboard = 'filter-dashboard/',
  activateUserPassword = 'user/set_password_activate/',
  reactivateClientPassword = 'client/set_password_reactivate/',
  resetUserPassword = 'auth/password/reset/confirm',
  contributorScenarioList = 'analytics-builder/contributor_scenarios/',
  contributorAnalyticsChart = 'filter-charts/?request_for=aa_contributor_charts_overview&request_from=app',
  timeframeDelete = 'bcm/timeframe/delete/',
  timeframeDeleteCheck = 'bcm/timeframe/delete/check/',
  issueAction = 'issue-tagged-action/',
  recommendationAction = 'recommendation-tagged-action/',
  recommendationActionEdit = 'audit-reporting/recommendation/action/',
  programIssueActionDetails = 'risk-program/issue/action/',
  programIssueAction = 'risk-program/issue/action/',
  riskProgramIssueAction = 'risk-program/issue/',
  riskProgramAction = 'risk-program/observation/action/',
  riskProgramObservationAction = 'risk-program/observation/',
  issueActionCreateReopen = 'issue-tagged-action/',
  issueActionEditReopen = 'approval/edit/reopen/V2/',
  issueActionEditDetails = 'approval-system/activity-action/',
  issueActionCreate = 'issue-tag-action/',
  recommendationActionCreate = 'recommendation-tag-action/',
  tokenValidation = 'token/validator?token=',
  resetTokenValidation = 'auth/password/reset/confirm?token=',
  taggedObservation = 'risk-assessment/',
  taggedRisk = 'audit-reporting/observation/',
  taggedRiskForObservation = 'audit-reporting/tag_risk/',
  moduleList = 'perm-modules/',
  adminModuleList = 'administrator-perm-modules/',
  modulePermissionList = 'module-permissions/',
  adminModulePermissionList = 'administrator-module-permissions/',
  incidentDocumentList = 'incident-reporting-upload-list/',
  riskRegisterList = 'risk/plan/list/',
  createRiskRegister = 'risk-plan/create',
  registerEdit = 'risk-plan/',
  deleteRegister = 'risk/plan/',
  policySummaryView = 'policymanagement-detail/',
  policyAddRisk = 'policymanagement/tagging_risk/add-list/',
  policyAddControl = 'policymanagement/tagging_control/add-list/',
  policyAddCompliance = 'policymanagement/tagging_compliance/add-list/',
  policyAddProcess = 'policymanagement/tagging_process/add-list/',
  policyDetail = 'policy-detail/',
  policyTaggedRisk = 'policy/details/tagged-risk/',
  policyTaggedControl = 'policy/details/tagged-control/',
  policyTaggedProcess = 'policy/details/tagged-process/',
  policyTaggedCompliance = 'policy/details/tagged-compliance/',
  deletePolicyRisk = 'policy/untag-risk/',
  deletePolicyControl = 'policy/untag-control/',
  deletePolicyProcess = 'policy/untag-process/',
  deletePolicyCompliance = 'policy/untag-compliance/',
  policyAddUser = 'policy/user-add/list/',
  deletePolicyUsers = 'policy/management-team/',
  policyTaggedUsers = 'policy/team/list/',
  policyVersionList = 'policymanagement/version-all-list/',
  policyVersion = 'policymanagement/version-list',
  policyUploadDocument = 'policy-document-upload-list/',
  policyTableList = 'policymanagement/list/',
  policyChartList = 'filter-charts/?request_for=policy_management&request_from=app&platform=web&',
  policyAllRatingList = 'dropdown-list/policy-management/policy_management_rating/',
  policyAllTypeList = 'dropdown-list/policy-management/policy_management_type/',
  policyAllUsersList = 'stake-holder-list/',
  policyRequestDynamicFields = 'policy-request/dynamic-field/list/',
  policyAllStakeholdersList = 'user-list/?request_for=contributor',
  policyAllRequestedByList = 'stake-holder-list/',
  policyApproversList = 'stake-holder-list/',
  policyAllReviewersList = 'stake-holder-list/',
  policyAllTeamList = 'stake-holder-list/',
  policyAllOwnerList = 'stake-holder-list/',
  policyAllMegaProcessList = 'dropdown-list/policy-management/mega_process/',
  policyAllMajorProcessList = 'dropdown-child-list/policy-management/major_process/',
  policyTaggableRisks = 'policymanagement/tagging_risk/add-list/',
  policyTaggableControls = 'policymanagement/tagging_control/add-list/',
  policyTaggableComplianceAudit = 'policymanagement/tagging_compliance/add-list/',
  policyTaggableBusinessProcess = 'policymanagement/tagging_process/add-list/',
  policyTaggableRequest = 'policymanagement/tagging_request/add-list/',
  policyStatusList = 'dropdown-list/policy-management/policy_management_status/',
  policyRequestList = 'policy-management/policy-request/dropdown/list/',
  policyCreate = 'policymanagement/create/',
  policyDepartment = 'dropdown/department/list/',
  policyHidenonConfigurable = 'incident-reporting/admin/policy-management/list/?configurable=Yes&pagination_config=all',
  allPolicyObjectGrid = 'incident-reporting/admin/policy-management/list/',
  controlpanelPolicyList = 'dropdown-list/policy-management',
  controlPanelIncident = 'dropdown-list/incident-reporting/',
  controlPanelBCP = 'dropdown-list/business-continuity-plan',
  controlPanelTimelogProject = 'dropdown-list/time-sheet/',
  policyControlPanelDropDrown = 'dropdown/',
  policyParentValue = 'parent-condition-megaprocess/major_process/list/?slug=policy-management',
  deletePolicyVersion = 'policymanagement-version-delete/',
  policyVersionRestore = 'policymanagement-version-restore/',
  policyVersionRecord = 'policy-management/version-record/list/',
  policyEditData = 'policymanagement/policy-edit/',
  policyDetailsById = 'policymanagement-detail/',
  policyDelete = 'policymanagement-delete/',
  policyDocumentList = 'policy-document-upload-list/',
  policyTaggedRiskList = 'policy/details/tagged-risk',
  policyTaggedControlList = 'policy/details/tagged-control',
  policyTaggedComplianceList = 'policy/details/tagged-compliance',
  policyTaggedBusinessProcessList = 'policy/details/tagged-process',
  untagPolicyRisk = 'policy/untag-risk/',
  untagPolicyControl = 'policy/untag-control/',
  untagPolicyCompliance = 'policy/untag-compliance/',
  untagPolicyBusinessProcess = 'policy/untag-process/',
  untagPolicyRequest = 'policy/tagged/request/',
  scenarioNetworkChart = 'analytics_builder/analytics-network/',
  dataProcessingPercentage = 'analytics_builder/druid/dataprocess/',
  issueTrackerRecommendation = 'all-auditrecommendation-list/',
  managementGrid = 'management-response/issue-tracker/list/',
  managementResponseGridForRiskProgram = 'risk-program/management-response/list/',
  managementResponseChart = 'filter-charts/?request_for=audit_planning&request_from=app&platform=web&',
  managementResponseChartRiskProgram = 'filter-charts/?request_for=risk_program&request_from=app&platform=web&',
  issueTrackerRecommendationChart = 'filter-charts/?request_for=issue_tracker&request_from=app&chart_type=audit_recommendation_dashboard&platform=web&',
  riskProgramIssueTrackerRecommendationChart = 'filter-charts/?request_for=issue_tracker&request_from=app&chart_type=risk_program_recommendation_dashboard&platform=web&',
  policyDocumentUpload = 'get/importdoc/file/',
  observationDashboardCharts = 'filter-charts/?request_for=issue_tracker&request_from=app&chart_type=observation_dashboard&platform=web',
  complianceObservationDashboardCharts = 'filter-charts/?request_for=compliance_audit&request_from=app&chart_type=dashboard-compliance-observation&platform=web',
  businessStrategyDocumentList = 'business-strategy-document-upload-list/',
  auditProgramDocumentList = 'working-paper-document-upload-list/',
  compauditProgramDocumentList = 'working-paper/',
  complianceAuditDocumentList='working-paper-document-upload-list/',
  businessStrategyPlanDropdown = 'business-strategy-plan/dropdown-list/',
  analyticsBuilderConnectToData = 'analytics-builder/connection/history/list/scenario/',
  strategyPlansList = 'business-strategy-plan/list/',
  strategyPlanCreate = 'business-strategy-plan/create',
  strategyPlanDetail = 'business-strategy-plan/',
  strategiesListInPlan = 'business-strategy-plan/strategy-list/',
  strategyPlanDropdownList = 'business-strategy-plan/dropdown-list/',
  digitalAuditReportPageGraph = 'audit-html-detail/',
  digitalRiskProgramReportPageGraph = 'risk-program-html-detail/',
  digitalComplianceReportPageGraph = 'compliance-html-detail/',
  auditProgramAnalyticData = 'working-paper/tagged-audit-analytics/',
  auditDetailAnalyticData = 'audit-planning/tagged-audit-analytics/',
  auditPlanModelData = 'audit-universe/model/list/',
  approvalEditOpen = 'approval-system/audit/',
  auditReviewDraftDelete = 'audit-draft/',
  policyReviewList = 'policy-management/review/list',
  policyAllExpertList = 'stake-holder-list/',
  policyAllLegalList = 'stake-holder-list/',
  policyGetReview = 'policy-management/policy-get-review/',
  viewLivePolicy = 'policy-management/live-policy/',
  viewPublicPolicy = 'policy-management/public-url/',
  publicPolicy = 'policy-management/public-policy/',
  policyReviewerType = 'dropdown-list/policy_management_reviewer_type/',
  policyTimeLine = 'policy-management/time-line/',
  policyRequestControlPanelFormEdit = 'policy-management/policy-request/control-panel/form-data/edit/',
  policyRequestCategoryTypeCreate = 'policy-management/policy-request/type/create/',
  policyRequestControlPanelDetails = 'policy-management/control-panel/form-data/',
  policyRequestCategoryList = 'policy-management/policy-request/type/list/',
  policyRequestHidenonConfigurable = 'incident-reporting/admin/policy-request/list/?configurable=Yes&pagination_config=all',
  allPolicyRequestObjectGrid = 'incident-reporting/admin/policy-request/list/',
  policyRequestCreate = 'policy-management/policy-request/create/',
  policyRequestEdit = 'policy-management/policy-request/',
  policyRequestTypeList = 'dropdown-list/policy-request/policy_request_type/',
  policyRequestRequestedByList = 'dropdown-list/policy-request/policy_request_requested_by/',
  policyRequestOversightList = 'dropdown-list/policy-request/oversight/',
  policyRequestPreviousVersionsList = 'policy-management/dropdown/list/',
  policyRequestStatus = 'dropdown-list/policy-request/policy_request_status/',
  policyRequestPriorityList = 'dropdown-list/policy-request/policy_request_priority/',
  policyRequestControlpanelList = 'dropdown-list/policy-request/',
  policyRequestCategory = 'policy-management/policy-request/type/',
  policyRequestDetailCreate = 'policy-management/policy-request/create-form/',
  policyRequestDetails = 'policy-management/policyrequest/details/',
  policyRequestApprove = 'policy-management/policy-request/approve/',
  policyRequestReject = 'policy-management/policy-request/reject/',
  policyRequestDocumentList = 'policy-request-document-upload-list/',
  getPolicyRequestAdditionalDropDownV1 = 'dropdown-list/policy-request/request_dropdown_v1/',
  getPolicyRequestAdditionalDropDownV2 = 'dropdown-list/policy-request/request_dropdown_v2/',
  getPolicyRequestAdditionalDropDownV3 = 'dropdown-list/policy-request/request_dropdown_v3/',
  getPolicyRequestAdditionalDropDownV4 = 'dropdown-list/policy-request/request_dropdown_v4/',
  getPolicyRequestAdditionalMultiDropDownV1 = 'dropdown-list/policy-request/request_multi_dropdown_v1/',
  getPolicyRequestAdditionalMultiDropDownV2 = 'dropdown-list/policy-request/request_multi_dropdown_v2/',
  getPolicyRequestAdditionalMultiDropDownV3 = 'dropdown-list/policy-request/request_multi_dropdown_v3/',
  getPolicyRequestAdditionalMultiDropDownV4 = 'dropdown-list/policy-request/request_multi_dropdown_v4/',
  complianceRequestDetails = 'compliance-management/compliancerequest/details/',
  requestDetails = 'request/details/',
  complianceRequestApprove = 'compliance-management/compliance-request/approve/',
  complianceRequestReject = 'compliance-management/compliance-request/reject/',
  requestReject = 'request/reject/',
  complianceRequestDocumentList = 'compliance-management/compliance-request/document-upload-list/',
  requestDocumentList = 'request/document-upload-list/',
  policyRequestCharts = 'filter-charts/?request_for=policy_request&request_from=app&platform=web&',
  requestCharts = 'filter-charts/?request_for=request&request_from=app&platform=web&',
  auditDraftWithdraw = 'audit-draft/',
  analyticsBuilderLoadMore = 'analytics-builder/druid/text-loadmore/',
  generateToken = 'socket/generate-token/',
  socketUrl = 'socket/live-feed/',
  auditRequestList = 'approval/audit-request-list/',
  policyRequestDelete = 'policy-management/policy-request/',
  policyDocumentCount = 'policy-compliance-request/',
  policyRequestLists = 'policyrequest/list/',
  policyReviewListForContributor = 'policymanagement/contributor/review/list/',
  policyReviewCommentCreate = 'contributer/review-comments/',
  policyReviewCommentEdit = 'review-comment/',
  policyReviewCommentList = 'contributor/review-comments/',
  policyReviewReviewed = 'policy-management/policy-status/change/',
  policyReviewVersionList = 'policymanagement/review-detail/version-list',
  policyReviewCommentReplyList = 'review-comment/',
  policyAreaList = 'dropdown-list/policy-management/policy_management_area/',
  policyRequestedByList = 'dropdown-list/policy-management/policy_management_requested_by/',
  auditActivityCreate = 'audit-program-activity/',
  riskProgramActivityCreate = 'risk-program-activity/',
  analyticsOverview = 'scenarios-by-process/',
  policyVersionRecordData = 'policy-management/review/version-record/list/',
  RiskListTagging = 'business-strategy/',
  IncidentsDetailCustomEdit = 'incident-reporting/incident/',
  auditPlanCreateApproval = 'audit-plan/create/?request_for=create-approval',
  auditPlanCreateWithoutApproval = 'audit-plan/create/',
  riskPlanCreate = 'risk-program-plan/create/',
  auditPlanDetails = 'approval-system/audit-plan-master/',
  incidentContributorCreateData = 'incident-reporting/incident/create/',
  activityActionEditDetail = 'approval-system/activity-action/',
  complianceHidenonConfigurable = 'incident-reporting/admin/compliance-audit/list/?configurable=Yes&pagination_config=all',
  allComplianceObjectGrid = 'incident-reporting/admin/compliance-audit/list/',
  complianceRequestHidenonConfigurable = 'incident-reporting/admin/compliance-request/list/?configurable=Yes&pagination_config=all',
  requestHidenonConfigurable = 'incident-reporting/admin/request/list/?configurable=Yes&pagination_config=all',
  complianceIssueHidenonConfigurable = 'incident-reporting/admin/compliance-issue/list/?configurable=Yes&pagination_config=all',
  allComplianceRequestObjectGrid = 'incident-reporting/admin/compliance-request/list/',
  allRequestObjectGrid = 'incident-reporting/admin/request/list/',
  allComplianceIssueObjectGrid = 'incident-reporting/admin/issue/list/',
  complianceAuditControlpanelList = 'dropdown-list/compliance-audit',
  complianceControlpanelDropdown = 'dropdown/',
  requestControlpanelDropdown = 'dropdown/',
  complianceParentValue = 'parent-condition-megaprocess/major_process/list/?slug=compliance-audit',
  complianceRequestControlpanelList = 'dropdown-list/compliance-request',
  requestControlpanelList = 'dropdown-list/request',
  complianceRequestFormDetails = 'compliance-management/control-panel/form-data/',
  requestFormDetails = 'request/control-panel/form-data/',
  complianceCategoryList = 'compliance-management/compliance-request/type/list/',
  requestCategoryList = 'request/type/list/',
  complianceRequestControlPanelFormEdit = 'compliance-management/compliance-request/control-panel/form-data/edit/',
  requestControlPanelFormEdit = 'request/control-panel/form-data/edit/',
  complianceRequestCategoryType = 'compliance-management/compliance-request/type/',
  requestCategoryType = 'request/type/',
  complianceAuditTableList = 'compliancemanagement/list/',
  complianceAllTypeList = 'dropdown-list/compliance-audit/compliance_audit_type/',
  complianceAreaList = 'dropdown-list/compliance-audit/compliance_audit_area/',
  complianceRequestedByList = 'dropdown-list/compliance-audit/compliance_audit_requested_by/',
  complianceAllMegaProcessList = 'dropdown-list/compliance-audit/mega_process/',
  complianceAllMajorProcessList = 'dropdown-child-list/compliance-audit/major_process/',
  complianceAllRatingList = 'dropdown-list/compliance-audit/compliance_audit_rating/',
  complianceStatusList = 'dropdown-list/compliance-audit/compliance_audit_status/',
  complianceAllRequestList = 'compliance-management/compliance-request/dropdown/list/',
  compliancePreviousVersionsList = 'compliance-audit/dropdown/list/',
  complianceTaggedRiskList = 'compliance-management/compliance-audit/details/tagged-risk',
  complianceTaggedControlList = 'compliance-management/compliance-audit/details/tagged-control',
  complianceTaggedBusinessProcessList = 'compliance-management/compliance-audit/details/tagged-process',
  complianceTaggedPolicyList = 'compliance-management/compliance-audit/details/tagged-policy',
  complianceTaggableRisks = 'compliance-management/compliance-audit/tagging_risk/add-list/',
  complianceTaggableControls = 'compliance-management/compliance-audit/tagging_control/add-list/',
  complianceTaggablePolicy = 'compliance-management/compliance-audit/tagging_policy/add-list/',
  complianceTaggableBusinessProcess = 'compliance-management/compliance-audit/tagging_process/add-list/',
  complianceTaggablRequest = 'compliance-management/compliance-audit/tagging_request/add-list/',
  untagComplianceRisk = 'compliance-management/compliance-audit/untag-risk/',
  untagComplianceControl = 'compliance-management/compliance-audit/untag-control/',
  untagCompliancePolicy = 'compliance-management/compliance-audit/untag-policy/',
  untagBcmPolicy ='bussinessprocess/untag-policy/',
  untagComplianceBusinessProcess = 'compliance-management/compliance-audit/untag-process/',
  complianceAuditCreate = 'compliance-management/compliance-audit/create/',
  complianceDocumentList = 'compliance-management/compliance-audit/compliance-document-upload-list/',
  complianceDetailsById = 'complianceaudit-detail/',
  complianceOccuranceList = 'dropdown-list/compliance-audit/compliance_audit_occurrence/',
  complianceEditData = 'compliance-management/compliance-audit/edit/',
  complianceAuditDelete = 'compliance-management/compliance-audit/delete/',
  complianceReviewListForContributor = 'compliance-management/compliance-audit/contributor/review/list/',
  complianceChartList = 'filter-charts/?request_for=compliance_audit&platform=web&request_from=app',
  complianceAuditPageFilter = 'get_filters/?request_for=compliance-audit',
  complianceDraftCount = 'global/draft-count/?slug=compliance-audit',
  complianceRequestDraftCount = 'global/draft-count/?slug=compliance-request',
  complianceReviewCount = 'global/review-count/?request_for=compliance_audit',
  policyRequestDraftCount = 'global/draft-count/?slug=policy-request',
  policyDraftCount = 'global/draft-count/?slug=policy-management',
  policyReviewCount = 'global/review-count/?request_for=policy_management',
  bcmDraftCount = 'global/draft-count/?slug=business-process',
  assetDraftCount = 'global/draft-count/?slug=asset-management',
  complianceRequestTypeList = 'dropdown-list/compliance-request/compliance_request_type/',
  requestTypeList = 'request/type/list/',
  requestRequestForTypeList = 'dropdown-list/request/type/',
  complianceRequestRequestedByList = 'dropdown-list/compliance-request/compliance_request_requested_by/',
  requestRequestedByList = 'dropdown-list/request/new_requested_by/',
  complianceRequestCategory = 'compliance-management/compliance-request/type/list/',
  requestCategory = 'dropdown-list/request/category/',
  complianceRequestPriorityList = 'dropdown-list/compliance-request/compliance_request_priority/',
  requestPriorityList = 'dropdown-list/request/priority/',
  complianceRequestCreate = 'compliance-management/compliance-request/create/',
  requestCreate = 'request/create/',
  complianceRequestEdit = 'compliance-management/compliance-request/',
  requestEdit = 'request/',
  requestDraftCount = 'global/draft-count/?slug=request',
  complianceRequestCreateFormDetails = 'compliance-management/compliance-request/create-form/',
  requestCreateFormDetails = 'request/create-form/',
  getAuditAnalyticsComment = 'contributor/comments/',
  complianceTimeLine = 'compliance-management/compliance-audit/time-line/list/',
  complianceReviewList = 'compliance-management/review/list/',
  complianceReviewVersionList = 'compliance-management/compliance-audit/review-detail/version-list',
  complianceVersionRecordData = 'compliance-management/compliance-audit/review/version-record/list/',
  complianceReviewReviewed = 'compliance-management/compliance-audit/compliance-status/change/',
  untagComplianceProcess = 'compliance-management/compliance-audit/untag-process/',
  tagKriBcm = 'kri/tagging-bcm/',
  tagWithCompliance = 'compliance-management/compliance-audit/',
  tagWithBcm = 'bussinessprocess/',
  tagWithComplianceIncident = 'compliance-audit/',
  tagWithBCMIncident = 'bussinessprocess/',
  tagRiskWithKri = 'kri/tagging-risk/',
  tagControKri = 'kri/tagging-control/',
  complianceTaggedRisk = 'compliance-management/compliance-audit/details/tagged-risk/',
  complianceTaggedControl = 'compliance-management/compliance-audit/details/tagged-control/',
  complianceTaggedPolicy = 'compliance-management/compliance-audit/details/tagged-policy/',
  BcmTaggedPolicy = 'bussinessprocess/tagged-policy/',
  complianceTaggedProcess = 'compliance-management/compliance-audit/details/tagged-process/',
  taggableComplianceRisk = 'compliance-management/compliance-audit/tagging_risk/add-list/',
  taggableComplianceControl = 'compliance-management/compliance-audit/tagging_control/add-list/',
  taggableCompliancePolicy = 'compliance-management/compliance-audit/tagging_policy/add-list/',
  taggableBcmPolicy = 'bussinessprocess/tagging_policy/add-list/',
  bcmTaggablePolicy = 'bussinessprocess/tagging_policy/add-list/',
  taggableComplianceProcess = 'compliance-management/compliance-audit/tagging_process/add-list/',
  bcmTaggedProcess = 'business-process/tagged-assessment/',
  taggedProcess = 'business-process/up-down-stream/',
  bcmScoringFactor = 'business-process/scoring-factor/categories/list',
  bcmRangeData = 'scoring-factor/logic/details?request_for=Process Impact Scoring',
  bcmDetailScoring = 'business-process/scoring-factor/',
  bcmScoringCreate = 'business-process/scoring-factor/create',
  bcmRatingExpression = 'scoring/logic/?request_for=Process Impact Scoring',
  tagProcessList = 'bussinessprocess/upstream-tagging/add-list/?',
  complianceAuditVersion = 'complianceaudit/version-list',
  complianceAuditVersionRecord = 'compliance-management/compliance-audit/version-record/list/',
  complianceAuditVersionRestore = 'compliance-management/compliance-audit/complianceaudit-version-restore/',
  deleteComplianceAuditVersion = 'compliance-management/compliance-audit/compliance-audit-version-delete/',
  publicCompliancePolicy = 'compliance-management/compliance-audit/public-policy/',
  viewPublicCompliancePolicy = 'compliance-management/compliance-audit/public-url/',
  viewLiveCompliancePolicy = 'compliance-management/compliance-audit/live-policy/',
  complianceSummaryView = 'complianceaudit-detail/',
  complianceReviewerType = 'dropdown-list/compliance-audit/compliance_audit_reviewer_type/',
  complianceAuditDocument = 'compliance-management/compliance-audit/compliance-document-upload-list/',
  riskProgramDocumentList = 'risk-program-upload-list/',
  complianceAuditGetReview = 'compliance-management/compliance-audit/compliance-get-review/',
  complianceAuditAddUser = 'compliance-management/compliance-audit/user-add/list/',
  untagComplianceAuditUsers = 'compliance-management/compliance-audit/management-team/',
  complianceAuditTeamList = 'compliance-management/compliance-audit/detail/team/list/',
  complianceAuditTaggedUsers = 'compliance-management/compliance-audit/detail/',
  complianceReviewCommentCreate = 'contributer/review-comments/',
  complianceReviewCommentList = 'contributor/review-comments/',
  complianceReviewCommentReplyList = 'review-comment/',
  complianceAuditActionDelete = 'compliance-management/compliance-audit/action/',
  complianceAuditActionEdit = 'compliance-management/compliance-audit-action-edit/',
  complianceActionsEdit = 'compliance/action/',
  complianceAuditObservationActionEdit = 'compliance-audit/observation/action/',
  complianceAuditActionCreate = 'compliance-audit-action-create/',
  complianceAuditActionList = 'action-compliance-audit/list/',
  complianceActionList = 'compliance/',
  complianceAuditActionDetailPage = 'compliance-management/compliance-audit/action/detail/',
  complianceAuditObservationActionDetailPage = 'compliance-audit/observation/action/',
  complianceAuditTaggedAssessments = 'compliance-management/compliance-tagged-assesments/',
  incidentReportingTaggedAssessments ='incident-reporting/tagged-assessment/',
  tagWithIncidentReport = 'incident-reporting/tagging-assessment/',
  complianceAuditTaggableAssessments = 'compliance-management/compliance-audit/',
  kri = 'kri/',
  complianceAuditAssessmentsDelete = 'compliance-management/compliance-tagged-assesments-delete/',
  incidentReportingAssessmentDelete ='incident-reporting/tagged-assessment/',
  complianceAuditAssessmentActionList = 'compliance-management/compliance-audit/action-assessment/list/',
  complianceRequestCharts = 'filter-charts/?request_for=compliance_request&request_from=app&platform=web&',
  complianceRequestDelete = 'compliance-management/compliance-request/',
  complianceRequestLists = 'compliance-management/compliance-request/list/',
  requestLists = 'request/list/',
  requestDelete = 'request/',
  getGridData = 'dashboard_builder/create_grid/?slug=',
  notificationList = 'reminder-notifications/',
  newNotificationList = 'reminder-notifications/submodule/list',
  setupWizard = 'setup-wizard/',
  setupWizardStatus = 'setup-wizard/status/',
  approvalSystemIssue = 'approval-system/issue/',
  issueEditReopen = 'approval/edit/reopen/V2/',
  complianceAuditExportCSV = '/compliance-management/compliance/csv_download',
  complianceAuditExportXLS = '/compliance-management/compliance/xls_download',
  complianceAuditExport = 'compliance-management/compliance-audit/export/',
  complianceRequestExportCSV = '/compliance-management/compliance-request/csv_download',
  complianceRequestExportXLS = '/compliance-management/compliance-request/xls_download',
  observationDashboardExportCSV = '/audit-reporting/dashboard/observation/csv_download',
  improvementExportCSV = '/audit-reporting/download-csv-improvement/csv_download/',
  improvementExport = 'improvements-export/',
  improvementActionCeleryExportCSV = 'audit-improvement-action-export/',
  improvementActionCeleryExportXSL = 'audit-improvement-action-export/',
  improvementContributorExportCSV = '/audit-reporting/download-csv-contributor-improvement/csv_download',
  improvementActionExportCSV = '/audit-reporting/download-csv-improvement-action/csv_download/',
  improvementCelleryResponseExport = 'improvement-mr-export/',
  improvementResponseExportCSV = '/audit-reporting/download-csv-improvement-management-response/csv_download/',
  complianceAuditRecommendationDashboardExportCSV = '/compliance-management/compliance_observation_recommendation_download_csv',
  complianceAuditRecommendationDashboardExportXLS = '/compliance-management/compliance_observation_recommendation_download_xls',
  complianceAuditMRDashboardExportCSV = '/compliance-management/compliance_audit_management_response_download_csv',
  complianceAuditActionDashboardExportCSV = '/compliance-management/compliance_action_issue_tracker/csv_download',
  complianceAuditMRDashboardExportXLS  = '/compliance-management/compliance_audit_management_response_download_xls',
  complianceAuditActionDashboardExportXLS  = '/compliance-management/compliance_action_issue_tracker/xls_download',
  complianceIssuetrackerIssuesDashboardExportCSV = '/compliance-management/compliance_audit_issue_download_csv',
  complianceIssuetrackerIssuesDashboardExportXLS = '/compliance-management/compliance_audit_issue_download_xls',
  complianceObservationDashboardExportCSV = '/compliance-management/dashboard/compliance-observation/csv_download',
  observationDashboardExportXLS = '/audit-reporting/dashboard/observation/xls_download',
  improvementExportXLS = '/audit-reporting/download-xls-improvement/xls_download/',
  improvementContributorExportXLS = '/audit-reporting/download-xls-contributor-improvement/xls_download',
  improvementActionExportXLS = '/audit-reporting/download-xls-improvement-action/xls_download/',
  improvementResponseExportXLS = '/audit-reporting/download-xls-management-response/xls_download/',
  auditPlanModelExport = '/api/v5/muilti-year/audit-plan-download-export/',
  complianceObservationDashboardExportXLS = '/compliance-management/dashboard/compliance-observation/xls_download',
  policyExportCSV = '/policy-management/policy-management/policy/csv_download',
  policyExportXLS = '/policy-management/policy-management/policy/xls_download',
  policyRequestExportCSV = '/policy-management/policy-management/policy-request/csv_download',
  policyExport = 'policy-management/policy-export/',
  policyRequestExportXLS = '/policy-management/policy-management/policy-request/xls_download',
  policyCountAPi = 'policy-management/',
  policyVersioncount = 'policymanagement/version-count/',
  policySignOffCount = 'policy-sign-off/',
  businessProcessExportCSV = '/business-continuity/business-process/csv_download',
  businessProcessExportXLS = '/business-continuity/business-process/xls_download',
  assetExportCSV = '/business-continuity/asset-management/csv_download',
  assetExportXLS = '/business-continuity/asset-management/xls_download',
  assetExport = 'asset-management/export/',
  timeLogTableList = 'time-sheet-task/list?request_for=my_time_log',
  timeLogReviewTableList = 'time-sheet-request/list?request_for=my_time_log',
  riskDynamicList = 'risk-assessment/dynamic-field/list/',
  dynamicFieldList = 'dynamicfields/list/',
  fieldListEdit = 'dynamic/edit/',
  fraudulantList = 'dropdown-list/risk-assessment/fraud_related/',
  decisionMaker = 'user-list/',
  mitigationStrategyResponsibility = 'dropdown-list/risk-assessment/risk_mitigation_strategy_responsibility_v1/',
  mitigationStrategyEffectiveness = 'dropdown-list/risk-assessment/risk_mitigation_strategy_effectiveness/',
  riskUniverseDynamicFields = 'risk-assessment/dynamic-field/list/',
  businessStrategyDynamicList = 'business-strategy/dynamic-field/list/',
  riskIssueDynamicField = 'risk-assessment/issue/dynamic-field/list/',
  riskRegisterDynamicFields = 'risk-plan/dynamic-field/list/',
  riskIssueDynamicGridList = 'risk-assessment/tagged-issue/',
  riskActionDynamicFields = 'risk-action/dynamic-field/list/',
  strategyPlanDynamicList = 'business-strategy-plan/dynamic-field/list/',
  strategyPlanStatus = 'dropdown-list/risk-assessment/business_plan_status',
  auditActivityDynamicFields = 'auditprogramactivity/dynamic-field/list/',
  auditActivityDynamicFieldList = 'auditprogramactivity/dynamic-field/list/',
  riskProgramActivityDynamicFieldList = 'risk-program-activity/dynamic-field/list/',
  RiskRegisterStatusDropDown = 'dropdown-list/risk-assessment/risk_plan_status/',
  compliancePlanStatusDropDown = 'dropdown-list/compliance-audit/compliance_plan_status/?',
  auditCalendarPlan = 'calender/audit-universe/list',
  riskPlanListDropdown = 'risk-program/risk-plan/dropdown/list/',
  controlDynamicFields = 'risk-assessment/detail/dynamic-field/list',
  updateOrder = 'dropdown-order/update/',
  bcmNewExport = 'bcm/export/',
  ObsRecommendationDynamicFieldList = 'audit-reporting/recommendation/dynamic-field-list/',
  dynamicControl = 'audit/risk-control/dynamic-field/header/list/?request_for=risk-assessment-details',
  dynamicBcmHeader = 'bussinessprocess/dynamic-field/header/list/?request_for=business-process',
  dynamicPolicyHeader ='bussinessprocess/dynamic-field/header/list/?request_for=policy-management',
  dynamicRisk = 'audit/risk-control/dynamic-field/header/list/?request_for=risk-assessment',
  issueActionsList = 'risk-issue-tagged-action/',
  issueActionDynamicFields = 'risk-issue-action/dynamic-field/list/',
  controlDetailsDynamicFields = 'audit/risk-control/dynamic-field/header/list/?request_for=risk-assessment-details&request_type=detail-header',
  managementResponseDynamic = 'audit-planning/managementresponse/dynamic-field-list/',
  riskProgramManagementResponseDynamic = 'risk-program/managementresponse/dynamic-field-list/',
  riskDetailsDynamicFields = 'audit/risk-control/dynamic-field/header/list/?request_for=risk-assessment&request_type=detail-header',
  auditObservationActionDynamicFieldList = 'audit-planning/action/dynamic-field-list/',
  observationRatingList = 'observation/rating-factor/list',
  observationCompRatingList = 'dropdown-list/compliance-audit/compliance_observation_rating/',
  observationCompRatingFactor = 'compliance-observation/rating-factor/list',
  observationRatingDrawGraph = 'observation/rating/draw-graph',
  observationCompRatingDrawGraph = 'compliance/observation/rating/draw-graph',
  dependenciesTaggedPolicy = 'policy-management/policy-request/details/tagged-policy/',
  dependenciesTaggablePolicy = 'policy-request/tagging_policy-audit/add-list/',
  dependenciesAddTaggablePolicy = 'policy-management/policy-request/',
  dependenciesDeleteTaggedPolicy = 'policy-request/untag-policy/',
  dependenciesTaggedCompliance = 'compliance-management/compliance-request/details/tagged-compliance-audit/',
  dependenciesTaggableCompliance = 'compliance-request/tagging_compliance-audit/add-list/',
  dependenciesAddTaggableCompliance = 'compliance-management/compliance-request/',
  dependenciesDeleteTaggedCompliance = 'compliance-request/untag-compliance-audit/',
  annexureGridList = 'upload-file/annexure/list',
  createAnnexure = 'upload-file/annexure',
  editAnnexure = 'annexure-file/',
  deleteAnnexure = 'annexure/',
  auditRcmFields = 'rcmfields/list/?request_for=',
  editAuditRcmFields = 'rcmfields/edit/?request_for=',
  auditRcmTableData = 'rcm-table-list/list/',
  auditRcmTableDataforComplianceObservationDashboard= 'dashboard-complianceobservation-matrices/list/',
  auditPlanRcmTableData = 'audit-plan/audit-universe/rcm-table',
  getNotificationsModuleList = 'reminder-notifications/modeule-list/',
  getNotificationsUserFieldList = 'reminder-notifications/user_field/list',
  getNotificationsFieldList = 'reminder-notifications/fields/list',
  getReminderType = 'reminder-notifications/reminder_type/list',
  getReminderDate = 'reminder-notifications/date_fields/list',
  getConditionMultiselect = 'reminder-notifications/dynamic/dropdown/list/',
  getReminderDetail = 'reminder-notifications/reminder/',
  getDynamicRecordList = 'reminder-notifications/dynamic/record/list/',
  reminderCreate = 'reminder-notifications/reminder_create',
  workflowUploader = 'workflow-uploader/',
  complianceWorkflowList = 'compliance-workflow-upload-list/',
  policyWorkflowList = 'policy-workflow-upload-list/',
  workflowFileUploader = 'workflow-file-uploader/',
  bulkDeleteDocument = 'file-uploader/bulk/document/delete/',
  observationAnnexureUpload = 'observation/annexure/clone/',
  auditRcmExport = '/audit-planning/rcm_export',
  auditRcmMatrixExport = '/audit-planning/rcm_matrix_export',
  complianceObservationDashboardRcmExport ='/compliance-management/compliance_observation_rcm_matrix_export',
  complianceObservation = 'compliance-observation-matrix/export/',
  observations = 'observation/',
  riskProgramObservations = 'risk-program/observation/management-response/',
  improvementResponse = 'audit-reporting/improvement/management-response/',
  riskProgramMRAdd = 'risk-program/observation/',
  responseDocumentList = 'audit-report-upload-list/',
  teamsTimeLog = 'time-sheet-task/list',
  timeSheetActivityList = 'time-sheet/stage-activities/list',
  teamsProjectList = 'timelog-project/list/',
  timelogProjectDynamicField = 'timelog-project/dynamic-field/list/',
  getSourceDataField = 'risk-audit-data-list/timelog-project/',
  createTimelogProject = 'timelog-project/create/',
  timlogProjectDetails = 'timelog-project/',
  timelogProjectDropdown = 'dropdown-list/time-sheet',
  teamsTimeLogRequest = 'time-sheet-request/list',
  timeLogApprove = 'time-sheet/approve/',
  timeLogReject = 'time-sheet/reject/',
  timeSheetCategory = 'dropdown-list/time-sheet/time_log_category/',
  timeSheetTimeStatus = 'admin/features/list?slug=time-entry-by-start-and-end-time&module=time-sheet',
  timesheetEdit = 'time-sheet-edit/',
  riskAssessmentBulkDelete = 'tagged-assessment/bulkdelete',
  riskDraftList = 'risk-assessment/draft/list/',
  riskDraftCount = 'risk-assessment/draftlist/dependency/count/',
  assessmentTableList = 'assignedassessments/',
  getObservationRecommendationList = 'audit-reporting/observation/recommendation/',
  tagControlRisk = 'risk-assessment/details/',
  tagControl = 'controls/entity-list/',
  tagControlERM = 'control/erm/',
  tagIncidentERM = 'enterprise-risk/',
  reminderModuleList = 'bulk-reminder/dropdown-list/',
  managementResponseList = 'management-response/issue-tracker/list/',
  managementResponseListComplianceDashboard = 'compliance-audit/issue-tracker/management-response/list/',
  actionListComplianceDashboard = 'compliance-audit/issue-tracker/action/list/',
  observationActionListComplianceDashboard = 'compliance-audit/issue-tracker/observation-action/list/',
  riskProgramIssueTrackerResponseChart = 'filter-charts/?request_for=issue_tracker&request_from=app&chart_type=risk_program_issue_tracker_management_response_dashboard&platform=web',
  issueTrackerResponseChart = 'filter-charts/?request_for=issue_tracker&request_from=app&chart_type=audit_management_response_dashboard&platform=web',
  complianceIssueTrackerResponseChart = 'filter-charts/?request_for=compliance_audit&request_from=app&chart_type=compliance_management_response_issuetracker&platform=web',
  listModules = 'records-module/list',
  recordsListCount = 'records-list-user-count/',
  getRecordList = 'records-list-user/',
  getRecordListByUser = 'records-list-by-user/',
  user = 'user/',
  bulkReminderList = 'reminder/scheduled-reminder-list/',
  reminderRecordList = 'reminder/dynamic-record-list/',
  reminderStatusList = 'reminder/dynamic-record-status-list/',
  bulkReminderDetails = 'reminder/scheduled-reminder/',
  timeLogHidenonConfigurable = 'incident-reporting/admin/time-sheet/list/?configurable=Yes',
  timeLogProjectHidenonConfigurable = 'incident-reporting/admin/timelog-project/list/?configurable=Yes',
  allTimeLogObjectGrid = 'incident-reporting/admin/time-sheet/list/',
  allTimeLogProjectObjectGrid = 'incident-reporting/admin/timelog-project/list/',
  timeLogControlpanelList = 'dropdown-list/time-sheet',
  bulkRequest = 'management-response/bulk-request/',
  bulkRequestRiskProgram = 'risk-program-management-response/bulk-request/',
  complianceObservationStatus = 'dropdown-list/compliance-audit/compliance_observation_status/',
  complianceObservationRatng = 'dropdown-list/compliance-audit/compliance_observation_rating/',
  complianceObservationRatingList = 'compliance-observation/rating-factor/list',
  complianceobservationRatingDrawGraph = 'compliance/observation/rating/draw-graph',
  complianceObservationCreate = 'compliance-audit/observation/',
  complianceObservationControls = 'compliance-audit/tagged_control/',
  complianceObservationRisks = 'compliance-audit/tag_risk/',
  complianceObservationAssessments = 'compliance-audit/observation-tagged-assessment/',
  complianceObservationTaggingRisk = 'compliance-observation-risk/tagging-list/',
  complianceObservationTaggingControl = 'compliance-observation/tagging_control/list/',
  complianceObservationDocumentList = 'compliance-report-upload-list/',
  complianceAuditObservation = 'compliance-audit/observation/',
  assessmentAuditObservation = 'working-paper/observation/untag-observation/',
  observationMinMaxValue = 'observation/scoring-model/min_max_value',
  observationRatingEdit = 'observation/scoring-model/edit',
  newObservationRatingStatus = 'observation/rating/feature/status',
  reminderDurationList = 'bulk-reminder/fields-dropdown-list/?field=schedule_type',
  withdrawReminder = 'reminder/',
  observationDetailDynamicFields = 'auditobservations/dynamic-field/list/',
  auditImprovementDynamicFields= 'improvements/dynamic-field/list/',
  reassignJobsList = 'mass-reassign/list/',
  deleteReassignedJob = 'mass-reassign/',
  megaProcessList = 'mega-major-process/list/',
  riskMajorProcessList = 'major-process/list/',
  megaMajorCreate = 'mega-major-process/create/',
  suggestionList = 'table-filter/suggestion-list/?',
  auditRcmExportForAuditPlan = '/audit-planning/rcm_export_audit_universe',
  fiscalYearList = 'lookups/fiscal-year-list/',
  fiscalDropdown = 'audit/audit-fiscal-year-list/',
  fiscalYearSave = 'lookups/fiscal-year-add/',
  sectorsList = 'admin-sector-list/',
  createSector = 'admin-sector/create/',
  sectorDetails = 'admin-sector-detail/',
  editSector = 'admin-sector-edit/',
  sectorDelete = 'admin-sector/',
  sortEntity = 'entity-order-update/',
  sortRegion = 'region-order-update/',
  sortDepartment = 'department-order-update/',
  sortSector = 'sector-order-update/',
  newProcessList = 'mega-major-process/',
  newSubProcessList = 'major-process/list/',
  auditMajor = 'major-process/list/',
  auditClause = 'clause-dropdown/list/',
  incidentMajor = 'public/major-process/list/',
  incidentMega = 'public/mega-process/list/',
  complianceObservationRatingEdit = 'compliance-audit/observation/scoring-model/edit',
  complianceScoringModelsList = 'compliance-audit/scoring-model/list',
  complianceObservationMinMaxValue = 'compliance-audit/observation/scoring-model/min_max_value',
  adminComplianceAuditRecommendationDataList = 'dropdown-list/compliance-audit/',
  complianceObservationDetail = 'compliance-audit/observation/',
  complianceObservationRecommendation = 'compliance-audit/recommendation/',
  complianceManagementResponse = 'observation/compliance-management-response/',
  getComplianceObservationRecommendationList = 'compliance-audit/observation/recommendation/',
  complianceRecommendationDetails = 'compliance-audit/recommendation/',
  complianceObservationControlsList = 'compliance-audit/tagged_control/',
  complianceObservationRisksList = 'compliance-audit/tag_risk/',
  complianceObservationAssessmentsList = 'compliance-audit/observation-tagged-assessment/',
  complianceAnnexureGridList = 'upload-file/compliance/annexure/list',
  editComplianceObservationAnnexure = 'annexure-file/compliance/',
  deleteComplianceObservationAnnexure = 'compliance/annexure/',
  complianceObservationAnnexureUpload = 'compliance/observation/annexure/clone/',
  complianceObservationResponseStatus = 'dropdown-list/management-response/compliance-audit/management_status',
  complianceObservationTaggedCount = 'compliance-observation/',
  complianceRecommendation = 'compliance-recommendation/',
  complianceRequestPendingLists = 'compliance-management/compliance-request-pending/list/',
  policyRequestPendingLists = 'policyrequest-pending/list/',
  documentUploadRiskIssue = 'issue-document-upload-list/',
  categoryCreate = 'erm-category/create',
  genericTagCreate = 'generic-dropdown/create',
  categoryEdit = 'erm-category/',
  categoryDetail = 'erm/category/list',
  askAuraa = 'nlp/suggestion',
  askAuraaFilter = 'nlp/parse',
  createErmRegister = 'erm-register/create',
  ermRegister = 'erm-register/',
  allErmRisk = 'enterprise-risk/list',
  allErmQuestions = 'erm-questions/list/',
  allErmAssessments = 'erm-assessments/list/',
  allErmAssessmentPlan = 'erm-assessment-plans/list/',
  riskErmDraftCount = 'draftlist/dependency/count/',
  ermDraftList = 'enterprise-risk/draft/list',
  categoryDelete = 'erm-category/',
  ermRiskDelete = 'enterprise-risk/',
  ermQuestionDelete = 'erm-questions/',
  ermAssessmentDelete = 'erm-assessments/',
  ermAssessmentPlanDelete = 'erm-assessment-plans/',
  ermRegisterStatusDropDown = 'dropdown-list/enterprise-risk/register_status',
  ermRegisterDynamicFields = 'erm-register/dynamic-field/list',
  adminComplianceObservationList = 'incident-reporting/admin/compliance-observation/list/?pagination_config=all',
  adminComplianceObservationNonConfigList = 'incident-reporting/admin/compliance-observation/list/?configurable=Yes&pagination_config=all',
  ComplianceRecommendationConfigurable = 'incident-reporting/admin/compliance-recommendation/list/?configurable=Yes&pagination_config=all',
  adminComplianceRecommendationList = 'incident-reporting/admin/compliance-recommendation/list/?pagination_config=all',
  newComplianceObservationRatingStatus = 'compliance-audit/observation/rating/feature/status',
  adminComplianceAnnexureGridList = 'upload-file/compliance/annexure/list',
  complianceCreateAnnexure = 'upload-file/compliance/annexure',
  complianceEditAnnexure = 'annexure-file/compliance/',
  ComplianceDeleteAnnexure = 'annexure/',
  complianceObservationList = 'compliance-audit/observation/',
  complianceObservationResponse = 'compliance-management-response/bulk-request/',
  designationGrid = 'designation/list/',
  designationCreate = 'admin-designation-create/',
  designationEdit = 'designation/',
  designationDelete = 'designation/',
  designationDetail = 'designation/list/',
  sortDesignation = 'designation-order-update/',
  complianceManagementResponseChart = 'filter-charts/?request_for=compliance_audit&request_from=app&platform=web&',
  complianceManagementGrid = 'compliance-management-response/issue-tracker/list/',
  complianceRecommendationList = 'compliance/recommendation/',
  ermRiskGraph = 'filter-charts/?request_for=enterprise_risk&request_from=app&platform=web',
  ermAssessmentGraph = 'filter-charts/?request_for=enterprise_risk_assessment&request_from=app&platform=web',
  ermRiskImpactDropdown = 'dropdown-list/enterprise-risk/inherent_impact/',
  ermClone = 'erm-clone/',
  ermRegisterList = 'erm-register/list',
  enterpriseRiskCategoryDropDown = 'erm/category/list',
  questionListForRisk = 'erm-risk-questions/list/?risk=',
  assessmentPlanList = 'erm-assessment-plans/list/?is_active=true',
  ermAssessmentScoringModel = 'erm-assessment-scoring-model/',
  ermAssessmentDetails = 'erm-assessments/list/?object_id=',
  divisionList = 'division/list/',
  ermRiskListForCategory = 'erm-category-risks/list/?category=',
  ermQuestionDetails = 'erm-questions/list/?object_id=',
  createErmQuestions = 'enterprise-risk-assessment/questionaire/create/',
  createErmQuestion = 'erm-questions/create/',
  createErmAssessment = 'erm-assessments/create/',
  editErmQuestion = 'erm-questions/update/',
  enterpriseRisk = 'erm/header/',
  enterpriseRiskIdentifier = 'dropdown-list/enterprise-risk/erm_identifier/',
  enterpriseRiskTypeDropDown = 'dropdown-list/enterprise-risk/erm_type/',
  enterpriseRiskTreatmentDropDown = 'dropdown-list/enterprise-risk/treatment/',
  enterpriseRiskStatusDropDown = 'dropdown-list/enterprise-risk/status/',
  enterpriseRiskFraudRelatedDropdown = 'dropdown-list/enterprise-risk/fraud_related/',
  enterpriseRiskMitigationStrategyResponsibility = 'dropdown-list/enterprise-risk/risk_mitigation_strategy_responsibility_v1/',
  enterpriseRiskMitigationStrategyEffectiveness = 'dropdown-list/enterprise-risk/risk_mitigation_strategy_effectiveness/',
  sectorDropdown = 'admin-sector-list/',
  sectorDropdownPublic = 'public-sector-list/',
  enterpriseRiskDetail = 'erm/header/detail/',
  enterpriseRiskDynamicList = 'erm/dynamic-field/list/',
  ermCategoryDynamicList = 'erm-category/dynamic-field/list/',
  enterpriseRiskFieldGrid = 'dropdown-list/enterprise-risk/',
  bcmControlPanel = 'dropdown-list/business-process/',
  adminErmUniverse = 'incident-reporting/admin/enterprise-risk/list/',
  ermHidenonConfigurable = 'incident-reporting/admin/enterprise-risk/list/?configurable=Yes&pagination_config=all',
  adminErmDetails = 'dropdown-list/enterprise-risk/',
  enterpriseRiskLikelihoodDropDown = 'dropdown-list/enterprise-risk/inherent_likelihood/',
  enterpriseRiskImpactDropDown = 'dropdown-list/enterprise-risk/inherent_impact/',
  uploadDocumentInAuditReport = 'digital-report/',
  complianceRecommendationDynamicList = 'compliance-audit/recommendation/dynamic-field-list/',
  complianceDynamicFieldList = 'complainceobservations/dynamic-field/list/',
  complianceManagementResponseDynamic = 'compliance-audit/managementresponse/dynamic-field-list/',
  complianceObservationDynamicFields = 'complainceobservations/dynamic-field/list/',
  complianceWorkingPaperDynamicFields = 'compliance-audit/dynamic-field/header/list/?request_for=working_paper',
  filterApiForObservationDashboard = 'get_filters/?request_for=audit-reporting&dynamic_fields=true',
  filterApiForComplainceObservationDashboard = 'get_filters/?request_for=compliance-observation&dynamic_fields=true',
  auditIssueDynamicFieldList = 'audit-planning/issue/dynamic-field-list/',
  complainceIssueDynamicFieldList = 'compliance-audit/issue/dynamic-field-list/',
  onGoingAuditList = 'audit/risk-list-from-control/?',
  onGoingRiskProgramList = 'risk-program/risk-list-from-control/?',
  complianceObservationExportCSV = '/compliance-management/download-csv-compliance-observation/',
  complianceObservationExportXLS = '/compliance-management/download-xls-compliance-observation/',
  complianceObservationExport = 'compliance-management/compliance-audit/observation-export/',
  complianceRecommendationPriority = 'dropdown-list/compliance-audit/compliance_observation_recommendation_priority/',
  complianceRecommendationStatus = 'dropdown-list/compliance-audit/compliance_observation_recommendation_status/',
  designationDropdownList = 'designation/list/',
  keyRiskDropDown = 'dropdown-list/risk-assessment/key_risk/',
  topRiskDropDown = 'dropdown-list/risk-assessment/top_risk/',
  riskDepartmentDropDown = 'dropdown-list/risk-assessment/department/',
  riskAppetiteDropDown = 'dropdown-list/risk-assessment/risk_appetite/',
  riskWithinAppetiteDropDown = 'dropdown-list/risk-assessment/risk_within_appetite/',
  riskMigrationRequiredDropDown = 'dropdown-list/risk-assessment/risk_mitigation_required/',
  targetResidualRiskDropDown = 'dropdown-list/risk-assessment/target_residual_risk/',
  riskAdditionalDepartmentDropDown = 'dropdown-list/risk-assessment/additional_department/',
  riskExtraDropDown = 'dropdown-list/risk-assessment/risk_extra_dropdown/',
  riskAdditionalDropDown = 'dropdown-list/risk-assessment/risk_additional_dropdown/',
  riskNewDropDown = 'dropdown-list/risk-assessment/risk_new_dropdown/',
  riskAuxiliaryDropDown = 'dropdown-list/risk-assessment/risk_auxiliary_dropdown/',
  riskSubsidiaryDropDown = 'dropdown-list/risk-assessment/risk_subsidiary_dropdown/',
  extraRiskDropDown = 'dropdown-list/risk-assessment/extra_dropdown_risk/',
  additionalDropDown = 'dropdown-list/risk-assessment/additional_dropdown_risk/',
  newDropDown = 'dropdown-list/risk-assessment/new_dropdown_risk/',
  complianceAuditDynamicFieldList = 'compliance-audit/dynamic-field-list/',
  incidentDynamicList = 'incident-reporting/dynamic-field/list/',
  incidentActionDynamicList = 'incident-action/dynamic-field/list/',
  incidentPBUDynamicList = 'incident-reporting/dynamic-field/public/list/',
  residualScoringFactorList = 'scoring-model-factor/residual/list',
  residualScoringEdit = 'risk-assessment/residual/',
  exportScenario = 'scenario_download_',
  complianceAuditActionDynamicFieldList = 'compliance-audit-action/dynamic-field-list/',
  complianceActionDynamicFieldList = 'compliance-audit/observation/action/dynamic-field-list/',
  complianceAuditObservationActionDynamicFieldList = 'compliance-audit/observation/action/dynamic-field-list/',
  complianceDurationList = 'dropdown-list/compliance-audit/compliance_duration/',
  getComplianceAdditionalDropDownV1 = 'dropdown-list/compliance-audit/compliance_additional_dropdown_v1/',
  getComplianceAdditionalDropDownV2 = 'dropdown-list/compliance-audit/compliance_additional_dropdown_v2/',
  getComplianceAdditionalDropDownV3 = 'dropdown-list/compliance-audit/compliance_additional_dropdown_v3/',
  getComplianceAdditionalDropDownV4 = 'dropdown-list/compliance-audit/compliance_additional_dropdown_v4/',
  getComplianceMultiDropDownV1 = 'dropdown-list/compliance-audit/compliance_additional_multi_dropdown_v1/',
  getComplianceMultiDropDownV2 = 'dropdown-list/compliance-audit/compliance_additional_multi_dropdown_v2/',
  getComplianceMultiDropDownV3 = 'dropdown-list/compliance-audit/compliance_additional_multi_dropdown_v3/',
  getComplianceMultiDropDownV4 = 'dropdown-list/compliance-audit/compliance_additional_multi_dropdown_v4/',
  getTimelogProjectDropdownv1 = 'dropdown-list/time-sheet/dropdown_v1/',
  getTimelogProjectDropdownv2 = 'dropdown-list/time-sheet/dropdown_v2/',
  getTimelogProjectMultiDropdownv1 = 'dropdown-list/time-sheet/multi_dropdown_v1/',
  getTimelogProjectMultiDropdownv2 = 'dropdown-list/time-sheet/multi_dropdown_v2/',
  businessStrategyFilterApi = 'get_filters/?request_for=business-strategy',
  dashboardBuilderChartFilter = 'dashboard_builder/chart/filter/list/',
  complianceRequestDynamicList = 'compliance-request/dynamic-field/list/',
  requestDynamicList = 'request/dynamic-field/list/',
  statusComplianceRequestList = 'dropdown-list/compliance-request/status_compliance_request/',
  statusRequestList = 'dropdown-list/request/request_status/',
  policyPendingRequestCount = 'policy/pending-request-count/',
  compliancePendingRequestCount = 'compliance/pending-request-count/',
  timelogApprovalCount = 'time-sheet/pending-request-count/',
  incidentTaggedCount = 'incident-reporting/dependencies/',
  editAuditReportRating = 'dropdown-list/audit-planning/report_rating/',
  editRiskProgramReportRating = 'dropdown-list/risk-program/risk_program_report_rating/',
  riskDepartmentDropdownList = 'department-form/list/',
  riskDepartmentLevelDropdownList="department-form/list/",
  riskuserDepartmentDropdownList = 'user-department/list/',
  adminBusinessStrategy = 'incident-reporting/admin/business-strategy/list/?configurable=Yes&pagination_config=all',
  adminBusinessStrategyNonConfigurable = 'incident-reporting/admin/business-strategy/list/?pagination_config=all',
  bcmDynamicList = 'bussinessprocess/admin-fields/list',
  riskControlPanelDynamicField = 'risk/admin-fields/list',
  kriControlPanelDynamicField = 'kri/admin-fields/list',
  complianceControlPanelDynamicField ='compliance/admin-fields/list',
  requestControlPanelDynamicField ='request/admin-fields/list',
  auditNotificationTemplateEdit = 'audit_activity_email/edit/',
  riskProgramNotificationTemplate = 'program-noification/email/content/',
  complianceNotificationTemplate = 'compliacne-audit-noification/email/content/',
  auditNotificationSignatureEdit = 'user-signature/list/',
  typeDropdown = 'dropdown-list/audit-reporting/type/',
  improvementTypeDropdown = 'dropdown-list/audit-reporting/improvement_type',
  categoryDropdown = 'dropdown-list/audit-reporting/category/',
  improvementCategoryDropdown = 'dropdown-list/audit-reporting/improvement_category',
  reccomendationcategoryDropdown = 'dropdown-list/audit-reporting-recommendation/category/',
  observationDropdownV1 = 'dropdown-list/audit-reporting/observation_dropdown_v1/',
  observationDropdownV2 = 'dropdown-list/audit-reporting/observation_dropdown_v2/',
  improvementDropdown1 = 'dropdown-list/audit-reporting/improvement_dropdown_1',
  improvementDropdown2 = 'dropdown-list/audit-reporting/improvement_dropdown_2',
  improvementDropdown3 = 'dropdown-list/audit-reporting/improvement_dropdown_3',
  improvementDropdown4 = 'dropdown-list/audit-reporting/improvement_dropdown_4',
  observationDropdownV3 = 'dropdown-list/audit-reporting/observation_dropdown_v3/',
  observationDropdownV4 = 'dropdown-list/audit-reporting/observation_dropdown_v4/',
  observationDropdownV5 = 'dropdown-list/audit-reporting/observation_dropdown_v5/',
  observationDropdownV6 = 'dropdown-list/audit-reporting/observation_dropdown_v6/',
  observationDropdownV7 = 'dropdown-list/audit-reporting/observation_dropdown_v7/',
  observationMultiDropdownV1 = 'dropdown-list/audit-reporting/observation_multi_dropdown_v1/',
  improvementMultiDropdownV1 = 'dropdown-list/audit-reporting/improvement_multi_dropdown_1',
  improvementMultiDropdownV2 = 'dropdown-list/audit-reporting/improvement_multi_dropdown_2',
  improvementMultiDropdownV3 = 'dropdown-list/audit-reporting/improvement_multi_dropdown_3',
  improvementMultiDropdownV4 = 'dropdown-list/audit-reporting/improvement_multi_dropdown_4',
  observationMultiDropdownV2 = 'dropdown-list/audit-reporting/observation_multi_dropdown_v2/',
  observationMultiDropdownV3 = 'dropdown-list/audit-reporting/observation_multi_dropdown_v3/',
  observationMultiDropdownV4 = 'dropdown-list/audit-reporting/observation_multi_dropdown_v4/',
  observationMultiDropdownV5 = 'dropdown-list/audit-reporting/observation_multi_dropdown_v5/',
  observationMultiDropdownV6 = 'dropdown-list/audit-reporting/observation_multi_dropdown_v6/',
  observationMultiDropdownV7 = 'dropdown-list/audit-reporting/observation_multi_dropdown_v7/',
  getIncidentAdditionalDropDownV1 = 'dropdown-list/incident-reporting/incident_additional_dropdown_v1/',
  getIncidentAdditionalDropDownV2 = 'dropdown-list/incident-reporting/incident_additional_dropdown_v2/',
  getIncidentAdditionalDropDownV3 = 'dropdown-list/incident-reporting/incident_additional_dropdown_v3/',
  getIncidentAdditionalDropDownV4 = 'dropdown-list/incident-reporting/incident_additional_dropdown_v4/',
  getIncidentAdditionalMultiDropDownV1 = 'dropdown-list/incident-reporting/additional_multi_dropdown_v1/',
  getIncidentAdditionalMultiDropDownV2 = 'dropdown-list/incident-reporting/additional_multi_dropdown_v2/',
  getIncidentAdditionalMultiDropDownV3 = 'dropdown-list/incident-reporting/additional_multi_dropdown_v3/',
  getIncidentAdditionalMultiDropDownV4 = 'dropdown-list/incident-reporting/additional_multi_dropdown_v4/',

  getPublicIncidentAdditionalDropDownV1 = 'dropdown-list/public/incident-reporting/incident_additional_dropdown_v1/',
  getPublicIncidentAdditionalDropDownV2 = 'dropdown-list/public/incident-reporting/incident_additional_dropdown_v2/',
  getPublicIncidentAdditionalDropDownV3 = 'dropdown-list/public/incident-reporting/incident_additional_dropdown_v3/',
  getPublicIncidentAdditionalDropDownV4 = 'dropdown-list/public/incident-reporting/incident_additional_dropdown_v4/',
  getPublicIncidentAdditionalMultiDropDownV1 = 'dropdown-list/public/incident-reporting/additional_multi_dropdown_v1/',
  getPublicIncidentAdditionalMultiDropDownV2 = 'dropdown-list/public/incident-reporting/additional_multi_dropdown_v2/',
  getPublicIncidentAdditionalMultiDropDownV3 = 'dropdown-list/public/incident-reporting/additional_multi_dropdown_v3/',
  getPublicIncidentAdditionalMultiDropDownV4 = 'dropdown-list/public/incident-reporting/additional_multi_dropdown_v4/',

  complianceManagementResponseDetail = 'compliance-audit/managementresponse/',
  adminAuditIssueList = 'incident-reporting/admin/issue/list/?pagination_config=all',
  auditIssueConfigurable = 'incident-reporting/admin/issue/list/?configurable=Yes&pagination_config=all',
  getIssueDropDownV1 = 'dropdown-list/audit-reporting-action/issue_dropdown_v1/',
  getIssueDropDownV2 = 'dropdown-list/audit-reporting-action/issue_dropdown_v2/',
  getIssueMultiDropDownV1 = 'dropdown-list/audit-reporting-action/issue_multi_dropdown_v1/',
  getIssueMultiDropDownV2 = 'dropdown-list/audit-reporting-action/issue_multi_dropdown_v2/',
  objectiveDropdownList = 'lookups/objective/list',
  objectiveCreate = 'lookups/objective/create/',
  objectiveEditAndDelete = 'lookups/objective/',
  auditTaggableRiskList = 'audit-risk-entity/tagging-list/',
  auditTaggableControlList = 'audit-control-entity/tagging-list/',
  deptEntityList = 'entity_new_list/',
  entityCreate = 'entity_new/create',
  entityMegaProcess = 'mega-major-process/list/?request_for=mega_process',
  entityMajorProcess = 'major-process/list/',
  entityType= 'dropdown-list/entities/entity_type_new/',
  entityDetails = 'entity_new-detail/',
  functionDetails = 'functions/',
  entityEditValue = 'entity_new/edit/',
  functionEditValue = 'functions/',
  entityDelete = 'entity_new/',
  functionDelete = 'functions/',
  entityDepartmentType= 'dropdown-list/accounts/type/',
  entityDepartmentrating= 'dropdown-list/accounts/department_rating/',
  createDepartment= 'department/create/',
  department= 'department/',
  deptList = 'department/list/',
  departmentsDetails = 'department/list/?id=',
  departmentsDelete = 'department/',
  deptEntityListDropdown = 'entity_listing_department/',
  kriCriticality = 'dropdown-list/kri/kri_criticality/',
  kriFrequency = 'dropdown-list/kri/kri_frequency/',
  kriStatus = 'dropdown-list/',
  kriFormat = 'dropdown-list/',
  publicIncidentDetails = 'incident-reporting/public-incident/detail/',
  publicTrackPermission = 'incident-reporting/public-incident/track-feature/',
  CpEntityDepartmentStatus = 'department/rating/feature/status',
  CpEntityDepartmentReportList = 'incident-reporting/admin',
  CpEntityDepartmentRating = 'scoring-model/list',
  CpEntityEditMinMaxValue = 'department/scoring-model/min_max_value',
  CpEntityEditRating = 'department/scoring-model/edit',
  CpEntityDeptDetails = 'dropdown-list/',
  entityRating= 'dropdown-list/entities/entity_rating/',
  entityAdditionalDropdown_1= 'dropdown-list/entities/entity_additional_dropdown_1/',
  entityAdditionalDropdown_2= 'dropdown-list/entities/entity_additional_dropdown_2/',
  entityAdditionalDropdown_3= 'dropdown-list/entities/entity_additional_dropdown_3/',
  entityAdditionalDropdown_4= 'dropdown-list/entities/entity_additional_dropdown_4/',
  entityAdditionalMultiDropdown_1= 'dropdown-list/entities/entity_additional_multi_dropdown_1/',
  entityAdditionalMultiDropdown_2= 'dropdown-list/entities/entity_additional_multi_dropdown_2/',
  entityAdditionalMultiDropdown_3= 'dropdown-list/entities/entity_additional_multi_dropdown_3/',
  entityAdditionalMultiDropdown_4= 'dropdown-list/entities/entity_additional_multi_dropdown_4/',
  entityDynamicFields= 'dynamic-field-list/entities/',
  extraFieldDropdown = 'dropdown-list/audit-planning/extra_field/',
  extraDropdown = 'dropdown-list/audit-planning/extra_dropdown/',
  extraDropdownForIssue ='dropdown-list/audit-reporting-action/extra_dropdown/',
  extraFieldDropdownForIssue = 'dropdown-list/audit-reporting-action/extra_field/',
  newDropdown = 'dropdown-list/audit-planning/new_dropdown/',
  newDropDownForIssue = 'dropdown-list/audit-reporting-action/new_dropdown/',
  policyDynamicFieldList = 'policy-management/dynamic-field-list/',
  getPolicyAdditionalDropDown = 'dropdown-list/policy-management/',
  auditWorkflowList = 'audit-workflow-list/',
  uploadAuditWorkflowDocument = 'audit-workflow/',
  getPublicUrl = 'client-url-formatting/',
  departmentScoringList = 'department/rating-factor/list',
  departmentScoringListDrawChart = 'department/rating/draw-graph',
  departmentScoringStatus = 'department/rating/feature/status',
  departmentScoringModelDropdown= 'department/scoring-model/drop-down/list/',
  departmetnScoringModelList='department/scoring-model/rating-factor/details/',
  departmentAdditionalDropdown1 = 'dropdown-list/accounts/department_additional_dropdown_1/',
  departmentAdditionalDropdown2 = 'dropdown-list/accounts/department_additional_dropdown_2/',
  departmentAdditionalDropdown3 = 'dropdown-list/accounts/department_additional_dropdown_3/',
  departmentAdditionalDropdown4 = 'dropdown-list/accounts/department_additional_dropdown_4/',
  departmentAdditionalMultiDropdown1 = 'dropdown-list/accounts/department_additional_multi_dropdown_1/',
  departmentAdditionalMultiDropdown2 = 'dropdown-list/accounts/department_additional_multi_dropdown_2/',
  departmentAdditionalMultiDropdown3 = 'dropdown-list/accounts/department_additional_multi_dropdown_3/',
  departmentAdditionalMultiDropdown4 = 'dropdown-list/accounts/department_additional_multi_dropdown_4/',
  getPolicyReviewDropdownStatus = 'policy-management/get-review-panal-availability/',
  actionDepartmentNewDropdown = 'department-entity/list/',
  entityTier = 'dropdown-list/entities/entity_tier/',
  CpEntityStatus = 'entity/rating/feature/status',
  cpDepartmentFactorNameEdit = 'department/rating-model/factor-text-edit',
  statusPolicyRequestList = 'dropdown-list/policy-request/status_policy_request/',
  entityManagementDependencyRiskProgram = 'entity-management/entity/tagged-risk_program/',
  entityManagementDependencyAudit = 'entity-management/entity/tagged-audit_program/',
  entityManagementDependencyDepartment = 'entity-management/entity/tagged-departments/',
  departmentDynamicList = 'dynamic-field-list/departments/',
  departmentManagementDependencyRiskProgram = 'entity-management/department/tagged-risk_program/',
  departmentManagementDependencyAudit = 'entity-management/department/tagged-audit/',
  departmentManagementDependencySection = 'entity-management/department/department-section/',
  multiYearAuditPlan = 'audit-planning/audit-universe/multi-year-audit-plan/list',
  userDepartmentList = 'user-department/list/',
  entityScoringList = 'entity/rating-factor/list',
  entityScoringEdit = 'entity/rating-model/factor-text-edit',
  entityMinAndMax = 'entity/rating/min_max_value',
  entityModelEdit = 'entity/rating-model/edit',
  entityScoringStatus = 'entity/rating/feature/status',
  entityScoringListDrawChart = 'entity/rating/draw-graph',
  complianceRequestAdditionalDropDownV1 = 'dropdown-list/compliance-request/request_dropdown_v1/',
  complianceRequestAdditionalDropDownV2 = 'dropdown-list/compliance-request/request_dropdown_v2/',
  complianceRequestAdditionalDropDownV3 = 'dropdown-list/compliance-request/request_dropdown_v3/',
  complianceRequestAdditionalDropDownV4 = 'dropdown-list/compliance-request/request_dropdown_v4/',
  requestAdditionalDropDownV1 = 'dropdown-list/request/request_dropdown_v1/',
  requestAdditionalDropDownV2 = 'dropdown-list/request/request_dropdown_v2/',
  requestAdditionalDropDownV3 = 'dropdown-list/request/request_dropdown_v3/',
  requestAdditionalDropDownV4 = 'dropdown-list/request/request_dropdown_v4/',
  complianceRequestAdditionalMultiDropDownV1 = 'dropdown-list/compliance-request/multi_dropdown_v1/',
  complianceRequestAdditionalMultiDropDownV2 = 'dropdown-list/compliance-request/multi_dropdown_v2/',
  complianceRequestAdditionalMultiDropDownV3 = 'dropdown-list/compliance-request/multi_dropdown_v3/',
  complianceRequestAdditionalMultiDropDownV4 = 'dropdown-list/compliance-request/multi_dropdown_v4/',
  requestAdditionalMultiDropDownV1 = 'dropdown-list/request/multi_dropdown_v1/',
  requestAdditionalMultiDropDownV2 = 'dropdown-list/request/multi_dropdown_v2/',
  requestAdditionalMultiDropDownV3 = 'dropdown-list/request/multi_dropdown_v3/',
  requestAdditionalMultiDropDownV4 = 'dropdown-list/request/multi_dropdown_v4/',

  entityTitle = 'dynamic-field/entities/',
  departmentTitle = 'dynamic-field/departments/',
  typeTitle = 'dynamic-field/incident-reporting/',
  timelogTypeTitle = 'dynamic-field/time-sheet/',
  cpBcpTitle = 'dynamic-field/business-continuity-plan/',
  cpTimeLogProjectTitle = 'dynamic-fields/time-sheet/',
  entityCardFilter = 'filter-charts/',
  divisionGrid = 'division/list/',
  divisionCreate = 'division/create/',
  divisionEdit = 'division/',
  divisionDelete = 'division/',
  divisionDetail = 'division/list/',
  sortDivision = 'division-order-update/',
  ermScoringValues = 'erm-assessment-scoring-model/',
  ermRiskAssessmentDetails = 'erm-assessments/list/?object_id=',
  ermAssessmentSubmit = 'erm-assessments/take-assessment/submit/',
  ermAssessmentSave = 'erm-assessments/take-assessment/partial/',
  ermImport = 'erm-assessment-scoring-import/?',
  entityByRatingChart = 'filter-charts/?request_for=entity_management&chart_type=entities&request_from=app&platform=web',
  functionByRatingChart = 'filter-charts/?request_for=entity_management&chart_type=functions&request_from=app&platform=web',
  detailincidentTaggedCount = 'incident-reporting/',
  departmentFormList = 'department-form/list/',
  departmentFormListAKI = 'department-form/public/list/',
  complianceRcmTableData = 'compliance-rcmfields/list/?request_for=',
  editComplianceRcmFields = 'compliance-rcmfields/edit/?request_for=',
  complianceAuditRcmTableData = 'compliance-rcm-table-list/list/',
  complianceRcmExport = '/compliance-management/rcm_export',
  entityPagelevelFilter = 'get_filters/?request_for=entities',
  functionPagelevelFilter = 'get_filters/?request_for=functions',
  assessmentPageLevelFilter = 'get_filters/?request_for=survey',
  selectScoringModelList = 'scoring-model/risk-form/list',
  identifyNewColumnDropodown = 'analytics-builder/date-fields/',
  taggedRiskDeleteStrategy = 'business-strategy/',
  ERMPageLevelFilter = 'get_filters/?request_for=enterprise-risk',
  getDashboardList = 'dashboard_builder/dashboard/folders/details/list/',
  getFolderDashboardList = 'dashboard_builder/dashboard/folders/list/',
  getFoldersList = 'dashboard_builder/folders/list/',
  complianceRequestPageFilter = 'get_filters/?request_for=compliance-request',
  requestPageFilter = 'get_filters/?request_for=request',
  complianceDepartmentLevels = 'department-level1-level2/list/',
  followUpReport = 'follow-up-audit-report/',
  requestDepartmentLevels = 'department-level1-level2/list/',
  policiesPageFilter = 'get_filters/?request_for=policy-management',
  policyRequestPageFilter = 'get_filters/?request_for=policy-request',
  mrDropdown1 = 'dropdown-list/management-response/audit-reporting/mr_dropdown_1/',
  mrDropdown2 = 'dropdown-list/management-response/audit-reporting/mr_dropdown_2/',
  mrDropdown3 = 'dropdown-list/management-response/audit-reporting/mr_dropdown_3/',
  mrDropdown4 = 'dropdown-list/management-response/audit-reporting/mr_dropdown_4/',
  mrDropdown5 = 'dropdown-list/management-response/audit-reporting/mr_dropdown_5/',
  mrDropdown6 = 'dropdown-list/management-response/audit-reporting/mr_dropdown_6/',
  mrDropdown7 = 'dropdown-list/management-response/audit-reporting/mr_dropdown_7/',
  auditDropdown = 'dropdown-list/management-response/audit-reporting/auditors_comment_status/',
  auditorsCommentCategory='dropdown-list/management-response/audit-reporting/auditors_comment_category/',
  AuditorCommentType='dropdown-list/management-response/audit-reporting/auditors_comment_type/',
  mrMultiDropdown1 = 'dropdown-list/management-response/audit-reporting/mr_multi_dropdown_1/',
  mrMultiDropdown2 = 'dropdown-list/management-response/audit-reporting/mr_multi_dropdown_2/',
  mrMultiDropdown3 = 'dropdown-list/management-response/audit-reporting/mr_multi_dropdown_3/',
  mrMultiDropdown4 = 'dropdown-list/management-response/audit-reporting/mr_multi_dropdown_4/',
  mrMultiDropdown5 = 'dropdown-list/management-response/audit-reporting/mr_multi_dropdown_5/',
  mrMultiDropdown6 = 'dropdown-list/management-response/audit-reporting/mr_multi_dropdown_6/',
  mrMultiDropdown7 = 'dropdown-list/management-response/audit-reporting/mr_multi_dropdown_7/',
  complianceDigitalReport = 'digital-report-compliance/',
  recommendationDropdown1 = 'dropdown-list/audit-reporting-recommendation/recommendation_dropdown_1',
  recommendationDropdown2 = 'dropdown-list/audit-reporting-recommendation/recommendation_dropdown_2',
  recommendationDropdown3 = 'dropdown-list/audit-reporting-recommendation/recommendation_dropdown_3',
  recommendationDropdown4 = 'dropdown-list/audit-reporting-recommendation/recommendation_dropdown_4',
  recommendationDropdown5 = 'dropdown-list/audit-reporting-recommendation/recommendation_dropdown_5',
  recommendationDropdown6 = 'dropdown-list/audit-reporting-recommendation/recommendation_dropdown_6',
  recommendationDropdown7 = 'dropdown-list/audit-reporting-recommendation/recommendation_dropdown_7',
  recommendationMultiDropdown1 = 'dropdown-list/audit-reporting-recommendation/recommendation_multi_dropdown_1',
  recommendationMultiDropdown2 = 'dropdown-list/audit-reporting-recommendation/recommendation_multi_dropdown_2',
  recommendationMultiDropdown3 = 'dropdown-list/audit-reporting-recommendation/recommendation_multi_dropdown_3',
  recommendationMultiDropdown4 = 'dropdown-list/audit-reporting-recommendation/recommendation_multi_dropdown_4',
  recommendationMultiDropdown5 = 'dropdown-list/audit-reporting-recommendation/recommendation_multi_dropdown_5',
  recommendationMultiDropdown6 = 'dropdown-list/audit-reporting-recommendation/recommendation_multi_dropdown_6',
  recommendationMultiDropdown7 = 'dropdown-list/audit-reporting-recommendation/recommendation_multi_dropdown_7',
  auditDepartmentLevel1List= 'department-modal/tagging-list',
  taggedObservationERM = 'enterprise-risk/',
  tagComplianceList = 'risk-assessment/compliance-audit/list',
  tagComplianceObservationList = 'risk-assessment/compliance-observation/',
  tagComplianceAuditObservation = 'risk-program/compliance-observation/',
  tagComplianceObservationToRiskProgram = 'risk-program/compliance-observation/',
  tagAuditObservationToRiskProgram = 'risk-assessment/details/tagging-observation/add-list/',
  tagRiskProgramObservation = 'risk-program/details/tagging-observation/add-list/',
  taggedERMComplianceObservationRemove = 'enterprise-risk/tagged/compliance-observation/',
  taggedRiskComplianceObservationRemove = 'risk-assessment/tagged/compliance-observation/',
  clientLicenceDetails = 'client/license-details/',
  addActivationKey = 'licence/activation-check/',
  allPageList = 'all-menu-sidebar/',
  menuOrderList = 'order-menu-links/list/',
  menuListUpdate = 'order-menu-links/save/?license_type=',
  keyERMDropDown = 'dropdown-list/enterprise-risk/key_risk/',
  topERMDropDown = 'dropdown-list/enterprise-risk/top_risk/',
  ermAppetiteDropDown = 'dropdown-list/enterprise-risk/risk_appetite/',
  ermWithinAppetiteDropDown = 'dropdown-list/enterprise-risk/risk_within_appetite/',
  ermMigrationRequiredDropDown = 'dropdown-list/enterprise-risk/risk_mitigation_required/',
  targetResidualERMDropDown = 'dropdown-list/enterprise-risk/target_residual_risk/',
  entityDependency = 'entity/',
  functionDependency = 'functions/',
  departmentDependency = 'department/',
  taggableWorkingPaperList = 'observation-tagging-working-paper/',
  departmentPagelevelFilter = 'get_filters/?request_for=departments',
  auditIncident = '/tagged-incident/',
  complianceAuditListInPlan ='compliance-plan/compliance-audit/list/',
  compliancePlanCreate = 'compliance-audit-plan/create/',
  complianceAuditPlan = 'compliance-audit-plan/',
  complianceAuditPlanList = 'compliance-audit-plan/list/',
  compliancePlanDynamicField = 'compliance-audit/plan/dynamic-field-list/',
  complianceAuditPlanDetail = 'compliance-audit-plan/detail/',
  compliancePlaningGraph = 'filter-charts/?request_for=compliance_audit&request_from=app&platform=web&chart_type=compliance-audit-plan',
  sectionDetails = 'sections/',
  complianceAuditDigitalReport = 'digital-report/compliance-audit/',
  complianceDeleteAudit ='compliance-audit-plan/detail/',
  sectionDynamicList = 'dynamic-field-list/sections/',
  compliancePlanDropDownList = 'compliance-audit/plan/dropdown/list/',
  sectionDelete = 'sections/',
  auditPlanHistory = '/audit-planning/history/export',
  compliancePlanDetails ='compliance-audit-plan/detail/',
  sectionScoringList = 'section/rating-factor/list',
  sectionScoringListDrawChart = 'section/rating/draw-graph',
  sectionParentDropdown = 'dropdown/department/list/',
  sectionScoringStatus = 'section/rating/feature/status',
  cpSectionFactorNameEdit = 'section/rating-model/factor-text-edit',
  cpSectionScoringModelEdit = 'section/scoring-model/edit',
  sectionMinAndMax = 'section/scoring-model/min_max_value',
  dashboardFolderDelete = 'dashboard_builder/dashboard/folders/',
  menusCreatorUserList = 'menu-licensetype/list/',
  menusCreateTemplate = 'menu-items-create/',
  menusEditTemplate = 'menu-items-update/',
  menusDeleteTemplate = 'menu-item/',
  customMenuDelete = 'menu-custom-links/',
  allPagesAndCustomLinkList = 'all-menu-sidebar/',
  addCustomLink = 'menu-custom-links/create/',
  editCustomLink = 'menu-custom-links/',
  menuCustomLinksDropdown = 'menu-custom-links/dropdown/link_type/list/',
  sectionDependency = 'section/',
  sectionManagementDependencyRiskProgram = 'entity-management/section/tagged-risk_program/',
  sectionManagementDependencyAudit = 'entity-management/section/tagged-audit/',
  sidebarMenus = 'menu-home/list/',
  configureHeader = 'audit-universe/headerlist/',
  dimentionData = 'audit-universe/selected-field/list/',
  departmentsPlan = 'audit-universe/department/list/',
  configureFields = 'audit-universe/field/list/',
  departmentDetail = 'department/detail/',
  sectionList = 'entity_management/section/list/',
  departmentLevelList = 'admin-panel/department/list/',
  cpDepartmentDetail = 'admin-panel/department/',
  complianceRcmRiskTableData = 'compliance-rcm-table-list/risk/list/',
  complianceRcmControlTableData = 'compliance-rcm-table-list/control/list/',
  complianceRiskRcmExport = '/compliance-management/risk_rcm_export',
  complianceRiskRcm = 'compliance-rcm/risk-export/',
  complianceControlRcmExport = '/compliance-management/control_rcm_export',
  complianceRcmControl = 'compliance-rcm/control-export/',
  complianceMRHidenonConfigurable = 'incident-reporting/admin/',
  complianceMRDropdown1 = 'dropdown-list/compliance-audit-management-response/mr_dropdown_1',
  complianceMRDropdown2 = 'dropdown-list/compliance-audit-management-response/mr_dropdown_2',
  complianceMRDropdown3 = 'dropdown-list/compliance-audit-management-response/mr_dropdown_3',
  complianceMRDropdown4 = 'dropdown-list/compliance-audit-management-response/mr_dropdown_4',
  complianceMRDropdown5 = 'dropdown-list/compliance-audit-management-response/mr_dropdown_5',
  complianceMRDropdown6 = 'dropdown-list/compliance-audit-management-response/mr_dropdown_6',
  complianceMRDropdown7 = 'dropdown-list/compliance-audit-management-response/mr_dropdown_7',
  complianceMRMultiDropdown1 = 'dropdown-list/compliance-audit-management-response/mr_multi_dropdown_1',
  complianceMRMultiDropdown2 = 'dropdown-list/compliance-audit-management-response/mr_multi_dropdown_2',
  complianceMRMultiDropdown3 = 'dropdown-list/compliance-audit-management-response/mr_multi_dropdown_3',
  complianceMRMultiDropdown4 = 'dropdown-list/compliance-audit-management-response/mr_multi_dropdown_4',
  complianceMRMultiDropdown5 = 'dropdown-list/compliance-audit-management-response/mr_multi_dropdown_5',
  complianceMRMultiDropdown6 = 'dropdown-list/compliance-audit-management-response/mr_multi_dropdown_6',
  complianceMRMultiDropdown7 = 'dropdown-list/compliance-audit-management-response/mr_multi_dropdown_7',
  complianceMRManagementStatus = 'dropdown-list/compliance-audit-management-response/management_status',
  issueTrackerComplianceRecommendation = 'compliance-audit/issue-tracker/recommendation/list/',
  complianceIssueTrackerRecommendationChart = 'filter-charts/?request_for=compliance_audit&request_from=app&chart_type=compliance_recommendation_issuetracker&platform=web',
  sectionsTitle = 'dynamic-field/sections/',
  functionsTitle = 'dynamic-field/functions/',
  entitySectionType= 'dropdown-list/sections/type/',
  entitySectionRating= 'dropdown-list/sections/section_rating/',
  sectionAdditionalDropdown1 = 'dropdown-list/sections/department_additional_dropdown_1/',
  sectionAdditionalDropdown2 = 'dropdown-list/sections/department_additional_dropdown_2/',
  sectionAdditionalDropdown3 = 'dropdown-list/sections/department_additional_dropdown_3/',
  sectionAdditionalDropdown4 = 'dropdown-list/sections/department_additional_dropdown_4/',
  sectionAdditionalMultiDropdown1 = 'dropdown-list/sections/department_additional_multi_dropdown_1/',
  sectionAdditionalMultiDropdown2 = 'dropdown-list/sections/department_additional_multi_dropdown_2/',
  sectionAdditionalMultiDropdown3 = 'dropdown-list/sections/department_additional_multi_dropdown_3/',
  sectionAdditionalMultiDropdown4 = 'dropdown-list/sections/department_additional_multi_dropdown_4/',
  allEmailTemplate = 'email_template/list/',
  deleteEmailTemplate = 'email_template/',
  riskProgramIssueTrackerIssueList = 'risk-program-all-issues/list',
  riskProgramIssueTrackerActionsList = 'risk-program/action/list',
  riskProgramIssueTrackerRecommendationList = 'risk-program/issue-tracker/recomendation/list',
  riskProgramIssueTrackerResponseList = 'risk_management-response/issue-tracker/list/',
  riskProgramIssueTrackerIssuesExportCSV = '/risk-program/risk_program_issue_download_csv',
  riskProgramIssueTrackerIssuesExportXLS = '/risk-program/risk_program_issue_download_xls',
  riskProgramIssueTrackerResponseExportCSV = '/risk-program/risk_program_mr_download_csv',
  riskProgramIssueTrackerResponseExportXLS = '/risk-program/risk_program_mr_download_xls',
  riskProgramIssueTrackerActionsExportCSV = '/risk-program/risk_action_download_csv',
  riskProgramIssueTrackerActionsExportXLS = '/risk-program/risk_action_download_xls',
  riskProgramIssueTrackerRecommendationExportCSV = '/risk-program/risk_program_recomendation_download_csv',
  riskProgramIssueTrackerRecommendationExportXLS = '/risk-program/risk_program_recomendation_download_xls',
  emailTemplateDropdown = 'email_template/list/?is_active=true',
  emailTemplateDetails = 'email_template/details/',
  emailTemplateAttachments = 'email-template-document-upload-list/',
  auditScheduleTemplateFields = 'audit-schedule-template-fields/',
  complianceAuditScheduleTemplateFields = 'audit-schedule-template-fields/?request_for=compliance_audit',
  riskAuditScheduleTemplateFields = 'program-schedule-template-fields/',
  getTemplateDetails= 'email_template/details/',
  getEmailDocument= 'email-template-document-upload-list/',
  publishTemplate = 'email_template/details/',
  getAllFields = 'email_template_sidebar/list/',
  deleteEmailDocument = 'file-uploader/',
  getEmailTemplateDetails = 'email_template/list/',
  fuctionEntityList = 'functions/list/',
  riskProgramIncidentTagList ='risk-program/',
  riskProgramIncidentDelete ='risk-program/tagged/incident/',
  incidentTaggedCompliance = 'incident-reporting/tagging-compliance/',
  taggableIncidentCompliance = 'incident-reporting/tagging-compliance/add-list/',
  taggableIncidentAssessment = 'risk-assessment/Survey/tagginglist/',
  getAuditPlanList = 'audit-universe/table-preview/list/',
  getAuditDepartment = 'audit-universe/department/preview/',
  populateAudit = 'audit-universe/user-table-preview/list/',
  checkDimention = 'universe-mandatory/fields',
  populateAuditDepartment = 'audit-universe/department-table-preview/list/',
  multiyearDept = 'audit-universe/Department/multiyear-table',
  universeTable = 'audit-universe/user-table-preview/list/',

  universeTableV2 = 'audit-universe/universe-table/detail',
  multiTable = 'audit-universe/multiyear-auditplan/table',
  selectedAudit = 'audit-universe/preview-audit/list/',
  generateAuditPlan = 'audit-universe/create-model/',
  planDetail = 'audit-universe/',
  planEdit = 'audit-universe/model/',
  cpAuditscheduleCreate='create-audit-schedule-template/',
  cpRiskscheduleCreate='create-program-schedule-template/',
  cpAuditschedule='audit-schedule-template',
  cpRiskschedule='program-schedule-template',
  cpAuditscheduleList='active-audit-schedule-template/list/',
  CPScheduleTemplateList='audit-schedule-template-list/',
  functionRating= 'dropdown-list/functions/function_rating/',
  functionAdditionalDropdown_1= 'dropdown-list/functions/function_additional_dropdown_1/',
  functionAdditionalDropdown_2= 'dropdown-list/functions/function_additional_dropdown_2/',
  functionAdditionalDropdown_3= 'dropdown-list/functions/function_additional_dropdown_3/',
  functionAdditionalDropdown_4= 'dropdown-list/functions/function_additional_dropdown_4/',
  functionAdditionalMultiDropdown_1= 'dropdown-list/functions/function_additional_multi_dropdown_1/',
  functionAdditionalMultiDropdown_2= 'dropdown-list/functions/function_additional_multi_dropdown_2/',
  functionAdditionalMultiDropdown_3= 'dropdown-list/functions/function_additional_multi_dropdown_3/',
  functionAdditionalMultiDropdown_4= 'dropdown-list/functions/function_additional_multi_dropdown_4/',
  DynamicfunctionFields= 'dynamic-field-list/functions/',
  functionCreate = 'functions/create',
  functionType= 'dropdown-list/functions/function_type/',
  complianceObservationType = 'dropdown-list/compliance-audit/observation_type/',
  complianceAuditProgramConfigurable = 'incident-reporting/admin/workingpaper/list/?configurable=Yes&pagination_config=all',
  functionDependencyDepartment = 'entity-management/functions/tagged-departments/',
  deptFunctionListDropdown = 'functions/dropdown-list/',
  functionTier= 'dropdown-list/functions/tier/',
  complianceAuditAssessmentRelatedObservation = 'compliance-assesment/',
  observationTaggedWorkingPaper = 'audit-reporting/tag-workingpaper/',
  observationTaggedRisk = 'audit-reporting/tag-risk/',
  universePayload = 'audit-universe/payload/list',
  auditScheduleSubActivity = 'audit-schedule-sub-activity-detail/',
  approverList = 'user-list/?request_for=professional',
  approverLockRevise = 'approval-process/lock-revise',
  approve = 'approval-process/level/',
  groupApprove = 'approval-process/group/',
  auditScheduleDynamicFields = 'audit-schedule-dynamic-fields/?audit_object_id=',
  complianceScheduleDynamicFields = 'compliance-audit-schedule-dynamic-fields/?compliance_audit_object_id=',
  auditScheduleDocuments = 'audit-schedule-sub-activity/',
  controlRating = 'control/rating-factor/',
  dynamicRating = 'feature/status/list',
  reminderHistory = 'observation/management-response/',
  bcpTagAssessment = 'bcp/',
  bcmTagAssessment = 'business-process/',
  reminderHistoryActivityAction = 'auditplan/auditprogram/activity/action/',
  reminderHistoryRecommendations = 'auditplan/recommendation/',
  reminderHistoryAction = 'auditplan/actions/',
  reminderHistoryActivity = 'auditplan/auditprogram/activity/',
  reminderHistoryIssueAction = 'auditplan/issue/issueactions/',
  residualRisk = 'feature/status/list',
  reminderAuditPlanningDetail = 'auditplan/',
  controlRatingMinMax = 'control/scoring-model/min_max_value',
  excelLoadMoreTypes = 'analytics-builder/data-source/',
  improvementCreate = 'audit-reporting/improvement/',
  notificationTemplateList = 'notification/template/list?is_active=true',
  notificationTemplateDetails = 'notification-template/?template_id=',
  notificationCreate = 'notification-activity-email/create/',
  getNotificationTemplateDetails = 'notification-template/',
  allNotificationEmailTemplate = 'notification/template/list/',
  auditFollowupReportStatus = 'follow-up-report/overdue-management-response/',
  auditFollowupReportPerformance = 'follow-up-report/current-year-closure-rate/',
  auditFollowupReportPerformanceYear = 'follow-up-report/five-year-closure-rate/',
  improvementActionList = 'audit-planning/action/',
  userMfaReset = 'mfa-reset/',
  improvementCountForList = 'audit-detail/improvement/',
  auditIssueDocumentList = 'issue-document-upload-list/',
  auditProgramDetailDocumentList = 'audit-program-document-upload-list/',
  fieldSecurityList = 'field-security-list/',
  controlIncidentData = 'risk-control/',
  requestRiskData = 'risk-assessment/',
  controlIncidentUntag = 'risk-control/tagged/incident/',
  departmentScoringModel = 'department/scoring-model/',
  departmentScoringModelList = 'department/scoring-model/list/',
  departmentScoringModelCreate = 'department/scoring-model/create/',
  departmentScoringModelDetail = 'department/scoring-model/rating-factor/details/',
  departmentRatingFactorList = 'department/rating-factor/control-panel/list',
  ermTaggingIncident = 'incident-reporting/tagging-erm/',
  modifyAuditList = 'generate-audit-plan/modify-audits/fields/list',
  allAuditDropdowns = 'generate-audit-plan/modify-audits-dropdowns/list',
  cmObservationActionDynamicFieldList = 'compliance-audit/observation/action/dynamic-field-list/',
  cmObservationActionList = 'compliance-audit/observation/',
  cmObservationActionCreate = 'compliance-audit/observation/',
  cmObservationAction = 'compliance-audit/observation/action/',
  assessmentTableExcel ='/api/v6/table_download_xls/',
  AuditPlanTable = 'dashboard/man-days-analysis/',
  AuditEngagement = 'dashboard/engagements-man-days-analysis/',
  internalAuditPerformanceChart ='internal-audit-performance/chart/',
  internalAuditPerformanceMegaProcessChart ='internal-audit-performance/control-effectiveness-status/',
  internalAuditMOAChart ='internal-audit-performance/method-of-audit/',
  internalAuditOpenObservationDepartment = 'internal-audit-performance/department-wise-open-observations/',
  internalAuditOpenObservationOverallWithAgeing1 = 'internal-audit-performance/open-observation/',
  internalAuditOpenObservationOverallWithAgeing2 = 'internal-audit-performance/open-observation-with-ageing/',
  internalAuditPerformanceStatusOfAuditPlanGrid = 'internal-audit-performance/status-of-audits/',
  controlRatingEdit = 'control/scoring-model/edit',
  complianceMrReminderHistory = 'compliance-observation/management-response/',
  complianceMrNewReminderHistory = 'generic-reminder-history/',
  complianceAuditReminderHistory ='compliance-audit/',
  analyticDataProcessFieldList = 'analytics-builder/scenario/feature-fields/list',
  analyticsAssistedModelling = 'analytics-builder/scenario/feature-fields/update/',
  AuditImprovementManagementConfigurable = 'incident-reporting/admin/improvement-management-response/list/?configurable=Yes&pagination_config=all',
  ImprovementDyamicField = 'audit-planning/imporvementmanagementresponse/dynamic-field-list/',
  adminAuditImprovementManagementResponseList = 'incident-reporting/admin/improvement-management-response/list/?pagination_config=all',
  improvementMrDropdown1 = 'dropdown-list/management-response/improvement-management-response/mr_dropdown_1/',
  improvementMrDropdown2 = 'dropdown-list/management-response/improvement-management-response/mr_dropdown_2/',
  improvementMrDropdown3 = 'dropdown-list/management-response/improvement-management-response/mr_dropdown_3/',
  improvementMrDropdown4 = 'dropdown-list/management-response/improvement-management-response/mr_dropdown_4/',
  improvementMrDropdown5 = 'dropdown-list/management-response/improvement-management-response/mr_dropdown_5/',
  improvementMrDropdown6 = 'dropdown-list/management-response/improvement-management-response/mr_dropdown_6/',
  improvementMrDropdown7 = 'dropdown-list/management-response/improvement-management-response/mr_dropdown_7/',
  improvementMrMultiDropdown1 = 'dropdown-list/management-response/improvement-management-response/mr_multi_dropdown_1/',
  improvementMrMultiDropdown2 = 'dropdown-list/management-response/improvement-management-response/mr_multi_dropdown_2/',
  improvementMrMultiDropdown3 = 'dropdown-list/management-response/improvement-management-response/mr_multi_dropdown_3/',
  improvementMrMultiDropdown4 = 'dropdown-list/management-response/improvement-management-response/mr_multi_dropdown_4/',
  improvementMrMultiDropdown5 = 'dropdown-list/management-response/improvement-management-response/mr_multi_dropdown_5/',
  improvementMrMultiDropdown6 = 'dropdown-list/management-response/improvement-management-response/mr_multi_dropdown_6/',
  improvementMrMultiDropdown7 = 'dropdown-list/management-response/improvement-management-response/mr_multi_dropdown_7/',
  improvementAuditDropdown = 'dropdown-list/management-response/improvement-management-response/auditors_comment_status/',
  improvementAuditorsCommentCategory='dropdown-list/management-response/improvement-management-response/auditors_comment_category/',
  improvementAuditorCommentType='dropdown-list/management-response/improvement-management-response/auditors_comment_type/',
  improvementResponseStatus = 'dropdown-list/management-response/improvement-management-response/management_status/',
  improvementStatusEqClosed =  'audit-reporting/improvement/age/',
  riskBasedObservationRating = 'observation/rating/risk-based-dynamic-rating/',
  riskDownload = '/media-management/download/template/on-premise/',
  entityFromAudit = 'audit-survey/entity/',
  getAuditSurveyTaggableList = 'audit-assessment/Survey/tagginglist/many-entity/',
  tagAuditSurvey = 'audit-survey/',
  auditSurveyList = 'audit-survey/tagged-assesments/',
  deleteAuditSurvey = 'audit-survey/tagged-assesments-delete/',
  auditSurveyListCSV = '/audit-reporting/download-csv-audit-survey-assessments/csv_download/',
  auditSurveyListXLS = '/audit-reporting/download-xls-audit-survey-assessments/xls_download/',
  auditSurveyDetail = 'audit-survey/audit-details/',
  auditSurveyGraph = 'filter-charts/?request_for=audit_planning&platform=web&request_from=app&chart_type=audit-survey',
  selectedItemList = 'bulk-edit/selected-records/list/',
  dynamicBulkEdit = 'bulk-edit/',
  dynamicInputList = 'bulk-edit/dynamic-field/',
  validateName = 'global-name-validation/',
  auditTeamDynamic = 'audit/team/dynamic_list/',
  analyticsScenarioExport = 'analytics-builder/druid/data-download/',
  auditRcmExportSocket = 'observation-matrix/export/',
  auditObsDashboardExport = 'audit-observation-dashboard-export/',
  complianceRcmExportSocket = 'compliance-observation-matrix/export/',
  fieldNameGettingApi = 'audit/admin-dynamic-fields/list',
  entriesprojectRequest = 'time-sheet/project-by-user/list',
  activitycreate='time-sheet/activity-create/create',
  timelogactivitylist= 'time-sheet/activity-stage-project/list',
  adminProjectList = "dropdown-list/time-sheet/time_log_admin",
  addAdminProject = "dropdown/create/",
  getcategorylist = "time-sheet/category-sub-category/list",
  timeLogDynamic = 'time-sheet/dynamic-field/list/?dynamic_fields=True',
  timemanagmentsubcategorylist = "time-sheet/sub-category/list?parent_category_id=",
  entiresexport = "time-sheet-projectlist-matrix/export/",
  scheduleDetailForNotification = 'audit-notifications-schedule-activity-detail/',
  getApprovalTimeline = 'approval-process/approver/request-list/timeline/',
  getApprovalType = 'approval-process/get-approval-type',
  generateAuditDepartment = 'audit-universe/department-plan/create/',
  editAuditDepartment = 'audit-plan/',
  adminAnalyticsMrDataList  = 'dropdown-list/analytics-scenario-management-response/',
  departmentRatingDashboard = 'department-ratings/',
  rootcause = 'dropdown-list/incident-reporting/',
  CPGridTemplateList='risk-universe-template-list',
  cpGriddelete='risk-universe-template',
  cpGridlist = 'risk-universe-template-fields',
  cpRiskGridCreate='create-risk-universe-template',
  cpRiskGrid='risk-universe-template',
  rootcauseTitle = 'dynamic-field/incident-root-cause/',
  incidentexport = 'incident/export/',
  latestVersion = 'policy-management/version-record/list/',
  incidentReportUpdate = 'admin/features/list?slug=incident-report-updates-for-public-users&module=incident-reporting',
  annexureMedia= 'audit-plan/media/',
  departmentRatingDashboardNew = 'new/department-ratings/',
  departmentRatingExport = 'department/export/export-data/',

  timelogexport = 'time-sheet/timelog/export/',
  myTimelogActivitiesExport = 'time-sheet/schedule-activities/export/',
  requestexport = 'request/export/',
  riskERMExport = 'erm/export/',
  massReassignExport = 'user-mass-reassign/export/',
  notificationStatus = 'notification-control/list/?request_type=user_status',
  notificationStatusUpdate = 'notification-control/status-update/',
  genericReminderHistory = 'generic-reminder-history/',
  policyReportSignOffList = 'policy-sign-off/',
  policyReportGenerator = 'policy-pdf-generator/policy-management/',
  policyDigitalReport = 'digital-report-compliance/',
  digitalReportSendSigners = 'digital-report-compliance-signoff/',
  policyReportDetail = 'policy-sign-off/',
  massUserTableExport = 'user-mass-reassign/module/export/',
  getFilters = 'get_filters/',
  subProcess = 'scenario/process-subprocess/list/',
  cmMrExport= 'compliance-management/issue-trcker/management-response-export/',
  notificationControlModules = 'notification-control/modules/',
  suvreyResponseAuth = 'survey-app/auth-survey-exist',
  commonAssessmentTagList = 'common-assessment/survey/tagginglist/',
  commonAssessmentList = 'common-assessment/survey/',
  associatedRiskList= 'risk-plan/risk-list/',
  complianceObservationDropdownV1 = 'dropdown-list/compliance-audit/observation_dropdown_1/',
  complianceObservationDropdownV2 = 'dropdown-list/compliance-audit/observation_dropdown_2/',
  complianceObservationDropdownV3 = 'dropdown-list/compliance-audit/observation_dropdown_3/',
  complianceObservationDropdownV4 = 'dropdown-list/compliance-audit/observation_dropdown_4/',
  complianceObservationDropdownV5 = 'dropdown-list/compliance-audit/observation_dropdown_5/',
  complianceObservationMultiDropdownV1 = 'dropdown-list/compliance-audit/observation_multi_dropdown_1/',
  complianceObservationMultiDropdownV2 = 'dropdown-list/compliance-audit/observation_multi_dropdown_2/',
  complianceObservationMultiDropdownV3 = 'dropdown-list/compliance-audit/observation_multi_dropdown_3/',
  complianceObservationMultiDropdownV4 = 'dropdown-list/compliance-audit/observation_multi_dropdown_4/',
  complianceObservationMultiDropdownV5 = 'dropdown-list/compliance-audit/observation_multi_dropdown_5/',
  complianceRecommendationDropdown1 = 'dropdown-list/compliance-audit/recommendation_dropdown_1/',
  complianceRecommendationDropdown2 = 'dropdown-list/compliance-audit/recommendation_dropdown_2/',
  complianceRecommendationDropdown3 = 'dropdown-list/compliance-audit/recommendation_dropdown_3/',
  complianceRecommendationDropdown4 = 'dropdown-list/compliance-audit/recommendation_dropdown_4/',
  complianceRecommendationDropdown5 = 'dropdown-list/compliance-audit/recommendation_dropdown_5/',
  complianceRecommendationMultiDropdown1 = 'dropdown-list/compliance-audit/recommendation_multi_dropdown_1/',
  complianceRecommendationMultiDropdown2 = 'dropdown-list/compliance-audit/recommendation_multi_dropdown_2/',
  complianceRecommendationMultiDropdown3 = 'dropdown-list/compliance-audit/recommendation_multi_dropdown_3/',
  complianceRecommendationMultiDropdown4 = 'dropdown-list/compliance-audit/recommendation_multi_dropdown_4/',
  complianceRecommendationMultiDropdown5 = 'dropdown-list/compliance-audit/recommendation_multi_dropdown_5/',
  AuditReportingObsConfigurableList = 'incident-reporting/admin/audit-reporting/list/?configurable=Yes&pagination_config=all',
  AuditReportingObsNonConfigurableList = 'incident-reporting/admin/audit-reporting/list/?pagination_config=all',
  organizationHierarchyList = 'organization-hierarchy/hierarchy-list/',
  organizationComponentList = 'organization-hierarchy/components-list/',
  organizationHierarchyUpdate = 'organization-hierarchy/config-update/',
  organizationHierarchyRelationCount = 'organization-hierarchy/relation-count/',
  generalApprovalStatus= 'approval-feature/details/',
  organizationHierarchySkip = 'organization-hierarchy/configuration/skip/',
  organizationHierarchyParent = 'organization-hierarchy/parent-component/',
  entityDetailsGraph = 'organization-hierarchy/charts/',
  libraryInstance = 'library/instance-check/',
  adminAuditImprovementNonConfigList = 'incident-reporting/admin/improvement/list/?pagination_config=all',
  riskScoringModelList= 'risk-scoring-model/list',
  moduleConfiguration= 'module_label/config',
  currentThemeId ='userprofile/theme-color/edit/',
  themeList ='dropdown-list/accounts/color_theme_user_profile/',
  tagWithRiskProgram = 'risk-program/tagged_risk_control/',
  organizationDataList = 'organization-hierarchy/component-data-list/',
  assessmentControlOverView = 'assessment/control-overview/',
  controlAssessmentTag = 'compliance/control-test/',
  controlWorkingPaperAssessmentTag = 'compliance/control-test/wp/',
  controlAssessmentAddList = 'assessment/control-test/',
  filterDashboard = 'filter-dashboard/',
  bcmAssetsRisk = 'module-dynamic-dependency/risk-assessment/',
  bcmAssetsControl = 'module-dynamic-dependency/risk-control/',
  complainceWpDelete = 'compliance/wp/',
  assetDepndencyCounts = 'business-process/asset/',
  auditSummaryDashboard = 'filter-charts/',
  universeFieldModel = 'universe-model/based-on/fields',
  auditableFields = 'universe-model/auditable-units/',
  suggetionFields = 'universe-model/auditable-fields/',
  auditModify = 'generate-audit-plan/modify-audits',
  selectFieldsModal = 'generate-audit-plan/header/list',
  organizationHierarchy='organization-hierarchy',
  filterChartGen = 'filter-charts/',
  digitalObsList  = 'audit-reporting/observation/',
  riskAssessmentAddList = 'assessment/risk-test/',
  tagRiskEvaluation = 'asset/risk-test/',
  assessmentRiskDetails = 'assessment/risk-overview/',
  overviewBoardRoomChart = 'filter-charts/',
  msConfig = 'analytics-builder/flow-builder/sharepoint-config/',
  assetRiskEvaluationList = 'asset/risk-evaluation/',
  managementResponse = 'management-response/',
  bcmTaggedAssessment = 'bcm-audit/',
  genericTagType = 'generic-dropdown/list',
  createGenericDropdown= 'generic-dropdown/create',
  genericEdit = 'generic-dropdown',
  adminFeaturesDocUpload = 'admin/features/doc_upload/',
  versionHistory = 'policy-management/version-history/',
  durationList = 'policy-management/duration-list/',
  sectionCoverpage = 'policy-management/section-coverpage/',
  authorizationList = 'policy-management/authorization-list/',
  assetAssessmentDelete = 'asset/',
  auditScheduleUserAvailablity = 'audit-schedule/template-user/availability',
  gridCustomTemplate = 'grid-custom-template/',
  gridTempList = 'grid-custom-template-select-list',
  auditDefaultTemplate = 'grid-customdefault-template/list',
  systemDriven = 'audit-universe/audit-criteria',
  crietriaExist = 'audit-universe/audit-criteria/exist',
  systemDrivenValues = 'audit-universe/coverage-dropdown',
  auditSystemTable = 'audit-universe/all-table-preview/list/',
  adhocAuditCreate ='audit-universe/adhoc-audit/create',
  universeV2Create = 'audit-universe/create/',
  universeV2AutoSave = 'audit-universe/auto-update/',
  universeFiscal = 'audit-plan/year-dropdown/',
  modifyAuditV2 = 'audit-universe/modify-audit/table',
  modifyAuditV2Edited = 'audit-universe/edit-modify-audit/table',
  submitPlan = 'generate-audit/audit-plan',
  moduleDependencies = 'import/dependency_modules',
  dependenciesModule = 'import/fields',
  importDependencies = 'generic/import',
  bcmFieldList = 'scoring-factor/logic/details?request_for=Process Impact Scoring',
  bcmFunctionCalculation = 'scoring/calculations/functions/list/',
  bcmFieldUpdate = 'scoring-factor/logic/conversion?request_for=Process Impact Scoring',
  bcmScoringFinalUpdate = 'scoring-factor/logic/update?request_for=Process Impact Scoring',
  networkChartGetNodes = 'network-chart/',
  auditableUnitPayload = 'audit-universe/selected-field/list/',
  auditableUnitGrid = 'audit-universe/static-table-preview/list/',
  auditUniverseSelectAuditableDropdowns = 'audit-universe/drop-down/list/',
  createGenericDocument = 'generic-document-template/create',
  updateGenericDocument = 'generic-document-template/edit/',
  genericDocumentList = 'generic-document-template/list',
  genericDocumentDetail = 'generic-document-template/detail/',
  genericDocumentDelete = 'generic-document-template/delete/',
  annexureDynamicList = '/annexure/dynamic-field-list/',
  departmentChart= 'filter-dashboard/',
  fileUplad='media_upload/',
  tagGenericDocument = 'generic-document-template/tag/',
  annexureConfigList = 'incident-reporting/admin/annexure/list/',
  userCardList= 'user-card-list/',
  cpWorkingPaperStep = 'incident-reporting/admin/workingpaper-step/list/',
  stepDropdownList = 'dropdown-list/workingpaper-step/',
  workflowGroups= 'work-flow/user-group-list',
  riskTabList= 'risk-team/list',
  getscoringTable= 'team-logic/list',
  logicFieldList= 'team-logic/field-list',
  updateLogic= 'team-logic-update',
  updateRange= 'range/update',
  featureStatus= 'feature-status'
}

export enum UrlsForXLSandCSV {
  riskExportCSV = 'https://dev.beinex.in/risk-assessment/download_csv_risk_control',
  riskExportXLS = 'https://dev.beinex.in/risk-assessment/download_xls_risk_control'
  }
  export const tableConfig = {
  tableLimitOption: [5, 10, 25, 50, 100],
  pager: 10,
  tableLimit: 10,
  newTableLimit: 21,
  initial: 0
  };

  export const rcmTableConfig = {
    tableLimitOption: [5, 10, 25, 50],
    pager: 10,
    tableLimit: 10
  };

  export const rcmTableConfigRisk = {
    tableLimitOption: [5, 10, 25, 50, 100, 250],
    pager: 10,
    tableLimit: 10
  };

  export const IncidenttableConfig = {
    tableLimitOption: [5, 10, 25, 50, 100],
    pager: 10,
    tableLimit: 5
    };

  export const validationRule = {
  emailMinLength: 5,
  rpoMaxLength: 6,
  emailMaxLength: 70,
  phoneNumberMinLength: 10,
  contactMaxLength: 17,
  phoneNumberMaxLength: 15,
  textAreaMinLength: 0,
  textAreaMaxLength: 65535,
  nameMinLength: 2,
  nameMaxLength: 100,
  nameMax30length: 30,
  passwordMinLength: 8,
  passwordMaxLength: 25,
  mobileMinLength: 10,
  mobileMaxLength: 20,
  textMinLength: 0,
  zipMaxLength:15,
  textMaxLength: 255,
  string: 'string',
  titleTextBoxMaxLength: 500,
  titleTextBoxMaxLength700: 700,
  descriptionTextAreaMaxLength: 1100,
  titleTextBoxMaxLength255: 255,
  assetTitleBox: 500,
  incidentTitleTextBoxMaxLength: 50,
  auditCustomField: 70,
  requestFormTitle: 50,
  tierMaxLength: 10,
  titleTextBoxMaxLength200: 200,
  cpTextBoxMaxLength: 70,
  colorcodeMaxlen: 7,
  costSavingMaxLength: 2147483647,
  numberOfExceptions: 100000000000000000,
  observationTitleLength: 300,
  documentUploadSizeLimit: 26214400,
  dataSourceDocumentLimit: 73400320,
  multipleDocumentUploadSizeLimit: 314572800,
  integerLimit: 2147483647,
  integerLimitNegative: -2147483647,
  logoUploadSizeLimit: 1048576,
  multipleDocumentVideoUploadSizeLimit: 2147483648,
  VideoUploadSizeLimit: 838860800,
  privateKeyFileLimit: 5242880,
  twoMbFileLimit:2097152,
  abbrevationLength: 40,
  importDocSizeLimit: 524000000,
  customIdFieldLimit: 50
  };

  export const maxUploadSize = {
    maxLogoSize: ((validationRule.logoUploadSizeLimit/ (1024 * 1024)).toFixed(0)) + ' MB',
    maxDocumentSize: ((validationRule.documentUploadSizeLimit/ (1024 * 1024)).toFixed(0)) + ' MB',
    maxDocumentSizeAllFile: ((validationRule.multipleDocumentUploadSizeLimit/ (1024 * 1024)).toFixed(0)) + ' MB',
    dataSourceMaxSize: ((validationRule.dataSourceDocumentLimit/ (1024 * 1024)).toFixed(0) + ' MB'),
    maxDocumentVideoSizeAllFile: ((validationRule.multipleDocumentVideoUploadSizeLimit/ (1024 * 1024 * 1024)).toFixed(2) + ' GB'),
    maxVideoUploadSizeLimit: ((validationRule.VideoUploadSizeLimit/ (1024 * 1024)).toFixed(0) + ' MB'),
    maxPriveKeyFileSizeLimit: ((validationRule.privateKeyFileLimit/ (1024 * 1024)).toFixed(0) + ' MB'),
    importDocFileSizeLimit: ((validationRule.importDocSizeLimit/ (1024 * 1024)).toFixed(0) + ' MB'),
  }

  export enum LocalStorageKey {
  kriDetails = 'kriDetails',
  AssetEdit = 'AssetEdit',
  userLogin = 'token',
  expDetails = 'expDetails',
  riskDetails = 'riskDetails',
  scoringDropdown = 'scoringDropdown',
  riskControl = 'riskControlDetails',
  auditDraft = 'auditDraft',
  auditEdit = 'auditEdit',
  auditAction = 'auditActionDetails',
  auditPlanDetail = 'auditPlanDetail',
  auditReportDetail = 'auditReportDetail',
  observationDetail = 'observationDetail',
  budgetDetail = 'budgetDetail',
  ComplianceUpload = 'complianceupload',
  timeDetails = 'timeDetails',
  universalFilter = 'filterDetails',
  LikelihoodData = 'likelihoodData',
  impactData = 'impactData',
  velocityData = 'velocityData',
  scoringModelData = 'scoringModel',
  vulnerabilityData = 'vulnerabilityData',
  riskOverview = 'riskOverview',
  riskScoring = 'riskScoring',
  riskScoringDetails = 'riskScoringDetails',
  templateDetail = 'templateDetail',
  actionDetail = 'actionDetail',
  experienceDetail = 'experienceDetail',
  educationDetail = 'educationDetail',
  certificationDetail = 'certificationDetail',
  allFilterApplied = 'allFilter',
  userDetails = 'userDetails',
  membersUserDetails = 'membersUserDetails',
  entityCount = 'entityCount',
  sessionId = 'sso-sessionid',
  riskScoringUpdated = 'riskScoringUpdated',
  auditPlanDetails = 'auditPlanDetails',
  compliancePlanDetail = 'compliancePlanDetail',
  auditObservationDetail = 'auditObservationDetail',
  recommendationDetail = 'recommendationDetail',
  mappedData = 'mappedData',
  fieldArray = 'fieldArray',
  connectionHistoryName = 'connectionHistoryName',
  validatedData = 'validatedData',
  warningIndex = 'warningIndex',
  warningIndexDate = 'warningIndexDate',
  warningIndexInt = 'warningIndexInt',
  warningIndexDecimal = 'warningIndexDecimal',
  updatedIndex = 'updatedIndex',
  tableName = 'tableName',
  subModuleFields = 'subModuleFields',
  connectionMethod = 'connectionMethod',
  additionalFields = 'additionalFields',
  observationRecommendationDetail = 'observationRecommendationDetail',
  auditActionDetail = 'auditActionDetail',
  editScenarioData = 'editScenarioData',
  existingTemplate = 'existingTemplate',
  usersDetails = 'userDetail',
  queryBuilder = 'queryBuilder',
  builderChanged = 'builderChanged',
  auditRecommendationDetail = 'auditRecommendationDetail',
  userDashboardMenu = 'userDashboardMenu',
  gridDataPreview = 'gridDataPreview',
  issueDetails = 'issueDetails',
  controlDetails = 'controlDetails',
  connectionHistoryObject = 'connectionHistoryObject',
  analyticsUpdateData = 'analyticsUpdateData',
  analyticsScheduleDetails = 'analyticsScheduleDetails',
  isUpdate = 'isUpdate',
  notificationId = 'notificationId',
  userType = 'userType',
  customField = 'customField',
  datatype = 'datatype',
  formChanges = 'formChanges',
  riskControls = 'riskControl',
  requestId = 'requestId',
  policyOverview = 'policyOverview',
  isLoadMore = 'isLoadMore',
  isTabClick = 'isTabClick',
  riskAssessmentList = 'riskAssessmentList',
  deletedAssessment = 'deletedAssessment',
  ermOverallRatingGraph = 'ermOverallRatingGraph',
  sectorCount = 'sectorCount',
  universalSectorFilter = 'sectorFilterDetails',
  allSectorFilterApplied = 'allSectorFilter',
  filterCount = 'filterCount',
  isDraftProgram = 'isDraftProgram',
  riskProgramEditFromDetail = 'riskProgramEditFromDetail',
  riskRatingData = 'riskRatingData',
  riskProgramDetails = 'riskProgramDetails',
  documentDetailText = 'Maximum file upload size (PDF - 16 MB, Images - 200 KB, Other documents 10 MB)',
  emailDocumentDetailText = "Maximum file upload size is 10 MB",
  IncidentEdit = 'IncidentEdit',
  toDisplay = 'toDisplay',
  permissions = 'permissions',
  assessmentBreadcrumbData = 'assessmentBreadcrumbData',
  libraryConfigured = 'libraryConfigured',
  restoreData = 'restoreData',
  headerCleared = 'headerCleared',
  columnCount = 'columnCount',
  deletedValues = 'deletedValues',
  existingData = 'existingData',
  breadCrumb ='breadCrumb',
  orginalValues = 'orginalValues',
  assessmentRedirectUrl = 'assessmentRedirectUrl'
  }

export enum ScoringConstants {
  active = 'active',
  title = 'title',
  riskAssessment = 'risk-assessment',
  likelihood = 'likelihood',
  impact = 'impact',
  velocity = 'risk_velocity',
  vulnerability = 'vulnerability',
  inherentImpact = 'inherent_impact',
  inherentLikelihood = 'inherent_likelihood',
  scoring = 'scoring',
  summary = 'summary',
  overView = 'overView',
  dependency = 'dependency',
  assessment = 'assessment',
  residual_rating = 'residual_rating',
  inherent_rating = 'inherent_rating',
  risk_category = 'risk_category'
  }
  export enum AssessmentConstants {
  summary = 'summary',
  design = 'design',
  preview = 'preview'
  }

  export enum AssessmentRedirectConstants {
    viewDetails = 'View Details',
    takeAssessment = 'Take Assessment',
  }
  export enum AssessmentQuestionConstants {
    delete = 'delete',
    duplicate = 'duplicate',
    update = 'update'
  }
  export enum CreateAuditConstants {
  overview = 'overview',
  schedule = 'schedule',
  program = 'program',
  finish = 'finish'
  }

  export enum CreateKriConstants {
    overview = 'overview',
    scoring = 'scoring',
    dependencies = 'dependencies',
    finish = 'finish'
  }

  export enum CreateComplianceConstants {
    overview = 'overview',
    schedule = 'schedule',
    policy = 'policy',
    finish = 'finish'
    }
  export enum ObservationConstants {
  observation = 'observation',
  auditArea = 'areas_audit',
  control = 'control',
  date = 'observed_date',
  description = 'description',
  department = 'department_new',
  rating = 'rating',
  status = 'observation_status',
  comments = 'management_comments',
  implementationOwner = 'implementation_owner',
  stakeholder = 'observation_business_user',
  issue = 'issue',
  survey = 'surveyv2',
  taggedIssue = 'tagged_issue',
  surveyV2 = 'survey_v2',
  controls = 'controls',
  likelihood = 'likelihood',
  impact = 'impact',
  risks = 'risks'
  }
  export enum AdminUsersWidgets {
  professional = 'PROFESSIONAL',
  firstName = 'first_name',
  lastName = 'last_name',
  client = 'is_client_admin',
  email = 'email',
  designation = 'designation',
  licenseType = 'license_type',
  userGroup = 'user_group',
  action = 'action',
  isActive = 'is_active',
  designationName = 'name',
  range = 'Range',
  pending = 'Pending',
  reportingTo = 'reporting_to',
  Range = 'range',
  userType = 'usertype',
  userCategory = 'usercategory',
  entities = 'entities',
  departments = 'departments',
  sections = 'sections',
  functions = 'functions',
  l3Departments = 'l3_departments',
  l4Departments = 'l4_departments',
  sectors = 'sectors',
  regions = 'regions'
  }
  export enum AdminAuditWidgets {
  changeToBy = 'change_to_by',
  sourceOfAudit = 'source_of_audit',
  observationRating = 'observation_rating',
  rating = 'rating',
  methodOfAudit = 'method_of_audit',
  requestedBy = 'requested_by',
  auditAreas = 'audit_areas',
  areasAudit = 'areas_audit',
  is_default = 'is_default',
  is_overdue = 'is_overdue',
  incidentRating = 'incident_rating',
  complianceObservationRating = 'compliance_observation_rating',
  methodOfAudits = 'method_of_audit',
  improvementStatus = 'improvement_status'
}
  export enum ConnectionTextDataConstants {
  gettingStarted = 'getting-started',
  mapping = 'mapping',
  review = 'review'
  }
  export enum ModelInsightsConstants {
  formFeatureSelection = 'feature-selection',
  formModelSelection = 'model-selection',
  formModelInsights = 'model-insights'
  }
  export enum AdminUserGroupWidgets {
  label = 'label',
  userRole = 'iam_roles'
  }
  export enum AuditRequestTable {
  audit = 'audit',
  request = 'request',
  pendingRequest = 'pendingRequest',
  pendingApproval = 'pendingApproval'
  // calendar = 'calendar'
  }
  export enum FeatureTable {
  allFeatures = 'allFeatures',
  approval = 'approval'
  }
  export enum PermissionTypes {
  permissions = 'Permission',
  adminPermissions = 'Admin permission'
  }
  export enum ComplianceDraftTable {
  complianceAudit = 'complianceAudit',
  complianceRequest = 'complianceRequest',
  review = 'review',
  draft = 'draft',
  pendingRequests = 'pendingRequests',
  calendar = 'calendar'
  }

  export enum RequestDraftTable {
    request = 'request',
    review = 'review',
    requestdraft = 'draft',
  }

  export enum BcmDraftTable {
  bcm = 'bcm',
  draft = 'draft',
  asset = 'asset'
  }
  export enum PolicyDraftTable {
  policy = 'policy',
  policyRequest = 'policyRequest',
  policyReview = 'policyReview',
  policyDraft = 'policyDraft'
  }
  export enum AdminEntityWidgets {
  name = 'name',
  entity_type = 'entity_type',
  address_line1 = 'address_line1',
  address_line2 = 'address_line2',
  city = 'city',
  state = 'state',
  country = 'country',
  zip_code = 'zip_code',
  contact_number = 'contact_number',
  entity_manager = 'entity_manager',
  region = 'region',
  entity_size = 'entity_size',
  is_default = 'is_default'
  }
  export enum FeatureWidgets {
  featureKey = 'feature_key',
  timeOut = 'time-out',
  days = 'days',
  daysLeft = 'days_left',
  content = 'content',
  subject = 'subject',
  emails = 'emails',
  scenarios = 'scenarios',
  status = 'status',
  session = 'Session Timeout',
  password_enforcement = 'Password Enforcement',
  ReminderOutstandingActions = 'Reminder Outstanding Actions',
  ReminderOutstandingRisk = 'Reminder Outstanding Risks',
  Escalation_outstanding_actions = 'Escalation Outstanding Actions',
  Analytics_email_notification = 'Analytics Email Notification',
  failedLogin = 'Failed login count',
  failedCount = 'failed-count'
  }
  export enum ColumnSecurityWidgets {
  module = 'module',
  user = 'user',
  column = 'column',
  control_type = 'control_type'
  }
  export enum AdminRoleWidgets {
  name = 'name',
  description = 'description',
  policies = 'policies'
  }
  export enum AssessmentWidgets {
  auditAssessment = 'auditAssessment',
  audit = 'audit',
  auditObservations = 'auditObservations',
  risk = 'riskUniverse',
  control = 'control',
  auditRiskAnalytics = 'auditRiskAnalytics',
  compliance = 'compliance',
  complianceSchedule ='complianceSchedule',
  Risk = 'Risk',
  riskProgram = 'riskProgramAssessment',
  incident = 'incident',
  auditSchedule= 'auditSchedule',
  bcp = 'bcp',
  bcm = 'bcm',
  kri = 'kri'
  }
  export enum ActionWidgets {
  title = 'title',
  issues = 'issue',
  dueDate = 'due_date',
  description = 'description',
  costSaving = 'cost_saving',
  department = 'department',
  department_new = 'department_new',
  actionStatus = 'action_status',
  actionPriority = 'action_priority',
  actionDepartment = 'department',
  recommendationAction='audit-recommendation-action',
  owner = 'action_owner',
  recommendationOwner = 'recommendation_owner',
  risk = 'risk',
  control = 'control',
  auditActivity = 'auditActivity',
  objectId = 'object_id',
  action = 'action',
  audit = 'audit',
  improvement = 'improvements',
  action_type = 'action_type',
  control_name = 'control_name',
  controlRating = 'controlRating',
  control_rating = 'control_rating',
  recommendation = 'Recommendation',
  auditReporting = 'audit-reporting',
  actionsStatus = 'action_status/',
  issueAction = 'issueAction',
  editAction = 'editAction',
  actionType = 'action_type',
  risks = 'Risk',
  audits = 'Audit',
  controls = 'Control',
  incident = 'incident_reporting',
  deleteAction = 'deleteAction',
  browseAction = 'browseAction',
  changedFields = 'changed_fields',
  recommendationStatus = 'recommendation_status',
  recommendationPriority = 'recommendation_priority',
  issueTracker = 'issueTracker',
  riskProgram = 'risk_program',
  tbd = 'TBD',
  departmentLevel1 = 'department_level1',
  departmentLevel2 = 'department_level2',
  departmentLevel3 = 'department_level3',
  departmentLevel4 = 'department_level4',
  programActivity = 'programActivity',
  programIssueAction = 'programIssueAction',
  controlScore = 'control_score',
  complianceObservation = 'complianceObservation',
  riskId = 'risk_id',
  incidentId = 'incident_id',
  compliance = 'compliance',
  auditProgramAction = 'auditProgramAction',
  risk_program = 'risk_program'
  }
  export enum LogoWidget {
  brand_logo_dark = 'brand_logo_dark',
  brand_logo_light = 'brand_logo_light',
  logo_dark = 'logo_dark',
  logo_light = 'logo_light'
  }
  export enum ActivityWidgets {
  name = 'name',
  due_date = 'due_date',
  assignee = 'assignee',
  status = 'status',
  stakeholder = 'stakeholder',
  auditor_comment = 'auditor_comment',
  reviewer = 'reviewer',
  file = 'file',
  is_reviewer = 'is_reviewer',
  changed_fields = 'changed_fields',
  activity_assignee = 'activity_assignee',
  activity_stakeholder = 'activity_stakeholder',
  level = 'level',
  megaProcess = 'mega_process',
  department_level1='department_level1',
  department_level2='department_level2',
  department_level3=' department_level3',
  department_level4='department_level4',
  media = 'media',
  majorProcess = 'major_process'
  }
  export enum NotificationRemark {
  remarks = 'remarks',
  requestId = 'request_id',
  actionType = 'action_type',
  notificationId = 'notification_id',
  requestFor = 'request_for'
  }
  export enum RiskmangementWidgets {
  slug = 'slug',
  field = 'field',
  order = 'order',
  weightage = 'weightage',
  color = 'color',
  previous_color = 'previous_color',
  previous_value = 'previous_value',
  value = 'value',
  parent = 'parent',
  is_default = 'is_default',
  is_overdue = 'is_overdue',
  description_v1 = 'description_v1',
  category = 'control_category'
  }
  export enum IssueWidgets {
  auditData = 'audit',
  riskData = 'risk',
  controlData = 'control'
  }
  export enum DraftConstants {
  isDraft = 'is_draft',
  draftTrue = 'True',
  draftFalse = 'False'
  }
  export enum ExperienceWidgets {
  title = 'title',
  id = 'id',
  employment_type = 'employment_type',
  company = 'company',
  location = 'location',
  start_date = 'start_date',
  end_date = 'end_date',
  description = 'description',
  current_flag = 'current_flag',
  certification_name = 'certification_name',
  organization = 'organization',
  issued_date = 'issued_date',
  expiry_date = 'expiry_date',
  credential_id = 'credential_id',
  credential_url = 'credential_url',
  signature = 'signature'
  }
  export enum UserWidgets {
  file = 'file',
  id = 'id',
  first_name = 'first_name',
  last_name = 'last_name',
  team = 'team',
  company = 'company',
  contact_number = 'contact_number',
  alternative_contact_number = 'alternative_contact_number',
  linkdin_id = 'linkdin_id',
  biography = 'biography',
  designation = 'designation',
  signature = 'signature',
  userType = 'usertype',
  userCategory = 'usercategory'
  }
  export enum PasswordWidgets {
  current_password = 'current_password',
  password = 'password',
  confirm_password = 'confirm_password',
  email = 'email'
  }
  export enum ScoringWidgets {
  scoringModel = 'scoring_model',
  vulnerability = 'vulnerability',
  velocity = 'risk_velocity',
  impact = 'inherent_impact',
  likelihood = 'inherent_likelihood',
  observationImpact = 'Impact',
  observationLikelihood = 'Likelihood'
  }
  export enum EducationWidgets {
  id = 'id',
  school = 'school',
  field_of_study = 'field_of_study',
  start_year = 'start_year',
  end_year = 'end_year'
  }
  export enum CertificationWidgets {
  id = 'id',
  certification_name = 'certification_name',
  organization = 'organization',
  issued_date = 'issued_date',
  expiry_date = 'expiry_date',
  credential_url = 'credential_url',
  credential_id = 'credential_id',
  observation = 'observation',
  action_type = 'action_type'
  }
  export enum ObservationWidgets {
  observation = 'observation',
  date = 'observed_date',
  description = 'description',
  department = 'department',
  auditArea = 'audit_area',
  rating = 'rating',
  stakeholder = 'observation_business_user',
  managementStatus = 'observation_status'
  }
  export enum AuditObservationWidgets {
  observation = 'observation',
  date = 'observed_date',
  description = 'description',
  management_comments = 'management_comments',
  department = 'department',
  reviewer = 'reviewer',
  is_reviewer = 'is_reviewer',
  auditArea = 'audit_area',
  rating = 'rating',
  business_user = 'business_user',
  observation_status = 'observation_status',
  auditeditArea = 'audit_area',
  auditAreas = 'areas_audit',
  stakeholder = 'stakeholder',
  issueDesignationV1 = 'issue_designation_v1',
  issueDropdownV1 = 'issue_dropdown_v1',
  issueDropdownV2 = 'issue_dropdown_v2'
  }
  export enum WorkingPaperWidgets {
  rating = 'rating',
  risk = 'risk',
  control = 'control',
  workingPaperNoData = 'WP - Not Generated',
  browseRecord = 'browseRecord',
  browseFieldWork = 'browseFieldwork',
  manual = 'Manual'
  }
  export enum AuditTeams {
  owningGroup = 'owningGroup'
  }
  export enum AuditObservationEditWidgets {
  auditArea = 'audit'
  }
  export enum AuditUniverseWidgets {
  planName = 'plan_name',
  stakeholder = 'plan_manager',
  reviewer = 'reviewer',
  approver = 'approver',
  startDate = 'start_date',
  endDate = 'end_date',
  auditUniverseManager = 'audit_universe_manager',
  universe = 'universe'
  }
  export enum ComplianceAction {
  title = 'title',
  complianceId = 'complianceId',
  dueDate = 'due_date',
  description = 'description',
  department = 'department',
  actionStatus = 'action_status',
  actionPriority = 'action_priority',
  owner = 'owner',
  stakeHolder = 'stakeholder',
  tagAssessment = 'tag_assessment',
  selectAssessmentQuestions = 'select_assessment_question',
  objectId = 'object_id'
  }
  export enum AuditPlanWidgets {
  planName = 'plan_name',
  planManager = 'plan_manager',
  startDate = 'start_date',
  endDate = 'end_date',
  objectId = 'object_id',
  audits_by_status = 'audits_by_status',
  reviewer = 'reviewer',
  approver = 'approver',
  riskPlanStatus = 'risk_plan_status',
  planStatus = 'business_plan_status',
  fiscalYear = 'fiscal_year',
  riskManager = 'is_auto_risk_manager',
  riskOwner = 'is_auto_risk_owner'
  }
  export enum BudgetConstants {
  riskAssessment = 'risk-assessment',
  auditPlanning = 'audit-planning',
  riskAssessmentV1 = 'risk-assessment-v1-details',
  audit = 'Audit',
  control = 'Control',
  riskManagement = 'Risk Management'
  }
  export enum AdminAuditWidgets {
  value = 'value',
  slug = 'slug',
  color = 'color',
  weightage = 'weightage',
  parent = 'parent',
  parentChild = '',
  field = 'field',
  order = 'order',
  abbreviation = 'abbreviation',
  name = 'name',
  overdueExclude = 'overdue_exclude',
  description_v1 = 'description_v1',
  category = 'control_category'
  }
  export enum RegionWidget {
  name = 'name',
  region_manager = 'region_manager',
  country = 'country'
  }
  export enum ReportWidget {
  firstPage = 'first_page',
  lastPage = 'last_page',
  introduction = 'introduction',
  sourceType = 'source_type'
  }
export enum PermissionWidget {
  userId = 'user_id',
  groupID = 'group_id',
  module = 'module',
  permGranted = 'perm_granted',
  permRevoked = 'perm_revoked'
  }
  export enum DepartmentWidget {
  name = 'name',
  order = 'order',
  departmentManager = 'department_manager',
  manager = 'manager'
  }
  export enum DesignationWidget {
  name = 'name',
  order = 'order'
  }
  export enum NotificationWidget {
  read = 'read',
  days = 'days',
  day = 'day',
  hours = 'hours',
  hour = 'hour',
  week = 'week',
  weeks = 'weeks',
  general = 'general',
  recordDiff = 'record-diff',
  mediaAttachment = 'mediaattachment',
  survey = 'surveyv2',
  keyvalue = 'key-value',
  comment = 'comment',
  riskAssessment = 'riskassessment',
  riskAssessmentDetails = 'riskassessmentdetails',
  observation = 'observation',
  observationAction = 'observationaction',
  Control = 'Control',
  Risk = 'Risk',
  auditPlan = 'auditplan',
  compliance = 'compliance',
  complianceAction = 'complianceauditaction',
  auditPlanData = 'auditplanmaster',
  auditObservationRecommendation = 'observationrecommendation',
  auditObservation='assignee-tagging-assessment-to-audit-observation',
  auditAssesment='assignee-tagging-assessment-to-audit',
  clear = 'clear',
  unread = 'unread',
  flagged = 'flagged',
  flag = 'flag',
  approve = 'approve',
  audit = 'Audit',
  issues = 'Issue',
  budget = 'Budget',
  incidentAction = 'incidentaction',
  deleteAction = 'deleted_action_activity',
  businessProcess = 'businessprocess',
  bulkEdit = 'control-bulk-edit',
  assetManagement = 'assetmanagement',
  auditProgramActivity = 'auditprogramactivity',
  assessment = 'surveyv2',
  businessStrategy = 'businessstrategy',
  issue = 'issue',
  budgetActivity = 'budgetactivity',
  analyticsException = 'analytics_exception',
  approved = 'Approved',
  rejected = 'Rejected',
  pending = 'Pending',
  approveCapital = 'Approve',
  rejectCapital = 'Reject',
  auditAnalytics = 'Audit Analytics',
  home = 'home',
  deleteNotification = 'deleted_notification',
  timesheet = 'timesheet',
  kriDetails = 'kridetails',
  kriscore = 'kriscore',
  incident = 'incident',
  policy = 'policymanagement',
  contributor = 'CONTRIBUTOR',
  createAssessment = 'create_assessment',
  taggedAssessment = 'tagging_assessment',
  taggingAssessment = 'assign_assessment',
  analyticsScenarioMaster = 'analyticsscenariomaster',
  createAudit = 'create_audit',
  editAudit = 'edit_audit',
  deleteAudit = 'delete_audit',
  approvalSystemV3 = 'approval_system_new_v3',
  policyRequest = 'policyrequest',
  activity = 'Activity',
  digitalreport = 'digitalreport',
  approvalrequest = 'approvalrequest',
  auditUniverse = 'Audit Universe',
  activityAction = 'Activity Action',
  policyRequestReview = 'policy_review_request',
  complianceAudit = 'complianceaudit',
  complianceRequest = 'compliancerequest',
  complianceRequestReview = 'compliance_review_request',
  businessPlan = 'businessplan',
  issueAction = 'Issue Action',
  observationRecommendation = 'Observation Recommendation',
  actionObservation = 'Observation Action',
  riskPlan = 'riskplan',
  enterpriserisk = 'enterpriserisk',
  categories = 'categories',
  register ='register',
  observations = 'Observation',
  issueDetails = 'Audit_Issue_Detail',
  commentAdded = 'comment_added',
  workingPaper = 'workingpaper',
  managementResponse = 'managementresponse',
  autoReminder = 'auto-reminder',
  bulkReminder = 'bulk-reminder',
  analyticsCalculatedField = 'Analytics Calculated field',
  analyticsCalculatedFieldCreate = 'Analytics calculation field create',
  complianceObservation = 'complianceobservation',
  complianceAuditObservationRecommendation = 'complianceauditobservationrecommendation',
  complianceManagementResponse = 'compliancemanagementresponse',
  complianceAuditManagementResponse = 'complianceauditmanagementresponse',
  export = 'export',
  mention = 'mention',
  approverReviewerUpdate = 'approver_reviewer_update',
  riskProgramPlan = 'riskprogramplan',
  RiskProgram = 'RiskProgram',
  unflag = 'unflag',
  commentDiff = 'comment-diff',
  program = 'riskprogram',
  riskProgramParent = 'Risk Program',
  takeErmAssessment = 'assign_erm_assessment',
  keyValueRecordDiff = 'key-value-record-diff',
  activityActionRiskProgram = 'ActivityAction',
  issueActionRiskProgram = 'IssueAction',
  analyticsDataCopy = 'Analytics data copy',
  analyticsDataImport = 'Analytics data import',
  analyticsDataLoadMore = 'Analytics data loadmore',
  analyticsScheduleLoad = 'Analytics schedule load',
  ermQuestions = 'ermquestionaire',
  ermAssessmentPlan = 'ermassessmentplan',
  dataExport = 'druid_data_export',
  ermAssessment = 'ermassessment',
  complianceAuditPlan = 'complianceauditplan',
  complianceAuditVersion = 'complianceversion',
  dashboardExport = 'report_export',
  ermcategory = 'ermcategory',
  ermregister = 'ermregister',
  incidentReporting= 'Incident',
  approvedSignOff = 'approved_signoff',
  systemNotification = 'System Notification',
  userNotification = 'User Notification',
  all = 'all',
  All = 'All',
  user = 'user',
  system = 'system',
  improvement= 'improvement',
  createComplianceObsAction= 'create_compliance_obs_action',
  complianceParent = 'Compliance Audit',
  documentKeyValue= 'document-key-value',
  multiTable= 'multi-table',
  workingPaperTakeAssessment = 'assignee_tagging-assessment-to-working-paper',
  bcmTakeAssessment = 'assignee-tagging-assessment-to-business-process',
  bcmAuditGenerateDoc = 'bcm_create_audit_doc',
  bcmAuditUploadReport = 'bcm-audit-report-upload',
  analyticsControlTrigger = 'analytics-control-trigger',
  analyticsRiskTrigger = 'analytics-risk-trigger',
  analyticsControlTaskTrigger ='analytics-control-task-trigger',
  analyticsRiskTaskTrigger = 'analytics-risk-task-trigger',
  managementResponseSendReminder = 'management-response-send-reminder',
  auditGroupApproval = 'audit-group-approval-process',
  bcpTakeAssessment= 'assignee-tagging-assessment-to-bcp',
  surveyAssigned= 'survey-participant-create',
  asssignSurvey='survey-participant-create-schedule',
  surveyResponseApproval= 'survey-response-approval-create-requestor',
  surveyApprovalRevoke= 'survey-response-approval-revoke',
  surveyApprovalApproved= 'survey-response-approval-approve',
  surveyApprovalRejected= 'survey-response-approval-reject',
  surveyApprovalCreate= 'survey-response-approval-create',
  bcmWorkingPaperTakeAssessment = 'assignee_tagging-assessment-to-bcm-working-paper',
  bcmWorkingPaperTakeAssessmentReassign = 'bcm-audit-wp-assessment-reassign-assignee',
  documentArchiveShare = 'for-shared-media-or-folder-share',
  bcmTakeAssessmentModal = 'assignee-tagging-assessment-to-bcm-audit',
  wpGroupApproval = 'wp-group-approval-process',
  auditPlanGroupApproval = 'audit-plan-group-approval-process',
  controlRatingSingle = 'control-rating-update-single',
  generatedObsImport = 'generative-ai-observations-import',
  analyticsScenarioBulkDelete = 'analytics-scenario-bulk-delete'
  }
export enum Toasts {
  validTimeInterval = 'Please Enter a valid time interval',
  lastRemoveTimeframe = 'You can\'t delete timeframe 1',
  lastSectionRemove = 'tab must have a single section',
  lastFieldRemove = 'section must have a single field',
  duplicateChoice = 'Choice must have unique value',
  formError = 'There are items that require your attention',
  kriUniqueValue = 'Values must be unique',
  noAnswer = 'You dont have answers to submit, Please provide some answers',
  selectRisk = 'Please select atleast one risk',
  selectTableName = 'Please select one table',
  selectQuestion = 'Please select atleast one question',
  selectOtherRisk = 'There is no questionnaire available with this risk. Please select some other risks',
  fillRequired = 'Please fill all the mandatory fields',
  fillProperly = 'Please fill all the mandatory fields properly',
  fillValidContactNumber = 'Please fill valid Contact number with 10 digits',
  fillValidContact = 'Please enter a valid contact number',
  fillValidAlternateContactNumber = 'Please fill alternate contact number with 10 digits',
  fillValidAlternateContact = 'Please enter a valid alternate number',
  tabRequired = 'Please assign value to all tab',
  changeRequired = 'Please change assignee',
  sectionRequired = 'Please assign value to all section',
  choiceRequired = 'Please assign value to all choice',
  fieldsRequired = 'Please assign value to all fields',
  uniqueValuesRequired = 'Source must have unique destination',
  somethingWentWrong = 'Something went wrong. Please try again!',
  noSameRiskDurationDates = 'Risk duration start and end dates can\'t be same',
  actionCreate = 'Action created',
  experinceCreate = 'Experience Added',
  certificationCreate = 'Certification Added',
  educationCreate = 'Education Added',
  passwordReset = 'Password Changed Successfully',
  observationCreated = 'Observation Created',
  recommendationCreate = 'Recommendation Created',
  actionEdit = 'Action edited',
  activityEdit = 'Activity edited',
  activityDeleted = 'Activity Deleted',
  experienceEdit = 'Experience Edited',
  userEdit = 'User Data Updated',
  educationEdit = 'Education Updated',
  certificationEdit = 'Certification Updated',
  categoryEdit = 'Category Updated',
  SubcategoryEdit = 'Sub Category Updated',
  observationEdit = 'Observation Edited',
  actionUpdate = 'Action updated',
  actionDelete = 'Action deleted',
  experienceDelete = 'Experience Deleted',
  educationDelete = 'Education Deleted',
  certificationDelete = 'Certification Deleted',
  observationDelete = 'Observation deleted',
  recommendationDelete = 'recommendation Deleted',
  recommendationUpdate = 'recommendation Updated',
  riskCreate = 'Risk created',
  riskUpdate = 'Risk updated',
  riskDelete = 'Risk deleted',
  riskControlCreate = 'Risk control created',
  riskControlUpdate = 'Risk control updated',
  riskControlDelete = 'Control deleted',
  selectFile = 'Please select file',
  documentUpload = 'Document uploaded',
  documentDelete = 'Document deleted',
  documentUpdate = 'Document updated',
  documentImport = 'Document imported',
  fileFormatError = 'Selected file format is not supported',
  fileSizeExceeds = 'Selected file size exceeds the limit, file should be less than',
  wrongImportedFile = 'Please ensure that the uploaded file is compatible',
  addRiskToControl = 'Risk is added to control',
  addRisksToControl = 'Risks are added to control',
  addAssessmentToControl = 'Assessment is added to control',
  addAssessmentsToControl = 'Assessments are added to control',
  addRiskToAudit = 'Risk is added to audit',
  addRisksToAudit = 'Risks are added to audit',
  addIssuesToObservation = 'Issues are added to observation',
  addIssueToObservation = 'Issue is added to observation',
  addUpstreamToBusinessProcess = 'Upstream Dependencies are added to Business Process',
  addRiskToAuditProgram = 'Risk is added to audit program',
  addRisksToAuditProgram = 'Risks are added to audit program',
  controlsFetched = 'Controls fetched to audit',
  risksFetched = 'Risks fetched to audit',
  addControlToAudit = 'Control is added to audit',
  addControlsToAudit = 'Controls are added to audit',
  addDownstreamToBusinessProcess = 'Downstream Dependencies are added to Business Process',
  addAssetsToBusinessProcess = 'Assets are added to Business Process',
  addPolicyToBusinessProcess = 'Policy added to the Business Process',
  addPoliciesToBusinessProcess = 'Policies added to the Business Process',
  addControlToAuditProgram = 'Control is added to audit program',
  addControlsToAuditProgram = 'Controls are added to audit program',
  addAssessmentToAudit = 'Assessment is added to audit',
  addAssessmentToRiskProgram = 'Assessment is Added to Risk Program',
  addAssessmentsToAudit = 'Assessments are added to audit',
  addAssessmentsToRiskProgram = 'Assessments are Added to Risk Program',
  addManagementTeamToAudit = 'Management team is added to audit',
  addManagementsTeamToAudit = 'Management teams are added to audit',
  addAuditeeToAudit = 'Auditee is added to audit',
  addAuditeesToAudit = 'Auditees are added to audit',
  addAssessmentsToKri = 'Assessments are Added to KRI',
  addAssessmentToKri = 'Assessment is Added to KRI',
  addAssessmentToObservation = 'Assessment is added to observation',
  addAssessmentToObservations = 'Assessments are added to observation',
  addObservationToControl = 'Observation is added to control',
  addObservationsToControl = 'Observations are added to control',
  addControlToRisk = 'Control is added to risk',
  addControlsToRisk = 'Controls are added to risk',
  addAssessmentToRisk = 'Assessment is added to risk',
  addAssessmentsToRisk = 'Assessments are added to risk',
  addObservationToRisk = 'Observation is added to risk',
  addObservationsToRisk = 'Observations are added to risk',
  fillAtLeastOne = 'Select at least one field',
  selectAtleastOne = 'Select at least one model.',
  dataTypeError = 'Please select a field of same type',
  varCharTypeError = 'Selected field is not of the same type',
  removeErrorFields = 'Please remove the faulty fields',
  dataCopyStarted = 'Data copying has been started',
  fillCorrectData = 'Please select fields of same type',
  addControlToObservation = 'Control is added to observation',
  addControlsToObservation = 'Controls are added to observation',
  addriskToObservation = 'Risk is added to observation',
  addrisksToObservation = 'Risks are added to observation',
  complianceCreate = 'Compliance Audit created successfully',
  complianceUpdate = 'Compliance Updated',
  auditCreate = 'Audit Created',
  scoringModelCreate = 'Scoring Model Created',
  scoringModelUpdate = 'Scoring Model Updated',
  mapDataAdditionalFieldCount = 'The maximum field count has been reached',
  fieldSelectedAlready = 'The field has been selected already.',
  auditUpdate = 'Audit updated',
  lengthRequired = 'Password must be 8 letters long',
  matchRequired = 'Passwords do not match',
  flagError = 'Current flag is already set',
  SelectData = 'Please select atleast one risk',
  SelectDeptData = 'Please select atleast one department',
  GeneratePlan = 'Audit plan generated',
  CategoryAdd = 'Category Added',
  SubCategoryAdd = 'Sub Category Added',
  categoryDelete = 'Category Deleted',
  SubcategoryDelete = 'Sub Category Deleted',
  validationError = 'Data too long',
  regionCreated = 'Region Created Successfully',
  departmentCreated = 'Department Created Successfully',
  regionEdited = 'Region Updated Successfully',
  departmentEdited = 'Department Updated Successfully',
  mapDataExistingFieldsDelete = 'This field has already been used in scenario creation and thus can\'t be deleted.',
  mapDataExistingDocumentFieldMapped = 'This field has already been mapped. Please select another one.',
  mapDataExistingTemplateFieldMapped = 'This field has already been mapped. Please select another one.',
  observationControl = 'There is no control in this observation',
  observationRecommendation = 'There is no recommendation in this observation',
  observationAction = 'There is no action in this observation',
  dashboardMeasuresEmpty = 'Values field cannot be empty. Please drop in a field.',
  draftRemove = 'Draft Removed Successfully',
  mapDataDuplicateModuleFields = 'Field exists. Please remove duplicate fields.',
  lengthValidate = 'has no more than 500 characters',
  emptyHeadersUploaded = 'There are empty header found at column ',
  uploadAgain = 'Please check and upload again.',
  mergedCellsUploaded = 'Merged cells found from',
  syntaxError = 'Syntax Error.',
  invalidExpression = 'Invalid Expression',
  invalidRange = 'The overall rating range exceeds the maximum of 13 characters. Try using a different expression.',
  mapAtLeastSingleNormalField = 'Please map at least one non custom field',
  pleaseSelectColumns = 'Please map at least one non custom field',
  oneFieldBeActive = 'One field must be active',
  errorMessageAskAura = 'This feature is disabled. Please contact your administrator',
  gateWayTimeOutToast = 'Failed to establish connection.',
  mustMapFieldsImported = 'Imported fields must be mapped',
  pleaseMapField = 'Please map at least one field',
  riskDeleted = 'Risk deleted successfully',
  controlDeleted = 'Control deleted successfully',
  assessmentDeleted = 'Assessment Deleted Successfully',
  assessment = 'Assessment',
  assessmentDelete = 'Deleted Successfully',
  issueDeleted = 'Issue deleted successfully',
  controlRemoved = 'Control removed successfully',
  riskRemoved = 'Risk removed successfully',
  metricsRemoved = 'Metrics removed successfully',
  assessmentRemoved = 'Assessment removed successfully',
  workingPaperRemoved = 'Working Paper Removed successfully',
  issueRemoved = 'Issue removed successfully',
  policyBusinessProcessRemoved = 'Business Process removed from policy',
  policyComplianceRemoved = 'Compliance removed from policy',
  policyControlRemoved = 'Control removed from policy',
  policyRiskRemoved = 'Risk removed from policy',
  policyRequestRemoved = 'Request removed from policy',
  policyBusinessProcessAdded = 'Business Process added to Policy',
  policyBusinessProcessesAdded = 'Business Processes added to Policy',
  policyComplianceAdded = 'Compliance added to Policy',
  policyCompliancesAdded = 'Compliances added to Policy',
  policyControlAdded = 'Control added to Policy',
  policyControlsAdded = 'Controls added to Policy',
  policyRiskAdded = 'Risk added to Policy',
  policyRisksAdded = 'Risks added to Policy',
  selectProperDates = 'Select proper dates',
  noMegaProcessSelected = 'No Mega process selected',
  enterValidPolicy = 'Please enter a valid policy !!',
  enterValidDocsal = 'Please enter a valid document !!',
  policyDocumentDeleted = 'Document deleted.',
  invalidDate = 'Please select valid date',
  tableNodata = 'Selected table has no data',
  auditNotEditable = 'You cannot edit this record as it has a pending edit request. You can edit this record after the request is completed.',
  permissionDenied = 'You are not allowed to view this page',
  permissionRestricted = 'You are not allowed to do this action',
  tagPermissionDenied = 'You don\'t have permission to perform this action',
  deletedRecord = 'The record doesn\'t exist',
  errorSheetName = 'Please select a sheet to proceed',
  errorDelimiter = 'Please select a delimiter to proceed',
  policyVersionDelete = 'Cannot delete the current version',
  downloadSuccesful = 'Downloaded successfully',
  docDownloaded = 'Document downloaded',
  mergedCellError = ' cells are found merged in this file. Please unmerge them and upload again!',
  selectCategoryWithForm = 'Please select a category with form fields',
  errorDelimiterSelected = 'Selected delimiter is not found in the uploaded file!',
  singleRecordError = 'No data in the file uploaded!',
  fillProperValue = 'Please fill value other than 0',
  complianceBusinessProcessRemoved = 'Business Process removed from Compliance',
  kriBusinessProcessRemoved = 'Business Process removed from KRI',
  complianceRequestRemoved = 'Request removed from Compliance',
  compliancePolicyRemoved = 'Policy removed from Compliance',
  bcmPolicyRemoved = 'Policy removed from Business Process',
  complianceControlRemoved = 'Control removed from Compliance',
  complianceRiskRemoved = 'Risk removed from Compliance',
  complianceBusinessProcessAdded = 'Business Process added to Compliance',
  complianceBusinessProcessesAdded = 'Business Processes added to Compliance',
  compliancePolicyAdded = 'Policy added to Compliance',
  compliancePoliciesAdded = 'Policies added to Compliance',
  complianceControlAdded = 'Control added to Compliance',
  complianceControlsAdded = 'Controls added to Compliance',
  compliceRequestAdded = 'Request added to Compliance',
  compliceRequestsAdded = 'Requests added to Compliance',
  policyrequestAdded = 'Request Added to Policy',
  policyrequestsAdded = 'Requests Added to Policy',
  kriControlsAdded = 'Controls added to KRI',
  kriControlAdded = 'Control added to KRI',
  complianceRiskAdded = 'Risk added to Compliance',
  complianceRisksAdded = 'Risks added to Compliance',
  kriRisksAdded = 'Risks added to KRI',
  kriRiskAdded = 'Risk added to KRI',
  kriProcessAdded = 'Business process added to KRI',
  kriProcessesAdded = 'Business process added to KRI',
  complianceAuditVersionDelete = 'Cannot delete the current version.',
  permissionUser = 'Cannot save without an user/user group',
  enterValidComplianceCitation = 'Please enter a valid citation !!',
  noChanges = 'No changes have been made',
  validInput = 'Please enter a valid input',
  sortEditorError = 'Please select sort type to continue.',
  tierTagMessage = 'Please tag a tier to the timeframe',
  maximumErrorMessage = 'Maximum Range should not be lesser than the other ranges',
  selectAFile = 'Please select a file',
  selectATemplate = 'Please select atleast one template',
  rcmFieldEmptyMessage = 'RCM Fields cannot be empty',
  rcmRiskFieldEmptyMessage = 'Please select atleast one field in risk',
  rcmControlFieldEmptyMessage = 'Please select atleast one field in control',
  rcmAssessmentFieldEmptyMessage = 'Please select atleast one field in assessment',
  riskRatingUpdate = 'Rating calculation is not updated',
  workflowDeleted = 'Workflow deleted successfully',
  enterAnswerChoice = 'Please enter answer choice properly for the columns added',
  noRecords = 'There are no records to reassign',
  fillAtLeastOneItem = 'Please select at least one item',
  bulkReminder = 'The record does not exist or has been deleted.',
  SelectObservation = 'Please select atleast one observation',
  validRiskName = 'Please provide a valid risk name',
  sameStartandEndDate = 'The risk duration\'s start and end dates cannot be the same.',
  dataTransferInprogressMsg = 'You can not chose this user. Data tranfer is in progress with this user',
  totalSizeExceed = 'The uploaded files exceeded the maximum file size of 10Mb.',
  fileSizeAudit = 'Selected file size exceeds the limit, file should be less than 10Mb',
  invalidStrategyDate = 'The selected dates should fall within the time period of the strategy plan.',
  invalidMinMax = 'Minimum and Maximum Value based on the calculation is the same. Please check the logic',
  invalidMinGreaterThanMix = 'Minimum Value is greater than the Maximum Value based on the calculation. Please check the logic',
  enterValidText = 'Please enter valid text !!',
  email = 'please give a vaild email',
  Unauthorized = 'You are not authorized to access this service',
  addComment = 'You are not authorized to add comments',
  validName = 'Please provide a valid risk program name',
  policyEmptyData = 'There is no active users in the selected review panel.',
  activePanel = 'There is no active review panel.',
  addWorkingPaperToObservation = 'Working paper is added to observation',
  addWorkingPapersToObservation = 'Working papers are added to observation',
  notAuthorizedToEdit = 'notAuthorizedToEdit',
  notAuthorizedToEditProgram = 'You are not authorized to edit in risk program',
  maximumFirstErrorMessage = 'Calculation error. Please check the input values.',
  noMenusAdded = 'Unable to save a empty menu list. Please add few menus',
  mainMenuNeedAttension = 'Unable to save a empty main menu. Please add few sub-menus to the main menu',
  observationRcmFieldEmptyMessage = 'Please select atleast one field in observation',
  recommendationRcmFieldEmptyMessage = 'Please select atleast one field in recommendation',
  managementResponseRcmFieldEmptyMessage = 'Please select atleast one field in management response',
  notAuthorizedToEditErm = 'You are not authorized to edit Enterprise Risk ',
  maximumTextlength = 'Maximum field length of the text field is exceeded',
  minimumTextlength = 'Minimum field length of the text field is required',
  unableToCreateFolder = 'Unable to create multiple folders at once',
  selectOneFolder = 'Please select a folder',
  unableToDropToPage = 'Unable to add a menu inside a page. Please drop it to a main menu',
  unableToDropMainMenu = 'Unable to drop a main menu to another main menu',
  duplicateMenu = 'Menu already exist in the list, You can not add same menu multiple times',
  notAuthorizedToEditMR = 'You are not authorized to edit Mangement Response',
  selectfields = 'Please select atleast one field',
  Cannotdelete = 'To create a template, at least one activity should be retained.',
  selectDimension = 'Please select dimensions',
  selectAudit = 'Please select atleast one audit',
  applyButtonValidation = 'Please first click apply button',
  enableShowButton = 'Please enable atleast one control to show',
  enableMegaProcess = 'Please enable show in mega process',
  requiredNotification = "Please add a notification",
  requiredAtlestUserOrGroup = 'Please select at least one user or one user group ',
  totalFileSizeExceeds = 'The selected file size exceeds the limit; the total upload file size should be less than 300 MB.',
  totalFileSizeExceedsVerification = 'The total upload file size should be less than 300 MB.',
  fileSizeTemplate = 'Selected file size exceeds the limit, file should be less than 25Mb',
  totalSizeExceedTemplate = 'The uploaded files exceeded the maximum file size of 25Mb.',
  approvalLock = 'Can\'t perform this action as approval is under process',
  selectRatingFactor = 'Please select atleast one rating factor',
  totalFileSizeExceedNotification = 'The selected file size exceeds the limit; the total upload file size should be less than 25 MB.',
  totalFileSizeExceedForCreateAudit_Risk_and_Compliance = 'The selected file size exceeds the limit; the total upload file size should be less than 75 MB.',
  actionRequired = 'Action cannot be performed as there is no change.',
  selectDates = 'Please select the dates',
  selectDatesNotsame = 'The start and end dates cannot be the same.',
  keepOneMandatory = 'At least one tab is required',
  inProperRangeLimit = "Invalid range. Please check your inputs and try again.",
  deletedData = 'Record has been deleted and is no longer available.',
  dateError ='Invalid date range. Please check your inputs.',
  consentError = 'Please accept consent statement',
  buttonSave = 'Please save the survey',
  participantSave= 'Please select at least one user or user group.',
  selectAtleastOneView = 'Please select at least one view.',
  addRiskAndControlToObservation = "Risk(s) and control(s) added.",
  approversFiledsEmpty ='To initiate the approval process, please add at least one user to the \Approver\ field.',
  actionPerformed = 'Action already performed',
  inactiveParticipantSave = 'You cannot add participants as the survey is inactive or the end date has expired.',
  dependencyUpdated = 'Dependencies updated',
  noImgUpload = 'No image upload allowed',
  groupsCannotBeEmpty= 'Groups cannot be empty',
  selectExistingFile = 'Select a file matching the column headers of the existing file',
  fieldCheck = 'Customize field names and map document fields with corresponding data types.',
  fileProcess = 'Loading sheets, please wait...',
  startEndDateCheck = 'End date should be greater than start date.',
  titleError = 'Title cannot be empty',
  sampleScoreLesserError = 'Sample score should be greater than minimum value.',
  sampleScoreGreaterError = 'Sample score should not be greater than maximum value.',
  fileSizeError = 'The single file size cannot exceed the maximum file size limit.',
  selectAtLeastOneFileFormat = 'Select at least one file format.',
  fileAlreadyUploaded = 'The file has already been uploaded.',
  noEditForStatusReview = "Editing is disabled during the 'IN-Review' state, Please await feedback from the assigned Reviewer before making any changes.",
  thresholdValueError = 'Updated value should be between minimum and maximum value.',
  dateValidationError = 'Date should be between 0 and 29',
  teamsWarningNotCancel = 'Cannot cancel this meeting in Teams, Since you are not the organizer',
  teamsWarningNotRemove = 'Cannot remove this meeting in Teams, Since meeting is not found',
  teamsWarningNotUpdate = 'Cannot update this interview, Since meeting is not found',
  teamsNotificationSent = 'Notification is sent',
  cmAddrisksToObservation = 'Risks are added to',
  cmAddriskToObservation = 'Risk is added to',
  cmAddControlToObservation = 'Control is added to',
  cmAddControlsToObservation = 'Controls are added to',
  cmAddAssessmentToObservation = 'Assessment is added to',
  cmAddAssessmentToObservations = 'Assessments are added to',
  cmAddWorkingPaperToObservation = 'Working paper is added to',
  cmAddWorkingPapersToObservation = 'Working papers are added to',
  checkinPermission = 'You do not have the necessary permissions to perform this action.',
  percentageValidation = 'Value should be less than or equal to 100',
  rollupofKpiValidation = 'Value should be less than or equal to 10',
  scoringCalculationUpdate = 'Scoring Calculation not updated',
  selectBaseYear = 'Select a base year to compare.',
  selectDiffYear = 'Select different years for comparison.',
  selectAnYear = 'Select years to compare.',
  selectAction = 'Select at least one action.',
  layerPermissionDisable = 'Access to this layer has been turned off in the control panel. To view and navigate this layer, please adjust the settings in the control panel.',
  conditionError = 'Condition cannot be empty.'
}

  export enum ConfigurableAudit {
  yes = 'Yes',
  fieldName = 'field_name',
  cancel = 'cancel',
  auditReporting = 'audit-reporting',
  foreignKey = 'ForeignKey',
  tableId = 'table_id',
  auditReportingAction = 'audit-reporting-action',
  department = 'department',
  actionDepartment = 'action_department',
  auditPlanning = 'audit-planning',
  color = '#E9F0F7',
  auditProgram='risk-assessment',
  auditManagementResponse='audit-reporting',
  auditRecommendation = 'audit-reporting-recommendation',
  recommendationDepartment = 'recommendation_department',
  futureSnapshot = '_futureSnapshot',
  configurable = 'configurable',
  status = 'status',
  auditStatus = 'audit_status',
  field = 'field',
  issueStatus = 'issue_status',
  issueRating = 'issue_rating',
  managentStatus = 'management_status',
  improvementManagementResponse = 'improvement-management-response',
  observationStatus = 'observation_status',
  statusManagement = 'Management Status',
  issueDropdown1 = 'issue_dropdown_1',
  issueDropdown2 = 'issue_dropdown_2',
  issueDropdownV1 = 'issue_dropdown_v1',
  issueDropdownV2 = 'issue_dropdown_v2',
  issueMultiDropdown1 = 'issue_multi_dropdown_1',
  issueMultiDropdown2 = 'issue_multi_dropdown_2',
  issueMultiDropdownV1 = 'issue_multi_dropdown_v1',
  issueMultiDropdownV2 = 'issue_multi_dropdown_v2',
  addActivationKey = 'addActivationKey',
  auditimprovement = 'audit-reporting',
  auditProgramWp = 'workingpaper',
  auditCpIssue = 'issue',
  auditCpMr = 'management-response'
  }
  export enum DashboardBuilderUrls {
  home = '/dashboard-builder/home',
  connectedData = '/dashboard-builder/connected-data-source',
  builder = '/dashboard-builder/builder'
  }
  export enum ReportBuilderUrls {
  home = '/report-builder/home',
  connectedData = '/report-builder/connected-data-source',
  builder = '/report-builder/builder'
  }
  export enum CategoryWidgets {
  category = 'category',
  budget = 'budget',
  color = 'color',
  entity = 'entity',
  country = 'country',
  slugValue = 'budget-activity',
  slug = 'slug',
  setColor = '#E9F0F7',
  categoryId = 'category_id',
  futureSnapShot = '_futureSnapshot'
  }
  export enum SubCategoryWidgets {
  category = 'category',
  sub_category = 'sub_category',
  sub_budget = 'sub_budget',
  color = 'color',
  subcategoryId = 'subcategory_id'
  }
  export enum ApiStatus {
  ok = 200,
  created = 201,
  noContent = 204,
  badRequest = 400,
  unAuthorized = 401,
  forbidden = 403,
  notFound = 404,
  notAcceptable = 406,
  conflict = 409,
  unprocessableEntity = 422,
  failedDependency = 424,
  serverDown = 500,
  timeOut = 504,
  securityStatus = 307,
  }
  export const assessmentErmConst = {
  category: 'category',
  risk: 'risk'
  };
  export enum RiskCodes {
  riskType = 280
  }
  export const colourForm = {
  color: ['#115caa', '#8559b4', '#cf4fa3', '#ff537c', '#ff764b',
          '#3943b7', '#e64b5f', '#610345',  '#be26f7', '#0f8cb4',
          '#ff521b', '#cd3c05', '#8b6f00', '#209299', '#156064',
          '#a34059', '#fa8778', '#e183ca', '#e43f6f', '#2f6586',
          '#a963ff', '#1068ff', '#1133e6', '#4aafff', '#a21212',
          '#62bb47', '#76c043', '#8ac640', '#9ccb3f', '#bad43b',
          '#e0e030', '#fae053', '#fcd029', '#fbb724', '#fba22c',
          '#f8952d', '#f58229', '#f26426', '#ef4824', '#0878bc',
          '#2a94cb', '#4fb0d7', '#77cae3', '#a0e4f0', '#cafeff']
  };
  export enum FileSize {
  size = 1000000000,
  dataLength = 500,
  totalSize = 10000000,
  templateSize = 26214400,
  fileSize75Mb=78643200
  }

  export const observation = {
  addObservation: 'Ensure observation'
  };
  export const observationList = {
  issues: 'Issues',
  control: 'Control',
  assessment: 'Assessment',
  workingPaper: 'Working Paper',
  risk: 'Risk'
  };
  export const userStatus = {
  active: 'Active',
  superAdmin: 'Super Admin',
  inActive: 'Inactive'
  };
  export const activityChartData = {
  action: 'action',
  activityStatus: 'activity_status',
  assignee: 'assignee'
  };
  export const issueChartData = {
  issueRating: 'audit_issues',
  issueStatus: 'issue_status',
  issueOwner: 'issue_owner',
  issueStatusChart: 'issueStatusChart',
  issueRatingChart: 'issueRatingChart',
  ratingIssue: 'issue_rating',
  recordType: 'record_type',
  requestType: 'request_type',
  requestStatus: 'request_status',
  requestByRecordType: 'requestByRecordType',
  requestByType: 'requestByType',
  observationByRating: 'observationByRating',
  observationByStatus: 'observationByStatus'
  };
  export const observationChartData = {
  auditStatus: 'audit__status_of_audit',
  observationStatus: 'observation_status',
  managementStatus: 'management_status',
  department: 'department',
  stakeholder: 'stakeholder',
  megaProcess: 'megaProcess',
  observationType: 'observation_type',
  riskProgramByStatus: 'risk_program__status_of_risk_program'
  };
  export const responseChartData = {
  responseByStatus: 'status',
  rating: 'rating'
  };
  export const recommendationData = {
  recommendationDepartment: 'recommendation_department',
  recommendationStatus: 'recommendation_status',
  recommendationPriority: 'recommendation_priority',
  recommendation: 'recommendations'
  };
  export const managementResponseData = {
  managementStatus: 'management_status',
  managementUser: 'management_user',
  observationRating: 'observation_rating'
  };
  export const departmenManagementResponseData = {
    department: 'department_levels',
    };
  export const timeLine = {
  statusChanged: 'Stage Status Changed',
  activityChanged: 'Activity status changed.'
  };
  export const issueContributorChartData = {
  issueRating: 'audit_issues',
  issueStatus: 'issue_status',
  issueOwner: 'issue_owner',
  issueStatusChart: 'issueStatusChart',
  issueRatingChart: 'issueRatingChart'
  };
  export const activityChartIdentifier = {
  activityByStatus: 'activityByStatus',
  actionByStatus: 'actionByStatus'
  };
  export const consolidatedChartIdentifier = {
  observationRating: 'observationRating',
  auditEntity: 'auditEntity',
  actionStatus: 'actionStatus',
  riskRating: 'riskRating',
  scenarioCritical: 'scenarioCritical',
  controlRating: 'controlRating'
  };
  export const issueTrackerData = {
  actionPriority: 'action_priority',
  actionsByOwner: 'actions_by_owner',
  actionStatus: 'action_status'
  };
  export const documentTypes = {
    allowedExtensions: ['jpg', 'jpeg', 'png', 'pdf', 'docx', 'doc', 'ppt', 'pptx', 'xls', 'xlsx', 'txt', 'odt', 'csv', 'psv', 'tsv', 'ssv', 'scsv', 'msg', 'mp4', 'avi', 'mkv', 'mov', 'wmv', 'webm', 'flv',]
  };
  export const excelTypes = {
    allowedExtensions: ['xls', 'xlsx']
  };

  export const privateKeyFileType = {
    allowedExtensions: ['pem']
  };

  export const textTypes = {
    allowedExtensions:['csv', 'txt', 'ssv', 'tsv', 'scsv', 'psv']
  }
  export const imageTypes = {
    allowedExtensions: ['jpg', 'jpeg', 'png', 'svg']
  };

  export const docTypes = {
    allowedExtensions: ['jpg', 'jpeg', 'png', 'pdf', 'docx', 'doc', 'ppt', 'pptx', 'xls', 'xlsx','csv']
  };

  export const auditStatus = [
  { label: 'All Audit Universes', data: 0, color: '#4e79a7' },
  { label: 'Active', data: 0, color: '#ffa600' },
  { label: 'Completed', data: 0, color: '#61DBB5' }
  ];
  export const auditStatusPlan = [
    { label: 'All Audit Plans', data: 0, color: '#4e79a7' },
    { label: 'Active', data: 0, color: '#ffa600' },
    { label: 'Completed', data: 0, color: '#61DBB5' }
    ];
  export const riskPlanStatus = [
  { label: 'All Risk Plans', data: 0, color: '#4e79a7' },
  { label: 'Active', data: 0, color: '#ffa600' },
  { label: 'Completed', data: 0, color: '#61DBB5' }
];

export const compliancePlanStatus = [
  { label: 'All Compliance Plans', data: 0, color: '#4e79a7' }
];
export const auditIssueConstant = {
  auditIssue: 'auditIssue',
  issueTrackerIssue: 'issueTrackerIssue',
  auditPlanning: 'Audit Planning',
  riskAssessment: 'Risk Assessment',
  complianceAudit : 'Compliance Audit',
  riskProgramWorkingPaper: 'riskProgramWorkingPaper',
  riskProgram: 'Risk Program',
  complianceIssue: 'complianceIssue'
  };

  export const commonDocumentEvents = {
    limit: 'limit',
    edit: 'edit',
    delete: 'delete',
    search: 'search',
    pageChange: 'pageChange',
    save: 'save',
    refresh: 'refresh',
    scroll: 'scroll'
  };

  export const riskUniverseQueryParams = {
  limit: tableConfig.pager,
  cursor: '',
  offset: 0,
  risk_category: '',
  risk_category1: '',
  residual_impact: '',
  inherent_impact: '',
  risk_owner: '',
  residual_likelihood: '',
  inherent_likelihood: '',
  risk_manager: '',
  risk_identifier: '',
  risk_velocity: '',
  multi_values: false,
  q: '',
  strategy_category: '',
  risk_plan: '',
  risk_plan_v1: '',
  status: '',
  risk_department: '',
  mega_process_v1: '',
  major_process_v1: '',
  mega_process_v2: '',
  major_process_v2: '',
  top_risk: '',
  risk_residual_rating: '',
  risk_inherent_rating: '',
  inherent_rating: '',
  residual_rating: '',
  erm_identifier: '',
  manager: '',
  erm_type: '',
  owner: '',
  mega_process: '',
  major_process: '',
  category:'',
  overall_rating:'',
  erm_owner: '',
  key_risk: '',
  risk_appetite: '',
  risk_mitigation_required: '',
  risk_type: '',
  risk_within_appetite: '',
  target_residual_risk: '',
  fiscal_year:'',
  risk_department_level1: '',
  risk_department_level2: '',
  department_level1:'',
  department_level2:'',
  domain: '',
  generic_tag: '',
  generic_clause:'',
  generic_type: '',
  treatment: '',
  fraud_related: '',
  risk_department_level3: '',
  risk_department_level4: '',
  regions: '',
  functions: '',
  sector: '',
  multi_entity: ''
  };
  export const bcmAssetQueryParams = {
  limit: tableConfig.initial,
  offset: 0,
  multi_values: false,
  q: '',
  asset_type_status: '',
  criticality_status: '',
  cursor: '',
  count: false,
  dynamic_fields: true,
  request_for: '',
  order_by_field: '',
  table_filters: ''

  };
  export const riskControlQueryParams = {
  limit: tableConfig.pager,
  cursor: '',
  offset: 0,
  control_type: '',
  control_rating: '',
  control_frequency: '',
  control_owner: '',
  control_manager: '',
  control_automation: '',
  multi_values: false,
  q: '',
  mega_process: '',
  major_process: '',
  department_level1: '',
  department_level2:'',
  status: '',
  inherent_likelihood: '',
  inherent_impact: '',
  overall_rating: '',
  fiscal_year: ''
  };

  export const riskControlQueryParamsFilter = {
    limit: tableConfig.pager,
    cursor: '',
    offset: 0,
    control_type: '',
    control_rating: '',
    control_frequency: '',
    owners: '',
    managers: '',
    control_automation: '',
    multi_values: false,
    q: '',
    mega_process: '',
    major_process: '',
    department_level1: '',
    department_level2: '',
    status: '',
    inherent_likelihood: '',
    inherent_impact: '',
    overall_rating: '',
    fiscal_year: '',
    domain: '',
    implementation_status: '',
    generic_tag: '',
    generic_clause:'',
    generic_type: '',
    key_control: '',
    risk_department_level3: '',
    risk_department_level4: '',
    regions: '',
    functions: '',
    sector: '',
    multi_entity: ''
  };

  export const riskIssueQueryParams = {
  limit: tableConfig.pager,
  offset: 0
  };
  export const riskControlTab = {
  controlType: 'control_type'
  };
  export const auditQueryParams = {
  requested_by: '',
  method_of_audit: '',
  audit_areas: '',
  rating: '',
  status_of_audit: '',
  audit_manager: '',
  plan: '',
  audit_status: '',
  multi_values: false,
  q: ''
  };
  export const auditsForPlanQueryParams = {
  status_of_audit: ''
  };
  export const complianDetailsParams = {
  limit: tableConfig.pager,
  offset: 0,
  compliance_action_status: '',
  multi_values: false,
  q: ''
  };
  export const incidentDetailsParams = {
  limit: tableConfig.pager,
  offset: 0,
  incident_status: '',
  multi_values: false
  };
  export const assetDetailsParams = {
  limit: tableConfig.pager,
  offset: 0,
  multi_values: false
  };
  export const bcmDetailsParams = {
  limit: tableConfig.pager,
  offset: 0,
  multi_values: false
  };
  export const auditObservationQueryParams = {
  action_status: '',
  recommendation_status: ''
  };
  export const auditCalendarQueryParams = {
  plan: ''
  };
  export const auditPlaningQueryParams = {
  requested_by: '',
  method_of_audit: '',
  audit_areas: '',
  areas_audit: '',
  rating: '',
  status_of_audit: '',
  audit_manager: '',
  plan: '',
  audit_status: '',
  plan_status: '',
  mega_process_v1: '',
  major_process_v1: '',
  manager: '',
  multi_values: false,
  audit_date: '',
  department_level1: '',
  department_level2: '',
  user_department_level1: '',
  program_plan_status: '',
  mega_process: '',
  major_process: '',
  compliance_plan_status: '',
  fiscal_year: '',
  audit_plan_owner: ''
};

export const kriQueryParams = {
  avg_rating: '',
  department_level1: '',
  avg_trend:'',
  multi_values: false,
  threshold:''
}
export const riskProgramQueryParams = {
  risk_program_requested_by: '',
  method_of_risk_program: '',
  audit_areas: '',
  areas_audit: '',
  rating: '',
  status_of_risk_program: '',
  audit_manager: '',
  plan: '',
  audit_status: '',
  plan_status: '',
  manager: '',
  mega_process: '',
  multi_values: false,
  program_date: '',
  fiscal_year: '',
  };
  export const auditRequestQueryParams = {
  audit_id: '',
  audit_name: '',
  type: '',
  date: '',
  request_form: '',
  limit: tableConfig.pager,
  offset: 0,
  approval_status: '',
  record_type: '',
  request_type: '',
  request_status: ''
  };
  export const entityQueryParams = {
  entity_type_new: '',
  sector: '',
  region: '',
  entity_managers: '',
  major_process: '',
  mega_process: '',
  multi_values: false,
  entity_rating: '',
  department_rating: '',
  entity_tier: ''
  };
  export const departmentQueryParams = {
  department_rating: '',
  type: '',
  manager: '',
  major_process: '',
  mega_process: '',
  sector: '',
  multi_values: false,
  department_type: ''
  };
  export const auditParams = {
  limit: tableConfig.pager,
  offset: 0
  };
  export const riskParams = {
    limit: tableConfig.pager,
    offset: 0,
    count: true,
    cursor: '',
    pagination_type: ''
    };
  export const historyQueryParams = {
  limit: tableConfig.pager,
  offset: 0
  };
  export const auditReportingQueryParams = {
  report_status: '',
  status_of_audit: '',
  audit_manager: '',
  plan: '',
  manager: '',
  multi_values: null,
  audit_date: '',
  approval_status: '',
  record_type: '',
  request_type: '',
  request_status: ''
  };
  export const auditRecommendationsQueryParams = {
  object_id: ''
  };
  export const auditIssueTrackerQueryParams = {
  multi_values: false,
  action_status: '',
  action_priority: '',
  action_department: '',
  issue_tracker_action_status: '',
  department: '',
  owner_action: '',
  owner: '',
  record_owner: '',
  plan: '',
  department_level1: '',
  department_level2: '',
  risk_program_name: '',
  compliance_action_status:'',
  compliance_action_status_all:'',
  compliance_action_department :'',
  compliance_action_priority:''
  };
  export const ermAssessmentQueryParams = {
  division: '',
  multi_values: false,
  assessment_status: ''
  };
  export const incidentReportingQueryParams = {
  incident_department: '',
  incident_rating: '',
  incident_type: '',
  incident_status: '',
  incident_action_status: '',
  multi_values: false
  };
  export const ermAssessmentConstants = {
  likelihoodRating: 'likelihood_rating',
  overallRating: 'overall_rating',
  impactRating: 'impact_rating'
  };
  export const contributorIncidentDasboardQueryParams = {
  incident_type: '',
  incident_department: '',
  incident_rating: '',
  incident_action_status: '',
  action_priority: '',
  action_owner: '',
  incident_status: '',
  incident_reporting_status: '',
  date_filter: '',
  date_range: '',
  multi_values: false,
  cursor: ''
  };
  export const incidentDashboardQueryParams = {
  incident_department: '',
  incident_rating: '',
  incident_type: '',
  incident_status: '',
  incident_action_status: '',
  multi_values: false,
  date_filter: '',
  date_range: '',
  incident_reporting_status: '',
  action_owner: '',
  action_priority: '',
  cursor: ''
  };
  export const controlPanelQueryParams = {
  incident_priority: '',
  incident_type: '',
  incident_status: '',
  incident_department: '',
  offset: 0,
  limit: 0
  };
  export const bcmAssetControlPanelQueryParams = {
  asset_type: '',
  offset: 0,
  limit: 0
  };
  export const complianceActionQueryParams = {
  limit: tableConfig.pager,
  offset: 0,
  compliance_action_department: '',
  compliance_action_status: '',
  compliance_action_priority: ''
  };
  export const incidentActionQueryParams = {
  limit: tableConfig.pager,
  offset: 0
  };
  export const bcmQueryParams = {
  limit: tableConfig.pager,
  offset: 0,
  q: '',
  business_process_department: '',
  business_process_mtpd_status: '',
  business_process_rto_status: '',
  status_of_business_process: '',
  multi_values: false,
  business_process_type : '',
  department_level_1: '',
  process_rating: '',
  department_level1: ''
  };
  export const assetQueryparam = {
  limit: tableConfig.pager,
  offset: 0,
  q: '',
  asset_type_status: '',
  criticality_status: '',
  overall_rating: '',
  multi_values: false
  };
  export const auComplianceAuditQueryParams = {
  limit: tableConfig.pager,
  offset: 0,
  compliance_category: '',
  compliance_status: '',
  compliance_action_status: '',
  assignee: '',
  multi_values: false
  };
  export const analyticsBuilderQueryParams = {
  limit: tableConfig.pager,
  offset: 0,
  multi_values: false
  };
  export const auditProgramQueryParams = {
  limit: tableConfig.pager,
  offset: 0
  };
  export const incidentSettingsQueryParams = {
  field_name: '',
  configurable: '',
  data_type: ''
  };
  export const requestSettingsQueryParams = {
    field_name: '',
    configurable: '',
    data_type: ''
    };
  export const budgetCategoryQueryParams = {
  category: 'category',
  created_date: 'created_date',
  modified_date: 'modified_date',
  object_id: 'object_id',
  budget: 'budget',
  created_by: 'created_by',
  updated_by: 'updated_by:',
  country: 'country',
  entity: 'entity',
  sub_category: 'sub_category',
  sub_budget: 'sub_budget'
  };
  export const riskUniverseSettingsQueryParams = {
  field_name: '',
  configurable: '',
  data_type: ''
  };
  export const askAuraaAuditParams = {
  'manager': [],
  'name': [],
  'start_date': [],
  'end_date': [],
  'entity': [],
  'type': [],
  'id': [],
  'modified_date': [],
  'created_date': [],
  'audit_areas': [],
  'plan': [],
  'current_stage': [],
  'status': []
  };
  export const askAuraaRiskParams = {
  'manager': [],
  'owner': [],
  'category': [],
  'name': [],
  'id': [],
  'rating': [],
  'status': [],
  'start_date': [],
  'end_date': [],
  'type': [],
  'entity': [],
  'modified_date': [],
  'inherent_likelihood': [],
  'inherent_impact': [],
  'residual_likelihood': [],
  'residual_impact': [],
  'residual_risk_rating': [],
  'created_date': []
  };
  export const askAuraaControlParams = {
  'manager': [],
  'name': [],
  'start_date': [],
  'end_date': [],
  'entity': [],
  'rating': [],
  'type': [],
  'id': [],
  'owner': [],
  'reduces_impact': [],
  'reduces_likelihood': [],
  'modified_date': [],
  'created_date': [],
  'control_frequency': [],
  'control_automation': []
  };
  export const activeClicked = {
  all: 'all',
  label: 'label',
  color: 'color',
  limit: 'limit',
  offset: 'offset',
  category: 'category',
  Others: 'Others'
  };
  export const askAuraaCountParams = {
  'manager': [],
  'name': [],
  'id': [],
  'category': [],
  'owner': [],
  'rating': [],
  'start_date': [],
  'end_date': [],
  'type': [],
  'status': [],
  'modified_date': [],
  'created_date': [],
  'entity': [],
  'reduces_impact': [],
  'reduces_likelihood': [],
  'control_automation': [],
  'control_frequency': [],
  'audit_areas': [],
  'plan': [],
  'current_stage': [],
  'inherent_likelihood': [],
  'inherent_impact': [],
  'residual_likelihood': [],
  'residual_impact': [],
  'residual_risk_rating': []
  };
  export const dateFormat = {
  serverDate: 'yyyy-MM-DD',
  userDate: 'DD MMM yyyy',
  monthYear: 'MMM YYYY',
  fullMonthYear: 'MMMM YYYY',
  timeSheetDate: 'MMMM DD, YYYY',
  timeSheetDateFilter: 'MMM DD, YYYY',
  date: 'DD-MM-YYYY HH:mm:ss',
  fullDate: 'YYYY-MM-DD  HH:mm:ss',
  userFullDate: 'DD MMM YYYY hh:mm A',
  time: 'hh:mm A',
  serverTime: 'H:M:S',
  createTimeApiFormat: 'yyyy-MM-dd',
  mediumDateFormat: 'hh. mm a, MMM dd yyyy',
  addTime: 'HH:mm:ss',
  movementTime: 'MM/DD/yyyy, HH:mm',
  extractTime: 'dd MMMM yyyy, hh:mm a',
  extractHr: 'hh:mm a',
  longDate: 'MMMM d, y',
  fullDateWithDay: 'd MMM yyyy, EEE',
  dateTime: 'dd MMM yyyy, hh:mm a'
  };
  export const budgetQueryParams = {
  limit: tableConfig.pager,
  offset: 0,
  category: '',
  analysis: '',
  budget_utilization: ''
  };
  export const assessmentsQueryParams = {
  survey_status: ''
  };
  export const obsQueryParams = {
    limit: tableConfig.pager,
    offset: 0,
    q: ''
  };
  export const assessmentQueryParams = {
  limit: tableConfig.pager,
  offset: 0,
  template_status: '',
  survey_category: '',
  created_by: '',
  survey_type: ''
  };
  export const businessStrategiesQueryParams = {
  q: '',
  limit: tableConfig.pager,
  offset: 0,
  strategy_category: '',
  plan: '',
  business_plan: '',
  strategy_owner: '',
  multi_values: false,
  strategy_status: '',
  department_level: '',
  department_level1: '',
  department_level2:'',
  objective_level: '',
  objective_level1: '',
  mega_process: '',
  major_process: '',
  strategy_plan: '',
  fiscal_year: ''
  };
  export const contributorObservationsQueryParams = {
  rating: '',
  observation_by_aging: '',
  observation_owner: '',
  observation_status: ''
  };
  export const contributorImprovementsQueryParams = {
    rating: '',
    improvements_by_aging: '',
    improvements_owner: '',
    improvements_status: ''
    };
  export const contributorIssuesQueryParams = {
  contributor_issue_rating: '',
  contributor_issue_status: '',
  contributor_issue_owner: '',
  contributor_issue_status_bar: '',
  issue_rating: '',
  observation_by_aging: '',
  issue_owner: '',
  issue_status: ''
  };
  export const contributorActionsQueryParams = {
  action_status: '',
  action_type: '',
  action_by_aging: '',
  action_priority: ''
  };
  export const auditActionQueryParams = {
  action_status: '',
  action_priority: '',
  action_department: ''
  };
  export const consolidatedDashboardParams = {
  entity__entity_size__value: '',
  rating: '',
  action_status: '',
  residual_risk_rating: '',
  overview_scenarios_by_criticality: '',
  control_rating: ''
  };
  export const auAuditProgramQueryParams = {
  issue_status: '',
  testing_status: '',
  activity_status: ''
  };
  export const auAuditIssueParams = {
  audit_issues: '',
  issue_owner: '',
  issue_status: '',
  issue_rating: ''
  };
  export const issueTrackerIssueParams = {
  issue_rating: '',
  issue_owner: '',
  status: '',
  issue_status: '',
  by_time: '',
  by_aging:'',
  multi_values: false,
  areas_audit: '',
  all_records: '',
  my_records: '',
  owner: '',
  plan: '',
  risk_program_name: '',
  tilecount: ''
  };
  export const issueTrackerComplianceIssueParams = {
    compliance_audit_issue_rating: '',
    issue_owner: '',
    status_issue: '',
    compliance_audit_issue_status: '',
    multi_values: false,
    areas_audit: '',
    all_records: '',
    my_records: '',
    owner: '',
    plan: '',
    risk_program_name: ''
    };

    export const issueTrackerComplianceObservationParams = {
      multi_values: false,
      areas_audit: '',
      all_records: '',
      my_records: '',
      owner: '',
      plan: '',
      observation_department: '',
      observation_status: '',
      observation_rating: '',
      status_card: '',
      order_by_field: '',
      table_filters: '',
      department_l1: '',
      department_l2: ''
      };
  export const issueTrackerRecommendationParams = {
  recommendation_priority: '',
  recommendation_department: '',
  recommendations: '',
  recommendation_status: '',
  multi_values: false,
  areas_audit: '',
  all_records: '',
  my_records: '',
  recommendation_owner: '',
  plan: '',
  department_l1: '',
  record_owner: '',
  risk_program_name: ''
  };
  export const observationDashboardParams = {
  observation_status: '',
  management_status: '',
  status_of_audit: '',
  observation_department: '',
  multi_values: false,
  stakeholder: '',
  megaprocess: '',
  observation_business_user: '',
  mega_process: '',
  department: '',
  audit__status_of_audit: '',
  rating: '',
  management_user: '',
  created_by: '',
  major_process: '',
  audit: '',
  date_filter: '',
  date_range: '',
  department_level1: '',
  department_level2: '',
  type : '',
  category: '',
  department_l1:'',
  compliance:'',
  compliance_status:'',
  observation_type: '',
  risk_program__status_of_risk_program: '',
  risk_program:'',
  fiscal_year: ''
  }
  export const issueTrackerResponseParams = {
  management_status: '',
  management_user: '',
  response_owner: '',
  observation_rating: '',
  all_records: '',
  my_records: '',
  response_status: '',
  multi_values: false,
  plan: '',
  risk_program_name: '',
  mega_process: '',
  major_process: '',
  audit_management_status: ''
  };
  export const managementResponseParams = {
  management_status: '',
  management_user: '',
  rating: '',
  multi_values: false
  };
  export const issueTrackerRecommendationRecords = [
  { label: 'All Records' },
  { label: 'My Records' },
  { label: 'Created By Me' }
  ];
  export const issueTrackerIssueRecords = [
  { label: 'All Records' },
  { label: 'My Records' },
  { label: 'Created By Me' }
  ];
  export const issueTrackerActionRecords = [
  { label: 'All Records' },
  { label: 'My Records' },
  { label: 'Created By Me' }
  ];
  export const issueTrackerIssueStatus = [
  { label: 'All Issues', data: 0, color: '#4e79a7' },
  { label: 'Overdue', data: 0, color: '#E15759' }
  ];
  export const issueTrackerRecommendationStatus = [
  { label: 'All Recommendations', data: 0, color: '#4e79a7' },
  { label: 'Overdue', data: 0, color: '#E15759' }
  ];
  export const observationStatus = [
  { label: 'All Observations', data: 0, color: '#4e79a7' },
  { label: 'Overdue', data: 0, color: '#E15759' }
  ];
  export const issueTrackerActionStatus = [
  { label: 'All Actions', data: 0, color: '#4e79a7' },
  { label: 'Overdue', data: 0, color: '#E15759' }
  ];
  export const managementResponseStatusCard = [
  { label: 'All Responses', data: 0, color: '#4e79a7' },
  { label: 'Overdue', data: 0, color: '#E15759' }
  ];
  export const recordDataSetConstant = {
  allRecords: 'All Records',
  myRecords: 'My Records',
  allRecord: 'all_records',
  myRecord: 'my_records',
  issueTrackerIssues: 'issueTrackerIssues',
  owner: 'owner',
  recommendationOwner: 'recommendation_owner',
  createdByMe: 'Created By Me',
  createdBy: 'created_by_me',
  actionOwner: 'owner_action',
  strategyOwner: 'strategy_owner',
  responseOwner: 'response_owner',
  recordOwner:'record_owner',
  asOnDate: 'As on Date'
  };
  export const auditRecommendationQueryParams = {
  recommendation_status: '',
  recommendation_priority: '',
  recommendation_department: ''
  };
  export const riskProgramRecommendationQueryParams = {
  recommendation_status: '',
  recommendation_priority: '',
  recommendation_department: '',
  multi_values: false
  };
  export const teamQueryParams = {
  user_id: '',
  avatar: '',
  first_name: '',
  last_name: '',
  designation: '',
  licence: '',
  user_group_count: '',
  first_time_login: ''
  };
  export const auditActivityQueryParams = {
  activity: '',
  due_date: '',
  status: '',
  assignee: '',
  stakeholder: '',
  comment: '',
  reviewer: ''
  };
  export const activityChartsQueryParams = {
  limit: tableConfig.pager,
  offset: 0,
  activity_status: '',
  assignee: '',
  action: ''
  };
  export const observationActionQueryParams = {
  action_status: ''
  };
  export const contributorRecommendationsQueryParams = {
  recommendation_status: '',
  recommendations: '',
  recommendation_by_aging: '',
  recommendation_priority: ''
  };
  export const contributorDasboardIssueTrackerQueryParams = {
  action_status: '',
  action_priority: '',
  actions_due: '',
  action_owner: '',
  date_filter: '',
  date_range: '',
  group: ''
  };
  export const auAuditActionsQueryParams = {
  limit: tableConfig.pager,
  offset: 0,
  template_status: '',
  survey_category: '',
  created_by: '',
  survey_type: ''
  };
  export const timeManagementQueryParams = {
  date_filter: null,
  date_range: '',
  group: '',
  category: '',
  project_type: ''
  };
  export const timeEntries:TimeEntryQueryParams = {
  users_time_spent: '',
  each_audit_stage_time_spent: ''
  };
  export const timeParams = {
  };
  export const chartConfig = {
  height: 220,
  width: 240,
  heightAddOn: 0,
  viewBoxAddOnWidth: 20,
  innerRadius: 50,
  outerRadius: 20
  };
  export const chartConfigProgress = {
  height: 100,
  width: 100,
  heightAddOn: 0,
  viewBoxAddOnWidth: 0,
  thickness: 10,
  innerRadius: 20,
  outerRadius: 10
  };
  export const auUserRole = {
  professional: 'PROFESSIONAL',
  contributor: 'CONTRIBUTOR',
  viewer: 'VIEWER',
  adminProfessional: 'ADMIN PROFESSIONAL',
  administrator: 'ADMINISTRATOR',
  explorer: 'EXPLORER',
  professionalAdministrator: 'PROFESSIONAL ADMINISTRATOR'
  };
  export const auUserStatus = {
  inactive: 'Inactive',
  active: 'Active',
  dataTransferInprogress: 'Data transfer in progress',
  pending: 'Pending'
  };
  export const userMassReassignConst = {
  allTypeDescription: 'This will replace the user from everywhere in the entire application. However, the activities done by the user will remain the same. Eg: Created by, Modified by, etc.'
  };
  export const scoringModelConstants = {
  scoringModels: 'scoring_models',
  scoringFactors: 'scoring_factors',
  inherentLikelihood: 'inherent_likelihood',
  inherentImpact: 'inherent_impact',
  configure_effectiveness: 'configure_effectiveness',
  risk_scoring_definitions: 'risk_scoring_definitions'
  };
  export const auditApprovalConstants = {
  pending: 'pending',
  rejected: 'rejected',
  approved: 'Approved',
  delete: 'delete',
  edit: 'edit',
  create: 'create',
  reviewer: 'Reviewer',
  approver: 'Approver',
  reviewed: 'Reviewed',
  review: 'Review',
  open: 'Open',
  completed: 'Completed',
  removed: 'removed',
  complete: 'completed',
  withdrew: 'withdrew'
  };
  export const scoringFactors = [
  {
  configurable: 'Yes',
  data_type: 'ForeignKey',
  field: 'inherent_likelihood',
  field_name: commonLanguage.inherent_likelihood,
  title: 'Likelihood',
  slug: 'risk-assessment'
  },
  {
  configurable: 'Yes',
  data_type: 'ForeignKey',
  field: 'inherent_impact',
  field_name: commonLanguage.inherent_impact,
  title: 'Impact',
  slug: 'risk-assessment'
  },
  {
  configurable: 'Yes',
  data_type: 'ForeignKey',
  field: 'risk_velocity',
  field_name: commonLanguage.riskVelocity,
  title: 'Risk velocity',
  slug: 'risk-assessment'
  },
  {
  configurable: 'Yes',
  data_type: 'ForeignKey',
  field: 'vulnerability',
  field_name: riskArabicTranslation.Vulnerability,
  title: 'Vulnerability',
  slug: 'risk-assessment'
  }
  ];

  export const scoringFactorsForAKI = [
    {
    configurable: 'Yes',
    data_type: 'ForeignKey',
    field: 'inherent_likelihood',
    field_name: commonLanguage.inherent_likelihood,
    title: 'Likelihood',
    slug: 'risk-assessment'
    },
    {
    configurable: 'Yes',
    data_type: 'ForeignKey',
    field: 'inherent_impact',
    field_name: commonLanguage.inherent_impact,
    title: 'Impact',
    slug: 'risk-assessment'
    },
    ];

  export const adminAuditObservation = [
  {
  configurable: 'Yes',
  field: 'rating',
  field_name: 'Observation rating',
  slug: 'audit-reporting',
  data_type: 'ForeignKey'
  },
  {
  configurable: 'Yes',
  field: 'observation_status',
  field_name: 'Observation status',
  slug: 'audit-reporting',
  data_type: 'ForeignKey'
  },
  {
  configurable: 'Yes',
  field: 'annexure',
  field_name: 'Annexure',
  slug: 'audit-reporting',
  data_type: 'ForeignKey'
  },
  {
  configurable: 'Yes',
  field: 'observation_dropdown_v1',
  field_name: 'Observation dropdown v1',
  slug: 'audit-reporting',
  data_type: 'ForeignKey'
  },
  {
  configurable: 'Yes',
  field: 'observation_dropdown_v2',
  field_name: 'Observation dropdown v2',
  slug: 'audit-reporting',
  data_type: 'ForeignKey'
  },
  {
    configurable: 'Yes',
    field: 'observation_dropdown_v3',
    field_name: 'Observation dropdown v3',
    slug: 'audit-reporting',
    data_type: 'ForeignKey'
  },
  {
    configurable: 'Yes',
    field: 'observation_dropdown_v4',
    field_name: 'Observation dropdown v4',
    slug: 'audit-reporting',
    data_type: 'ForeignKey'
  },
  {
    configurable: 'Yes',
    field: 'observation_dropdown_v5',
    field_name: 'Observation dropdown v5',
    slug: 'audit-reporting',
    data_type: 'ForeignKey'
  },
  {
    configurable: 'Yes',
    field: 'observation_dropdown_v6',
    field_name: 'Observation dropdown v6',
    slug: 'audit-reporting',
    data_type: 'ForeignKey'
  },
  {
    configurable: 'Yes',
    field: 'observation_dropdown_v7',
    field_name: 'Observation dropdown v7',
    slug: 'audit-reporting',
    data_type: 'ForeignKey'
  },
  {
  configurable: 'Yes',
  field: 'observation_multi_dropdown_v1',
  field_name: 'Observation multi dropdown v1',
  slug: 'audit-reporting',
  data_type: 'ForeignKey'
  },
  {
  configurable: 'Yes',
  field: 'observation_multi_dropdown_v2',
  field_name: 'Observation multi dropdown v2',
  slug: 'audit-reporting',
  data_type: 'ForeignKey'
  },
  {
    configurable: 'Yes',
    field: 'observation_multi_dropdown_v3',
    field_name: 'Observation multi dropdown v3',
    slug: 'audit-reporting',
    data_type: 'ForeignKey'
  },
  {
    configurable: 'Yes',
    field: 'observation_multi_dropdown_v4',
    field_name: 'Observation multi dropdown v4',
    slug: 'audit-reporting',
    data_type: 'ForeignKey'
  },
  {
    configurable: 'Yes',
    field: 'observation_multi_dropdown_v5',
    field_name: 'Observation multi dropdown v5',
    slug: 'audit-reporting',
    data_type: 'ForeignKey'
  },
  {
    configurable: 'Yes',
    field: 'observation_multi_dropdown_v6',
    field_name: 'Observation multi dropdown v6',
    slug: 'audit-reporting',
    data_type: 'ForeignKey'
  },
  {
    configurable: 'Yes',
    field: 'observation_multi_dropdown_v7',
    field_name: 'Observation multi dropdown v7',
    slug: 'audit-reporting',
    data_type: 'ForeignKey'
  },
  {
  configurable: 'Yes',
  field: 'type',
  field_name: 'Type',
  slug: 'audit-reporting',
  data_type: 'ForeignKey'
  },
  {
  configurable: 'Yes',
  field: 'category',
  field_name: 'Category',
  slug: 'audit-reporting',
  data_type: 'ForeignKey'
  }
  ];
  export const businessStrategy = [
  {
  configurable: 'Yes',
  data_type: 'ForeignKey',
  field: 'strategy_category',
  field_name: 'Category',
  slug: 'risk-assessment'
  },
  {
  configurable: 'Yes',
  data_type: 'ForeignKey',
  field: 'strategy_priority',
  field_name: 'Priority',
  slug: 'risk-assessment'
  },
  {
  configurable: 'Yes',
  data_type: 'ForeignKey',
  field: 'strategy_status',
  field_name: 'Strategy Status',
  slug: 'risk-assessment'
  }
  ];
  export const reviewerStatus = {
  yes: 'Yes',
  no: 'No'
  };
  export enum BusinessStrategyAdmin {
  strategyCategory = 'strategy_category',
  strategyStatus = 'strategy_status',
  strategyPriority = 'strategy_priority',
  riskOutcome = 'risk_outcome'
  }
  export enum CommentsTask {
  addComment = 'addComment',
  editComment = 'editComment',
  deleteComment = 'deleteComment',
  addReply = 'addreply',
  viewReplies = 'viewReplies',
  toggleResolveStatus = 'toggleResolveStatus',
  filterClick = 'filterClick',
  commentText = 'comment_text',
  file = 'file',
  parent = 'parent'
  }
  export enum AuIssuesData {
  risk = 'Risk Assessment',
  risks = 'risk',
  control = 'control'
  }
  export enum AuAuditObservation {
  yes = 'Yes',
  no = 'No',
  reviewer = 'reviewer'
  }
  export enum AuRiskConstants {
  Preventive = 'Preventive',
  Detective = 'Detective',
  Corrective = 'Corrective',
  riskId = 'Risk ID',
  riskName = 'Risk Name',
  riskManager = 'Risk Manager',
  riskOwner = 'Risk Owner',
  riskVelocity = 'Risk Velocity',
  budget_utilization = 'Budget Utilization',
  residual_risk_rating = 'Residual Risk Rating',
  residual_impact = 'Residual Impact',
  residual_likelihood = 'Residual Likelihood',
  inherent_risk_rating = 'Inherent Risk Rating',
  inherent_impact = 'Inherent Impact',
  inherent_likelihood = 'Inherent Likelihood',
  risk_category = 'Risk Category',
  riskType = 'Risk Type',
  riskAutomation = 'Risk Automation',
  riskRating = 'Risk Rating',
  risk_rating = 'risk_rating',
  risk_owner = 'risk_owner',
  mega_process = 'Mega Process',
  major_process = 'Major Process',
  riskVulnerability = 'Vulnerability',
  inherantScore = 'Inherent Score',
  residualScore = 'Residual Score',
  createdBy = 'Created by',
  controlCount = 'Controls',
  keyRisk = 'Key Risk',
  topRisk = 'Top Risk',
  riskSource = 'Risk Source',
  department = 'Department',
  riskAppetite = 'Risk Appetite',
  createdDate = 'Created Date',
  updatedBy = 'Updated by',
  updatedDate = 'Updated Date',
  riskDescription = 'Risk Description',
  targetResidualRisk = 'Target Residual Risk',
  manager = 'Manager',
  businessStrategy = 'Business Strategy',
  status = 'Status',
  entity = 'Entity',
  riskRegister = 'Risk Register',
  scoringModel = 'Scoring Model'

  }
  export enum AuControlConstants {
  controlsByOwner = 'Controls by Owner',
  controlsByRating = 'Controls by Rating',
  controlsByFrequency = 'Controls by Frequency',
  controlId = 'Control ID',
  controlName = 'Control Name',
  controlManager = 'Control Manager',
  controlOwner = 'Control Owner',
  controlFrequency = 'Control Frequency',
  controlType = 'Control Type',
  controlAutomation = 'Control Automation',
  controlRating = 'Control Rating',
  controlCategory = 'Control Category',
  control_rating = 'control_rating',
  control_frequency = 'control_frequency',
  control_owner = 'control_owner',
  createdBy = 'Created by',
  riskCount = 'Risks',
  status = 'status',
  inherent_likelihood = 'inherent_likelihood',
  inherent_impact = 'inherent_impact',
  overall_rating = 'overall_rating',
  controlScore = 'Control Score',
  approvalStatus = 'Approval Status',
  approvedBy = 'Approved By',
  approvedOn = 'Approved On'
  }
  export enum AuRecommendationsConstants {
  ID = 'ID',
  Title = 'Title',
  Rating = 'Rating',
  Action = 'Action',
  Recommendations = 'Recommendations',
  Cost_Saving = 'Cost Saving',
  Department = 'Department',
  Audit_Area = 'Audit Area'
  }
  export enum AuApproveOrReject {
  approved = 'approve',
  rejected = 'reject',
  requestType = 'request_type',
  actionType = 'action_type',
  id = 'id',
  requestId = 'request_id',
  slug = 'slug',
  audit = 'audit-planning',
  create = 'create',
  false = 'False',
  recordId = 'record_id',
  transitionId = 'transition_id',
  transition = 'transition_id',
  comment = 'comment_text',
  approverType = 'approver_type',
  reviewerReject = 'Reviewer Reject'
  }
  export enum AuditIssueTracker {
  auditIssue = 'audit_issue_tracker',
  auditDetail = 'audit_detail',
  auditObservation = 'observation'
  }
  export enum AuComplianceOverviewConstants {
  id = 'Assessment ID',
  Assessments = 'Assessments',
  Compliance = 'Compliance%',
  Assessed_by = 'Assessed by',
  Created_date = 'Created date',
  Modified_date = 'Modified date',
  Assignee = 'Assignee',
  Status = 'Status',
  Due_date = 'Due date'
  }
  export enum AuditProgramChart {
  activityChart = 'activityChart',
  issueChart = 'issueChart',
  auditProgramChart = 'auditProgramChart',
  testingStatus = 'testing_status',
  issueStatus = 'issue_status',
  activityStatus = 'activity_status',
  limit = 'limit',
  offset = 'offset',
  category = 'category',
  others = 'Others',
  all = 'all'
  }
  export const recommendationChartIdentifier = {
  recommendationsByStatus: 'recommendationsByStatus',
  recommendationsByPriority: 'recommendationsByPriority',
  auditRecommendation: 'auditRecommendation',
  complianceRecommendation: 'complianceRecommendation'
  };
  export const fileFormats = {
    extensionsAllowed: ['jpg', 'jpeg', 'png', 'pdf', 'docx', 'doc', 'ppt', 'pptx', 'xls', 'xlsx', 'txt', 'odt', 'csv', 'psv', 'tsv', 'ssv', 'scsv', 'msg', 'mp4', 'avi', 'mkv', 'mov', 'wmv', 'webm', 'flv', 'vsd', 'vsdx'],
  extensionsAllowedForAnnexureTemplate: ['docx', 'doc', 'xls', 'xlsx'],
  extensionsAllowedForWorkflow: ['jpg', 'jpeg', 'png'],
  extensionsAllowedForAuditReport: ['pdf', 'doc', 'docx'],
  extensionsAllowedForAuditWorkflow: ['jpg', 'jpeg', 'png'],
  extensionsAllowedForNotificationTemplate: [ 'pdf', 'docx', 'doc', 'ppt', 'pptx', 'xls', 'xlsx', 'txt', 'odt', 'jpg', 'png', 'jpeg', 'csv', 'psv', 'tsv', 'ssv', 'scsv', 'msg']
  };
  export const createDropdownFields = {
  controlCategory:'Control Rating',
  auditUniverse :'auditUniverse',
  topic: 'topic',
  subtopic: 'subtopic',
  active: 'active',
  offTrack: 'offTrack',
  strategyList: 'strategyList',
  objective: 'objective',
  goal: 'goal',
  singleSelection: 'singleSelection',
  single_selection_1: 'single_selection_1',
  single_selection_2: 'single_selection_2',
  single_selection_3: 'single_selection_3',
  single_selection_4: 'single_selection_4',
  single_selection_5: 'single_selection_5',
  single_selection_6: 'single_selection_6',
  single_selection_7: 'single_selection_7',
  single_selection_8: 'single_selection_8',
  single_selection_9: 'single_selection_9',
  single_selection_10: 'single_selection_10',
  multiple_selection_1: 'multiple_selection_1',
  multiple_selection_2: 'multiple_selection_2',
  multiple_selection_3: 'multiple_selection_3',
  multiple_selection_4: 'multiple_selection_4',
  multiple_selection_5: 'multiple_selection_5',
  multiple_selection_6: 'multiple_selection_6',
  multiple_selection_7: 'multiple_selection_7',
  multiple_selection_8: 'multiple_selection_8',
  multiple_selection_9: 'multiple_selection_9',
  multiple_selection_10: 'multiple_selection_10',
  esg_metrics_single_selection_4: 'esg_metrics_single_selection_4',
  esg_metrics_single_selection_5: 'esg_metrics_single_selection_5',
  esg_metrics_single_selection_6: 'esg_metrics_single_selection_6',
  esg_metrics_single_selection_7: 'esg_metrics_single_selection_7',
  esg_metrics_single_selection_8: 'esg_metrics_single_selection_8',
  esg_metrics_single_selection_9: 'esg_metrics_single_selection_9',
  esg_metrics_single_selection_10: 'esg_metrics_single_selection_10',
  esg_metrics_multiple_selection_1: 'esg_metrics_multiple_selection_1',
  esg_metrics_multiple_selection_2: 'esg_metrics_multiple_selection_2',
  esg_metrics_multiple_selection_3: 'esg_metrics_multiple_selection_3',
  esg_metrics_multiple_selection_4: 'esg_metrics_multiple_selection_4',
  esg_metrics_multiple_selection_5: 'esg_metrics_multiple_selection_5',
  esg_metrics_multiple_selection_6: 'esg_metrics_multiple_selection_6',
  esg_metrics_multiple_selection_7: 'esg_metrics_multiple_selection_7',
  esg_metrics_multiple_selection_8: 'esg_metrics_multiple_selection_8',
  esg_metrics_multiple_selection_9: 'esg_metrics_multiple_selection_9',
  esg_metrics_multiple_selection_10: 'esg_metrics_multiple_selection_10',
  branch_level1: 'branch_level1',
  branch_level2: 'branch_level2',
  branch_level3: 'branch_level3',
  branch_level4: 'branch_level4',
  topic_level1: 'topic_level1',
  topic_level2: 'topic_level2',
  topic_level3: 'topic_level3',
  topic_level4: 'topic_level4',
  procedure_level1: 'procedure_level1',
  procedure_level2: 'procedure_level2',
  procedure_level3: 'procedure_level3',
  procedure_level4: 'procedure_level4',
  categoryLevel1: 'categoryLevel1',
  categoryLevel2: 'categoryLevel2',
  categoryLevel3: 'categoryLevel3',
  categoryLevel4: 'categoryLevel4',
  esg_metrics_status_1: 'esg_metrics_status_1',
  esg_metrics_criticality: 'esg_metrics_Criticality',
  esg_metrics_status: 'esg_metrics_Status',
  status_1: 'status_1',
  statusOne: 'statusOne',
  multiSelection1: 'multiSelection1',
  multiSelection2: 'multiSelection2',
  multiSelection3: 'multiSelection3',
  multiSelection4: 'multiSelection4',
  multiSelection5: 'multiSelection5',
  multiSelection6: 'multiSelection6',
  multiSelection7: 'multiSelection7',
  multiSelection8: 'multiSelection8',
  multiSelection9: 'multiSelection9',
  multiSelection10: 'multiSelection10',
  singleSelection1: 'singleSelection1',
  singleSelection2: 'singleSelection2',
  singleSelection3: 'singleSelection3',
  singleSelection4: 'singleSelection4',
  singleSelection5: 'singleSelection5',
  singleSelection6: 'singleSelection6',
  singleSelection7: 'singleSelection7',
  singleSelection8: 'singleSelection8',
  singleSelection9: 'singleSelection9',
  singleSelection10: 'singleSelection10',
  riskAppetiteData: 'risk-appetite',
  complianceKpi: 'compliance-kpi',
  strategykpi: 'strategy-kpi',
  metrics: 'metrics',
  esgMetrics: 'esg-metrics',
  listDropdown: 'listDropdown',
  kri: 'Kri',
  kriData: 'kri',
  format: 'format',
  ProcessType: 'ProcessType',
  functionDropdown: 'functionDropdown',
  regionDropdown: 'regionDropdown',
  riskType: 'Risk type',
  riskIdentifier: 'Risk Identifier',
  riskCategory: 'Risk Category',
  riskSubCategory: 'Subcategory',
  status: 'Status',
  controlType: 'Control Type',
  controlAutomation: 'Control Automation',
  controlRating: 'Control Rating',
  controlFrequency: 'Control Frequency',
  process: 'Process',
  subProcess: 'Sub Process',
  keyControl: 'Key Control',
  easeofImplementation: 'Ease of implementation',
  easeofMaintain: 'Ease of maintain',
  costtoImplementMaintain: 'Cost to implement maintain',
  reducesLikelihood: 'Reduces Likelihood',
  reducesImpact: 'Reduces Impact',
  controlDesignEvaluation: 'Control design evaluation',
  commentsActionStatus: 'Action Status',
  incidentType: 'Incident Type',
  areasAudit: 'Audit Areas',
  approvers: 'Approver',
  reviewer: 'Reviewer',
  requestedBy: 'Requested by',
  auditMethod: 'Source of Audit',
  riskEntity: 'Risk entity',
  megaProcess: 'Mega process',
  majorProcess: 'Major process',
  treatment: 'Treatment',
  category: 'Category',
  strategyStatus: 'Strategy status',
  complianceCategory: 'Compliance category',
  complianceOccurance: 'Compliance occurance',
  incident_type: 'Incident type',
  location: 'Location',
  assetType: 'Asset type',
  criticality: 'Criticality',
  expenseCategory: 'ExpenseCategory',
  strategyCategory: 'Strategy category',
  strategyCategories: 'Strategy categories',
  esgCategory: 'esg category',
  strategySubCategory: 'Sub category',
  strategySubCategories: 'Strategy Sub category',
  esgTopic: 'esg topic',
  esgSubtopic: 'esg sub topic',
  strategyTopic: 'Strategy topic',
  strategySubtopic: 'Strategy sub topic',
  businessProcessStatus: 'Business process status',
  assetCriticalityRating: 'Asset criticality rating',
  entity: 'entity',
  businessStrategy: 'businessStrategy',
  riskManger: 'riskManager',
  riskOwner: 'riskOwner',
  department: 'department',
  approver: 'approver',
  businessProcessDuration: 'business_process_duration',
  projectType: 'projectType',
  project: 'project',
  task: 'task',
  expenceFor: 'expenceFor',
  audit: 'audit',
  assetManagement: 'asset-management',
  businessProcess: 'business-process',
  compliance: 'compliance',
  risk: 'risk',
  analytics: 'audit-analytics',
  control: 'control',
  surveyType: 'Survey type',
  surveyCategory: 'Survey category',
  survey: 'survey',
  policyType: 'policy-type',
  policyOwner: 'policyOwner',
  policyTeam: 'policyTeam',
  policyReviewer: 'policyReviewer',
  policyApprover: 'policyApprover',
  policyUser: 'policyUser',
  stakeholder: 'stakeholder',
  policy: 'policy',
  scenarioCriticalityRating: 'Scenario Criticality Rating',
  calculatedField: 'Calculated Field',
  exceptionCriteria: 'Exception Criteria',
  analyticsManager: 'Analytics Manager',
  analyticsEffectiveness: 'Analytics Effectiveness',
  auditee: 'Auditee',
  analyticsProcess: 'Analytics Process',
  analyticsSubProcess: 'Analytics Sub Process',
  actionPriority: 'Action Priority',
  actionStatus: 'Action Status',
  tagAssessment: 'Tag Assessment',
  incidentRating: 'Incident Rating',
  assignee: 'Assignee',
  actionEntity: 'action Entity',
  policyRating: 'policyRating',
  policyStatus: 'policyStatus',
  policyRequest: 'policyRequest',
  region: 'region',
  scoring: 'scoring',
  issues: 'issues',
  register: 'RiskRegister',
  incidentStatus: 'incidentStatus',
  policyExperts: 'policyExperts',
  policyLegal: 'policyLegal',
  policyArea: 'policyArea',
  previousVersions: 'previousVersions',
  strategyPlan: 'strategyPlan',
  complianceType: 'complianceType',
  complianceArea: 'complianceArea',
  complianceStatus: 'complianceStatus',
  complianceRequest: 'complianceRequest',
  complianceRating: 'complianceRating',
  complianceOwner: 'complianceOwner',
  complianceTeam: 'complianceTeam',
  complianceReviewer: 'complianceReviewer',
  complianceApprover: 'complianceApprover',
  complianceUser: 'complianceUser',
  complianceExperts: 'complianceExperts',
  complianceLegal: 'complianceLegal',
  complianceAuditOccurance: 'complianceOccurance',
  complianceAudit: 'complianceaudit',
  recommendationStatus: 'recommendationStatus',
  recommendationPriority: 'recommendationPriority',
  fraudRelated: 'Fraud Related',
  decisionMaker: 'Decision Maker',
  riskMitigationStrategyResponsibility: 'Risk Mitigation Strategy Responsibility V1',
  riskMitigationStrategyEffectiveness: 'Risk Mitigation Strategy Effectiveness',
  activityAssignee: 'Activity Assignee',
  activityStakeholder: 'Activity Stakeholder',
  activityStatus: 'Activity Status',
  observationDepartment: 'Observation Department',
  observationAuditArea: 'Observation Audit Area',
  observationRating: 'Observation Rating',
  observationManagementStatus: 'Observation Management Status',
  observationImplementationOwner: 'Observation Implementation Owner',
  observationStakeholder: 'Observation Stakeholder',
  issue: 'issue',
  billable: 'billable',
  auditPlan: 'Audit Plan',
  sector: 'Sector',
  ermRisk: 'ermrisk',
  ermType: 'Erm type',
  ermIdentifier: 'Erm identifier',
  keyRisk: 'Key Risk',
  topRisk: 'Top Risk',
  riskSource: 'Risk Source',
  riskDepartment: 'Department',
  riskAppetite: 'Risk Appetite',
  riskWithinAppetite: 'Risk Within Appetite',
  targetResidualRisk: 'Target Residual Risk',
  riskMigrationRequired: 'Risk Mitigation Required',
  additionalDepartment: 'Additional Department',
  riskExtraDropdown: 'Risk Extra Dropdown',
  riskAdditionalDropdown: 'Risk Additional Dropdown',
  riskNewDropdown: 'Risk New Dropdown',
  riskAuxiliaryDropdown: 'Risk Auxiliary Dropdown',
  riskSubsidiaryDropdown: 'Risk Subsidiary Dropdown',
  extraDropdownRisk: 'Extra Dropdown Risk',
  additionalDropdownRisk: 'Additional Dropdown Risk',
  additionalMajorProcess: 'Additional Major Process',
  newDropdown: 'New Dropdown Risk',
  duration: 'duration',
  additionalDropDown1: 'additionalDropDown1',
  additionalDropDown2: 'additionalDropDown2',
  additionalDropDown3: 'additionalDropDown3',
  additionalDropDown4: 'additionalDropDown4',
  dropdown1: 'dropdown_1',
  dropdown2: 'dropdown_2',
  dropdown3: 'dropdown_3',
  dropdown4: 'dropdown_4',
  additionalMultiDropDown1: 'additionalMultiDropDown1',
  additionalMultiDropDown2: 'additionalMultiDropDown2',
  additionalMultiDropDown3: 'additionalMultiDropDown3',
  additionalMultiDropDown4: 'additionalMultiDropDown4',
  multiDropDown1: 'multi_dropdown_1',
  multiDropDown2: 'multi_dropdown_2',
  multiDropDown3: 'multi_dropdown_3',
  multiDropDown4: 'multi_dropdown_4',
  additionalDesignation: 'additionalDesignation',
  requestType: 'Request Type',
  departmentRequestedBy: 'Department Requested By',
  priority: 'Priority',
  toBeWrittenBy: 'To Be Written By',
  oversight: 'Oversight',
  statusComplianceRequest: 'Status Compliance Request',
  departments: 'Department',
  level1: 'level1',
  level2: 'level2',
  level3: 'level3',
  level4: 'level4',
  departmentLevel1: 'departmentLevel1',
  departmentLevel2: 'departmentLevel2',
  departmentLevel3: 'departmentLevel3',
  departmentLevel4: 'departmentLevel4',
  branchLevel1: 'branchLevel1',
  branchLevel2: 'branchLevel2',
  branchLevel3: 'branchLevel3',
  branchLevel4: 'branchLevel4',
  topicLevel1: 'topicLevel1',
  topicLevel2: 'topicLevel2',
  topicLevel3: 'topicLevel3',
  topicLevel4: 'topicLevel4',
  procedureLevel1: 'procedureLevel1',
  procedureLevel2: 'procedureLevel2',
  procedureLevel3: 'procedureLevel3',
  procedureLevel4: 'procedureLevel4',
  issueStatus: 'issue_status',
  issueRating: 'issue_rating',
  type: 'type',
  observationUser: 'Observation User',
  observationDesignation: 'ObservationDesignation',
  observationDropdownV1: 'ObservationDropdownV1',
  observationDropdownV2: 'ObservationDropdownV2',
  observationDropdownV3: 'ObservationDropdownV3',
  observationDropdownV4: 'ObservationDropdownV4',
  observationDropdownV5: 'ObservationDropdownV5',
  observationDropdownV6: 'ObservationDropdownV6',
  observationDropdownV7: 'ObservationDropdownV7',
  improvementDropdownV3: 'Improvement Dropdown 3',
  improvementDropdownV4: 'Improvement Dropdown 4',
  improvementDropdownV5: 'Improvement Dropdown 5',
  improvementDropdownV6: 'Improvement Dropdown 6',
  improvementDropdownV7: 'Improvement Dropdown 7',
  improvementDropdownV8: 'Improvement Dropdown 8',
  improvementDropdownV9: 'Improvement Dropdown 9',
  observationMultiDropdownV1: 'ObservationMultiDropdownV1',
  observationMultiDropdownV2: 'ObservationMultiDropdownV2',
  observationMultiDropdownV3: 'ObservationMultiDropdownV3',
  observationMultiDropdownV4: 'ObservationMultiDropdownV4',
  observationMultiDropdownV5: 'ObservationMultiDropdownV5',
  observationMultiDropdownV6: 'ObservationMultiDropdownV6',
  observationMultiDropdownV7: 'ObservationMultiDropdownV7',
  improvementMultiDropdownV3: 'Improvement Multi Dropdown 3',
  improvementMultiDropdownV4: 'Improvement Multi Dropdown 4',
  improvementMultiDropdownV5: 'Improvement Multi Dropdown 5',
  improvementMultiDropdownV6: 'Improvement Multi Dropdown 6',
  improvementMultiDropdownV7: 'Improvement Multi Dropdown 7',
  improvementMultiDropdownV8: 'Improvement Multi Dropdown 8',
  improvementMultiDropdownV9: 'Improvement Multi Dropdown 9',
  fiscalYear: 'fiscal_year',
  manager: 'Manager',
  parent: 'Parent',
  objectiveLevel1: 'objectiveLevel1',
  objectiveLevel2: 'objectiveLevel2',
  objectiveLevel3: 'objectiveLevel3',
  objectiveLevel4: 'objectiveLevel4',
  objectiveLevel5: 'objectiveLevel5',
  objectiveLevel6: 'objectiveLevel6',
  objectiveParent1: 'objectiveParent1',
  objectiveParent2: 'objectiveParent2',
  objectiveParent3: 'objectiveParent3',
  objectiveParent4: 'objectiveParent4',
  objectiveParent5: 'objectiveParent5',
  dropdownv1: 'dropdownv1',
  dropdownv2: 'dropdownv2',
  dropdownv3: 'dropdownv3',
  dropdownv4: 'dropdownv4',
  dropdownv5: 'dropdownv5',
  dropdownv6: 'dropdownv6',
  dropdownv7: 'dropdownv7',
  multidropdownv1: 'multidropdownv1',
  multidropdownv2: 'multidropdownv2',
  multidropdownv3: 'multidropdownv3',
  multidropdownv4: 'multidropdownv4',
  multidropdownv5: 'multidropdownv5',
  multidropdownv6: 'multidropdownv6',
  multidropdownv7: 'multidropdownv7',
  additionalDropDownV1: 'additionalDropDownV1',
  additionalDropDownV2: 'additionalDropDownV2',
  additionalDropDownV3: 'additionalDropDownV3',
  additionalDropDownV4: 'additionalDropDownV4',
  multipleDropdownV1: 'multiple_dropdown_v1',
  multipleDropdownV2: 'multiple_dropdown_v2',
  multipleDropdownV3: 'multiple_dropdown_v3',
  multipleDropdownV4: 'multiple_dropdown_v4',
  multipleDropdownV5: 'multiple_dropdown_v5',
  extraDropdownV1: 'extra_dropdown_v1',
  extraDropdownV2: 'extra_dropdown_v2',
  extraDropdownV3: 'extra_dropdown_v3',
  extraDropdownV4: 'extra_dropdown_v4',
  extraDropdownV5: 'extra_dropdown_v5',
  extraUserListV1: 'extra_user_list_v1',
  extraUserListV2: 'extra_user_list_v2',
  extraUserListV3: 'extra_user_list_v3',
  additionalMultiDropDownV1: 'additionalMultiDropDownV1',
  additionalMultiDropDownV2: 'additionalMultiDropDownV2',
  additionalMultiDropDownV3: 'additionalMultiDropDownV3',
  additionalMultiDropDownV4: 'additionalMultiDropDownV4',
  riskProgram: 'risk_program',
  riskProgramRequestedBy: 'risk_program_requested_by',
  methodofRiskProgram: 'method_of_risk_program',
  departmentRequestBy: 'department_requested_by',
  toBeWrittenByDropdown: 'toBeWrittenByDropdown',
  user: 'user',
  statusPolicyRequest: 'Status Policy Request',
  userDepartments: 'userDepartments',
  userlevel2: 'userlevel2',
  userlevel3: 'userlevel3',
  userlevel4: 'userlevel4',
  question: 'question',
  assessmentPlan: 'assessmentPlan',
  observationUserV1: 'Observation User V1',
  observationUserV2: 'Observation User V2',
  improvementUserV3: 'Improvement User 3',
  improvementUserV4: 'Improvement User 4',
  ERMMitigationStrategyResponsibility: 'Risk Mitigation Strategy Responsibility v1',
  designation: 'designation',
  compliancePlan: 'compliancePlan',
  incidentUser: 'incidentUser',
  incidentReporting: 'incident-reporting',
  incidentAdditionalDropDown1: 'Incident Additional DropDown V1',
  incidentAdditionalDropDown2: 'Incident Additional DropDown V2',
  incidentAdditionalDropDown3: 'Incident Additional DropDown V3',
  incidentAdditionalDropDown4: 'Incident Additional DropDown V4',
  incidentAdditionalMultiDropDown1: 'Additional Multi DropDown V1',
  incidentAdditionalMultiDropDown2: 'Additional Multi DropDown V2',
  incidentAdditionalMultiDropDown3: 'Additional Multi DropDown V3',
  incidentAdditionalMultiDropDown4: 'Additional Multi DropDown V4',
  emailTemplates: 'emailTemplates',
  mrReviewerRefresher: 'mrReviewerRefresher',
  activityperformedBy: 'activityperformedBy',
  frequency:'frequency',
  failure: 'failure',
  dependancy: 'dependancy',
  currentStatus: 'currentStatus',
  backupOwner: 'backupOwner',
  rating: 'rating',
  mao: 'mao',
  functions: 'functions',
  escalationPoint:'escalationPoint',
  escalationPoints:'escalation_points',
  auditProfessionalUsers:'audit_Professional_Users',
  businessContinuityPlan:'business-continuity-plan',
  bcmAudit: 'bcm-audit',
  strategy: 'business-strategy',
  esgStrategy: 'esg-strategy',
  root_cause:'root_cause',
  root_cause_dropdown_v1:'root_cause_dropdown_v1',
  root_cause_dropdown_v2:'root_cause_dropdown_v2',
  root_cause_multi_dropdown_v1:'root_cause_multi_dropdown_v1',
  root_cause_multi_dropdown_v2:'root_cause_multi_dropdown_v2',
  industries: 'industries',
  recommendationMultiDropdownV1: 'RecommendationMultiDropdown1',
  recommendationMultiDropdownV2: 'RecommendationMultiDropdown2',
  recommendationMultiDropdownV3: 'RecommendationMultiDropdown3',
  recommendationMultiDropdownV4: 'RecommendationMultiDropdown4',
  recommendationMultiDropdownV5: 'RecommendationMultiDropdown5',
  recommendationDropDownV1: 'RecommendationDropdown1',
  recommendationDropDownV2: 'RecommendationDropdown2',
  recommendationDropDownV3: 'RecommendationDropdown3',
  recommendationDropDownV4: 'RecommendationDropdown4',
  recommendationDropDownV5: 'RecommendationDropdown5',
  scoringMethod :'scoringMethod',
  generic_tag: 'generic_tag',
  generic_clause:'generic_clause',
  sign_off_reviewer: 'signOffReviewer',
  sign_off_aprrover: 'signOffApprover',
  distribution_list_user: 'distributionListUser',
  distribution_list_department: 'distributionListDepartment',
  ditribution_designation: 'distributionDesignation',
  asset_classification: 'asset_classification',
  asset_group: 'asset_group',
  asset_category: 'asset_category',
  grc_team: 'grc_team',
  mtpd: 'mtpd',
  rto: 'rto',
  domain: 'domain',
  controlDomainDropdown: 'domain',
  domainDropdown: 'domain',
  implementationStatus: 'implementation_status',
  team: 'team',
  stractGoal: 'stract-goal',
  riskTeam: 'risk_team',
  clause: 'clause'
  };
  export const tabMenuBarConstant = {
  full: 'full',
  miniSideBar: 'mini-sidebar',
  difference: 215,
  breakWidthConstant: 350
  };
  export const rightSideBarConstant = {
  auditArea: 'Audit Area'
  };
  export const auraaSlug = {
  risk: 'risk-assessment',
  control: 'risk-assessment-v1-details',
  audit: 'audit-planning',
  incident: 'incident_reporting',
  compliance: 'compliance',
  incidentManagement: 'incident-management',
  businessProcess: 'business-process',
  assetManagement: 'asset-management',
  budget: 'budget-activity',
  survey: 'survey',
  policyManagement: 'policy-management',
  complianceAudit: 'compliance-audit',
  ermRisk: 'enterprise-risk',
  riskProgram: 'risk-program',
  incidentReporting: 'incident-reporting',
  kri: 'kri',
  riskAppetiteData: 'risk-appetite',
  businessContinuityPlan: 'business-continuity-plan',
  bcmAudit: 'bcm-audit',
  businessStrategy: 'business-strategy',
  esgStrategy: 'esg-strategy',
  compliancekpi: 'compliance-kpi',
  strategykpi: 'strategy-kpi',
  metrics: 'metrics',
  esgMetrics: 'esg-metrics',
  generic_tag: 'generic-tag',
  generic_clause:'generic-clause',
  grc_team: 'grc_team',
  stractGoal: 'stract-goal'
  };
  export enum RecommendationWidgets {
  title = 'title',
  dueDate = 'due_date',
  description = 'description',
  costSaving = 'cost_saving',
  recommendationDepartment = 'department',
  recommendationStatus = 'recommendation_status',
  recommendationPriority = 'recommendation_priority',
  owner = 'owner',
  objectId = 'object_id',
  recommendation = 'recommendation',
  observation = 'observation',
  audits = 'audits'
  }
  export enum SendMailWidgets {
  from = 'from_address',
  to = 'to_address',
  cc = 'cc_address',
  bcc = 'bcc_address',
  subject = 'subject',
  content = 'content',
  link = 'link',
  file = 'file',
  url = 'download_url',
  id = 'record_id'
  }
  export enum CommonWidgets {
  reload = 'reload'
  }
  export enum ApprovalCardsDetails {
  audit = 'audit',
  create = 'create',
  open = 'Open',
  risk = 'risk',
  control = 'control',
  edit = 'edit',
  Activity = 'Activity',
  Audit = 'Audit',
  underAudit = 'under-audit',
  Report = 'digitalreport',
  universe = 'Audit Universe',
  auditUniverse = 'universe',
  activityAction = 'Activity Action',
  observationRecommendation = 'Observation Recommendation',
  observationAction = 'Observation Action',
  issue = 'issue',
  issueAction = 'Issue Action',
  observation = 'Observation',
  auditplan = 'auditplan',
  approver = 'approver',
  reviewer = 'reviewer',
  auditPlans = 'Audit Plan'
  }

  // commenting for hide compliance observation tab
  export const approvalMainMenu = [
  { title: 'Audit Overview', route: 'overview', attribute: 'auditoverview' },
  { title: 'Digital Audit Report', route: 'audit-digital-report', attribute: 'auditdigitalreport' },
  { title: 'Audit Notifications', route: 'audit-notifications', attribute: 'auditnotification' },
  { title: 'Schedule', route: 'schedule-details', attribute: 'schedule' },
  { title: 'Audit Program', route: 'approval-audit-program', attribute: 'auditprogram' },
  { title: 'Activities', route: 'activities', attribute: 'activities' },
  { title: 'Issues', route: 'issues', attribute: 'issues' },
  { title: 'Audit Observations', route: 'observations', attribute: 'auditobservation' },
  { title: 'Improvements', route: 'improvement-list', attribute: 'improvements' },
  // { title: 'Compliance Observations', route: 'compliance-observations', attribute: 'complianceobservation' },
  { title: 'Risk Program Observations', route: 'risk-program-observations', attribute: 'riskprogramobservation' },
  { title: 'Incident', route: 'audit-incident', attribute: 'incident' },
  { title: 'Management Responses', route: 'management-responses', attribute: 'managementresponse' },
  { title: 'Actions', route: 'actions', attribute: 'actions' },
  { title: 'Recommendations', route: 'recommendations', attribute: 'recommendations' },
  { title: 'Reports', route: 'report', attribute: 'reports' },
  { title: 'Audit Survey', route: 'audit-survey', attribute: 'auditsurvey' },
  { title: 'Diagrams', route: 'workflow', attribute: 'diagram' },
  { title: 'Network', route: 'network', attribute: 'network' },
  { title: 'Time Entries', route: 'time-entries', attribute: 'timeentries' },
  { title: 'Assessments', route: 'approval-assessments', attribute: 'assessments' },
  { title: 'RCM', route: 'rcm', attribute: 'rcm' },
  { title: 'Requests', route: 'request', attribute: 'requests' },
  { title: 'Team', route: 'team', attribute: 'team' },
  { title: 'Related Audits', route: 'related-audits', attribute: 'previousaudits' },
  { title: 'History', route: 'history', attribute: 'history' },
  { title: 'Analytic Automation', route: 'analytics-automation', attribute: 'analyticautomation' },
  { title: 'Reminders', route: 'last-reminder', attribute: 'reminders' }

  ];
  export const auditMenu = [
  { title: 'Audit Overview', route: 'overview', attribute: 'auditoverview' },
  { title: 'Digital Audit Report', route: 'audit-digital-report', attribute: 'auditdigitalreport' },
  { title: 'Audit Notifications', route: 'audit-notifications', attribute: 'auditnotification' },
  { title: 'Schedule', route: 'schedule-details', attribute: 'schedule' },
  { title: 'Audit Program', route: 'audit-program', attribute: 'auditprogram' },
  { title: 'Activities', route: 'activities', attribute: 'activities' },
  { title: 'Issues', route: 'issues', attribute: 'issues' },
  { title: 'Audit Observations', route: 'observations', attribute: 'auditobservation' },
  { title: 'Improvements', route: 'improvement-list', attribute: 'improvements' },
  // { title: 'Compliance Observations', route: 'compliance-observations', attribute: 'complianceobservation' },
  { title: 'Risk Program Observations', route: 'risk-program-observations', attribute: 'riskprogramobservation' },
  { title: 'Incident', route: 'audit-incident', attribute: 'incident' },
  { title: 'Management Responses', route: 'management-responses', attribute: 'managementresponse' },
  { title: 'Actions', route: 'actions', attribute: 'actions' },
  { title: 'Recommendations', route: 'recommendations', attribute: 'recommendations' },
  { title: 'Reports', route: 'report', attribute: 'reports' },
  { title: 'Audit Survey', route: 'audit-survey', attribute: 'auditsurvey' },
  { title: 'Diagrams', route: 'workflow', attribute: 'diagram' },
  { title: 'Network', route: 'network', attribute: 'network' },
  { title: 'Time Entries', route: 'time-entries', attribute: 'timeentries' },
  { title: 'Assessments', route: 'assessments', attribute: 'assessments' },
  { title: 'RCM', route: 'rcm', attribute: 'rcm' },
  { title: 'Requests', route: 'request', attribute: 'requests' },
  { title: 'Team', route: 'team', attribute: 'team' },
  { title: 'Related Audits', route: 'related-audits', attribute: 'previousaudits' },
  { title: 'History', route: 'history', attribute: 'history' },
  { title: 'Analytic Automation', route: 'analytics-automation', attribute: 'analyticautomation' },
  { title: 'Reminders', route: 'last-reminder', attribute: 'reminders' }
  ];
  export const auditApprovalMenu = [
    { title: 'Audit Overview', route: 'overview', attribute: 'auditoverview' },
    { title: 'Digital Audit Report', route: 'audit-digital-report', attribute: 'auditdigitalreport' },
    { title: 'Audit Notifications', route: 'audit-notifications', attribute: 'auditnotification' },
    { title: 'Schedule', route: 'schedule-details', attribute: 'schedule' },
    { title: 'Audit Program', route: 'audit-program', attribute: 'auditprogram' },
    { title: 'Activities', route: 'activities', attribute: 'activities' },
    { title: 'Issues', route: 'issues', attribute: 'issues' },
    { title: 'Audit Observations', route: 'observations', attribute: 'auditobservation' },
    { title: 'Improvements', route: 'improvement-list', attribute: 'improvements' },
    // { title: 'Compliance Observations', route: 'compliance-observations', attribute: 'complianceobservation' },
    { title: 'Risk Program Observations', route: 'risk-program-observations', attribute: 'riskprogramobservation' },
    { title: 'Incident', route: 'audit-incident', attribute: 'incident' },
    { title: 'Management Responses', route: 'management-responses', attribute: 'managementresponse' },
    { title: 'Actions', route: 'actions', attribute: 'actions' },
    { title: 'Recommendations', route: 'recommendations', attribute: 'recommendations' },
    { title: 'Reports', route: 'report', attribute: 'reports' },
    { title: 'Audit Survey', route: 'audit-survey', attribute: 'auditsurvey' },
    { title: 'Diagrams', route: 'workflow', attribute: 'diagram' },
    { title: 'Network', route: 'network', attribute: 'network' },
    { title: 'Time Entries', route: 'time-entries', attribute: 'timeentries' },
    { title: 'Assessments', route: 'assessments', attribute: 'assessments' },
    { title: 'RCM', route: 'rcm', attribute: 'rcm' },
    { title: 'Requests', route: 'request', attribute: 'requests' },
    { title: 'Team', route: 'team', attribute: 'team' },
    { title: 'Related Audits', route: 'related-audits', attribute: 'previousaudits' },
    { title: 'History', route: 'history', attribute: 'history' },
    { title: 'Analytic Automation', route: 'analytics-automation', attribute: 'analyticautomation' },
    { title: 'Reminders', route: 'last-reminder', attribute: 'reminders' },
    { title: 'Approval', route: 'approval-list', attribute: 'approval-list' }
    ];
  export const adminPanelUser = {
  license_card: '',
  license_utilisation_status: '',
  license_utilisation_by_type: '',
  users_by_status: '',
  limit: tableConfig.pager,
  offset: 0
  };
  export const adminAuditPlanning = {
  field_name: '',
  data_type: '',
  configurable: ''
  };
  export const adminAuditPlanningQuery = {
  color: '',
  value: '',
  order: '',
  users_by_status: '',
  limit: tableConfig.pager,
  offset: 0
  };
  export const contributorObservation = {
  management_status: '',
  department_level1: '',
  department_level2: ''
  };
  export const consolidatedRecordsParams = {
  limit: tableConfig.pager,
  offset: 0
  };
  export const managementResponseConst = {
  audit: 'Audit',
  compliance: 'Compliance',
  auditRiskProgram: 'Audit & Risk Program',
  Risk: 'Risk Program',
  Analytics: 'Analytics',
  improvementMR: 'Improvement',
  observationMR: 'Observation'
  };
  export const dBDataType = {
  char: 'char',
  varChar: 'char',
  int: 'int',
  dateTime: 'datetime',
  date: 'date',
  decimal: 'decimal'
  };
  export const entityData = {
  entity_manager: { id: 0, value: '-' },
  country: { id: 0, value: '-' },
  entity_size: { id: 0, value: '-' },
  state: { id: 0, value: '-' }
  };
  export const idConstants = {
  auditId: 'audit_id',
  programId: 'program_id',
  issueId: 'issue_id',
  id: 'id',
  observationId: 'observation_id',
  identifier: 'identifier',
  slug: 'slug',
  results: 'results',
  planId: 'plan_id',
  actionDocument: 'actionDocument',
  managementResponse: 'management_response',
  manual: 'Manual',
  responseId: 'response_id',
  complianceId: 'complianceId',
  };
  export const dependencyList = {
  updateDependencyCount: 'updateDependencyCount',
  updateDependenciesKriEvent: 'updateDependenciesKriEvent',
  activities: 'Activities',
  issues: 'Issues',
  canceled: 'canceled',
  updateEvent: 'updateEvent',
  issueObservation: 'issueObservation',
  relatedIssues: 'Related Issues',
  updateDependenciesEvent: 'updateDependenciesEvent',
  relatedObservations: 'Related Observations',
  updateDocumentEvent: 'updateDocumentEvent',
  refreshCountEvent: 'refreshCountEvent',
  updateDocumentEmit:'updateDocumentEmit',
  updateActionEvent: 'updateActionEvent',
  updateAuditsEvent: 'updateAuditsEvent',
  updateAnalyticsScenarioEvent: 'updateAnalyticsScenarioEvent',
  updateAuditData: 'updateAuditData',
  planCreated: 'planCreated',
  updateDynamicEvent: 'updateDynamicEvent',
  actions: 'Actions',
  documents: 'Documents',
  approvals: 'Approvals',
  updateRecommendationEvent: 'updateRecommendationEvent',
  updateAssessmentEvent: 'updateAssessmentEvent',
  approvalEvents: 'approvalEvents',
  deletionRedirectionEvent: 'deletionRedirectionEvent',
  audit: 'audit',
  recommendations: 'Recommendations',
  controls: 'Controls',
  incidenttab: 'Incidents',
  risks: 'Risks',
  assessments: 'Assessments',
  analyticAutomation: 'Analytics Automation',
  history: 'History',
  Approval: 'Approval',
  observation: 'observations',
  observationTitle: 'Observations',
  relObs: 'Related Observations',
  updateControlEvent: 'updateControlEvent',
  updateIncidentEvent: 'updateIncidentEvent',
  auditObservation: 'Audit Observation',
  auditObservations: 'Audit Observations',
  updateAuditEvent: 'updateAuditEvent',
  riskAssessment: 'risk_assessment',
  EnterpriseRisk: 'enterprise_risk',
  control: 'control',
  risk: 'risk',
  issue: 'issue',
  issueDynamic: 'issue&dynamic_fields_for=compliance-audit',
  issueTitle: 'Issue',
  riskAssessmentDetails: 'risk_assessment_details',
  observationRecommendation: 'observation_recommendation',
  auditOverview: 'auditOverview',
  createIssue: 'create_issue',
  observationAction: 'observation_action',
  observationActionKri : 'observation_action_kri',
  auditSchedule: 'audit_schedule',
  actionDocument: 'actionDocument',
  observationActionDocument: 'risk-program-observation-action',
  riskMovement: 'Risk Movements',
  controlMovement: 'Control Movements',
  updateIssueEvent: 'updateIssueEvent',
  updateDependencyEvent: 'updateDependencyEvent',
  recommendation: 'recommendation',
  auditRecommendation: 'Audit Recommendations',
  auditAction: 'audit Actions',
  issueActions: 'Issue Actions',
  parentIssue: 'Issues',
  auditActionTitle: 'Audit Actions',
  incident: 'incident',
  incidents: 'incidents',
  workingPaper: 'working paper',
  businessStrategy: 'business_strategy',
  auditProgramActivity: 'working_paper',
  activity: 'activity',
  policyRequest: 'policy_request',
  updateAuditProgramEvent: 'updateAuditProgramEvent',
  complianceRequest: 'compliance_request',
  request: 'requests',
  updateRequestEvent: 'updateRequestEvent',
  entityGridUpdateEvent: 'entityGridUpdateEvent',
  regionGridUpdateEvent: 'regionGridUpdateEvent',
  departmentGridUpdateEvent: 'departmentGridUpdateEvent',
  userGroupUpdateEvent: 'userGroupUpdateEvent',
  userUpdateEvent: 'userUpdateEvent',
  riskDataUpdateEvent: 'riskDataUpdateEvent',
  controlDataUpdateEvent: 'controlDataUpdateEvent',
  auditPlanningDataUpdateEvent: 'auditPlanningDataUpdateEvent',
  auditReportingDataUpdateEvent: 'auditReportingDataUpdateEvent',
  analyticsDataUpdateEvent: 'analyticsDataUpdateEvent',
  complianceDataUpdateEvent: 'complianceDataUpdateEvent',
  incidentDataUpdateEvent: 'incidentDataUpdateEvent',
  bcmDataUpdateEvent: 'bcmDataUpdateEvent',
  policyDataUpdateEvent: 'policyDataUpdateEvent',
  bugetDataUpdateEvent: 'bugetDataUpdateEvent',
  policy: 'Policy',
  policies:'Policies',
  document: 'Document',
  policyDocument: 'Uploads',
  riskDep: 'Risks',
  controlDep: 'Controls',
  compliance: 'Compliance',
  complianceAudit: 'compliance',
  complianceAuditNew: 'Compliance Audit',
  updatePolicyEvent: 'updatePolicyEvent',
  updateComplianceEvent: 'updateComplianceEvent',
  updateRiskProgramEvent: 'updateRiskProgramEvent',
  updateBCMEvent: 'updateBCMEvent',
  businessProcess: 'Business Process',
  businessProcesses: 'Business Processes',
  updateDependenciesRiskEvent: 'updateDependenciesRiskEvent',
  updateDependenciesControlEvent: 'updateDependenciesControlEvent',
  updateDependenciesProcessEvent: 'updateDependenciesProcessEvent',
  updateDependenciesPolicyEvent: 'updateDependenciesPolicyEvent',
  updateDependenciesCompliance: 'updateDependenciesCompliance',
  updateDependenciesIncidentEvent: 'updateDependenciesIncidentEvent',
  updateDependenciesRiskObservationEvent: 'updateDependenciesRiskObservationEvent',
  updateDependenciesAuditObservationEvent: 'updateDependenciesAuditObservationEvent',
  annexure: 'Annexure',
  updateAnnexure: 'updateAnnexure',
  managementResponse: 'Management Responses',
  updateResponse: 'updateResponse',
  Response: 'management_response',
  riskIssue: 'Risk Issue',
  observationIssueDocument: 'risk-program-observation-issue',
  controlIssue: 'control-issue',
  complianceRecommendation: 'compliance_recommendation',
  recommendationHistory: 'compliance_observation_recommendation',
  designationGridUpdateEvent: 'designationGridUpdateEvent',
  updateRiskEvent: 'updateRiskEvent',
  riskPrograms: 'Risk Programs',
  auditPrograms: 'Audit Programs',
  sections: 'Sections',
  department: 'Departments',
  network: 'Network',
  riskProgram: 'risk_program',
  riskProgramDependancy: 'riskProgramDependancy',
  workingPaperTitle: 'Working Paper',
  updateWorkingPaperEvent: 'updateWorkingPaperEvent',
  observations: 'observations',
  associatedRisk: 'associatedRisk',
  associatedControl: 'associatedControl',
  riskMap: 'Risk Map',
  auditRisk: 'Audit and Risk Program Observations',
  complianceObservation: 'Compliance Observations',
  enterpriseRisk: 'Enterprise Risk',
  taggedRecommendation:'Recommendation',
  riskProgramObservation: 'Risk Program Observation',
  riskProgramObservations: 'Risk Program Observations',
  relatedObservation: 'Related Observations',
  programObservation: 'observation',
  reminder: 'reminders',
  riskProgramData: 'Risk Program',
  bcm: 'BCM',
  History: 'History',
  scheduleSubActivity : 'schedule Sub Activity',
  workingPaperImprovement: 'Improvements',
  improvementHistoryReq_for: 'improvement',
  improvementDocumentReq_for: 'improvements',
  complianceObservationAction: 'compliance_observation_action',
  complianceReminder: 'Reminders',
  updateHistoryEvent: 'updateHistoryEvent',
  riskWorkingPaper: 'risk-program-working-paper-risk',
  controlWorkingPaper: 'risk-program-working-paper-control',
  manualWorkingPaper: 'risk-program-working-paper',
  updateRelatedObservations: 'updateRelatedObservations',
  customEvent: 'customEvent',
  policyReport: 'Policies',
  policySignoff: 'Sign off',
  approval: 'Approval',
  policyReportCountEmit: 'policyReportCountEmit',
  policySignOffEmit: 'policySignOffEmit',
  update: 'update',
  updateIssueCount: 'updateIssueCount',
  timeEntries:'TIme Entries',
  updated:'updated',
  asset: 'Assets',
  updateCompliance: 'updateCompliance',
  arxUploadDocumentEvent: 'arxUploadDocumentEvent'
  };

  export const fieldDataConstants = {
    organizationDetails: 'organization_details'
  }

  export const tabConstants = {
  notSupporting: 'Selected file format is not supported',
  fileExceeds: 'Selected file size exceeds the limit, file should be less than 100mb',
  reviewer: 'reviewer',
  file: 'file',
  yes: 'Yes',
  no: 'No',
  risk: 'risk',
  control: 'control',
  riskUniverse: 'riskUniverse',
  riskErm: 'riskErm',
  riskControl: 'riskControl',
  riskAssessment: 'risk_assessment',
  riskAssessmentDetails: 'risk-assessment-details',
  araModule: 'aramodule',
  incidentDetails: 'incident_reporting',
  auditRecommendation: 'auditRecommendation',
  auditProgramActivity: 'audit_program_activity',
  process: 'Process',
  auditObservations: 'auditObservations',
  auditActivity: 'auditActivity',
  auditPlanning: 'auditPlanning',
  businessStrategy: 'business_strategy',
  auditsDependency: 'auditsDependency',
  observation: 'observation',
  recommendation: 'auditRecommendation',
  issue: 'issue',
  actionDocument: 'actionDocument',
  complianceObervation: 'complianceObservationDependency',
  complianceRecommendation: 'complianceObservationRecommendation',
  riskIssue: 'Risk Issue',
  riskProgram: 'risk_program',
  riskProgramDependancy: 'riskProgramDependancy',
  associatedRisk: 'associatedRisk',
  enterpriseRisk: 'Enterprise Risk',
  entity: 'entity',
  department: 'department',
  InicidentRisk: 'InicidentRisk',
  IncidentERM: 'IncidentERM',
  section: 'section',
  function: 'function',
  auditSchedule: 'auditSchedule',
  complianceSchedule: 'complianceSchedule',
  bcmProcess: 'bcmProcess',
  request: 'request',
  complianceObservationAction: 'complianceObservationAction',
  requestCompliance: 'requestCompliance',
  bcmDependency: 'bcmDependency',
  auditProgram: 'working_paper',
  auditSurvey: 'audit_survey',
  riskProgramRequest: 'riskProgram',
  bcmAuditProgram: 'bcm_working_paper',
  bcmAuditProgramSurvey: 'bcm_workingpaper_survey',
  bcmAuditProgramSurveyConsolidated: 'bcm_consolidated_workingpaper_survey',
  bcmAudit: 'bcmAudit',
  auditImprovement: 'audit-improvement'
};

export enum CreateTaskWidgets {
  };
  export enum CreateTaskWidgets {
  subject = 'subject',
  due_date = 'due_date',
  assigned_to = 'assigned_to',
  name = 'name',
  related_to = 'related_to',
  sub_type = 'sub_type'
  }
  export enum CreateEventWidgets {
  subject = 'subject',
  description = 'description',
  start_date = 'start_date',
  end_date = 'end_date',
  start_time = 'start_time.value',
  end_time = 'end_time.value',
  location = 'location',
  assigned_to = 'assigned_to',
  name = 'name',
  related_to = 'related_to',
  sub_type = 'sub_type'
  }
  export enum FieldType {
  calculated = 'calculated',
  default = 'default',
  custom = 'custom',
  relation = 'relation'
  }
  export const extensionTypes = ['csv', 'xls', 'xlsx'];
  export const extensionTypesExcel = ['xls', 'xlsx'];
  export const extensionTypesText = ['csv', 'txt', 'ssv', 'tsv', 'scsv', 'psv'];
  export const connectionMethods = {
  existing: 'Existing',
  new: 'New'
  };


  export enum LayoutDirection {
  ltr = 'ltr',
  rtl = 'rtl'
  }
  export const commonEvent = {
  edit: 'Edit',
  link: 'link',
  delete: 'Delete',
  update: 'Update',
  view: 'View',
  sendReminder: 'sendReminder',
  markAsException: 'markAsException',
  markAsFalsePositive: 'markAsFalsePositive',
  markAsOpen: 'markAsOpen',
  markAsClosed: 'markAsClosed',
  changePassword: 'changePassword',
  araModule: 'aramodule',
  dataSource: 'dataSource',
  dataFlush: 'dataFlush',
  dataProcess: 'dataProcess',
  jdoc: 'jdoc',
  id: 'id',
  status: 'status',
  objectId: 'objectId',
  subSectionObjectId: 'subSectionObjectId',
  back: 'back',
  download: 'download',
  cancel: 'cancel',
  import: 'import',
  info: 'Info',
  schedule: 'Schedule',
  remove: 'Remove',
  deleteDocument: 'deleteDocument',
  viewIssue: 'viewIssue',
  editactivity: 'editactivity',
  browseactivity: 'browseactivity',
  digitalReport: 'digitalReport',
  browse: 'browse',
  browsePlan: 'browsePlan',
  create: 'Create',
  withdraw: 'withdraw',
  loadMore: 'loadMore',
  deleteActivity: 'deleteActivity',
  viewPublicUrl: 'viewPublicUrl',
  sendEmail: 'sendEmail',
  generate: 'generate',
  upload: 'Upload',
  activateLicenceKey: 'activateLicenceKey',
  browseIncident: 'browseIncident',
  approval: 'Approval',
  duplicate: 'duplicate',
  clone: 'clone',
  refresh: 'refresh',
  save: 'Save',
  coplylink: 'Copylink',
  publish: 'Publish',
  preview: 'preview',
  filter: 'filter',
  move: 'move',
  version: 'Version',
  addTime: 'addTime',
  action: 'Action',
  add: 'add',
  editDirection: 'editDirection',
  configure: 'configure'
  };

  export const bcmDropdownConfig = {
  displayKey: 'value',
  search: true,
  clearOnSelection: true,
  placeholder: '-',
  height: '150px'
  };
  export const bcmDropdownNameConfig = {
  displayKey: 'name',
  search: true,
  clearOnSelection: true,
  placeholder: '-',
  height: '150px'
  };
  export const bcmDropdownValueConfig = {
  displayKey: 'value',
  search: true,
  clearOnSelection: true,
  placeholder: '-',
  height: '150px'
  };
  export const bcmDropdownDurationConfig = {
  displayKey: 'value',
  search: true,
  clearOnSelection: true,
  placeholder: '-',
  height: '150px'
  };
  export const bcmCreateTemplates = {
  overview: 'overview',
  impactAssessment: 'impactAssessment',
  workaround: 'workaround',
  finish: 'finish',
  dependencies: 'dependencies',
  save: 'save'
  };
  export const bcmCreateTemplatesLabels = {
  overview: 'Overview',
  impactAssessment: 'Impact Assessment',
  workaround: 'Workaround',
  disasterRecovery: 'Disaster Recovery Plan',
  dependencies: 'Process Dependencies',
  finish: 'Finish'
  };
  export const permissionDocument = {
  'Upload Document': true,
  'Edit Document': true,
  'Delete Document': true
  };
  export const permissionTable = {
  'Edit': true,
  'Delete': true
  };
  export const permissionUpload = {
  'Upload Document': true
  };
  export const bcmCreateOverViewStatus = {
  overview: true,
  impactAssessment: false,
  Workaround: false,
  dependencies: false,
  finish: false
  };
  export const bcmCreateImpactAssessmentStatus = {
  overview: false,
  impactAssessment: true,
  Workaround: false,
  dependencies: false,
  finish: false
  };
  export const bcmCreateWorkaroundStatus = {
  overview: false,
  impactAssessment: false,
  Workaround: true,
  dependencies: false,
  finish: false
  };
  export const bcmCreateFinishStatus = {
  overview: false,
  impactAssessment: false,
  Workaround: false,
  dependencies: false,
  finish: true
  };
  export const bcmCreateDependencyStatus = {
  overview: false,
  impactAssessment: false,
  Workaround: false,
  dependencies: true,
  finish: false
  };
  export const actionDropDown = {
  create: 'Create',
  import: 'Import',
  export: 'Export'
  };
  export const araConstant = {
  araModule: 'araModule'
  };
  export const tableConstant = {
  new: 'new',
  removed: 'removed'
  };
  export const assessmentQuestionType = {
  one: 1,
  two: 2,
  three: 3,
  four: 4,
  five: 5,
  six: 6,
  seven: 7,
  eight: 8
  };

  export const doughnutTempData = [
  {
  label: '',
  data: 1,
  isTemp: true,
  color: '#e9e9e9',
  colors: '#e9e9e9'
  }
  ];

  export const lineTempData = [
    {
    name: 'no_data',
    values: [{
    label: 'L1',
    data: 0,
    colors: '#e9e9e9'
    }, {
    label: 'L2',
    data: 20,
    colors: '#e9e9e9'
    }, {
    label: 'L3',
    data: 40,
    colors: '#e9e9e9'
    }, {
    label: 'L4',
    data: 30,
    colors: '#e9e9e9'
    }, {
    label: 'L5',
    data: 45,
    colors: '#e9e9e9'
    }, {
    label: 'L6',
    data: 60,
    colors: '#e9e9e9'
    }, {
    label: 'L7',
    data: 55,
    colors: '#e9e9e9'
    }, {
    label: 'L8',
    data: 70,
    colors: '#e9e9e9'
    },
    {
      label: 'L9',
      data: 65,
      colors: '#e9e9e9'
    },
    {
      label: 'L10',
      data: 80,
      colors: '#e9e9e9'
    },
    {
      label: 'L11',
      data: 75,
      colors: '#e9e9e9'
    },
    {
      label: 'L12',
      data: 90,
      colors: '#e9e9e9'
    }
   ]
    }
    ];

    export const lineMultipleTempData = [
      {
        label: 'Line1',
        values: [
      { label: 'Jan', data: 15 },
      { label: 'Feb', data: 25 },
      { label: 'Mar', data: 40 },
      { label: 'Apr', data: 30 },
      { label: 'May', data: 45 },
      { label: 'Jun', data: 60 },
      { label: 'Jul', data: 55 },
      { label: 'Aug', data: 70 },
      { label: 'Sep', data: 65 },
      { label: 'Oct', data: 80 },
      { label: 'Nov', data: 75 },
      { label: 'Dec', data: 120 }
        ],
        color: 'darkgrey'
      },
    ]

  export const stackedChartData = {
  data: [{ 'name': '', 'data': [10, 9, 8, 7, 6, 5] }],
  color: ['#e9e9e9'],
  label: ['a', 'b', 'c', 'd', 'e', 'f'],
  tooltip: { 'a': { '-': '-' }, 'b': { '-': '-' }, 'c': { '-': '-' }, 'd': { '-': '-' }, 'e': { '-': '-' }, 'f': { '-': '-' } }
  };
  export const heatMapData = [
  [
  {
  'row': 0,
  'column': 3,
  'impact': '',
  'likelihood': '',
  'count': '-'
  },
  {
  'row': 1,
  'column': 3,
  'impact': '',
  'likelihood': '',
  'count': '-'
  },
  {
  'row': 2,
  'column': 3,
  'impact': '',
  'likelihood': '',
  'count': '-'
  },
  {
  'row': 3,
  'column': 3,
  'impact': '',
  'likelihood': '',
  'count': '-'
  }
  ],
  [
  {
  'row': 0,
  'column': 2,
  'impact': '',
  'likelihood': '',
  'count': '-'
  },
  {
  'row': 1,
  'column': 2,
  'impact': '',
  'likelihood': '',
  'count': '-'
  },
  {
  'row': 2,
  'column': 2,
  'impact': '',
  'likelihood': '',
  'count': '-'
  },
  {
  'row': 3,
  'column': 2,
  'impact': '',
  'likelihood': '',
  'count': '-'
  }
  ],
  [
  {
  'row': 0,
  'column': 1,
  'impact': '',
  'likelihood': '',
  'count': '-'
  },
  {
  'row': 1,
  'column': 1,
  'impact': '',
  'likelihood': '',
  'count': '-'
  },
  {
  'row': 2,
  'column': 1,
  'impact': '',
  'likelihood': '',
  'count': '-'
  },
  {
  'row': 3,
  'column': 1,
  'impact': '',
  'likelihood': '',
  'count': '-'
  }
  ],
  [
  {
  'row': 0,
  'column': 0,
  'impact': '',
  'likelihood': '',
  'count': '-'
  },
  {
  'row': 1,
  'column': 0,
  'impact': '',
  'likelihood': '',
  'count': '-'
  },
  {
  'row': 2,
  'column': 0,
  'impact': '',
  'likelihood': '',
  'count': '-'
  },
  {
  'row': 3,
  'column': 0,
  'impact': '',
  'likelihood': '',
  'count': '-'
  }
  ]
  ];
  export const heatMapXYData = [' ', ' ', ' ', ' '];
  export const horizontalBarData = [
  {
  'label': ' ',
  'data': 0,
  'color': '#e9e9e9',
  'identifier': ' '
  },
  {
  'label': ' ',
  'data': 0,
  'color': '#e9e9e9',
  'identifier': ' '
  },
  {
  'label': ' ',
  'data': 0,
  'color': '#e9e9e9',
  'identifier': ' '
  },
  {
  'label': ' ',
  'data': 0,
  'color': '#e9e9e9',
  'identifier': ' '
  },
  {
  'label': ' ',
  'data': 0,
  'color': '#e9e9e9',
  'identifier': ' '
  },
  {
  'label': ' ',
  'data': 0,
  'color': '#e9e9e9',
  'identifier': ' '
  },
  {
  'label': ' ',
  'data': 0,
  'color': '#e9e9e9',
  'identifier': ' '
  },
  {
  'label': ' ',
  'data': 0,
  'color': '#e9e9e9'
  }
  ];

  export const horizontalGroupedBarData = [
    {
      label: '',
      data: [
        { label: '', data: 12, color: '#e9e9e9' },
      ],
    },
    {
      label: '',
      data: [
        { label: '', data: 8, color: '#e9e9e9' }
      ],
    },
    {
      label: '',
      data: [
        { label: '', data: 10, color: '#e9e9e9' },
      ],
    }
  ];

  export const barChartData = [
  {
  'label': '',
  'data': 10,
  'colors': '#e9e9e9'
  },
  {
  'label': '  ',
  'data': 2,
  'colors': '#e9e9e9'
  },
  {
  'label': '   ',
  'data': 8,
  'colors': '#e9e9e9'
  },
  {
  'label': '    ',
  'data': 5,
  'colors': '#e9e9e9'
  },
  {
  'label': '     ',
  'data': 9,
  'colors': '#e9e9e9'
  }
  ];
  export const auditData = [
  {
  'label': null,
  'data': 0,
  'color': null,
  'identifier': 'AuditRequester'
  }
  ];
  export enum Feature {
  reminderOutstandingRisks = 'Reminder Outstanding Risks',
  reminderOutstandingActions = 'Reminder Outstanding Actions',
  failedCount = 'Failed login count',
  edit = 'edit'
  }
  export enum AuditPlans {
  completed = 'Completed',
  universe = 'universe',
  completedApproval = 'completed',
  audit = 'audit',
  rcm = 'rcm',
  auditPlanDetail = 'audit-plan-detail',
  auditPlan = 'auditPlan',
  approval = 'Approval'
  }
  export enum IssueWidget {
  auditPlanning = 'Audit Planning',
  riskAssessment = 'Risk Assessment',
  auditArea = 'areas_audit',
  undefined = 'undefined',
  audit = 'Audit',
  risk = 'risk',
  control = 'control',
  manual = 'Manual',
  riskUniverse = 'Risk Universe',
  riskControl = 'Risk Control',
  riskProgram = 'Risk Program',
  auditIssue = 'auditIssue',
  workingPaper ='Working Paper',
  complianceIssue = 'complianceIssue',
  complianceAudit = 'Compliance Audit',
  bcmAudit = 'BCM Audit',
  riskOrControl = 'risk/control'
  }
  export const auditRiskAnalyticsControlPanel = {
  true: true,
  false: false
  };
  export const analyticsScenarioDetailPage = {
  updateControlEvent: 'updateControlEvent',
  updateAssessmentEvent: 'updateAssessmentEvent',
  riskControls: 'Risk Controls',
  aramodule: 'aramodule',
  risks: 'Risks',
  auditObservations: 'Audit Observations',
  assessments: 'Assessments',
  araModule: 'araModule',
  riskMap: 'Risk Map'
  };
  export const auditSchedule = {
  limit: 10
  };
  export enum ClientConstants {
  edit = 'edit',
  delete = 'delete',
  action = 'Action',
  licenseKey = 'License Key',
  numberOfUsers = 'Number of Users',
  email = 'Email',
  clientName = 'Client Name',
  Edit = 'Edit',
  Delete = 'Delete',
  ChangePassword = 'Change Password',
  changePassword = 'changePassword',
  resendEmail = 'Resend Email',
  resend = 'resend',
  Deactivate = 'Deactivate',
  deactivate = 'deactivate',
  home = 'Home',
  clients = 'Clients',
  language = 'Language',
  view = 'view',
  activate = 'activate',
  createInstance = 'createInstance',
  reactivate = 'REACTIVATE',
  deactivateApi = 'DEACTIVATE',
  listId = 'list_id',
  licenceId = 'licence_id',
  key = 'key',
  id = 'id',
  clientUrl = '/controlpanel/clients',
  clientAdminUrl = '/controlpanel/admin-panel/client/',
  editUrl = '/edit',
  activateLicenceKey = 'activateLicenceKey'
  }
  export const observationsData = {
  scheduleData: [{ color: '#e5e5e5', data: 100, identifier: 'default', label: 'Nodata' }]
  };
  export const summaryNoData = {
  velocity: {
  value: 0,
  label: '-',
  color: '#e5e5e5',
  id: 0,
  min: '',
  high: ''
  },
  vulnerability: {
  value: 0,
  label: '-',
  color: '#e5e5e5',
  id: 0,
  min: '',
  high: ''
  },
  likelihood: {
  value: 0,
  label: '-',
  color: '#e5e5e5',
  id: 0,
  min: '',
  high: ''
  },
  impact: {
  value: 0,
  label: '-',
  color: '#e5e5e5',
  id: 0,
  min: '',
  high: ''
  }
  };
  export const displayMoreComments = {
  less: 'Less',
  more: 'More'
  };
  export const fileBytes = {
  units: ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  };
  export const assessmentList = {
  createdDate: '_created_date',
  createdUser: '_created_user'
  };
  export const actionStatusType = {
  number: 'number'
  };
  export enum CreatePasswordConstants {
  mustMatch = 'mustMatch',
  passwordMustMatch = 'Passwords must match',
  passwordMustHaveAMinimumOf = 'Password must have a minimum of :',
  minimumLength = 'minimumLength',
  passwordMinimumLength = 'Eight characters',
  upperCase = 'upperCase',
  passwordMinimumOneUpperCase = 'One uppercase letter',
  lowerCase = 'lowerCase',
  passwordMinimumOneLowerCase = 'One lowercase letter',
  number = 'number',
  passwordMinimumOneNumber = 'One number',
  specialCharacter = 'specialCharacter',
  passwordOneSpecialCharacter = 'One special character',
  password = 'password',
  confirmPassword = 'confirmPassword',
  type = 'type',
  id = 'id',
  body = 'body',
  hoverFocus = 'hover focus',
  resetUserPassword = 'Reset A User\'s Password',
  CreateNewPassword = 'Create A New Password',
  CreateNewUserPassword = 'Create A New User Password',
  CreateNewClientPassword = 'Create A New Client Password',
  success = 'success',
  failed = 'failed',
  error = 'Error',
  message = 'message',
  msg = 'msg',
  loading = 'Loading...'
  }
  export const dashboardUrls = {
  auditDashboard: 'views/AssesmentResultsDashboard_New/AssesmentResults',
  assessmentDashboard: 'views/AssesmentResults/AssesmentResults'
  };
  export const weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  export const weekDay = [{value: 'Monday'}, {value: 'Tuesday'}, {value: 'Wednesday'}, {value: 'Thursday'}, {value: 'Friday'}, {value: 'Saturday'}, {value: 'Sunday'}]
  export enum DaysOfWeek {
  sunday = 'Sunday',
  monday = 'Monday',
  tuesday = 'Tuesday',
  wednesday = 'Wednesday',
  thursday = 'Thursday',
  friday = 'Friday',
  saturday = 'Saturday'
  }

  export enum GanttChartConstants {
  auditSchedule = 'auditSchedule',
  auditCalendar = 'auditcalendar',
  auditDetails = 'auditDetails',
  auditTimeline = 'auditTimeline'
  }
  export enum EditedAuditConstants {
  auditName = 'Audit Name',
  auditUniverse = 'Audit Universe',
  entity = 'Entity',
  auditArea = 'Audit Area',
  source = 'Source of Audit',
  requested = 'Requested by',
  scope = 'Audit Scope',
  objective = 'Audit Objective',
  conclusion = 'Conclusion'
  }
  export enum EditedUniverseConstants {
  universeName = 'Universe Name',
  start = 'Start Date',
  end = 'End Date'
  }
  export enum TagDependencyTabs {
    risk = 'Risks',
    control = 'Controls',
    policy = 'Policies',
    document = 'Documents'
  }
  export enum PermissionModuleSlug {
    surveyTemplate = 'py-survey-template',
    survey = 'attend-py-survey',
    request = 'assessment-request',
    assessmentTemplate = 'survey'
  }
  export enum TagDependencyMoreOptions {
    editRisk = 'editRisk',
    editControl = 'editControl',
    editDocument = 'editDocument',
    removeRisk = 'removeRisk',
    removeControl = 'removeControl',
    removeDocument = 'removeDocument',
    removePolicy = 'removePolicy',
    downloadDoc = 'downloadDoc'
  }
  export enum EditedActionConstants {
  actionName = 'Action Title',
  cost = 'Cost Saving',
  department = 'Department',
  date = 'Due Date',
  description = 'Description',
  priority = 'Action Priority',
  status = 'Action Status'
  }
  export enum AuditApprovalLabel {
  reviewerReject = 'Reviewer Reject',
  approverReject = 'Approver Reject',
  approverApproved = 'Approver Approve',
  reviewerApproved = 'Reviewer Approve',
  open = 'Open',
  pending = 'Pending',
  edit = 'edit',
  overview = 'overview',
  delete = 'delete',
  reviewer = 'Reviewer',
  pendingTrue = 'pending',
  approver = 'Approver',
  progress = 'in progress'
  }
  export enum ActivityLabelConstants {
  name = 'Activity Title',
  stakeholder = 'Stakeholder',
  assignee = 'Assignee',
  status = 'Status',
  dueDate = 'Due Date',
  auditorComment = 'Auditor Comment',
  completed = 'completed',
  create = 'create',
  response = 'mangementResponse'
  }
  export enum ActivityOpenConstants {
  create = 'create',
  edit = 'edit',
  delete = 'delete',
  browsePlan = 'browse',
  megaProcess = 'Mega Process',
  majorProcess = 'Major Process',
  fiscalYear = 'fiscalYear',
  AuditUniverseManager='Audit Universe Manager',
  auditUniverse='auditUniverse',
  manager ='manager',
  entity = 'entity',
  sector = 'sector',
  clone = 'clone'
  }
  export const auditEdit = {
  plan: 'plan',
  auditName: 'audit_name',
  requested: 'requested_by',
  method: 'method_of_audit',
  scope: 'scope',
  objective: 'objective',
  conclusion: 'conclusion',
  jdoc: 'jdoc',
  auditArea: 'areas_audit',
  entity: 'entity_new',
  auditee: 'auditee',
  manager: 'manager',
  team: 'management_team',
  approver: 'approver',
  reviewer: 'reviewer',
  risk: 'risk',
  control: 'control',
  survey: 'survey_v2',
  // auditplanDetailSet: 'auditplandetail_set',
  auditplanDetailSet: 'activities',
  file: 'file',
  person : 'person',
  departmentRating : 'department_rating',
  worflow: 'workflow'
  };
  export const sidebar = {
  primary: 'primary',
  mainMenu: 'mainMenu',
  title: 'title',
  titleKey: 'titleKey',
  parentTitleKey: 'parentTitleKey'
  };
  export const auditOverdueTab = {
  overdue: 'Overdue',
  overdues: 'overdue',
  actionStatus: 'action_status',
  issueStatus: 'issue_status',
  recommendationStatus: 'recommendation_status',
  managementStatus: 'management_status',
  observation: '',
  statusCard: 'status_card',
  };
  export const issueTrackerActionType = {
  observation: 'observation',
  issue: 'issue',
  recommendation: 'recommendation',
  activity: 'activity',
  issueTracker: 'issueTracker',
  control: 'control',
  risk: 'risk',
  improvement: 'improvement',
  bcp: 'BCP',
  workingPaper: 'working_paper',
  asset: 'Asset',
  businessProcess: 'Business Process',
  esg_strategy: 'esg_strategy',
  esgmetrics: 'ESG Metrics',
  business_strategy: 'business_strategy'
  };

  export const auditActionType = {
    observation: 'observation',
    issue: 'issue',
    recommendation: 'recommendation',
    activity: 'activity',
    improvement: 'improvement',
    workingPaper: 'working_paper'
    };

  export const issueCreateConstant = {
  issueStatus: 'issue_status',
  issueRating: 'issue_rating',
  status: 'status',
  rating: 'rating',
  likelihood: 'likelihood',
  impact: 'impact'
  };
  export const issueActionData = {
  actionDocument: 'actionDocument',
  id: 'id',
  auditId: 'audit_id',
  riskId: 'risk_id',
  controlId: 'control_id',
  issueId: 'issue_id',
  observationId: 'observation_id',
  auditReporting: 'audit-reporting',
  actionStatus: 'actionstatus/',
  commentText: 'comment_text',
  file: 'file',
  parent: 'parent',
  replies: 'replies',
  userDetails: 'userDetails',
  result: 'result',
  approvalId: 'approval_id',
  riskIssue: 'Risk Issue'
  };

  export const recommendationActionData = {
    id: 'id',
    auditId: 'audit_id',
    recId: 'recommendation_id',
    };

  export const activeOptionClient = {
  option: [
  {
  icon: 'au-icon au-icon--edit',
  class: '',
  label: 'Edit',
  event: 'edit'
  },
  {
  icon: 'au-icon au-icon--delete',
  class: 'au-color--red',
  label: 'Delete',
  event: 'delete'
  },
  {
  icon: 'au-icon au-icon--reactivate',
  class: '',
  label: 'Reactivate',
  event: 'activate'
  }
  ]
  };
  export const activeOptionClientNone = {
  option: [
  {
  icon: 'au-icon au-icon--edit',
  class: '',
  label: 'Edit',
  event: 'edit'
  },
  {
  icon: 'au-icon au-icon--delete',
  class: 'au-color--red',
  label: 'Delete',
  event: 'delete'
  }
  ]
  };
  export const deactivateOptionClient = {
  option: [
  {
  icon: 'au-icon au-icon--edit',
  class: '',
  label: 'Edit',
  event: 'edit'
  },
  {
  icon: 'au-icon au-icon--delete',
  class: 'au-color--red',
  label: 'Delete',
  event: 'delete'
  },
  {
  icon: 'au-icon au-icon--none',
  class: '',
  label: 'Deactivate',
  event: 'deactivate'
  }
  ]
  };
  export const instanceOptionClient = {
  option: [
  {
  icon: 'au-icon au-icon--edit',
  class: '',
  label: 'Edit',
  event: 'edit'
  },
  {
  icon: 'au-icon au-icon--delete',
  class: 'au-color--red',
  label: 'Delete',
  event: 'delete'
  },
  {
  icon: 'au-icon au-icon--instance',
  class: '',
  label: 'Create Instance',
  event: 'createInstance'
  }
  ]
  };
  export const login = {
  password: 'password',
  confirmPassword: 'con_password',
  token: 'token'
  };
  export const clientDetails = {
  email: '',
  name: '',
  professionalLicenseCount: 0,
  contributorLicenseCount: 0,
  viewerLicenseCount: 0,
  adminLicenseCount: 0,
  explorerLicenseCount: 0,
  professionaladminLicenseCount: 0,
  licenseKey: '',
  copyDummyData: false,
  status: '',
  language: '',
  stage: '',
  saasUrl: '',
  deploymentMode: '',
  type: '',
  TotalCount: 0,
  licenseId: 0,
  active: false,
  instance: false,
  id: 0,
  expireDate: ''
  };
  export const licenceClientDetails = {
  email: '',
  name: '',
  professionalLicenseCount: 0,
  contributorLicenseCount: 0,
  viewerLicenseCount: 0,
  licenseKey: '',
  }
  export const riskTableCollapseConstants = {
  objectId: 'object_id',
  controlApply: 'controlApply',
  controlRemove: 'controlRemove',
  possibleException: 'possible_exception',
  selectedData: 'selectedData'
  };
  export const IncidentTableCollapseConstants = {
  objectId: 'object_id',
  IncidentApply: 'IncidentApply',
  IncidentRemove: 'IncidentRemove',
  possibleException: 'possible_exception',
  selectedData: 'selectedData'
  };
  export const riskControlEditConstants = {
  possibleException: 'possible_exception'
  };
  export enum NetworkDiagramDirectionConstants {
    topBottom = 'topBottom',
    leftRight = 'leftRight'
    }
  export const returnUrl = 'returnUrl';
  export const loginUrls = {
  contributor: '/contributor/home',
  contributor_response: '/contributor/management-status',
  dashboard: environment.clientName === 'tawazun' ? '/login-redirection' : '/home',
  login: '/',
  logout: '/logout',
  redirect: '/redirect',
  pagenotFound :'/404',
  mfa: '/mfa',
  admin: '/controlpanel/admin-users'
  };
  export const loginValue = {
  detail: 'detail',
  email: 'email',
  message: 'message',
  msg: 'msg'
  };
  export const tableMoreOptionIssueTracker = {
  mainIcon: 'au-icon au-icon--actions',
  option: [
  {
  icon: 'au-icon au-icon--view',
  class: '',
  label: 'Browse Action',
  event: 'View'
  }
  ]
  };
  export const dashboardIssueTrackerQueryParams = {
  action_status: '',
  action_type: '',
  actions_due: '',
  action_owner: '',
  date_filter: '',
  date_range: '',
  group: ''
  };
  export const dashboardIssueTrackerConstant = {
  actionStatus: 'actionStatus',
  actionType: 'actionType',
  actionsDue: 'actionsDue',
  actionOwner: 'actionOwner',
  dateFilter: 'dateFilter',
  dateRange: 'dateRange',
  group: 'group',
  allActions: 'all actions',
  all: 'all',
  DateFormat: 'DD MMM, YYYY',
  DateFormatFilter: 'MMM yy',
  limit: 'limit',
  offset: 'offset',
  category: 'category',
  others: 'Others',
  label: 'label',
  color: 'color',
  actionStatusParams: 'action_status',
  dateFilterParams: 'date_filter',
  allCaps: 'All',
  tbd: 'TBD',
  notClassified: 'Not Classified'
  };
  export const dashboardIssueTrackerTableConstant = {
  riskAppetite: 'Risk Appetite',
  kri: 'KRI',
  recommendation:'recommendation',
  pointer: 'au-table-option',
  custom: 'custom',
  messageIcon: 'au-icon au-icon--mail',
  icon: 'icon',
  tooltip: 'tooltip',
  iconItem: 'iconitem',
  sendReminder: 'send reminder',
  action: 'Action',
  tableId: 'table_id',
  route: 'route',
  priority: 'Priority',
  status: 'Status',
  age: 'Age',
  text: 'text',
  owner: 'Owner',
  dueDate: 'Due Date',
  title: 'title',
  risk: 'Risk',
  control: 'Control',
  audit: 'Audit',
  issue: 'issue',
  label: 'label',
  riskProgram: 'Risk Program',
  compliance:'Compliance Audit',
  incident:'Incident',
  bcp: 'BCP',
  bcmAudit: 'Bcm Audit',
  workingPaper: 'working_paper',
  complianceKPI: 'Compliance KPI',
  metrics: 'Metrics',
  strategyKPI: 'Strategy KPI',
  strategy: 'Business Strategy',
  asset: 'Asset'
  };
  export const actionsStatusIssueTracker = [
  { label: 'All Actions', data: 0, color: '#4e79a7' },
  { label: 'Overdue', data: 0, color: '#E15759' }
  ];
  export const actionsByTypeIssueTracker = {
  center: 0,
  title: '',
  data: [],
  cardTitle: {
  title: 'Actions by Type',
  class: 'title-card'
  },
  identifier: 'actionsByType'
  };
  export const actionsByStatusIssueTracker = {
  center: 0,
  title: '',
  data: [],
  cardTitle: {
  title: 'Actions by Status',
  class: 'title-card'
  },
  identifier: 'actionsByStatus'
  };
  export const actionsByOwnerIssueTracker = {
  data: [],
  label: [],
  color: [],
  legend: [],
  cardTitle: {
  title: 'Actions by Owner',
  class: 'title-card'
  }
  };
  export const actionsByDueDateIssueTracker = {
  data: [],
  label: [],
  color: [],
  cardTitle: {
  title: 'Actions by Due Date',
  class: 'title-card'
  }
  };
  export const issueTrackerUrls = {
  auditAction: '/audit-management/audit-planing/action/',
  issueTracker: '/dashboards/issue-tracker',
  riskAction: '/risk-management/risk-universe/',
  riskDetails: '/risk-details-action/',
  controlAction: '/risk-management/risk-control/',
  complianceObservationAction: '/compliance-management/compliance-audit/action'
  };
  export const dateFilterIssueTracker = [
  {
  label: '1W',
  type: 'days',
  range: 7
  },
  {
  label: '2W',
  type: 'days',
  range: 14
  },
  {
  label: '1M',
  type: 'months',
  range: 1
  },
  {
  label: '3M',
  type: 'months',
  range: 3
  },
  {
  label: '1Y',
  type: 'year',
  range: 1
  }
  ];
  export const timeSheetFilterIssueTracker = [{
  label: 'All'
  },
  {
  label: 'Week'
  },
  {
  label: 'Month'
  },
  {
  label: 'Quarterly'
  },
  {
  label: 'Year'
  }];
  export const customConstant = {
  jdoc: 'jdoc',
  edit: 'edit'
  };
  export const dataTypeData = { 'type': 'CharField', 'title': 'Text' };
  export const bcmRouteAndTitle = {
  summary: 'Summary',
  impactAssessment: 'Impact Assessment',
  workaround: 'Workaround',
  backupProcedure: 'Backup Procedures',
  disaterRecoverySteps: 'Disaster Recovery Steps',
  assets: 'Assets',
  internalDependencies: 'Internal Dependencies',
  risksControls: 'Risk & Controls',
  dependency: 'Dependencies',
  network: 'Network',
  bcmMedicalSummary: 'bcm-summary',
  bcmMedicalImpactAssesment: 'bcm-impact-assesment',
  bcmMedicalWorkaround: 'bcm-workaround',
  bcmRequirements: 'bcm-requirements',
  bcmInternalDependencies: 'bcm-internal-dependencies',
  bcmRiskAndControls: 'bcm-risk-and-controls',
  bcmDependency: 'bcm-dependency',
  bcmNetwork: 'bcm-network',
  process: 'Process',
  documents: 'Documents',
  documentsRoute: 'bcm-documents',
  request: 'bcm-request',
  bcmRequest: 'Requests',
  bcmApproval: 'bcm-approval',
  approval: 'Approval',
  assessment: 'Assessment',
  bcmAssessment: 'bcm-assessment',
  history: 'History',
  historyRoute: 'history',
  bcmBackup: 'bcm-backup-procedure',
  bcmDisaster:'bcm-disaster-recovery-steps',
  resourceUtilization: 'Resource Utilization',
  bcmResourceUtilization: 'bcm-resource-utilization',
  bcmDisasterRecoveryPlan: 'bcm-disaster-recovery-plan',
  bcmDisasterTitle: 'Disaster Recovery Plan'
  };

  export const bcpRouteAndTitle = {
    summary: 'Summary',
    assessment: 'Assessment',
    action: 'Actions',
    dependencies: 'Dependencies',
    bcpSummary: 'bcp-summary',
    bcpAssessment: 'bcp-assessment',
    bcpDependencies: 'bcp-dependencies',
    bcpAction: 'bcp-action',
    request: 'bcm-request',
    bcmRequest: 'Requests',
    bcmApproval: 'bcm-approval',
    approval: 'Approval'
    };
  export const bcmNetWorkTabChangeTab = [
  {
  icon: 'au-icon au-icon--arrow-bottom',
  class: '',
  label: 'Top to Bottom',
  event: NetworkDiagramDirectionConstants.topBottom
  },
  {
  icon: 'au-icon au-icon--arrow-right',
  class: '',
  label: 'Left to Right',
  event: NetworkDiagramDirectionConstants.leftRight
  }
  ];
  export const bcmNetworkConstant = {
  objectId: 'objectId',
  id: 'id',
  asset: 'asset',
  upstream: 'upstream',
  downstream: 'downstream',
  strategy: 'strategy',
  assetRoute: '/business-continuity-management/asset-management-details/',
  upstreamRoute: '/business-continuity-management/bcm-detail-page/',
  strategyRoute: '/risk-management/business-strategies/strategy-details/',
  strategyManagementRoute: '/strategy/strategy-management/strategies/#object_id#/detailpage/overview',
  bcmSummeryRoute: '/bcm-summary',
  detailRoute: '/details/1/1',
  nullData: '-'
  };
  export const bcmAssetsRouteAndTitle = {
  Dependencies: 'Dependencies',
  dependencies: 'dependencies',
  Overview: 'Overview',
  overview: 'overview',
  network: 'Network',
  bcmDetail: '/business-continuity-management/asset-management-details/',
  detailRoute: '/details/',
  zero: '0/0',
  history: 'History'
  };
  export const strategyDetailsBcm = {
  strategyId: 'Strategy ID',
  strategyTitle: 'Strategy Title',
  category: 'Category',
  dueDate: 'Due Date',
  description: 'Description',
  priority: 'Priority',
  owner: 'Owner',
  entity: 'Entity',
  status: 'Status',
  viewStrategy: 'View Business Strategy',
  createdBy: 'Created by'
  };
  export const processDetailsBcm = {
  processId: 'Process ID',
  processName: 'Process Name',
  status: 'Status',
  department: 'Department',
  description: 'Description',
  locations: 'Locations',
  owner: 'Owner',
  entity: 'Entity',
  reviewer: 'Reviewer',
  approver: 'Approver',
  E1E1E1: 'E1E1E1',
  viewProcess: 'View Business Process'
  };
  export const assetDetailsBcm = {
  assetId: 'Asset ID',
  assetTitle: 'Asset Title',
  assetType: 'Asset Type',
  RTO: 'RTO',
  workaround: 'Does this asset has an alternate process level workaround?',
  singlePoint: 'Is this a single point of failure?',
  owner: 'Owner',
  criticality: 'Criticality',
  viewAsset: 'View Asset'
  };
  export const clientTableConstant = {
  pointer: 'pointer',
  custom: 'custom',
  tableId: 'table_id',
  route: 'route',
  text: 'text',
  title: 'title',
  id: 'id',
  active: 'ACTIVE',
  notActive: 'NOT_ACTIVE'
  };
  export const clientUrls = {
  client: '/controlpanel/admin-panel/client/',
  edit: '/edit',
  createClient: '/controlpanel/admin-panel/client/add'
  };
  export const passwordTypeUrl = {
  userActivate: 'user-activate',
  userReset: 'reset-user-password',
  clientActivate: 'client-activate',
  clientReactivate: 'client-reactivate'
  };

  export const contributorTableConfig = {
  tableLimitOption: [5, 10, 25, 50, 100],
  pager: 10,
  tableLimit: 10,
  offset: 0
  };
  export const autoStatusExcludedApiUrls = [
  ApiEndpoints.Login,
  ApiEndpoints.passwordReset,
  ApiEndpoints.tokenValidation,
  ApiEndpoints.activateClientPassword,
  ApiEndpoints.reactivateClientPassword,
  ApiEndpoints.resetUserPassword,
  ApiEndpoints.activateUserPassword,
  ApiEndpoints.resetTokenValidation,
  ApiEndpoints.suvreyResponseAuth,
  mfaAPI.mfaVerifyToken,
  mfaAPI.emailOtpResend
  ];
  export const contributorChartIdentifier = {
  firstDoughnut: '1',
  secondDoughnut: '2',
  thirdDoughnut: '3'
  };
  export const verticalStackedChartData = [
  {
  name: '  ',
  data: [
  10,
  8,
  20,
  26
  ]
  }
  ];
  export const verticalStackedColor = ['#e9e9e9'];
  export const verticalStackedLabel = [
  '',
  ' ',
  '  ',
  '   '];
  export const incidentConstant = {
  charField: 'CharField',
  textField: 'TextField',
  edit: 'edit',
  view: 'view',
  booleanField: 'BooleanField',
  choiceField: 'ChoiceField',
  decimalField: 'DecimalField',
  dateField: 'DateField',
  integerField: 'IntegerField',
  overview: 'Overview',
  dependencies: 'Dependencies',
  assessments : 'Assessments',
  userDetails: 'userDetails',
  Unassigned: 'Unassigned',
  incidentType: 'incident_type',
  incidentDepartment: 'department_level1',
  incidentActionStatus: 'incident_action_status',
  incidentStatus: 'incident_status',
  incidentRating: 'incident_rating',
  incidentStatusTitle: 'Incident Status',
  incidentTypeTitle: 'Incident Type',
  incidentDepartmentTitle: 'Incident Department',
  incidentPriority: 'Incident Priority',
  rootcauseanalysis: 'Root Cause Analysis',
  checkboxField: 'CheckboxField',
  tableField: 'TableField',
  actions: 'Actions',
  documents: 'Documents',
  history: 'History',
  approval: 'Approval'
  };
  export const assetConstant = {
  charField: 'CharField',
  textField: 'TextField'
  };
  export const policyManagementChartParams = {
  policy_management_rating: '',
  policy_management_type: '',
  policy_management_department: '',
  policy_management_process_status: '',
  policy_management_status: '',
  multi_values: false
  };
  export const complianceManagementChartParams = {
  compliance_action_status_all: '',
  compliance_score: '',
  compliance_status: '',
  compliance_type: '',
  compliance_action_status: '',
  assignee: '',
  multi_values: false
  };
  export const policyCreateTemplateLabel = {
  overview: 'Overview',
  designPolicy: 'Design Policy',
  dependencies: 'Dependencies',
  finish: 'Finish'
  };
  export const policyDependencies = {
  risks: 'risks',
  controls: 'controls',
  compliance: 'compliance',
  process: 'process',
  request:'request',
  addRisk: 'Add Risk',
  addControl: 'Add Control',
  addCompliance: 'Add Compliance',
  addProcess: 'Add Business Process',
  addrequest:'Add Request'
  };
  export const policyAttributes ={
    summary: 'summary',
    policy: 'policy',
    approval: 'approval',
    team: 'team',
    workflow: 'workflow',
    versions: 'version',
    compliance: 'compliance',
    dependencies: 'dependencies',
    documents: 'documents',
    reviews: 'reviews',
    timeline: 'timeline',
    history: 'history',
    publishedPolicy: 'Policy',
    acknowledgement: 'acknowledgement',
    policyOverview: 'Policy Overview',
    acknowledgementReq: 'Acknowledgement Requests'
  }
  export const policyRoutesAndTitle = {
  summary: 'Overview',
  policy: 'Policy',
  team: 'Team',
  versions: 'Versions',
  compliance: 'Compliance',
  dependencies: 'Dependencies',
  documents: 'Documents',
  salDoc: 'Document',
  summaryRoute: 'policy-summary',
  policyRoute: 'policy-view',
  teamRoute: 'policy-team',
  versionsRoute: 'policy-versions',
  complianceRoute: 'policy-compliance',
  dependenciesRoute: 'policy-dependency',
  policyData: 'Policy',
  documentsRoute: 'policy-documents',
  reviews: 'Review',
  reviewsRoute: 'policy-reviews',
  workflow: 'Workflow',
  workflowRoute: 'policy-workflow',
  timeline: 'Timeline',
  timelineRoute: 'policy-timeline',
  request: 'request',
  approval: 'Approval',
  approvalRoute: 'policy-approval',
  attachments: 'Attachments',
  files: 'Files',
  history: 'History',
  historyRoute: 'history',
  designPolicy: 'Design Policy',
  publishedPolicy: 'Policy',
  publishedPolicyRoute: 'published-policy',
  acknowledgement: 'Acknowledgement',
  acknowledgementRoute:'policy-acknowledgement',
  acknowledgementRequestRoute: 'acknowledge-request',
  acknowledgementRequest: 'Acknowledgement Requests',
  policyOverview: 'Policy Overview',
  policyOverviewRoute: 'policy-overview',
  designPolicyDetail: 'policy-detailpage'
  };
  export const complianceRequestRoutesAndTitle = {
  request: 'complianceRequest'
  };
  export const requestRoutesAndTitle = {
    request: 'request_new'
    };
  export const dropdownConfigByValue = {
  displayKey: 'value',
  search: true,
  clearOnSelection: true,
  placeholder: '-',
  height: '150px'
  };
  export const dropdownConfigByName = {
  displayKey: 'name',
  search: true,
  clearOnSelection: true,
  placeholder: '-',
  height: '150px'
  };
  export const auditDeleteConstant = {
  Removed: 'Removed'
  };
  export const commentsConstant = {
  commentText: 'comment_text',
  file: 'file',
  parent: 'parent'
  };
  export enum PolicyDependencyConstants {
  risk = 'Risk',
  control = 'Control',
  add = 'add',
  delete = 'delete',
  addControl = 'Add Control',
  deleteControl = 'Delete Control',
  addRisk = 'Add Risk',
  deleteRisk = 'Delete Risk',
  process = 'Business Process',
  addProcess = 'Add Business Process',
  deleteProcess = 'Delete Business Process',
  deleteCompliance = 'Delete Compliance',
  browse = 'browse',
  deleteUser = 'Delete',
  viewProfile = 'View Profile',
  eventMainIcon = 'mainIcon',
  view = 'view Review',
  compareVersion = 'compareversion',
  compliance = 'Compliance',
  request = 'Request',
  version = 'Version'
  }
  export const policyDependencyHeaderData = [
  {
  value: 'Risk',
  count: 0
  },
  {
  value: 'Control',
  count: 0
  },
  {
  value: 'Business Process',
  count: 0
  }
  ];
  export const policyTeamHead = [
  {
  value: 'Team',
  count: 0,
  status: false,
  name:'team'
  },
  {
  value: 'Owners',
  count: 0,
  status: false,
  name:'owner'
  },
  {
  value: 'Stakeholder',
  count: 0,
  status: false,
  name:'stakeholder'
  },
  {
  value: 'Users',
  count: 0,
  status: false,
  name:'users'
  },
  {
  value: 'Experts',
  count: 0,
  status: false,
  name:'experts'
  },
  {
  value: 'Legal',
  count: 0,
  status: false,
  name:'legal'
  }
  ];

  export const policyVersionHead = [
    {
    value: 'All Versions',
    count: 0,
    status: false,
    name:'all'
    },
    {
    value: 'Published',
    count: 0,
    status: false,
    name:'published'
    },
    {
    value: 'Drafts',
    count: 0,
    status: false,
    name:'draft'
    }
    ];
  export const policyTeamData = {
  team: 'team',
  approver: 'approver',
  reviewer: 'reviewer',
  owner: 'owner',
  users: 'users',
  stakeholder: 'stakeholder',
  Experts: 'expert_panel',
  Legal: 'legal_panel'
  };
  export const policyVersionData = {
    all: 'all',
    published: 'published',
    draft: 'draft'
    };

  export const digtilAuitConstant = {
  recommendation: 'recommendation',
  control: 'control',
  action: 'action'
  };
  export const sliderConstant = {
  from: 'from',
  to: 'to',
  min: 'min',
  max: 'max',
  count: 'count',
  block: 'block',
  right: 'right',
  none: 'none',
  update: 'update',
  percentage: '%'
  };
  export const policyControlPanelQueryParams = {
  type: '',
  offset: 0,
  limit: 0
  };



  export const ganttChartPlaceholderData = [
  {
  'object_id': '72fb924e-70d8-482d-af72-527958d72d3a',
  'stage': 'a3e83e50-1759-4a07-8cb4-4897f3686295',
  'type': 'Start',
  'startTime': '2021-07-09',
  'endTime': '2021-07-10',
  'task': 'Start',
  'color': '#e6e6e6',
  'duration': '2 Days',
  'name': 'Admin Apps',
  'user': []
  },
  {
  'object_id': '72fb924e-70d8-482d-af72-527958d72d3b',
  'stage': 'a3e83e50-1759-4a07-8cb4-4897f3686295',
  'type': 'Start',
  'startTime': '2021-07-09',
  'endTime': '2021-07-11',
  'task': 'Start',
  'color': '#e6e6e6',
  'duration': '3 Days',
  'name': 'Admin Apps',
  'user': []
  },
  {
  'object_id': '72fb924e-70d8-482d-af72-527958d72d3c',
  'stage': 'a3e83e50-1759-4a07-8cb4-4897f3686295',
  'type': 'Start',
  'startTime': '2021-07-09',
  'endTime': '2021-07-12',
  'task': 'Start',
  'color': '#e6e6e6',
  'duration': '4 Days',
  'name': 'Admin Apps',
  'user': []
  },
  {
  'object_id': '72fb924e-70d8-482d-af72-527958d72d3d',
  'stage': 'a3e83e50-1759-4a07-8cb4-4897f3686295',
  'type': 'Start',
  'startTime': '2021-07-09',
  'endTime': '2021-07-13',
  'task': 'Start',
  'color': '#e6e6e6',
  'duration': '5 Days',
  'name': 'Admin Apps',
  'user': []
  },
  {
  'object_id': '72fb924e-70d8-482d-af72-527958d72d3e',
  'stage': 'a3e83e50-1759-4a07-8cb4-4897f3686295',
  'type': 'Start',
  'startTime': '2021-07-09',
  'endTime': '2021-07-14',
  'task': 'Start',
  'color': '#e6e6e6',
  'duration': '6 Days',
  'name': 'Admin Apps',
  'user': []
  }
  ];
  export const riskRegister = {
  delete: 'delete',
  browsePlan: 'browsePlan',
  edit: 'edit',
  totalRegister: 'total_register',
  risk: 'Risks',
  true: 'True',
  false: 'False'
  };
  export const policyControlPanelConstants = {
  majorProcessTitle: 'Major Process',
  majorProcess: 'major_process',
  megaProcessTitle: 'Mega Process',
  megaProcess: 'mega_process',
  policyTypeTitle: 'Policy Type',
  policyType: 'policy_management_type',
  policyStatusTitle: 'Policy Status',
  policyStatus: 'policy_management_status',
  policyRatingTitle: 'Policy Rating',
  policyRating: 'policy_management_rating',
  type: 'Policy type',
  policyMegaProcess: 'Mega process',
  policyMajorProcess: 'Major process',
  status: 'Policy status',
  rating: 'Policy rating',
  slug: 'policy-management',
  create: 'create',
  edit: 'edit',
  policyAreaTitle: 'Policy Area',
  newRequestedBy: 'Requested By',
  policyArea: 'policy_management_area',
  requestedBy: 'policy_management_requested_by',
  area: 'Policy area',
  policyRequestedBy: 'Requested by'
  };
  export enum PolicyConstants {
  designPolicy = 'Design Policy',
  dependencies = 'Dependencies',
  finish = 'Finish',
  overview = 'Overview',
  editPolicy = 'Edit Policy',
  createPolicy = 'Create Policy',
  policyId = 'policyId',
  policyPlaceholder = 'Insert Policy Here ...',
  salDocPlaceholder = 'Insert Document Here ...',
  uploadedBy = 'uploaded_by',
  uploadedOn = 'uploaded_on',
  file = 'file',
  item = 'item',
  section = 'section',
  designpolicy ='design_policy',
  value = 'value',
  name = 'name',
  status = 'status',
  limit = 'limit',
  offset = 'offset',
  removedContent = 'Removed Content',
  newlyAddedContent = 'Newly added Content',
  policiesByDepartment = 'Policies by Department',
  policiesByRating = 'Policies by Rating',
  policiesByStatus = 'Policies by Status',
  policiesByType = 'Policies by Type',
  all = 'all',
  policyManagement = 'policy_management',
  allPolicies = 'All Policies',
  policyParams = 'policy_management_process_status',
  reviewId = 'reviewId',
  policyRequest = 'policy_request',
  live = 'live',
  publish = 'publish',
  review = 'review',
  versionId = 'versionId',
  policyRating = 'policy_management_rating',
  policyType = 'policy_management_type',
  policyDepartment = 'policy_management_department',
  policyStatus = 'policy_management_status',
  drafts = 'Drafts',
  requestFor = 'request_for',
  isDraft = 'is_draft',
  allRequest = 'All Requests',
  false = 'false',
  workflow = 'workflow',
  upload = 'Supported Documents: \'.jpg\', \'.png\', \'.jpeg\'',
  id = 'id',
  commentCreate = 'comment_create',
  commentUpdate = 'comment_update',
  commentDelete = 'comment_delete',
  pendingRequests = 'Pending Requests',
  expert = 'Expert',
  legal = 'Legal',
  stakeholder = 'Stakeholder',
  policyModule = 'Policy',
  document = 'Document'
  }
  export enum BcmConstants {
  draft = 'Draft',
  requestFor = 'request_for',
  isDraft = 'is_draft',
  businessProcess = 'Business Process',
  commentCreate = 'comment_create',
  commentUpdate = 'comment_update',
  commentDelete = 'comment_delete',
  assetType = 'asset_type',
  asseManagement = 'asset-management',
  criticality = 'criticality',
  bcp = 'BCP'
  }
  export enum AssetsConstants {
  draft = 'Draft',
  requestFor = 'request_for',
  isDraft = 'is_draft',
  allAssets = 'All Assets'
  }
  export enum ComplianceConstants {
  designCompliance = 'Design Policy',
  dependencies = 'Dependencies',
  finish = 'Finish',
  overview = 'Overview',
  schedule = 'schedule',
  editCompliance = 'Edit Compliance',
  createCompliance = 'Create Compliance',
  complianceId = 'complianceId',
  compliancePlaceholder = 'Insert Citation Here ...',
  uploadedBy = 'uploaded_by',
  uploadedOn = 'uploaded_on',
  createdBy = 'created_by',
  file = 'file',
  item = 'item',
  value = 'value',
  name = 'name',
  status = 'status',
  limit = 'limit',
  offset = 'offset',
  removedContent = 'Removed Content',
  newlyAddedContent = 'Newly Added Content',
  complianceByDepartment = 'Compliance by Department',
  complianceByRating = 'Compliance by Rating',
  complianceByType = 'Compliance by Type',
  all = 'all',
  complianceManagement = 'compliance_management',
  allCompliance = 'All Compliance',
  complianceParams = 'compliance_status',
  reviewId = 'reviewId',
  complianceRequest = 'compliance_request',
  live = 'live',
  publish = 'publish',
  review = 'review',
  actionByStatus = 'Action by Status',
  complianceByCategory = 'Compliance by Type',
  complianceScore = 'Compliance Score',
  view = 'view',
  id = 'id',
  browseAction = 'Browse',
  edit = 'Edit',
  delete = 'Delete',
  objectId = 'objectId',
  versionId = 'versionId',
  data = 'data',
  compliance = 'compliance',
  complianceDetails = 'complianceDetails',
  result = 'result',
  commentText = 'comment_text',
  parent = 'parent',
  userDetails = 'userDetails',
  commentCreate = 'comment_create',
  commentUpdate = 'comment_update',
  commentDelete = 'comment_delete',
  replies = 'replies',
  addComment = 'Add Comment',
  viewComment = 'View Comment',
  editComment = 'Edit Comment',
  deleteComment = 'Delete Comment',
  downloadDocument = 'Download Document',
  uploadDocument = 'Upload Document',
  tag = 'Tag',
  Assessments = 'Assessments',
  CompliancePerCent = 'Compliance Score',
  Score = 'Score',
  Assessed_by = 'Assessed by',
  Created_date = 'Created Date',
  Modified_date = 'Modified Date',
  Due_date = 'Due Date',
  Assignee = 'Assignee',
  Status = 'Status',
  completed = 'Completed',
  assessment_Id = 'Assessment ID',
  policyId = 'policyId',
  policyRequest = 'policy_request',
  avatar = 'avatar',
  auditId = 'auditId',
  complianceActionDetail = 'compliance_action_detail',
  complianceAction = 'compliance-action',
  assessmentId = 'assessmentId',
  complianceAudit = 'compliance_audit',
  compliance_audit = 'Compliance Audit',
  compliances_audit = 'Compliance',
  assessment_id = 'Assessment ID',
  complianceAuditAction = 'compliance-audit-action',
  compliance_audit_action = 'compliance_audit_action',
  observation_action = 'observation_action',
  draft = 'Draft',
  reviews = 'Reviews',
  requestFor = 'request_for',
  isDraft = 'is_draft',
  request = 'All Requests',
  false = 'false',
  workflow = 'workflow',
  upload = 'Supported Documents: \'.jpg\', \'.png\', \'.jpeg\'',
  complianceObservation = 'complianceObservation',
  complianceObservationField = 'compliance_observation',
  complianceManagementResponse = 'complianceManagementResponse',
  complianceObservationRecommendation = 'complianceObservationRecommendation',
  mangementResponse = 'compliance_management_response',
  observationRecommendation = 'compliance_observation_recommendation',
  complianceRecommendation = 'compliance_recommendation',
  recommendation = 'observation_recommendation',
  observation = 'observation',
  pendingRequests = 'Pending Requests',
  addedComplianceManagementResponse = 'added_compliance_management_response',
  bulkComplianceManagementResponse = 'bulk_compliance_management_response',
  complianceRecommendations = 'complianceRecommendation',
  recurring = 'Recurring',
  addedRiskProgramManagementResponse = 'program_added_management_response',
  editedRiskProgramManagementResponse = 'program_management_response_update',
  complianceIssue = 'complianceIssue',
  complianceObservationActionDocument = 'complianceObservationAction',
  Attended_date = 'Attended Date',
  Overdue = "Overdue",
  DetailPagination = "detail-pagination",
  cursorPagination = 'cursor-pagination',
  cursor = 'cursor',
  calendar = 'Calendar'
  }
  export enum RequestConstants {
    designCompliance = 'Design Policy',
    dependencies = 'Dependencies',
    finish = 'Finish',
    overview = 'Overview',
    editRequest = 'Edit Request',
    createRequest = 'Create Request',
    requestId = 'requestId',
    requestPlaceholder = 'Insert Citation Here ...',
    uploadedBy = 'uploaded_by',
    uploadedOn = 'uploaded_on',
    file = 'file',
    item = 'item',
    value = 'value',
    name = 'name',
    status = 'status',
    limit = 'limit',
    offset = 'offset',
    removedContent = 'Removed Content',
    newlyAddedContent = 'Newly Added Content',
    requestByDepartment = 'Request by Department',
    requestByRating = 'Request by Rating',
    requestByType = 'Request by Type',
    all = 'all',
    allRequest = 'All Request',
    requestParams = 'request_status',
    reviewId = 'reviewId',
    live = 'live',
    publish = 'publish',
    review = 'review',
    actionByStatus = 'Action by Status',
    requestByCategory = 'Request by Type',
    requestScore = 'Request Score',
    view = 'view',
    id = 'id',
    browseAction = 'Browse',
    edit = 'Edit',
    delete = 'Delete',
    objectId = 'objectId',
    versionId = 'versionId',
    data = 'data',
    compliance = 'compliance',
    result = 'result',
    commentText = 'comment_text',
    parent = 'parent',
    userDetails = 'userDetails',
    commentCreate = 'comment_create',
    commentUpdate = 'comment_update',
    commentDelete = 'comment_delete',
    replies = 'replies',
    addComment = 'Add Comment',
    viewComment = 'View Comment',
    editComment = 'Edit Comment',
    deleteComment = 'Delete Comment',
    downloadDocument = 'Download Document',
    uploadDocument = 'Upload Document',
    tag = 'Tag',
    Assessments = 'Assessments',
    RequestPerCent = 'Request Score',
    Assessed_by = 'Assessed by',
    Created_date = 'Created Date',
    Modified_date = 'Modified Date',
    Due_date = 'Due Date',
    Assignee = 'Assignee',
    Status = 'Status',
    completed = 'Completed',
    assessment_Id = 'Assessment ID',
    policyId = 'policyId',
    policyRequest = 'policy_request',
    avatar = 'avatar',
    auditId = 'auditId',
    requesteActionDetail = 'request_action_detail',
    requestAction = 'request-action',
    assessmentId = 'assessmentId',
    assessment_id = 'Assessment ID',
    draft = 'Draft',
    reviews = 'Reviews',
    requestFor = 'request_for',
    isDraft = 'is_draft',
    request = 'All Requests',
    false = 'false',
    workflow = 'workflow',
    upload = 'Supported Documents: \'.jpg\', \'.png\', \'.jpeg\'',
    requestObservation = 'requestObservation',
    requestObservationField = 'request_observation',
    mangementResponse = 'request_management_response',
    recommendation = 'observation_recommendation',
    observation = 'observation',
    pendingRequests = 'Pending Requests',
    recurring = 'Recurring',
    addedRiskProgramManagementResponse = 'program_added_management_response',
    editedRiskProgramManagementResponse = 'program_management_response_update'
    }


  export const allowedPolicyDocuments = ['jpg', 'jpeg', 'png', 'pdf', 'docx', 'doc', 'ppt', 'pptx', 'xls', 'xlsx', 'txt', 'odt', 'csv', 'psv', 'tsv', 'ssv', 'scsv', 'msg'];
  export const allowedComplianceDocuments = ['jpg', 'jpeg', 'png', 'pdf', 'docx', 'doc', 'ppt', 'pptx', 'xls', 'xlsx', 'txt', 'odt', 'csv', 'psv', 'tsv', 'ssv', 'scsv', 'msg'];
  export const allowedDocumentsForWorkflow = ['jpg', 'jpeg', 'png'];
  export enum StrategyChartConstant {
  inherent = 'inherent',
  residual = 'residual'
  }
  export enum CalendarConstant {
  color = '#FCCE56',
  dateRange = 'MMM yy',
  all = 'All',
  format = 'YYYY-MM-DD'
  }
  export const dateFilter = [
  {
  label: 'All'
  },
  {
  label: '1M',
  type: 'months',
  range: 1
  },
  {
  label: '3M',
  type: 'months',
  range: 3
  },
  {
  label: '6M',
  type: 'months',
  range: 6
  },
  {
  label: '1Y',
  type: 'year',
  range: 1
  }
  ];
  export const policyVersionLegends = [
  {
  color: '#c7f0d2',
  label: PolicyConstants.newlyAddedContent
  },
  {
  color: '#f9d7dc',
  label: PolicyConstants.removedContent
  }];
  export const stageList = [
  { audit_stage: 'Planning', team: '' },
  { audit_stage: 'Kick-Off Meeting', team: '' },
  { audit_stage: 'List of Requirements (LOR) Communication', team: '' },
  { audit_stage: 'LOR Response', team: '' },
  { audit_stage: 'Initial Data Analytics', team: '' },
  { audit_stage: 'Preparing Audit Program / RCM', team: '' },
  { audit_stage: 'Sampling Request', team: '' },
  { audit_stage: 'Sampling Response', team: '' },
  { audit_stage: 'Audit Execution', team: '' },
  { audit_stage: 'Observations and Recommedations', team: '' },
  { audit_stage: 'Management Response', team: '' },
  { audit_stage: 'Draft IA Report', team: '' },
  { audit_stage: 'Final IA Report', team: '' }
  ];
  export const stageDefaultList = [
  { audit_stage: 'Planning', team: [] },
  { audit_stage: 'Kick-Off Meeting', team: [] },
  { audit_stage: 'List of Requirements (LOR) Communication', team: [] },
  { audit_stage: 'LOR Response', team: [] },
  { audit_stage: 'Initial Data Analytics', team: [] },
  { audit_stage: 'Preparing Audit Program / RCM', team: [] },
  { audit_stage: 'Sampling Request', team: [] },
  { audit_stage: 'Sampling Response', team: '' },
  { audit_stage: 'Audit Execution', team: '' },
  { audit_stage: 'Observations and Recommedations', team: '' },
  { audit_stage: 'Management Response', team: '' },
  { audit_stage: 'Draft IA Report', team: '' },
  { audit_stage: 'Final IA Report', team: '' }
  ];
  export const stageDefaultListRiskProgram = [
  { risk_program_stage: 'Planning', team: [] },
  { risk_program_stage: 'Kick-Off Meeting', team: [] },
  { risk_program_stage: 'List of Requirements (LOR) Communication', team: [] },
  { risk_program_stage: 'LOR Response', team: [] },
  { risk_program_stage: 'Initial Data Analytics', team: [] },
  { risk_program_stage: 'Preparing Audit Program / RCM', team: [] },
  { risk_program_stage: 'Sampling Request', team: [] },
  { risk_program_stage: 'Sampling Response', team: '' },
  { risk_program_stage: 'Audit Execution', team: '' },
  { risk_program_stage: 'Observations and Recommedations', team: '' },
  { risk_program_stage: 'Management Response', team: '' },
  { risk_program_stage: 'Draft IA Report', team: '' },
  { risk_program_stage: 'Final IA Report', team: '' }
  ];
  export const stages = {
  dotted: 'dotted'
  };
  export enum RequireReviewer {
  yes = 'yes',
  no = 'no'
  }
  export enum WorkingPaperConstants {
  testing_conclusion = 'testing_conclusion',
  audits = 'audits',
  reviewer_comment = 'reviewer_comment',
  conclusion = 'conclusion',
  testing_description = 'testing_description',
  sample_selection_methodology = 'sample_selection_methodology',
  population_year = 'population_year',
  sample_selected_review = 'sample_selected_review',
  sample_selected_year = 'sample_selected_year',
  number_of_exceptions = 'number_of_exceptions',
  title = 'title',
  description = 'description_remarks',
  programId = 'program_id',
  testing_status='Testing Status Editor',
  complianceAuditId = 'compliance_audits',
  test_of_control = 'test_of_control',
  test_of_design = 'test_of_design',
  control = 'control',
  risk ='risk'
  }
  export const permissionConstant = {
  unchecked: 'unchecked',
  User: 'User',
  Group: 'Group',
  user: 'user',
  checked: 'checked',
  crossed: 'crossed',
  group: 'group',
  allUsers: 'All Users',
  allUser: 'allUser',
  users: 'allusers',
  view: 'View',
  admin: 'Admin',
  viewAny: 'View Any',
  viewOwn: 'View Own',
  Status: 'Status',
  Edit: 'Edit',
  viewDocument: 'View Document',
  uploadDocument: 'Upload Document',
  downloadDocument: 'Download Document',
  viewDependency: 'View Dependency',
  tagDependency: 'Tag Dependency',
  viewRecord: 'View Record'
  };
  export enum IncidentConstants {
  uploadedBy = 'uploaded_by',
  uploadedOn = 'uploaded_on',
  item = 'item',
  file = 'file',
  risk = 'risk',
  control = 'control',
  audit = 'audit',
  client_name_url = 'client_name_url',
  incident = 'incident'
  }
  export const policyUploadableDocumentFormats = ['docx', 'odt', 'pdf', 'txt'];
  export const ackDocumentFormats = ['pdf', 'docx', 'ppt', 'pptx', 'xls', 'xlsx', 'tsv', 'csv', 'psv', 'odt', 'scsv', 'ssv', 'txt', 'mp4', 'avi', 'mkv', 'mov', 'wmv', 'web', 'flv', 'jpeg', 'jpg', 'png', 'vsd', 'vsdx', 'msg']
  export const quillTextEditorConstants = {
  placeholderText: 'Insert Text Here ...',
  themeSnow: 'snow'
  };
  export const strategyPlanQueryParam = {
  business_strategy_by_status: '',
  multi_values: false
  };
  export const strategyPlanDetailQueryParam = {
  strategy_category: '',
  order_by_field: '',
  table_filters: ''
  };
  export const queryParamForDashboardSquareCards = {
  business_strategy_by_status: 'business_strategy_by_status',
  strategy_category: 'strategy_category'
  };
  export const strategyPlanDoughnutRender = {
  total_strategy: 'total_strategy',
  Strategy: 'Strategy'
  };
  export enum PolicyRequestsConstants {
  requestsByPriority = 'Requests By Priority',
  requestsByStatus = 'Requests By Status',
  requestsByAssignee = 'Requests By Assignee',
  requestsByStakeholders = 'Requests By Stakeholders',
  requestsByType = 'Requests By Type',
  requestsByDepartment = 'Requests By Department',
  departmentRequestedBy = 'Department Requested By',
  requestProcessStatus = 'policy_request_process_status',
  requestPriority = 'policy_request_priority',
  requestStatus = 'policy_request_status',
  requestType = 'policy_request_type',
  requestDepartmentRequestedBy = 'policy_request_department_requested_by',
  all = 'all',
  policyManagement = 'policy_management',
  allPolicies = 'All Policies',
  policyParams = 'policy_management_process_status',
  reviewId = 'reviewId',
  policyRequest = 'policy_request',
  file = 'file',
  item = 'item',
  value = 'value',
  name = 'name',
  status = 'status',
  limit = 'limit',
  offset = 'offset'
  }
  export const policyRequestsChartParams = {
  policy_request_process_status: '',
  policy_request_priority: '',
  policy_request_type: '',
  policy_request_department_requested_by: '',
  policy_request_status_policy_request: '',
  multi_values: false
  };
  export const policyRequestData = {
  request_title: '',
  request_type: '',
  requested_by: '',
  department_requested_by: '',
  source: '',
  entity: [],
  priority: '',
  to_be_written_by: '',
  oversight: [],
  previous_versions: [],
  documents: [],
  assignee: [],
  stakeholder: '',
  request_status: '',
  created_by: '',
  requested_date: '',
  policy_request_type: '',
  policy_request_data_set: [{
  'field_data': {}
  }]
  };
  export const licenseConstants = {
  boolean: 'boolean'
  };
  export const assessmentTakeRequestFor = {
  kri: 'kri',
  riskUniverse: 'risk_assessment',
  control: 'risk_assessment_v1_details',
  auditPlanning: 'audit_planning',
  compliance: 'compliance',
  observation: 'observation',
  auditAnalytics: 'audit_analytics',
  complianceAudit: 'compliance_audit',
  riskProgram: 'risk_program',
  incident: 'incident_reporting',
  bcp: 'bcp',
  bcm: 'business_process',
  auditSchedule: 'auditSchedule',
  auditObservation: 'auditObservations',
  riskObservation: 'riskProgramObservations',
  complianceSchedule: 'complianceSchedule',
  complianceObservation: 'complianceObservation',
  riskApetite: 'risk_apetite',
  complianceKpi: 'compliance-kpi'
  };
  export const assessmentTakeFormDataConstants = {
  requestFor: 'request_for',
  objectId: 'object_id',
  submit: 'submit'
  };
  export const approvalStatusTypes = {
  done: 'done',
  pending: 'pending',
  rejected: 'rejected',
  Inprogress: 'in progress'
  };
  export const policyRequestControlPanelConstants = {
  categoryTitle: 'Category',
  field: 'policy_request_category',
  slug: 'policy-request',
  policyRequestForm: ' Policy Request Forms',
  policyRequestType: 'Category',
  requestTypeTitle: 'Request Type',
  requestType: 'Request type',
  newRequestedBy: 'Requested by',
  priority: 'Priority',
  requestStatus: 'Request status',
  status: 'policy_request_status',
  requestPriority: 'policy_request_priority',
  requestedBy: 'policy_request_requested_by',
  type: 'policy_request_type',
  category: 'Request Category',
  requestBy: 'Requested By',
  categoryData: 'Category'
  };
  export const approvalRequestTypes = {
  delete: 'delete',
  withdrawal: 'withdrawal'
  };
  export const columnSecurityConstant = {
  column: 'column',
  user: 'user',
  usergroups: 'usergroups'
  };
  export enum PolicymangementWidgets {
  slug = 'slug',
  field = 'field',
  order = 'order',
  weightage = 'weightage',
  color = 'color',
  value = 'value',
  parent = 'parent',
  is_default = 'is_default',
  is_overdue = 'is_overdue'
  }
  export const dropdownConstant = {
  color: '#E9F0F7',
  auditArea: 'audit_areas',
  areasAudit: 'areas_audit'
  };
  export enum AuditTableConstant {
  audit = 'Audit',
  activity = 'Activity',
  record = 'digitalreport',
  create = 'create',
  universe = 'Audit Universe',
  activityAction = 'Activity Action',
  issueAction = 'Issue Action',
  observationRecommendation = 'Observation Recommendation',
  ObservationAction = 'Observation Action',
  issue = 'issue',
  observation = 'Observation'
  }
  export enum PolicyReviewData {
  reviewer_type = 'reviewer_type',
  comment_text = 'comment_text'
  }
  export const policyCreateRequestConstants = {
  charField: 'CharField',
  textField: 'TextField',
  edit: 'edit',
  view: 'view'
  };
  export const policyRequestConstants = {
  charField: 'CharField',
  textField: 'TextField',
  commentText: 'comment_text',
  parent: 'parent',
  file: 'file',
  id: 'id',
  userDetails: 'userDetails',
  replies: 'replies',
  requestId: 'requestId',
  tabId: 'tabId',
  undefined: 'undefined',
  fieldData: 'field_data',
  booleanField: 'BooleanField',
  choiceField: 'ChoiceField',
  decimalField: 'DecimalField',
  dateField: 'DateField',
  integerField: 'IntegerField',
  open: 'Open',
  rejected: 'Rejected',
  approved: 'Approved',
  underReview: 'Under Review',
  edit: 'edit',
  view: 'view',
  show: 'show',
  label: 'label',
  ar: 'ar',
  en: 'en-us'
  };
  export const renderType = {
  downloadRender: 'Download Render',
  date: 'Date',
  name: 'Name',
  dotName: 'dotName',
  iconName: 'iconName',
  class: 'Class',
  multipleRender: 'Multiple Render',
  icon: 'Icon',
  countText: 'Count Text',
  countTextLink: 'Count Text Link',
  description: 'Description',
  multipleNameRender: 'Multiple Name Render',
  noView: 'noView',
  longText: 'Long Text',
  shortNumber: 'shortNumber',
  fullText: 'fullText',
  auSelect: 'AuSelect',
  approval: 'approval',
  richText: 'richText',
  status: 'status',
  badge: 'badge',
  multiBadge: 'multiBadge',
  checkBox: 'checkBox',
  html:'html',
  tableBadge: 'tableBadge',
  multiIconCard: 'multiIconCard',
  axLongText: 'longText',
  progressline: 'progressline',
  idClickable: 'idClickable',
  severityLabel: 'severityLabel',
  nameLoader: 'nameLoader',
  inputField: 'inputField',
  selectField: 'selectField',
  progressbar: 'progressbar',
  actionEdit: 'actionEdit',
  input: 'input',
  uploadDoc: 'uploadDoc',
  valueWithBadge: 'valueWithBadge',
  upAndDownStatus: 'upAndDownStatus',
  nameShortText: 'nameShortText',
  time: 'time',
  textareaField: 'textareaField',
  scoringBadge: 'scoringBadge',
  multiIcon: 'multiIcon',
  valueWithcount: 'valueWithcount',
  dot: 'dot',
  momAction:'momAction',
  dropdown: 'dropdown',
  nameWithActions: 'nameWithActions',
  verticalBadge: 'verticalBadge',
  subText: 'subText',
  arxStatusBadge: 'arxStatusBadge',
  arxBadge: 'arxBadge',
  multiGroupIcon : 'multiGroupIcon',
  tableMultiBadge: 'tableMultiBadge',
  axBadge: 'axBadge',
  toggleButtons: 'toggleButtons'
  };
  export const clientNames = {
    ajm: 'ajm',
    alhabtoorAccess: 'alhabtoorAccess',
    boodaiAccess: 'boodaiAccess',
    galadariAccess: 'galadariAccess',
    urbanretalAccess: 'urbanretalAccess',
    akiAccess:'aki',
    amlakAccess: 'amlak',
    salAccess:'sal',
    sal:'sal',
    tawazun: 'tawazun',
    iyelo: 'iyelo',
    preprod: 'pre-prod',
    ddaAccess: 'dda',
    tabreed: 'tabreed',
    ku: 'ku',
    miahona: 'miahona',
    gacStage: 'gac stage',
    gacProd: 'gac prod',
    saudiacargo: 'saudiacargo'
  }


  export const multiApprovalClients = [
    clientNames?.salAccess, clientNames?.amlakAccess, clientNames?.ajm, clientNames?.iyelo
  ];

  export const doughnutConstant = {
  risks: 'Risks',
  pending: 'pending',
  active: 'active',
  multipleRender: 'Multiple Render',
  color: '#e1e1e1',
  risksByResidualImpact: 'Risks By Residual Impact',
  businessStrategyByStatus: 'Business Strategy by Status',
  audit: 'Audits',
  riskPrograms: 'Risk Programs',
  complianceAudit: environment.clientName === clientNames.salAccess ? complianceTitles?.sal : complianceTitles?.other,
};
export const analyticsPath = {
  auditAnalytics: 'audit-analytics',
  detailPage: 'detailPage',
  dataSource: 'data-source',
  dataSourceView: 'data-source-view',
  dataSourceViewPage: 'data-source-view-page',
  processTab: 'process-tab',
  scenarioList: 'scenario-list',
  createAnalyticsScenario: 'create-analytics-scenario',
  publishScenario: 'publish-scenario',
  connectedDataSource: 'connected-data-source',
  schedules: 'schedules',
  jobs: 'jobs',
  workFlow: 'work-flow',
  intelligentData: 'intelligent-data',
  connectToTextData: 'connect-to-text-data',
  connection: 'connection',
  selectTable: 'select-table',
  analyticsDynamicFieldList: 'analytics-dynamic-field-list',
  dataRefreshHistory: 'data-refresh-history',
  mapData: 'map-data',
  validateData: 'validate-data',
  update: 'update',
  kri: 'kri',
  reportBuilder: 'Report Builder',
  userDashboards: 'user-dashboards'
  };
  export const bcmPath = {
  businessContinuityManagement: 'business-continuity-management',
  bcmDetailPage: 'bcm-detail-page',
  createBusinessProcess: 'create-business-process',
  createAssetManagement: 'create-asset-management',
  assetManagementDetails: 'asset-management-details'
  };
  export const compliancePath = {
  complianceRequests: 'compliance-requests',
  requests: 'requests',
  editRequest: 'edit-request',
  createRequest: 'create-request',
  complianceAudit: 'compliance-audit',
  createCompliance: 'create-compliance',
  editCompliance: 'edit-compliance',
  compliancePlan: 'compliance-audit-plan',
  complianceAuditOverview: 'compliance-audit-overview'
};

  export const bcmPermission = {
  add: 'Add',
  addComment: 'Add Comment',
  admin: 'Admin',
  approval: 'Approval',
  delete: 'Delete',
  deleteComment: 'Delete Comment',
  deleteDocument: 'Delete Document',
  download: 'Download',
  downloadDocument: 'Download Document',
  edit: 'Edit',
  editComment: 'Edit Comment',
  editDocument: 'Edit Document',
  export: 'Export',
  import: 'Import',
  tag: 'Tag',
  upload: 'Upload',
  uploadDocument: 'Upload Document',
  view: 'View',
  viewAny: 'View Any',
  viewOwn: 'View Own',
  ViewComment: 'View Comment'
  };
  export const compliancePermission = {
  add: 'Add',
  addComment: 'Add Comment',
  admin: 'Admin',
  approval: 'Approval',
  delete: 'Delete',
  deleteComment: 'Delete Comment',
  deleteDocument: 'Delete Document',
  download: 'Download',
  downloadDocument: 'Download Document',
  edit: 'Edit',
  editComment: 'Edit Comment',
  editDocument: 'Edit Document',
  export: 'Export',
  import: 'Import',
  tag: 'Tag',
  upload: 'Upload',
  uploadDocument: 'Upload Document',
  view: 'View',
  ViewComment: 'View Comment'
  };
  export const incidentPermission = {
    add: 'Add',
    addComment: 'Add Comment',
    admin: 'Admin',
    approval: 'Approval',
    delete: 'Delete',
    deleteComment: 'Delete Comment',
    deleteDocument: 'Delete Document',
    download: 'Download',
    downloadDocument: 'Download Document',
    edit: 'Edit',
    editComment: 'Edit Comment',
    editDocument: 'Edit Document',
    export: 'Export',
    import: 'Import',
    tag: 'Tag',
    upload: 'Upload',
    uploadDocument: 'Upload Document',
    view: 'View',
    ViewComment: 'View Comment'
    };

    export const requestPermission = {
      add: 'Add',
      addComment: 'Add Comment',
      admin: 'Admin',
      approval: 'Approval',
      delete: 'Delete',
      deleteComment: 'Delete Comment',
      deleteDocument: 'Delete Document',
      download: 'Download',
      downloadDocument: 'Download Document',
      edit: 'Edit',
      editComment: 'Edit Comment',
      editDocument: 'Edit Document',
      export: 'Export',
      import: 'Import',
      tag: 'Tag',
      upload: 'Upload',
      uploadDocument: 'Upload Document',
      view: 'View',
      ViewComment: 'View Comment'
      };
  export const list = {
  risk: 'risk-assessment',
  control: 'risk-assessment-v1-details',
  audit: 'audit-planning',
  riskName: 'risk_name',
  controlName: 'control_name',
  auditName: 'audit_name',
  policy: 'policy-management',
  policyName: 'policy_name',
  compliance: 'compliance-audit',
  complianceName: 'compliance_title',
  bcm: 'business-process',
  processName: 'process_title',
  riskData: 'Risk',
  controlData: 'Control',
  auditData: 'Audit',
  policyData: 'Policy',
  complianceData: 'Compliance',
  process: 'Business Continuity',
  adminData: 'Admin',
  riskProgram: 'Risk Program',
  riskProgramName: 'risk-program',
  programName: 'risk_program_name',
  admin: 'timelog-project',
  adminName: 'project',
  adminproject:'Admin',
  bcmAudit: 'bcm-audit',
  bcmAuditField: 'bcm_audit_name',
  bcmAuditName:'BCM Audit',
  };

  export const projectTypeConstants = {
    Risk: {
      url: 'risk-assessment',
      label: 'risk_name',
      type: 'Risk'
    },
    Control: {
      url: 'risk-assessment-v1-details',
      label: 'control_name',
      type: 'Control'
    },
    Compliance: {
      url: 'compliance-audit',
      label: 'compliance_title',
      type:'Compliance'
    },
    Policy: {
      url: 'policy-management',
      label: 'policy_name',
      type:'Policy'
    },
    Admin: {
      url: 'timelog-project',
      label: 'project',
      type: 'Admin'
    },
    Audit: {
      url: 'audit-planning',
      label: 'audit_name',
      type: 'Audit'
    },
    'Risk Program': {
      url: 'risk-program',
      label: 'risk_program_name',
      type: 'Risk Program'
    },
    'BCM Audit': {
      url: 'bcm-audit',
      label: 'bcm_audit_name',
      type: 'BCM Audit'
    },
    'Business Continuity': {
      url: 'business-process',
      label: 'process_title',
      type: 'Business Continuity'
    }
  };

  export const inherentChartConstant = {
  inherentImpact: 'Inherent Impact',
  inherentLikelihood: 'Inherent Likelihood',
  inherentRating: 'Inherent Rating'
  };
  export const residualChartConstant = {
  residualImpact: 'Residual Impact',
  residualLikelihood: 'Residual Likelihood',
  riskResidualRating: 'Risk Residual Rating'
  };
  export const documentWidgetFormConstants = {
  description: 'description',
  title: 'title',
  file: 'file',
  data: 'data',
  auditWorkflow: 'workflow'
  };
  export const assessmentWidgetEvents = {
  deleteAssessment: 'deleteAssessment',
  takeAssessment: 'Take Assessment',
  viewDetails: 'View Details',
  risk: 'risk',
  edit: 'Edit',
  viewTemplate: 'viewTemplate'
  };
  export enum QuillEditorTooltips {
  upload = 'Supported Documents : \'.pdf\', \'.txt\', \'.docx\''
  }
  export enum PolicyEditorTooltips {
  upload = 'Supported Documents : \'.pdf\', \'.txt\', \'.docx\''
  }
  export const riskActionWidgetFormConstants = {
  control_tag: 'control_tag',
  control_id: 'control_id',
  control_rating: 'control_rating',
  risk: 'risk'
  };
  export const incidentWidgetFormConstants = {
  incident_id: 'incident_id',
  incident_name: 'control_name',
  incident_rating: 'incident_rating',
  risk: 'risk',
  object_id: 'object_id'
  };
  export const analyticsRiskControlAddCases = {
  aIExceptionScore: 'AI Exception Score',
  newExceptions: 'New Exceptions',
  exceptionConfirmation: 'Exception Confirmation'
  };
  export const riskControlWidgetMoreOptionConstant = {
  browseControl: 'browseControl',
  editControl: 'editControl',
  removeControl: 'removeControl'
  };
  export const IncidentWidgetMoreOptionConstant = {
  browseIncident: 'browseIncident',
  editIncident: 'editIncident',
  removeIncident: 'removeIncident'
  };
  export const analyticsRiskControlEditCases = {
  possible_exception_rating: 'possible_exception_rating',
  score_exception_rating: 'score_exception_rating',
  confirmation_rating: 'confirmation_rating'
  };
  export const auditProgramSidebarConstant = {
  downloadDocument: 'downloadDocument',
  is_observation: 'is_observation',
  file: 'file',
  business_user: 'business_user',
  title: 'title',
  description: 'description',
  discussion: 'discussion',
  cause: 'cause',
  impact: 'impact',
  addIssue: 'addIssue',
  viewIssue: 'viewIssue',
  risk: 'risk',
  control: 'control',
  areas_audit: 'areas_audit',
  stakeholder: 'stakeholder',
  rating: 'rating',
  status: 'status',
  issueBusinessUser: 'issue_business_user',
  auditorComment: 'auditor_comment',
  extraInformation: 'extra_information',
  issueTextV1: 'issue_text_v1',
  issueTextV2: 'issue_text_v2',
  moreInformation: 'more_information',
  issueTextareaV1: 'issue_textarea_v1',
  issueTextareaV2: 'issue_textarea_v2',
  due_date: 'due_date'
  };
  export const riskDetailsConstant = {
  kri: 'kri',
  'risk-appetite': 'risk-appetite',
  riskManagement: 'Risk Management',
  strategyOverview: 'Strategy Overview',
  observationrecommendation: 'observationrecommendation',
  issue: 'issue',
  observation: 'observation',
  auditActivity: 'auditprogramactivity',
  audit : 'auditplan&request_for_auditplandetail=auditplandetail',
  riskControl: 'Risk Control',
  ERMUniverse: 'ERM Risk Management',
  managementresponse: 'managementresponse',
  auditUniverse: 'auditplanmaster',
  compliance: 'Compliance Request',
  incident: 'Incident',
  observationaction: 'observationaction',
  entity: 'Entity',
  function: 'Function',
  department: 'Department',
  section: 'Section',
  policy: 'policy',
  policyRequest: 'policy-request',
  riskProgram: 'riskprogram&request_for_auditplandetail=riskprogramdetail',
  riskProgramManagementResponse: 'risk_program_managementresponse',
  riskProgramRecommendation: 'riskobservationrecommendation',
  riskObservation: 'risk_program_observation',
  riskProgramActivty: 'risk_program_activity',
  bcm: 'Bcm',
  improvement: 'improvement',
  complianceManagement : 'Compliance Management',
  request: 'Request',
  bcp: 'BCP',
  analyticsMr:'analyticsMr',
  bcmAudit: 'bcm_audit',
  timeManagement: 'Time Management',
  esgMetrics:'esg-metrics',
  metrics: 'metrics',
  complianceKpi: 'compliance-kpi',
  analytics: 'analytics'
  };
  export const riskGridConstant = {
  import: 'import',
  export: 'export'
  };
  export const documentAddWidgetMoreOptionConstant = {
  deleteDocument: 'deleteDocument',
  editDocument: 'editDocument',
  downloadDocument: 'downloadDocument'
  };
  export const riskScoringModalConstant = {
  impact: 'Inherent impact',
  likelihood: 'Inherent likelihood',
  residual_rating: 'Residual rating',
  inherent_rating: 'Inherent rating',
  risk_category: 'Risk category'
  };
  export const incidentControlPanelConstants = {
  create: 'create',
  edit: 'edit',
  cancel: 'cancel',
  slug: 'incident-reporting',
  incidentType: 'incident_type',
  status: 'incident_status',
  incidentStatusTitle: 'Incident Status',
  rating: 'incident_rating',
  ratingTitle: 'Incident Rating',
  reportingForm: 'Incident Reporting Forms',
  incidentRating: 'Incident rating',
  incidentTypes: 'Incident type',
  incidentStatus: 'Incident status',
  typeTitle: 'Incident Type',
  delete: 'delete'
  };
  export const quillEditorTooltips = {
  upload: 'Supported Documents : \'.pdf\', \'.txt\', \'.docx\'',
  font: 'Select a font',
  size: 'Select a font size',
  header: 'Select the text style',
  bold: 'Bold',
  italic: 'Italic',
  underline: 'Underline',
  strike: 'Strikethrough',
  color: 'Select a text color',
  background: 'Select a background color',
  script: {
  sub: 'Subscript',
  super: 'Superscript'
  },
  list: {
  ordered: 'Numbered list',
  bullet: 'Bulleted list'
  },
  indent: {
  '-1': 'Decrease indent',
  '+1': 'Increase indent'
  },
  direction: {
  'rtl': 'Text direction (right to left)',
  'ltr': 'Text direction (left to right)'
  },
  align: 'Text alignment',
  link: 'Insert a link',
  undo: 'Undo Changes',
  redo: 'Redo Changes',
  blockquote: 'Add a quote'
  };
  export const policyEditorTooltips = {
  upload: 'Supported Documents : \'.pdf\', \'.txt\', \'.docx\'',
  font: 'Select a font',
  size: 'Select a font size',
  header: 'Select the text style',
  bold: 'Bold',
  italic: 'Italic',
  underline: 'Underline',
  strike: 'Strikethrough',
  color: 'Select a text color',
  background: 'Select a background color',
  script: {
  sub: 'Subscript',
  super: 'Superscript'
  },
  list: {
  ordered: 'Numbered list',
  bullet: 'Bulleted list'
  },
  indent: {
  '-1': 'Decrease indent',
  '+1': 'Increase indent'
  },
  direction: {
  'rtl': 'Text direction (right to left)',
  'ltr': 'Text direction (left to right)'
  },
  align: 'Text alignment',
  link: 'Insert a link',
  undo: 'Undo Changes',
  redo: 'Redo Changes',
  blockquote: 'Add a quote'
  };
  export const auditCreateDependencyConstants = {
  risks: 'risks',
  controls: 'controls',
  assessments: 'assessments'
  };
  export const auditAddWidgetConstants = {
  risk: 'risk',
  control: 'control',
  browseObservation: 'browseObservation',
  removeObservation: 'removeObservation'
};

export const incidentAddWidgetConstants = {
  IncidentRisk: 'IncidentRisk',
  IncidentERM: 'IncidentERM',
  browseObservation: 'browseObservation',
  removeObservation: 'removeObservation',
  incidentControl: 'IncidentControl'
};

export const requestAddWidgetConstants = {
  browseRequest: 'browseRequest',
  removeRequest: 'removeRequest',

};


export const taggedRiskControlConstants = {
  browseRisk: 'browseRisk',
  editRisk: 'editRisk',
  removeRisk: 'removeRisk'
  };
  export const policyColorCodes = {
  defaultBadgeColor: '#e1e1e1',
  defaultFontColorBlack: '#000000',
  defaultFontColorWhite: '#fff',
  noBadgeColor: '#010101'
  };
  export const complianceCreateTemplateLabel = {
  overview: 'Overview',
  schedule : 'schedule',
  designPolicy: 'Design Policy',
  dependencies: 'Dependencies',
  finish: 'Finish'
  };
  export enum ComplianceDependencyConstants {
  risk = 'Risk',
  control = 'Control',
  add = 'add',
  delete = 'delete',
  addControl = 'Add Control',
  deleteControl = 'Delete Control',
  addRisk = 'Add Risk',
  deleteRisk = 'Delete Risk',
  process = 'Business Process',
  addProcess = 'Add Business Process',
  deleteProcess = 'Delete Business Process',
  deleteCompliance = 'Delete Compliance',
  browse = 'browse',
  deleteUser = 'Delete',
  viewProfile = 'View Profile',
  eventMainIcon = 'mainIcon',
  view = 'view Review',
  viewReview = 'View Review',
  id = 'id',
  policy = 'Policy',
  editObservation = 'editObservation',
  browseObservation = 'browseObservation'
  }
  export const complianceDependencies = {
  risks: 'risks',
  controls: 'controls',
  policy: 'policy',
  process: 'process',
  request:'request',
  addRisk: 'Add Risk',
  addControl: 'Add Control',
  addPolicy: 'Add Policy',
  addProcess: 'Add Business Process',
  addrequest: 'Add Request'
  };
  export const complianceAuditQueryParams = {
  limit: tableConfig.pager,
  offset: 0,
  q: '',
  compliance_type: '',
  compliance_status: '',
  compliance_action_status: '',
  compliance_action_status_all: '',
  compliance_major_process: '',
  compliance_mega_process: '',
  compliance_department_l1: '',
  assignee: '',
  compliance_score: '',
  multi_values: false,
  cursor:'',
  count:true
  };
  export const bcmDropdownNameV1Config = {
  displayKey: 'name_v1',
  search: true,
  clearOnSelection: true,
  placeholder: '-',
  height: '150px'
  };
  export const bcmDependencyHeaderData = [
  {
  value: 'Risks'
  },
  {
  value: 'Controls'
  },
  {
  value: 'Incident'
  },
  {
  value: 'Policy'
  },
  {
    value: 'Requests'
    },
    {
      value: 'Actions'
      }
  ];
  export enum AuBcmDependencyConstants {
  risks = 'Risks',
  controls = 'Controls',
  incident = 'Incident',
  policy = 'Policy',
  add = 'add',
  delete = 'delete',
  addControl = 'Add Control',
  deleteControl = 'Delete Control',
  addRisk = 'Add Risk',
  deleteRisk = 'Delete Risk',
  upstream = 'Upstream Dependencies',
  downstream = 'Downstream Dependencies',
  eventMainIcon = 'mainIcon',
  requests = 'Requests',
  backupProcedures = 'Backup Procedures',
  disasterRecoverySteps = 'Disaster Recovery Steps',
  actions = 'Actions'
  }
  export const bcmInternalDependencyHeaderData = [
  {
  value: 'Upstream Dependencies'
  },
  {
  value: 'Downstream Dependencies'
  },
  ];
  export const assetDependencyHeaderData = [
  {
  value: 'Process'
  }
  ];
  export enum AssetDependencyConstants {
  process = 'Process'
  }
  export const assetConstants = {
  charField: 'CharField',
  textField: 'TextField',
  booleanField: 'BooleanField',
  choiceField: 'ChoiceField',
  decimalField: 'DecimalField',
  dateField: 'DateField',
  integerField: 'IntegerField',
  commentText: 'comment_text',
  parent: 'parent',
  file: 'file',
  id: 'id',
  userDetails: 'userDetails',
  replies: 'replies',
  undefined: 'undefined',
  fieldData: 'field_data'
  };
  export const auAdminRiskUniverseWidgetConstants = {
  create: 'create',
  cancel: 'cancel',
  defaultColorPickerColor: '#E9F0F7'
  };
  export const complianceDependencyHeaderData = [
  {
  value: 'Risk',
  count: 0
  },
  {
  value: 'Control',
  count: 0
  },
  {
  value: 'Business Process',
  count: 0
  },
  {
  value: 'Policy',
  count: 0
  }
  ];
  export const kriDependencyHeaderData = [
    {
    value: 'Risk',
    count: 0
    },
    {
    value: 'Control',
    count: 0
    },
    {
      value: 'Business Process',
      count: 0
      },
      {
        value: 'Assessments',
        count: 0
        }
    ];
  export enum ComplianceAuditConstants {
  designPolicy = 'Design Policy',
  dependencies = 'Dependencies',
  finish = 'Finish',
  overview = 'Overview',
  editPolicy = 'Edit Policy',
  createPolicy = 'Create Policy',
  policyId = 'policyId',
  placeholder = 'Insert Policy Here ...',
  uploadedBy = 'uploaded_by',
  uploadedOn = 'uploaded_on',
  file = 'file',
  item = 'item',
  value = 'value',
  name = 'name',
  status = 'status',
  limit = 'limit',
  offset = 'offset',
  removedContent = 'Removed Content',
  newlyAddedContent = 'Newly Added Content',
  policiesByDepartment = 'Policies by Department',
  policiesByRating = 'Policies by Rating',
  policiesByType = 'Policies by Type',
  all = 'all',
  policyManagement = 'policy_management',
  allPolicies = 'All Policies',
  policyParams = 'policy_management_process_status',
  reviewId = 'reviewId',
  policyRequest = 'policy_request',
  live = 'live',
  publish = 'publish',
  review = 'review',
  versionId = 'versionId',
  id = 'id'
  }
  export enum LanguageConstants {
  arabic = 'ar',
  english = 'en-us',
  owner = 'Activities by Owners',
  status = 'Activities by Status',
  actionStatus = 'Action by Status',
  actionPriority = 'Action by Priority',
  actionDepartment = 'Action by Department',
  observationStatus = 'Observations by Status',
  response = 'Management Responses',
  audit = 'Audits by Status',
  department = 'Observations by Department',
  process = 'Observations by Mega Process',
  stakeholder = 'by Stakeholder',
  observationType = 'Observation by Type',
  issueStatus = 'Issues by Status',
  issueRating = 'Issues by Rating',
  issueOwner = 'Issues by Owners',
  riskProgramByStatus = 'Risk Program By Status',
  observationRating = 'Observations By Rating',
  actionsStatus= 'Actions By Status',
  control_rating= 'Controls by Rating',
  managementResponsesByStatus= 'Management Responses by Status',
  issuesByStatus= 'Issues by Status',
  observationsByStatus= 'Observations by Status'
  }
  export enum ComplianceAuditReviewData {
  reviewer_type = 'reviewer_type',
  comment_text = 'comment_text'
  }
  export const complianceAuditVersionLegends = [
  {
  color: '#c7f0d2',
  label: PolicyConstants.newlyAddedContent
  },
  {
  color: '#f9d7dc',
  label: PolicyConstants.removedContent
  }];
  export const complianceTeamHead = [
  {
  value: 'Team',
  count: 0,
  status: false
  },
  {
  value: 'Owners',
  count: 0,
  status: false
  },
  {
  value: 'Stakeholders',
  count: 0,
  status: false
  },
  {
  value: 'Users',
  count: 0,
  status: false
  },
  {
    value: 'Escalation Points',
    count: 0,
    status: false
  }
  ];
  export const complianceTeamData = {
  team: 'team',
  approver: 'approver',
  reviewer: 'reviewer',
  owner: 'owner',
  users: 'users',
  stakeholder: 'stakeholder',
  Experts: 'expert_panel',
  Legal: 'legal_panel',
  escalationPoints: 'escalation_points'
  };
  export const allowedComplianceAuditDocuments = ['jpg', 'jpeg', 'png', 'pdf', 'docx', 'doc', 'ppt', 'pptx', 'xls', 'xlsx', 'txt', 'odt', 'csv', 'psv', 'tsv', 'ssv', 'scsv', 'msg'];
  export const complianceAuditRoutesAndTitle = {
  summary: 'Summary',
  policy: 'Policy',
  team: 'Team',
  versions: 'Versions',
  compliance: 'Citation',
  taggedPolicy: 'Policy',
  dependencies: 'Dependencies',
  documents: 'Documents',
  report: 'Reports',
  policyData: 'Policy',
  reviews: 'Review',
  workflow: 'Workflow',
  timeline: 'Timeline',
  action: 'Actions',
  assessment: 'Assessment',
  notification: 'Notification',
  summaryRoute: 'compliance-summary',
  policyRoute: 'policy-view',
  teamRoute: 'team',
  versionsRoute: 'compliance-versions',
  dependenciesRoute: 'dependency',
  documentsRoute: 'documents',
  reviewsRoute: 'compliance-reviews',
  workflowRoute: 'workflow',
  timelineRoute: 'timeline',
  request: 'request',
  actionRoute: 'action',
  assessmentRoute: 'assessment',
  complianceId: 'complianceId',
  id: 'id',
  complianceAudit: 'compliance-audit',
  taggedPolicyRoute: 'tagged-policy',
  complianceRoute: 'compliance-view',
  observation: 'Observations',
  observationRoute: 'compliance-observation',
  managementResponseRoute: 'compliance-management-response',
  managementResponse: 'Management Responses',
  recommendationRoute: 'compliance-recommendation',
  recommendation: 'Recommendations',
  rcm: 'RCM',
  rcmRoute: 'compliance-rcm',
  reportRoute:'compliance-report',
  notificationRoute:'compliance-notification',
  auditObservation: 'Audit Observations',
  riskProgramObservation: 'Risk Program Observation',
  auditObservationRoute: 'compliance-audit-observation',
  incident:'incidents',
  incidentRoute:'compliance-incident',
  riskProgramObservationRoute: 'risk-program-observation',
  workingPaper: 'Audit Program',
  salWorkingPaper: 'Assessment Program',
  schedule: 'Schedule',
  wokringPaperRoute: 'compliance-working-paper',
  scheduleRoute: 'schedule-details',
  issues: 'Issues',
  issuesRoute: 'issues',
  reminder:'Reminders',
  reminderRoute:'reminders',
  history: 'History',
  historyRoute: 'history',
  complianceSoa: 'Statement of Applicability',
  complianceSoaRoute: 'soa',
  relatedAudits: 'Related Audits',
  relatedAuditsRoute: 'related-audits',
};

export const complianceAuditAssessmentListParams = {
  limit: tableConfig.pager,
  offset: 0,
  compliance_action_status: '',
  multi_values: false
  };
  export const complianceControlPanelConstants = {
  majorProcessTitle: 'Major Process',
  majorProcess: 'major_process',
  megaProcessTitle: 'Mega Process',
  megaProcess: 'mega_process',
  ratingTitle: 'Compliance Rating',
  rating: 'compliance_audit_rating',
  statusTitle: 'Compliance Status',
  status: 'compliance_audit_status',
  typeTitle: 'Compliance Type',
  type: 'compliance_audit_type',
  areaTitle: 'Compliance Area',
  area: 'compliance_audit_area',
  requestedByTitle: 'Requested By',
  requestedBy: 'compliance_audit_requested_by',
  slug: 'compliance-audit',
  priorityTitle: 'Priority',
  priority: 'compliance_request_priority',
  requestStatusTitle: 'Request Status',
  requestStatus: 'compliance_request_status',
  requestTypeTitle: 'Request Type',
  requestType: 'compliance_request_type',
  requestRequestedBy: 'compliance_request_requested_by',
  requestSlug: 'compliance-request',
  complianceRequestTypeTitle: 'Category',
  complianceAuditType: 'Compliance type',
  complianceMegaProcess: 'Mega process',
  complianceMajorProcess: 'Major process',
  complianceRating: 'Compliance rating',
  complianceStatus: 'Compliance status',
  complianceArea: 'Compliance area',
  occuranceTitle: 'Compliance Occurrence',
  occurance: 'compliance_audit_occurrence',
  complianceOccurance: 'Compliance occurrence',
  complianceRequestedBy: 'Requested by',
  complianceRequestForms: ' Compliance Request Forms',
  complianceType: 'Request type',
  compliancePriority: 'Priority',
  complianceRequestStatus: 'Request status',
  complianceRequestType: 'Category',
  requestComplianceType: 'compliance_request_category',
  categoryTitle: 'Request Category',
  statusComplianceRequest: 'Compliance request status',
  statusComplianceRequests: 'compliance_request_status',
  complianceRequestApprovalStatus: 'Compliance request approval status',
  complianceRequestsApprovalStatus: 'compliance_request_approval_status'
  };
  export const complianceControlPanelQueryParams = {
  type: '',
  offset: 0,
  limit: 0
  };
  export enum ComplianceMangementWidgets {
  slug = 'slug',
  field = 'field',
  order = 'order',
  weightage = 'weightage',
  color = 'color',
  value = 'value',
  parent = 'parent',
  is_default = 'is_default',
  is_overdue = 'is_overdue'
  }
  export const complianceRequestConstants = {
  charField: 'CharField',
  textField: 'TextField',
  commentText: 'comment_text',
  parent: 'parent',
  file: 'file',
  id: 'id',
  userDetails: 'userDetails',
  replies: 'replies',
  requestId: 'requestId',
  tabId: 'tabId',
  undefined: 'undefined',
  fieldData: 'field_data',
  booleanField: 'BooleanField',
  choiceField: 'ChoiceField',
  decimalField: 'DecimalField',
  dateField: 'DateField',
  integerField: 'IntegerField',
  open: 'Open',
  rejected: 'Rejected',
  approved: 'Approved',
  underReview: 'Under Review',
  edit: 'edit',
  view: 'view',
  show: 'show',
  label: 'label',
  ar: 'ar',
  en: 'en-us'
  };
  export enum ComplianceRequestsConstants {
  requestsByPriority = 'Requests By Priority',
  requestsByAssignee = 'Requests By Assignee',
  requestsByStakeholders = 'Requests By Stakeholders',
  requestsByType = 'Requests By Type',
  requestsByDepartment = 'Requests By Department',
  departmentRequestedBy = 'Department Requested By',
  requestProcessStatus = 'compliance_request_process_status',
  requestPriority = 'compliance_request_priority',
  requestType = 'compliance_request_type',
  requestDepartmentRequestedBy = 'compliance_request_department_requested_by',
  all = 'all',
  complianceManagement = 'compliance_management',
  allCompliance = 'All Compliance',
  complianceParams = 'compliance_management_process_status',
  reviewId = 'reviewId',
  complianceRequest = 'compliance_request',
  file = 'file',
  item = 'item',
  value = 'value',
  name = 'name',
  status = 'status',
  limit = 'limit',
  offset = 'offset',
  requestsByStatus = 'Requests By Status',
  requestStatus ='compliance_request_approval_status'
  }
  export const complianceRequestsChartParams = {
  compliance_request_process_status: '',
  compliance_request_priority: '',
  compliance_request_type: '',
  compliance_request_department_requested_by: '',
  compliance_request_approval_status: '',
  multi_values: false
  };
  export const complianceRequestData = {
  request_title: '',
  request_type: '',
  requested_by: '',
  department_requested_by: '',
  source: '',
  entity: [],
  priority: '',
  to_be_written_by: '',
  oversight: [],
  previous_versions: [],
  documents: [],
  assignee: [],
  stakeholder: '',
  request_status: '',
  created_by: '',
  requested_date: '',
  compliance_request_type: '',
  compliance_request_data_set: [{
  'field_data': {}
  }]
  };

  export enum RequestWidgets {
    slug = 'slug',
    field = 'field',
    order = 'order',
    weightage = 'weightage',
    color = 'color',
    value = 'value',
    parent = 'parent',
    is_default = 'is_default',
    is_overdue = 'is_overdue'
  }

  export const requestConstants = {
    charField: 'CharField',
    textField: 'TextField',
    commentText: 'comment_text',
    parent: 'parent',
    file: 'file',
    id: 'id',
    userDetails: 'userDetails',
    replies: 'replies',
    requestId: 'requestId',
    tabId: 'tabId',
    undefined: 'undefined',
    fieldData: 'field_data',
    booleanField: 'BooleanField',
    choiceField: 'ChoiceField',
    decimalField: 'DecimalField',
    dateField: 'DateField',
    integerField: 'IntegerField',
    open: 'Open',
    rejected: 'Rejected',
    approved: 'Approved',
    underReview: 'Under Review',
    edit: 'edit',
    view: 'view',
    show: 'show',
    label: 'label',
    ar: 'ar',
    en: 'en-us'
  };

  export const requestsChartParams = {
    request_process_status: '',
    request_priority: '',
    request_type: '',
    request_department_requested_by: '',
    request_status: '',
    multi_values: false
  };

  export enum RequestsConstants {
    requestsByPriority = 'Requests By Priority',
    requestsByAssignee = 'Requests By Assignee',
    requestsByStakeholders = 'Requests By Stakeholders',
    requestsByType = 'Requests By Type',
    requestsByDepartment = 'Requests By Department',
    departmentRequestedBy = 'Department Requested By',
    requestProcessStatus = 'request_process_status',
    requestPriority = 'request_priority',
    requestType = 'type_request',
    request = 'request',
    requestDepartmentRequestedBy = 'request_department_requested_by',
    all = 'all',
    allRequest = 'All Request',
    requestParams = 'request_process_status',
    reviewId = 'reviewId',
    file = 'file',
    item = 'item',
    value = 'value',
    name = 'name',
    status = 'status',
    limit = 'limit',
    offset = 'offset',
    requestsByCategory = 'Requests By Category',
    requestsByStatus = 'Requests By Status',
    requestStatus ='request_status'
  }

  export const requestData = {
    request_title: '',
    category: '',
    requested_by: '',
    department_requested_by: '',
    source: '',
    entity: [],
    priority: '',
    to_be_written_by: '',
    oversight: [],
    documents: [],
    assignee: [],
    stakeholder: '',
    request_status: '',
    created_by: '',
    requested_date: '',
    request_type: '',
    compliance_request_data_set: [{
    'field_data': {}
    }]
    };

  export const requestControlPanelConstants = {
    majorProcessTitle: 'Major Process',
    majorProcess: 'major_process',
    megaProcessTitle: 'Mega Process',
    megaProcess: 'mega_process',
    ratingTitle: 'Request Rating',
    rating: 'compliance_audit_rating',
    statusTitle: 'Request Status',
    status: 'compliance_audit_status',
    typeTitle: 'Request Type',
    type: 'compliance_audit_type',
    areaTitle: 'Request Area',
    area: 'compliance_audit_area',
    requestedByTitle: 'Requested By',
    requestedBy: 'request_audit_requested_by',
    slug: 'compliance-audit',
    priorityTitle: 'Priority',
    priority: 'compliance_request_priority',
    requestStatusTitle: 'Request Status',
    requestStatus: 'request_status',
    requestTypeTitle: 'Request Type',
    requestType: 'request_type',
    requestRequestedBy: 'compliance_request_requested_by',
    requestSlug: 'request',
    requestTypeTitleCategory: 'Category',
    complianceAuditType: 'Compliance type',
    complianceMegaProcess: 'Mega process',
    complianceMajorProcess: 'Major process',
    complianceRequestedBy: 'Requested by',
    requestForms: 'Request Forms',
    complianceType: 'Request type',
    compliancePriority: 'Priority',
    complianceRequestStatus: 'Request status',
    complianceRequestType: 'Category',
    requestCategoryType: 'request_category',
    categoryTitle: 'Request Category',
    statusRequest: 'Compliance request status',
    statusRequests: 'request_status',
    requestApprovalStatus: 'Request approval status',
    requestsApprovalStatus: 'request_approval_status'
};
export const requestControlPanelQueryParams = {
  type: '',
  offset: 0,
  limit: 0
  };
  export const auditOverviewInputTextareaWidgetConstants = {
  auditName: 'Audit Name',
  auditUniverse: 'Audit Universe',
  sourceOfAudit: 'Source of Audit',
  requestedBy: 'Requested by',
  auditScope: 'Audit Scope',
  auditObjective: 'Audit Objective',
  conclusion: 'Conclusion',
  riskProgram: 'risk_program'
  };
  export const auditUniverseInputTextareaWidgetConstants = {
  universe: 'Universe Name',
  start: 'Start Date',
  end: 'End Date'
  };
  export const recommendationInputTextareaWidgetConstants = {
  recommendation: 'Recommendation Title',
  department: 'Department',
  status: 'Recommendation Status',
  priority: 'Recommendation Priority',
  date: 'Due Date',
  description: 'Description',
  costSaving: 'Cost Saving'
  };
  export const observationInputTextareaWidgetConstants = {
  department: 'Department',
  description: 'Description',
  rating: 'Observation Rating',
  status: 'Status',
  feedback: 'Management Feedback',
  observation: 'Observation',
  date: 'Observed Date',
  response: 'Management Response',
  likelihood: 'Likelihood',
  impact: 'Impact',
  overallRating: 'Overall Rating',
  managementResponseSummary: 'Management Response',
  managementComment: 'Management Comment'
  };
  export const activityGraphQueryParams = {
  activity_status: '',
  assignee: '',
  action: ''
  };
  export const setupWizardMenuItems = {
  users: 'User',
  logo: 'Logo',
  entity: 'Entity',
  region: 'Region',
  department: 'Department',
  designation: 'Designation',
  incident: 'Incident',
  risk: 'Risk',
  audit: 'Audit',
  bcm: 'Business Process',
  asset: 'Asset',
  compliance: 'Compliance',
  policy: 'Policy',
  time: 'Time Sheet',
  survey: 'Survey',
  incidentForm: 'incidentForm',
  businessCategory: 'businessCategory',
  businessTier: 'businessTier',
  businessTimeframe: 'businessTimeframe',
  assetType: 'assetType',
  assetForm: 'assetForm',
  policyType: 'policyType',
  policyForm: 'policyForm',
  complianceType: 'complianceType',
  complianceForm: 'complianceForm',
  budgetCategory: 'budgetCategory',
  budgetSubCategory: 'budgetSubCategory',
  analyticsProcess: 'analyticsProcess',
  analyticsSubProcess: 'analyticsSubProcess',
  businessStrategy: 'Business Strategy',
  strategyPriority: 'strategyPriority',
  strategyStatus: 'strategyStatus',
  auditArea: 'auditArea',
  auditRequestedBy: 'auditRequestedBy',
  sourceOfAudit: 'sourceOfAudit',
  approvalStatus: 'approvalStatus',
  auditReportingArea: 'auditReportingArea',
  observationRating: 'observationRating',
  observationStatus: 'observationStatus',
  riskUniverse: 'Risk Universe',
  riskIdentifier: 'riskIdentifier',
  inherentLikelihood: 'inherentLikelihood',
  inherentImpact: 'inherentImpact',
  residualLikelihood: 'residualLikelihood',
  residualImpact: 'residualImpact',
  inherentRiskRating: 'inherentRiskRating',
  residualRiskRating: 'residualRiskRating',
  riskVelocity: 'riskVelocity',
  treatment: 'treatment',
  riskStatus: 'riskStatus',
  riskSubcategory: 'riskSubcategory',
  vulnerability: 'vulnerability',
  riskType: 'riskType',
  riskMegaProcess: 'riskMegaProcess',
  riskMajorProcess: 'riskMajorProcess',
  fraudRelated: 'fraudRelated',
  decisionMaker: 'decisionMaker',
  riskMitigationStrategyResponsibility: 'riskMitigationStrategyResponsibility',
  riskMitigationStrategyEffectiveness: 'riskMitigationStrategyEffectiveness',
  controlFrequency: 'controlFrequency',
  controlType: 'controlType',
  controlAutomation: 'controlAutomation',
  controlRating: 'controlRating',
  controlProcess: 'controlProcess',
  controlSubProcess: 'controlSubProcess',
  keyControl: 'keyControl',
  easeOfImplementation: 'easeOfImplementation',
  easeOfMaintain: 'easeOfMaintain',
  costToImplemenMaintain: 'costToImplemenMaintain',
  reducesImpact: 'reducesImpact',
  reducesLikelihood: 'reducesLikelihood',
  controlDesignEvaluation: 'controlDesignEvaluation',
  surveyStatus: 'surveyStatus',
  surveyCategory: 'surveyCategory',
  projectType: 'projectType',
  userGroup: 'userGroup'
  };
  export const setupWizardFieldData = {
  strategyCategory: 'strategy_category',
  strategyStatus: 'strategy_status',
  strategyPriority: 'strategy_priority',
  riskCategory: 'risk_category',
  riskIdentifier: 'risk_identifier',
  treatment: 'treatment',
  status: 'status',
  riskSubcategory: 'subcategory',
  risktype: 'risk_type',
  riskMegaProcess: 'mega_process',
  riskMajorProcess: 'major_process',
  fraudRelated: 'fraud_related',
  decisionMaker: 'decision_maker',
  riskResponsibility: 'risk_mitigation_strategy_responsibility',
  riskEffectiveness: 'risk_mitigation_strategy_effectiveness',
  controlFrequency: 'control_frequency',
  controlType: 'control_type',
  controlAutomation: 'control_automation',
  controlRating: 'control_rating',
  controlProcess: 'process',
  controlSubProcess: 'sub_process',
  keyControl: 'key_control',
  easeOfImplementation: 'ease_of_implementation',
  easeOfMaintain: 'ease_of_maintain',
  controlCostMaintain: 'cost_to_implement_maintain',
  reducesImpact: 'reduces_impact',
  reducesLikelihood: 'reduces_likelihood',
  controlDesignEvaluation: 'control_design_evaluation',
  auditRequestedBy: 'requested_by',
  sourceOfAudit: 'source_of_audit',
  observationRating: 'observation_rating',
  observationStatus: 'observation_status'
  };
  export const issueApprovalFields = {
  title: 'title',
  description: 'description',
  discussion: 'discussion',
  cause: 'cause',
  impact: 'impact',
  issueRating: 'issue_rating',
  department: 'department',
  departmentNew: 'department_new',
  areasAudit: 'areas_audit',
  businessUser: 'business_user',
  issueStatus: 'issue_status',
  isObservation: 'is_observation',
  isReviewer: 'is_reviewer',
  reviewer: 'reviewer',
  issueBusinessUser: 'issue_business_user'
  };
  export const securityConstants = {
  securityKey: 's9f$dg&dn*bsd_b3',
  securityIV: 'BnxAuraDBContvty'
  };
  export const languageConstants = {
  language: 'en-us'
  };
  export const setupWizardParams = {
  activeWizardItem: '',
  activeChild: ''
  };
  export const languageSelected = {
  language: 'en-us'
  };
  export const isBackupDropDown = [
  { 'value': 'Yes' },
  { 'value': 'No' }
  ];
  export const isBcmWorkaround = [
  { 'value': 'Yes' },
  { 'value': 'No' }
  ];
  export const isAssetFailure = [
  { 'value': 'Yes' },
  { 'value': 'No' }
  ];
  export const isAssetWorkaround = [
  { 'value': 'Yes' },
  { 'value': 'No' }
  ];
  export const fieldEditorSlugs = {
  'risk-appetite': 'risk_appetite',
  kri: 'kri',
  metrics: 'metrics',
  'compliance-kpi': 'compliance_kpi',
  metrics_score: 'metrics_score',
  kri_score: 'kri_score',
  risk_appetite_score: 'risk_appetite',
  'strategy-kpi': 'strategy_kpi',
  'strategy-kpi-score': 'strategy_kpi_score',
  riskUniverse: 'risk_assessment',
  riskRegister: 'risk_register',
  strategyOverview: 'business_strategy',
  strategyPlan: 'business_plan',
  riskIssue: 'risk_general_issue',
  auditActivity: 'auditprogramactivity',
  riskAction: 'risk_general_action',
  control: 'risk_assessment_details',
  observationaction: 'observationaction',
  observationRecommendation: 'observationrecommendation',
  auditImprovement: 'improvement',
  audit: 'auditplan&request_for_auditplandetail=auditplandetail',
  managementResponse: 'managementresponse',
  improvementManagementResponse: 'improvement_managementresponse',
  observation: 'observation',
  auditUniverse: 'auditplanmaster',
  complianceObservation: 'compliance_observation',
  complianceRecommendation: 'compliance_recommendation',
  complianceAuditManagementResponse: 'complianceauditmanagementresponse',
  ermRegister: 'erm_register',
  ermRisk: 'enterprise-risk',
  ermCategory: 'erm-category',
  auditProgram: 'workingpaper',
  auditIssue: 'issue',
  complianceGeneralIssue: 'compliance_general_issue',
  complianceAudit: 'compliance-audit',
  complianceAuditAction: 'compliance-audit-action',
  complianceRequest: 'compliance_request',
  request: 'request',
  riskPlan: 'riskprogramplan',
  riskProgram: 'riskprogram&request_for_auditplandetail=riskprogramdetail',
  workingPaper: 'riskprogramworkingpaper',
  issue: 'issue',
  observationrecommendation: 'observationrecommendation',
  policyRequest: 'policy_request',
  policies: 'policy_management',
  riskObservation: 'risk_program_observation',
  entityManagement: 'entities',
  entityFunction: 'functions',
  entityDepartment: 'departments',
  riskProgramManagementResponse: 'risk_program_management_response',
  riskProgramActivty: 'risk_program_activity',
  riskProgramRecommendation: 'riskobservationrecommendation',
  compliancePlan: 'complianceauditplan',
  entitySection: 'sections',
  incidentReporting: 'incident_reporting',
  incidentAction: 'incident_reporting_action',
  complianceAuditWorkingPaper: 'complianceauditworkingpaper',
  residualRisk: "residual-rating",
  complianceIssue: 'compliance_general_issue',
  bcm: 'business_process',
  complainceIssue : 'compliance_general_issue',
  complianceObservationAction: 'compliance_observation_action',
  invokeBcp:'business_continuity_plan',
  invokeBcpAction: 'bcpobservationaction',
  exceptionMr:'exception-management-response',
  riskProgramV1: 'riskprogram',
  incidentrootcause:'incident_root_cause',
  timeSheet: 'time_sheet',
  timeProject: 'time_project',
  analyticsScenario:'analytics-scenario',
  esgOverview:'esg_strategy',
  esgPlan:'esg_plan',
  startegyAction: 'startegy_action',
  esgAction: 'esg_action',
  'esg-metrics': 'esg_metrics',
  auditUniverseSlug: 'audit-universe',
  assetAction: 'asset-management-action',
  bcmAction: 'business-process-action',
  assetManagement: 'asset_management',
  goal: 'goal',
  kpi: 'kpi',
  performance_plan: 'performance_plan',
  goalAction: 'goal_action',
  goal_action: 'goal',
  drStrategy: 'dr-strategy'
  };
  export const actionSlug = {
  risk: 'risk-assessment',
  control: 'risk-assessment-details'
  };
  export const actionOptions = {
  add: 'Add',
  sort: 'Sort'
  };

  export const headerOptions = {
    refresh: 'refresh',
    filter: 'filter',
    export: 'export'
  };

  export const actionOptionEvent = {
  add: 'add',
  sort: 'sort'
  };

  export const actionAdd = {
    add: 'Add'
  }
  export const sortCustomConstants = {
  cancel: 'cancel',
  update: 'update'
  };
  export const workingPaperConstant = {
  risk: 'risk',
  control: 'control',
  workingPaper: 'workingPaper',
  manual: 'Manual',
  riskType: 'Risk',
  controlType: 'Control',
  auditplan: 'auditplan',
  notCommenced: 'Not Commenced',
  manuals: 'manual',
  edit: 'Edit',
  delete: 'Delete',
  createRequestType: 'create'
  };
  export const dynamicConfigForAuditObservationActionIssues = {
  'field_name': 'issue',
  'default_field_name': {
  'ar': 'Issue',
  'en-us': 'Issue'
  },
  'language': {
  'ar': 'Issue',
  'en-us': 'Issue'
  },
  'is_mandatory': false,
  'is_shown': true,
  'description_required': false,
  'description': null,
  'configuration': {
  'show': true,
  'mandatory': false,
  'description': false
  },
  'is_editable': false,
  'permission_status': true
  };
  export const digitalReport = {
  report: 'report',
  observationRating: '&observation_rating=',
  issueRating: '&issue_rating=',
  actionRating: '&action_rating=',
  recommendationRating: '&recommendation_rating=',
  executiveSummary: 'Executive Summary',
  pendingRequest: 'pendingRequest'
  };
  export const policyPermissionData = {
  add: 'Add',
  addComment: 'Add Comment',
  admin: 'Admin',
  approval: 'Approval',
  delete: 'Delete',
  deleteComment: 'Delete Comment',
  deleteDocument: 'Delete Document',
  download: 'Download',
  downloadDocument: 'Download Document',
  edit: 'Edit',
  editComment: 'Edit Comment',
  editDocument: 'Edit Document',
  export: 'Export',
  import: 'Import',
  upload: 'Upload',
  uploadDocument: 'Upload Document',
  view: 'View Own',
  ViewComment: 'View Comment',
  parentModule: 'Management Policy',
  tag: 'Tag',
  viewOwn: 'View',
  viewAny: 'View Any',
  acknowledge: 'Acknowledge',
  acknowledgeSetting: 'Acknowledgment Setting'
  };
  export const policyPath = {
  detailpage: 'detailpage',
  create: 'create-policy',
  policyManagement: 'policy-management',
  policies: 'policies',
  request: 'policy-requests',
  editPolicy: 'edit-policy',
  reviews: 'policy-reviews',
  requestDetails: 'requests',
  createRequest: 'create-request',
  editRequest: 'edit-request'
  };
  export const controlPanelPath = {
  controlpanel: 'controlpanel',
  license: 'LICENSE',
  adminPermission: 'Admin Permissions'
  };
  export const adminUrlAndSlug = [
  {
  url: 'admin-users',
  slug: 'USERS'
  },
  {
  url: 'admin-users-groups',
  slug: 'USER_GROUP'
  },
  {
  url: 'clients',
  slug: 'CLIENTS'
  },
  {
  url: 'license',
  slug: 'LICENSE'
  },
  {
  url: 'Risk%20Management/risk-management',
  slug: 'RISK_MANAGEMENT_PERMISSIONS'
  },
  {
  url: 'Audit%20Management/audit-management',
  slug: 'AUDIT_MANAGEMENT_PERMISSIONS'
  },
  {
  url: 'Analytics%20Management/audit-analytics',
  slug: 'ANALYTICS_MANAGEMENT_PERMISSIONS'
  },
  {
  url: 'Compliance%20Management/compliance-audit-management',
  slug: 'COMPLIANCE_MANAGEMENT_PERMISSIONS'
  },
  {
  url: 'Business%20Continuity/businesscontinuity-management',
  slug: 'BUSINESS_CONTINUITY_PERMISSIONS'
  },
  {
  url: 'Budget%20Management/budget-management',
  slug: 'BUDGET_MANAGEMENT_PERMISSIONS'
  },
  {
  url: 'permissions/Assessments',
  slug: 'ASSESSMENT_PERMISSIONS'
  },
  {
  url: 'object-manager/risk-management',
  slug: 'RISK_MANAGEMENT'
  },
  {
  url: 'object-manager/audit-management',
  slug: 'AUDIT_MANAGEMENT'
  },
  {
  url: 'Analytics-controlPanel',
  slug: 'ANALYTICS'
  },
  {
  url: 'expense/admin-panel',
  slug: 'BUDGET_MANAGEMENT'
  },
  {
  url: 'incident-reporting-controlpanel',
  slug: 'INCIDENT'
  },
  {
  url: 'bcm-controlpanel',
  slug: 'BUSINESS_CONTINUITY'
  },
  {
  url: 'policy-controlpanel',
  slug: 'POLICY_MANAGEMENT'
  },
  {
  url: 'compliance-controlpanel',
  slug: 'COMPLIANCE_MANAGEMENT'
  },
  {
  url: 'time-log-controlpanel',
  slug: 'TIME_LOG_MANAGEMENT'
  },
  {
  url: 'feature',
  slug: 'FEATURES'
  },
  {
  url: 'logo-configuration',
  slug: 'LOGO_CONFIGURATION'
  },
  {
  url: 'entity-management',
  slug: 'ENTITY_MANAGEMENT'
  },
  {
  url: 'admin-panel/entity',
  slug: 'ENTITY_MANAGEMENT'
  },
  {
  url: 'sectors',
  slug: 'SECTOR_MANAGEMENT'
  },
  {
  url: 'region-management',
  slug: 'REGION_MANAGEMENT'
  },
  {
  url: 'admin-panel/region',
  slug: 'REGION_MANAGEMENT'
  },
  {
  url: 'admin-panel/department',
  slug: 'DEPARTMENT_MANAGEMENT'
  },
  {
  url: 'admin-panel/objective-management',
  slug: 'OBJECTIVE_MANAGEMENT'
  },
  {
  url: 'admin-panel/designation',
  slug: 'DESIGNATION_MANAGEMENT'
  },
  {
  url: 'admin-panel/mega-and-major-process',
  slug: 'PROCESS_MANAGEMENT'
  },
  {
  url: 'admin-panel/notifications',
  slug: 'AUTO_REMINDERS'
  },
  {
  url: 'settings/mass-reassign-user',
  slug: 'MASS_REASSIGN_USER'
  },
  {
  url: 'fiscal-year',
  slug: 'FISCAL_YEAR'
  },
  {
  url: 'admin-security',
  slug: 'SECURITY_PERMISSIONS'
  }
  ];
  export const annexureConstants = {
  fileTypes: '\'docx\', \'doc\', \'xls\', \'xlsx\'',
  controlPanel: 'control-panel',
  auditReporting: 'audit_reporting',
  complianceUpload: 'compliance-annexure',
  auditUpload: 'audit-annexure'
  };
  export const rcmTableRowData = {
  'field_name': 'RCM',
  'data_type': '-',
  'configurable': 'Yes',
  'slug': 'audit-planning',
  'field': 'risk-control-metrics'
  };
  export const ratingTableRowData = {
  'field_name': 'Department rating',
  'data_type': '-',
  'configurable': 'Yes',
  'slug': 'audit-planning',
  'field': 'department_rating'
  };
  export const departmentRatingRiskProgram = {
  'field_name': commonLanguage.risk_program_department_rating,
  'data_type': '-',
  'configurable': 'Yes',
  'slug': 'risk-program',
  'field': 'risk_program_department_rating'
  };
  export const rightSidebarNetworkConstants = {
  userTemplate: 'user-template'
  };
  export const rcmConstants = {
  id: 'id',
  auditId: 'audit_id',
  auditName: 'audit_name',
  auditDetail: 'audit-detail',
  auditPlanDetail: 'audit-plan-detail',
  risk: 'risk',
  control: 'control',
  riskRcm: 'risk_rcm',
  controlRcm: 'control_rcm',
  show: 'show',
  category: 'category',
  label: 'label',
  custom: 'custom',
  self: '_self',
  fieldStatus: 'field_status',
  csv: 'csv',
  xls: 'xls',
  riskExport: 'risk_export',
  controlExport: 'control_export',
  export: 'Export',
  auditPlanning: 'audit-planning',
  defaultTitle: 'Risk and Control Metrics',
  observation: 'observation',
  recommendation: 'recommendation',
  managementResponse: 'management_response',
  observationRcm: 'observation_rcm',
  recommendationRcm: 'recommendation_rcm',
  managementResponseRcm: 'management_response_rcm',
  rcmDataRequestFor: 'audit_planning,observation',
  rcmRequestForRiskAndControls: 'audit_planning',
  rcmRequestForObservation: 'observation',
  shortTextLengthForRcmFieldsInControlPanel: 50,
  observationDashboard: 'observation_dashboard',
  response: 'response',
  observationId: 'observation_id',
  controlId: 'control_id',
  riskId: 'risk_id',
  complianceObservationDashboard: 'dashboard-compliance-observation',
  complianceObservation: 'compliance-observation',
  complianceId: 'compliance_id',
  complianceName: 'compliance_name',
  assessment: 'assessment',
  observationLIst:'ObservationLIst',
  riskProgramObservationDashboard:'RP-observation-dashboard',
  rpobservationDashboard: 'observation_dashboard',
  riskProgram: 'risk-program',
  rpObservationMatrixExport: 'riskprogram_observation',
  audit_rcm: 'audit_rcm',
  audit: 'audit'
  };
  export const recommendationContributorChartData = {
  recommendationStatus: 'recommendation_status',
  recommendations: 'recommendations',
  recommendationByAging: 'recommendation_by_aging',
  recommendationPriority: 'recommendation_priority'
  };

  export const MrContributorChartData = {
  recommendationStatus: 'management_status',
  departmentLevel1: 'department_level1',
  departmentLevel2: 'department_level2'
  };
  export const mangementResponse = {
  mangementResponse: 'mangementResponse'
  };
  export const issueCreateFormConstants = {
  department: 'department',
  auditAreas: 'audit_areas',
  rating: 'rating',
  managementStatus: 'management_status',
  issueStatus: 'issue_status',
  issueRating: 'issue_rating',
  observationStakeholder: 'observation_stakeholder',
  megaProcess: 'megaProcess',
  majorProcess: 'majorProcess',
  issueDesignation: 'issueDesignation',
  issueDropdown: 'issueDropdown',
  issueUser: 'issueUser',
  issueMulti: 'issueMulti',
  issueDropdownV1: 'issueDropdownV1',
  issueDropdownV2: 'issueDropdownV2',
  issueMultiDropdownV1: 'issueMultiDropdownV1',
  issueMultiDropdownV2: 'issueMultiDropdownV2',
  issueWorkingPaper: 'issueWorkingPaper',
  departments: 'departments',
  level2: 'level2',
  level3: 'level3',
  level4: 'level4',
  extraField: 'extraField',
  extraDropdown: 'extraDropdown',
  newDropdown:'newDropdown'
  };
  export const auditRecommendationConstants = {
  id: 'id',
  ar: 'ar',
  en: 'en-us',
  recommendationsByStatus: 'Recommendations by Status',
  recommendationsByStatuss: 'Status',
  recommendationsByPriority: 'Recommendations by Priority',
  recommendationsByPrioritys: 'Priority',
  recommendationsByDepartment: 'Recommendations by Department',
  recommendationsByDepartments: 'Department',
  auditPlanning: 'audit-planning',
  recommendationsByType: 'Recommendations by Type',
  recommendationsByAging: 'Recommendations by Aging',
  auditRecommendation: 'auditRecommendation',
  recommendationId: 'recommendation_id',
  auditId: 'audit_id',
  observationId: 'observation_id',
  recommendationStatus: 'recommendation_status',
  recommendationPriority: 'recommendation_priority',
  identifier: 'identifier',
  recommendationDepartment: 'recommendation_department',
  deleteRecommendation: 'deleteRecommendation',
  editRecommendation: 'editRecommendation',
  browseRecommendation: 'browseRecommendation',
  show: 'show',
  legend: 'legend',
  all: 'all',
  label: 'label',
  color: 'color',
  limit: 'limit',
  offset: 'offset',
  category: 'category',
  others: 'Others',
  complianceRecommendation: 'complianceRecommendation',
  notClassified: 'Not Classified',
  overdue : 'Overdue'
  };
  export const timeLogConstants = {
  rejected: 'Rejected',
  approved: 'Approved',
  userDetails: 'userDetails',
  pending: 'Pending',
  audit: 'Audit',
  admin: 'Admin'
  };
  export const routeClearList = [
  'Object Management',
  'Permissions'
  ];
  export const observationDashboardConstants = {
  auditsByStatus: 'Audits by Status',
  observationByStatus: 'Observations by Status',
  observationDepartment: 'Observations by Department',
  observationMegaprocess: 'Observations by Mega Process',
  byStakeholder: 'by Stakeholder',
  managementStatus: 'Management Responses',
  observationStatus: 'observation_status',
  managementByStatus: 'management_status',
  auditByStatus: 'audit__status_of_audit',
  observationByDepartment: 'observation_department',
  megaprocess: '',
  stakeholder: '',
  user: 'observation_business_user',
  allObservations: 'All Observations',
  observationMetrices: 'Observation Metrices',
  observationDashboard: 'observation_dashboard',
  complianceObservationDashboard: 'dashboard-compliance-observation',
  observationType: 'observation_type',
  programByStatus: 'Risk Program by Status',
  RpobservationDashboard: 'RP-observation-dashboard'
  };
  export const dropdownEvent = {
    sort: 'sort',
    filter: 'filter',
    hide: 'hide',
    edit: 'edit',
    delete: 'delete',
    refresh: 'refresh',
    download: 'download'
    };
  export const billableData = [
  { 'value': 'Yes' },
  { 'value': 'No' }
  ];

  export const sourceDefaultData = [
    { 'value': 'Project' },
    { 'value': 'Leave' }
    ];
  export enum TimeLogConstants {
  timesheet = 'Time Sheet',
  reviews = 'Reviews',
  all = 'all',
  entriesbyproject = "Entries by Project",
  project = "Projects",
  activityList ="Assigned to me"
  }
  export const tableFilter = {
  option: [
  {
  icon: 'au-icon au-icon--filter-mob',
  class: '',
  label: 'Filter',
  event: dropdownEvent.filter
  }
  ]
  };
  export const tableSortFilter = {
  option: [
  {
  icon: 'au-icon au-icon--sort-za',
  class: '',
  label: 'Sort',
  event: dropdownEvent.sort
  },
  {
  icon: 'au-icon au-icon--filter-mob',
  class: '',
  label: 'Filter',
  event: dropdownEvent.filter
  }
  ]
  };
  export const tableSortOnly = {
  option: [
  {
  icon: 'au-icon au-icon--sort-za',
  class: '',
  label: 'Sort',
  event: dropdownEvent.sort
  }
  ]
  };
  export const tableFilterOnly = {
  option: [
  {
  icon: 'au-icon au-icon--filter-mob',
  class: '',
  label: 'Filter',
  event: dropdownEvent.filter
  }
  ]
  };
  export const tableSortType = {
  asc: 'asc',
  dec: 'dsc'
  };
  export const tableFilterCondition = [
  {
  label: 'contains',
  value: 'icontains'
  },
  {
  label: 'is',
  value: 'iexact'
  },
  {
  label: 'is not',
  value: '_isnot_iexact'
  },
  {
  label: 'doesn\'t contains',
  value: '_isnot_icontains'
  },
  {
  label: 'start with',
  value: 'istartswith'
  },
  {
  label: 'ends with',
  value: 'iendswith'
  }
  ];
  export const tableDateFilterCondition = [
    {
    label: 'contains',
    value: 'icontains'
    },
    {
    label: 'is',
    value: 'iexact'
    },
    {
    label: 'is not',
    value: '_isnot_iexact'
    },
    {
    label: 'doesn\'t contains',
    value: '_isnot_icontains'
    },
    {
      label: 'greater than',
      value: 'gt'
    },
    {
      label: 'less than',
      value: 'lt'
    },
    {
      label: 'greater than or equal to',
      value: 'gte'
    },
    {
      label: 'less than or equal to',
      value: 'lte'
    }
    ];

    export const conditionDateFilter = [
      {
      label: 'is',
      value: 'iexact'
      },
      {
      label: 'is not',
      value: '_isnot_iexact'
      },
      {
        label: 'greater than',
        value: 'gt'
      },
      {
        label: 'less than',
        value: 'lt'
      },
      {
        label: 'greater than or equal to',
        value: 'gte'
      },
      {
        label: 'less than or equal to',
        value: 'lte'
      }
    ];
  export const managementResponseConstants = {
  status: 'Response by Status',
  owner: 'Response by Owner',
  rating: 'Observation by Rating',
  show: 'show',
  label: 'label'
  };
  export const riskDraftTable = {
  risk: 'risk',
  draft: 'draft',
  takeAssessment: 'Take Assessment',
  viewDetails: 'View Details',
  questionnaire: 'questionnaire',
  assessments: 'assessments',
  assessmentPlan: 'assessmentPlan'
  };

  export const kriTable = {
    allKri: 'allkri',
    assignedKri: 'assignedKri'
  };

  export const permission = {
  titleConstant: 'permission'
  };
  export const generatePlanRiskParamConstants = {
  id: 'id',
  ar: 'ar',
  en: 'en-us',
  value: 'value',
  name: 'name',
  residual: 'residual',
  megaProcessChartTitle: 'Risks by Mega Process',
  mega_process: 'mega_process',
  megaProcessTitle: 'Mega Process',
  majorProcessTitle: 'Major Process',
  major_process: 'major_process',
  auditPlanning: 'audit-planning'
  };
  export const managementResponseConstant = {
  managementResponsebyStatus: 'Responses by Status',
  managementResponsebyOwner: 'Responses by Owner',
  observationbyRating: 'Observations by Rating'
  };
  export const timeLogControlPanelConstants = {
    categoryField: 'time_log_category',
    projectType: 'Project type',
    category: 'Time log category',
    categoryTitle: 'Time Log Category',
    create: 'create',
    edit: 'edit',
    slug: 'time-sheet',
    typeField: 'project_type',
    projectTypeTitle: 'Project Type',
    subcategoryfiled:'time_log_sub_category',
    activityfield:'time-activity',
    dynamicCategory: 'category',
    dynamicSubCategory: 'sub_category',
    dynamciActivity: 'activity'
  };
  export enum TimeLogWidgets {
  slug = 'slug',
  field = 'field',
  order = 'order',
  weightage = 'weightage',
  color = 'color',
  value = 'value',
  parent = 'parent',
  is_default = 'is_default',
  is_overdue = 'is_overdue'
  }
  export const timeLogControlPanelQueryParams = {
  type: '',
  offset: 0,
  limit: 0
  };
  export const myTimeLogConstants = {
  commentText: 'comment_text',
  parent: 'parent',
  file: 'file',
  id: 'id',
  replies: 'replies',
  userDetails: 'userDetails',
  timeSlug: 'time-entry-by-start-and-end-time'
  };
export const eSignOptionConstants = {
  signature: 'Signature',
  date: 'Date',
  send: 'Send',
  cancel: 'Cancel',
  incidentReportingIcon: 'icon-Incident-reporting'
  };
  export const managementResponseAddWidgetConstants = {
  managementUser: 'management_user',
  managemmentResponseUser: 'management-response-user',
  responseExpectedBy: 'response_expected_by',
  complianceManagementResponseUser: 'compliance-management-response-user',
  managementResponsebyOwners:'Owners',
  managementResponseReviewer: 'management_reviewer',
  managementResponseTaggedReccomendation:'management_recommendation'

  };

  export const managementResponseAddWidgetRiskConstants = {
    managementUser: 'management_user',
    managemmentResponseUser: 'management-response-user',
    responseExpectedBy: 'response_expected_by',
    complianceManagementResponseUser: 'compliance-management-response-user',
    managementResponsebyStatus: 'Response by Status',
    managementResponsebyStatuss: 'Status',
    managementResponsebyOwner: 'Response by Owner',
    managementResponsebyOwners:'Owners',
    observationbyRating: 'Observation by Rating'
    };
  export const recordType = {
  pdf: 'PDF',
  doc: 'DOC',
  docx: 'DOCX'
  };
  export const adminAuditObservationDynamicRating = [
  {
  configurable: 'Yes',
  field: 'dynamic_rating',
  field_name: 'Dynamic Rating',
  slug: 'audit-reporting',
  data_type: 'ForeignKey'
  },
  {
  configurable: 'Yes',
  field: 'dynamic_rating_factors',
  field_name: 'Dynamic Rating Factors',
  slug: 'audit-reporting',
  data_type: 'ForeignKey'
  },
  {
  configurable: 'Yes',
  field: 'observation_status',
  field_name: 'Observation status',
  slug: 'audit-reporting',
  data_type: 'ForeignKey'
  },
  {
  configurable: 'Yes',
  field: 'annexure',
  field_name: 'Annexure',
  slug: 'audit-reporting',
  data_type: 'ForeignKey'
  },
  {
  configurable: 'Yes',
  field: 'observation_dropdown_v1',
  field_name: 'Observation dropdown v1',
  slug: 'audit-reporting',
  data_type: 'ForeignKey'
  },
  {
  configurable: 'Yes',
  field: 'observation_dropdown_v2',
  field_name: 'Observation dropdown v2',
  slug: 'audit-reporting',
  data_type: 'ForeignKey'
  },
  {
    configurable: 'Yes',
    field: 'observation_dropdown_v3',
    field_name: 'Observation dropdown v3',
    slug: 'audit-reporting',
    data_type: 'ForeignKey'
  },
  {
    configurable: 'Yes',
    field: 'observation_dropdown_v4',
    field_name: 'Observation dropdown v4',
    slug: 'audit-reporting',
    data_type: 'ForeignKey'
  },
  {
    configurable: 'Yes',
    field: 'observation_dropdown_v5',
    field_name: 'Observation dropdown v5',
    slug: 'audit-reporting',
    data_type: 'ForeignKey'
  },
  {
    configurable: 'Yes',
    field: 'observation_dropdown_v6',
    field_name: 'Observation dropdown v6',
    slug: 'audit-reporting',
    data_type: 'ForeignKey'
  },
  {
    configurable: 'Yes',
    field: 'observation_dropdown_v7',
    field_name: 'Observation dropdown v7',
    slug: 'audit-reporting',
    data_type: 'ForeignKey'
  },
  {
  configurable: 'Yes',
  field: 'observation_multi_dropdown_v1',
  field_name: 'Observation multi dropdown v1',
  slug: 'audit-reporting',
  data_type: 'ForeignKey'
  },
  {
  configurable: 'Yes',
  field: 'observation_multi_dropdown_v2',
  field_name: 'Observation multi dropdown v2',
  slug: 'audit-reporting',
  data_type: 'ForeignKey'
  },
  {
    configurable: 'Yes',
    field: 'observation_multi_dropdown_v3',
    field_name: 'Observation multi dropdown v3',
    slug: 'audit-reporting',
    data_type: 'ForeignKey'
  },
  {
    configurable: 'Yes',
    field: 'observation_multi_dropdown_v4',
    field_name: 'Observation multi dropdown v4',
    slug: 'audit-reporting',
    data_type: 'ForeignKey'
  },
  {
    configurable: 'Yes',
    field: 'observation_multi_dropdown_v5',
    field_name: 'Observation multi dropdown v5',
    slug: 'audit-reporting',
    data_type: 'ForeignKey'
  },
  {
    configurable: 'Yes',
    field: 'observation_multi_dropdown_v6',
    field_name: 'Observation multi dropdown v6',
    slug: 'audit-reporting',
    data_type: 'ForeignKey'
  },
  {
    configurable: 'Yes',
    field: 'observation_multi_dropdown_v7',
    field_name: 'Observation multi dropdown v7',
    slug: 'audit-reporting',
    data_type: 'ForeignKey'
  },
  {
  configurable: 'Yes',
  field: 'type',
  field_name: 'Type',
  slug: 'audit-reporting',
  data_type: 'ForeignKey'
  },
  {
  configurable: 'Yes',
  field: 'category',
  field_name: 'Category',
  slug: 'audit-reporting',
  data_type: 'ForeignKey'
  },
  {
  configurable: 'Yes',
  field: 'observation_dropdown_1',
  field_name: 'Observation dropdown 1',
  slug: 'compliance-audit',
  data_type: 'ForeignKey'
  },
  ,
  {
  configurable: 'Yes',
  field: 'observation_dropdown_2',
  field_name: 'Observation dropdown 2',
  slug: 'compliance-audit',
  data_type: 'ForeignKey'
  },
  ,
  {
  configurable: 'Yes',
  field: 'observation_dropdown_3',
  field_name: 'Observation dropdown 3',
  slug: 'compliance-audit',
  data_type: 'ForeignKey'
  },
  ,
  {
  configurable: 'Yes',
  field: 'observation_dropdown_4',
  field_name: 'Observation dropdown 4',
  slug: 'compliance-audit',
  data_type: 'ForeignKey'
  },
  ,
  {
  configurable: 'Yes',
  field: 'observation_dropdown_5',
  field_name: 'Observation dropdown 5',
  slug: 'compliance-audit',
  data_type: 'ForeignKey'
  },
  {
    configurable: 'Yes',
    field: 'observation_multi_dropdown_1',
    field_name: 'Observation multi dropdown 1',
    slug: 'compliance-audit',
    data_type: 'ForeignKey'
    },
    {
    configurable: 'Yes',
    field: 'observation_multi_dropdown_2',
    field_name: 'Observation multi dropdown 2',
    slug: 'compliance-audit',
    data_type: 'ForeignKey'
    },
    {
      configurable: 'Yes',
      field: 'observation_multi_dropdown_3',
      field_name: 'Observation multi dropdown 3',
      slug: 'compliance-audit',
      data_type: 'ForeignKey'
    },
    {
      configurable: 'Yes',
      field: 'observation_multi_dropdown_4',
      field_name: 'Observation multi dropdown 4',
      slug: 'compliance-audit',
      data_type: 'ForeignKey'

    },
    {
      configurable: 'Yes',
      field: 'observation_multi_dropdown_5',
      field_name: 'Observation multi dropdown 5',
      slug: 'compliance-audit',
      data_type: 'ForeignKey'
      },
  ];
  export const scoringFactorsObservationRating = [
  {
  configurable: 'Yes',
  data_type: 'ForeignKey',
  field: 'likelihood',
  field_name: 'Likelihood',
  title: 'Likelihood',
  slug: 'audit-reporting'
  },
  {
  configurable: 'Yes',
  data_type: 'ForeignKey',
  field: 'impact',
  field_name: 'Impact',
  title: 'Impact',
  slug: 'audit-reporting'
  }
  ];
  export const configureAuditControlRatingFactors = [
    {
      configurable: 'Yes',
      data_type: 'ForeignKey',
      field: 'audit_likelihood',
      field_name: 'Audit Likelihood',
      title: 'Audit Likelihood',
      slug: 'audit-reporting'
    },
    {
      configurable: 'Yes',
      data_type: 'ForeignKey',
      field: 'audit_impact',
      field_name: 'Audit Impact',
      title: 'Audit Impact',
      slug: 'audit-reporting'
    },
    {
      configurable: 'Yes',
      data_type: 'ForeignKey',
      field: 'audit_rating',
      field_name: 'Audit Rating',
      title: 'Audit Rating',
      slug: 'audit-reporting'
    }
  ]
  export const cpProcessConstants = {
  create: 'create',
  cancel: 'cancel',
  defaultColorPickerColor: '#E9F0F7',
  processId: 'process_id',
  fieldName: 'field_name',
  redirectTo: 'redirectTo',
  megaProcess: 'mega_process',
  majorProcess: 'major_process',
  name: 'name',
  description: 'description',
  order: 'order',
  assetColor: 'asset_color',
  parent: 'parent',
  browse: 'Browse',
  add: 'Add',
  level: 'department_level',
  entity: 'entity'
  };

  export const cptypeConstants = {
    value:'value',
    color:'color',
    is_default:'is_default',
    field: 'field',
    parent: 'parent',
    };

  export const adminComplianceAudit = {
  field_name: '',
  data_type: '',
  configurable: ''
  };
  export const cpProcessTypeList = [
  {
  field_name: 'Mega Process',
  process_type: 'mega_process',
  type: 'ForeignKey',
  configurable: 'Yes'
  },
  {
  field_name: 'Major Process',
  process_type: 'major_process',
  type: 'ForeignKey',
  configurable: 'Yes'
  }
  ];
  export const moduleName = {
  risk: 'RiskAssessment',
  control: 'RiskAssessmentDetails',
  BusinessStrategy: 'BusinessStrategy',
  BusinessPlan: 'BusinessPlan',
  RiskRegister: 'RiskPlan',
  ERMRegister: 'ErmPlan',
  entity: 'Entity',
  department: 'Department',
  section: 'Section',
  functions: 'Functions'
  };
  export const monthsList = [
  { name: 'January' },
  { name: 'February' },
  { name: 'March' },
  { name: 'April' },
  { name: 'May' },
  { name: 'June' },
  { name: 'July' },
  { name: 'August' },
  { name: 'September' },
  { name: 'October' },
  { name: 'November' },
  { name: 'December' }];
  export const auditOverviewConstants = {
  id: 'id',
  ar: 'ar',
  en: 'en-us',
  auditsByMegaProcess: 'Audits by Mega Process',
  megaProcess: 'Mega Process',
  auditPlanning: 'audit-planning',
  auditId: 'audit_id',
  observationId: 'observation_id',
  show: 'show',
  legend: 'legend',
  all: 'all',
  label: 'label',
  color: 'color',
  limit: 'limit',
  offset: 'offset',
  category: 'category',
  others: 'Others'
  };
  export const adminComplianceObservationDynamicRating = [
  {
    configurable: 'Yes',
    field: 'rating',
    field_name: 'Compliance observation rating',
    slug: 'compliance-audit',
    data_type: 'ForeignKey'
  },
  {
  configurable: 'Yes',
  field: 'dynamic_rating',
  field_name: 'Dynamic Rating',
  slug: 'compliance-audit',
  data_type: 'ForeignKey'
  },
  {
  configurable: 'Yes',
  field: 'dynamic_rating_factors',
  field_name: 'Dynamic Rating Factors',
  slug: 'compliance-audit',
  data_type: 'ForeignKey'
  },
  {
  configurable: 'Yes',
  field: 'compliance_observation_status',
  field_name: 'Observation Status',
  slug: 'compliance-audit',
  data_type: 'ForeignKey'
  },
  {
  configurable: 'Yes',
  field: 'annexure',
  field_name: 'Annexure',
  slug: 'compliance-audit',
  data_type: 'ForeignKey'
  },
  {
  configurable: 'Yes',
  field: 'observation_type',
  field_name: 'Observation Type',
  slug: 'compliance-audit',
  data_type: 'ForeignKey'
  },
  {
  configurable: 'Yes',
  field: 'observation_dropdown_1',
  field_name: 'Observation dropdown 1',
  slug: 'compliance-audit',
  data_type: 'ForeignKey'
  },
  {
  configurable: 'Yes',
  field: 'observation_dropdown_2',
  field_name: 'Observation dropdown 2',
  slug: 'compliance-audit',
  data_type: 'ForeignKey'
  },
  {
  configurable: 'Yes',
  field: 'observation_dropdown_3',
  field_name: 'Observation dropdown 3',
  slug: 'compliance-audit',
  data_type: 'ForeignKey'
  },
  {
  configurable: 'Yes',
  field: 'observation_dropdown_4',
  field_name: 'Observation dropdown 4',
  slug: 'compliance-audit',
  data_type: 'ForeignKey'
  },
  {
  configurable: 'Yes',
  field: 'observation_dropdown_5',
  field_name: 'Observation dropdown 5',
  slug: 'compliance-audit',
  data_type: 'ForeignKey'
  },
  {
  configurable: 'Yes',
  field: 'observation_multi_dropdown_1',
  field_name: 'Observation multi dropdown 1',
  slug: 'compliance-audit',
  data_type: 'ForeignKey'
  },
  {
  configurable: 'Yes',
  field: 'observation_multi_dropdown_2',
  field_name: 'Observation multi dropdown 2',
  slug: 'compliance-audit',
  data_type: 'ForeignKey'
  },
  {
    configurable: 'Yes',
    field: 'observation_multi_dropdown_3',
    field_name: 'Observation multi dropdown 3',
    slug: 'compliance-audit',
    data_type: 'ForeignKey'
  },
  {
    configurable: 'Yes',
    field: 'observation_multi_dropdown_4',
    field_name: 'Observation multi dropdown 4',
    slug: 'compliance-audit',
    data_type: 'ForeignKey'
  },
  {
    configurable: 'Yes',
    field: 'observation_multi_dropdown_5',
    field_name: 'Observation multi dropdown 5',
    slug: 'compliance-audit',
    data_type: 'ForeignKey'
  }
  ];
  export const adminComplianceObservation = [
  {
  configurable: 'Yes',
  field: 'rating',
  field_name: 'Compliance observation rating',
  slug: 'compliance-audit',
  data_type: 'ForeignKey'
  },
  {
  configurable: 'Yes',
  field: 'compliance_observation_status',
  field_name: 'Observation Status',
  slug: 'compliance-audit',
  data_type: 'ForeignKey'
  },
  {
  configurable: 'Yes',
  field: 'annexure',
  field_name: 'Annexure',
  slug: 'compliance-audit',
  data_type: 'ForeignKey'
  },
  {
    configurable: 'Yes',
    field: 'observation_type',
    field_name: 'Observation Type',
    slug: 'compliance-audit',
    data_type: 'ForeignKey'
  },
  {
    configurable: 'Yes',
    field: 'observation_dropdown_1',
    field_name: 'Observation dropdown 1',
    slug: 'compliance-audit',
    data_type: 'ForeignKey'
    },
    {
    configurable: 'Yes',
    field: 'observation_dropdown_2',
    field_name: 'Observation dropdown 2',
    slug: 'compliance-audit',
    data_type: 'ForeignKey'
    },
    {
    configurable: 'Yes',
    field: 'observation_dropdown_3',
    field_name: 'Observation dropdown 3',
    slug: 'compliance-audit',
    data_type: 'ForeignKey'
    },
    {
    configurable: 'Yes',
    field: 'observation_dropdown_4',
    field_name: 'Observation dropdown 4',
    slug: 'compliance-audit',
    data_type: 'ForeignKey'
    },
    {
    configurable: 'Yes',
    field: 'observation_dropdown_5',
    field_name: 'Observation dropdown 5',
    slug: 'compliance-audit',
    data_type: 'ForeignKey'
    },
    {
    configurable: 'Yes',
    field: 'observation_multi_dropdown_1',
    field_name: 'Observation multi dropdown 1',
    slug: 'compliance-audit',
    data_type: 'ForeignKey'
    },
    {
    configurable: 'Yes',
    field: 'observation_multi_dropdown_2',
    field_name: 'Observation multi dropdown 2',
    slug: 'compliance-audit',
    data_type: 'ForeignKey'
    },
    {
      configurable: 'Yes',
      field: 'observation_multi_dropdown_3',
      field_name: 'Observation multi dropdown 3',
      slug: 'compliance-audit',
      data_type: 'ForeignKey'
    },
    {
      configurable: 'Yes',
      field: 'observation_multi_dropdown_4',
      field_name: 'Observation multi dropdown 4',
      slug: 'compliance-audit',
      data_type: 'ForeignKey'
    },
    {
      configurable: 'Yes',
      field: 'observation_multi_dropdown_5',
      field_name: 'Observation multi dropdown 5',
      slug: 'compliance-audit',
      data_type: 'ForeignKey'
    }
  ];
  export enum ConfigurableCompliance {
  yes = 'Yes',
  fieldName = 'field_name',
  cancel = 'cancel',
  foreignKey = 'ForeignKey',
  tableId = 'table_id',
  color = '#E9F0F7',
  complianceRecommendation = 'compliance-audit',
  recommendationDepartment = 'recommendation_department',
  futureSnapshot = '_futureSnapshot',
  configurable = 'configurable',
  status = 'status',
  auditStatus = 'audit_status',
  field = 'field',
  managentStatus = 'management_status',
  observationStatus = 'compliance_observation_status',
  statusManagement = 'Observation Status',
  complianceAudit = 'compliance-audit',
  recommendationStatus = 'recommendation_status',
  recommendationPriorty = 'recommendation_priority',
  cmRecommendationPriorty = 'compliance_observation_recommendation_priority',
  cmRecommendationStatus = 'compliance_observation_recommendation_status',
  observationStatuss = 'observation_status',
  complianceAuditType = 'compliance_audit_type',
  complianceType = 'compliance_type',
  complianceAuditOccurence = 'compliance_audit_occurrence',
  complianceOccurence = 'compliance_occurrence',
  complianceAuditStatus = 'compliance_audit_status',
  complianceStatus = 'compliance_status',
  complianceAuditArea = 'compliance_audit_area',
  complianceArea = 'compliance_area',
  complianceAuditRating = 'compliance_audit_rating',
  complianceRating = 'compliance_rating',
  complianceRequestedBy = 'requested_by',
  complianceAuditRequestedBy = 'compliance_audit_requested_by',
  complianceRequest = 'compliance-request',
  requestPriority = 'compliance_request_priority',
  priority = 'priority',
  complianceRequestType = 'compliance_request_type',
  requestType = 'request_type',
  complianceRequestRequestedBy = 'compliance_request_requested_by',
  requestStatus = 'compliance_request_status',
  complianceRequestStatus = 'status_compliance_request',
  complianceRequestForm = ' Compliance Request Forms',
  category = 'Category',
  complianceAuditResponse = 'compliance-audit-management-response',
  testingStatus ='testing_status',
  complianceTestingStatus='compliance_testing_status',
  rating= '_rating',
  issueStatus= 'issue_status',
  issueRating= 'issue_rating',
  CiStatus= 'status',
  issueDropdownV1= 'issue_dropdown_v1',
  issueDropdownV2= 'issue_dropdown_v2',
  issueMultiDropdownV1= 'issue_multi_dropdown_v1',
  issueMultiDropdownV2= 'issue_multi_dropdown_v2',
  complianceWPRating='compliance_audit_working_paper_rating',
  complianceIssueRating='compliance_audit_issue_rating',
  complianceIssueStatus='compliance_audit_issue_status',
  complianceIssueDrop1='compliance_issue_dropdown_v1',
  complianceIssueDrop2='compliance_issue_dropdown_v2',
  complianceIssueMultiDrop1='compliance_issue_multi_dropdown_v1',
  complianceIssueMultiDrop2='compliance_issue_multi_dropdown_v2',
  observationType = 'observation_type'
  }
  export enum CompliancePendingRequestsTable {
  complianceAudit = 'complianceAudit',
  complianceRequest = 'complianceRequest',
  review = 'review',
  draft = 'draft',
  pendingRequests = 'pendingRequests'
  }

  export enum ConfigurableRequest {
    yes = 'Yes',
    fieldName = 'field_name',
    cancel = 'cancel',
    foreignKey = 'ForeignKey',
    tableId = 'table_id',
    color = '#E9F0F7',
    futureSnapshot = '_futureSnapshot',
    configurable = 'configurable',
    status = 'status',
    auditStatus = 'audit_status',
    field = 'field',
    managentStatus = 'management_status',
    observationStatus = 'request_observation_status',
    statusManagement = 'Observation Status',
    complianceAudit = 'compliance-audit',
    recommendationStatus = 'recommendation_status',
    recommendationPriorty = 'recommendation_priority',
    reqRecommendationPriorty = 'request_observation_recommendation_priority',
    reqecommendationStatus = 'request_observation_recommendation_status',
    observationStatuss = 'observation_status',
    complianceAuditType = 'compliance_audit_type',
    requestType = 'request_type',
    complianceAuditOccurence = 'compliance_audit_occurrence',
    complianceOccurence = 'compliance_occurrence',
    complianceAuditStatus = 'compliance_audit_status',
    complianceStatus = 'compliance_status',
    complianceAuditArea = 'compliance_audit_area',
    complianceArea = 'compliance_area',
    complianceAuditRating = 'compliance_audit_rating',
    complianceRating = 'compliance_rating',
    complianceRequestedBy = 'requested_by',
    complianceAuditRequestedBy = 'compliance_audit_requested_by',
    request = 'request',
    requestPriority = 'compliance_request_priority',
    priority = 'priority',
    complianceRequestType = 'compliance_request_type',
    complianceRequestRequestedBy = 'compliance_request_requested_by',
    requestStatus = 'compliance_request_status',
    complianceRequestStatus = 'status_compliance_request',
    requestForm = ' Request Forms',
    category = 'Category',
    complianceAuditResponse = 'compliance-audit-management-response',
    testingStatus ='testing_status',
    requestTestingStatus='request_testing_status',
    rating= '_rating',
    issueStatus= 'issue_status',
    issueRating= 'issue_rating',
    CiStatus= 'status',
    issueDropdownV1= 'issue_dropdown_v1',
    issueDropdownV2= 'issue_dropdown_v2',
    issueMultiDropdownV1= 'issue_multi_dropdown_v1',
    issueMultiDropdownV2= 'issue_multi_dropdown_v2',
    requestWPRating='request_audit_working_paper_rating',
    requestIssueRating='request_audit_issue_rating',
    requestIssueStatus='request_audit_issue_status',
    requestIssueDrop1='request_issue_dropdown_v1',
    requestIssueDrop2='request_issue_dropdown_v2',
    requestIssueMultiDrop1='request_issue_multi_dropdown_v1',
    requestIssueMultiDrop2='request_issue_multi_dropdown_v2',
    observationType = 'observation_type'
    }

  export enum PolicyPendingRequestsTable {
  policy = 'policy',
  policyRequest = 'policyRequest',
  policyReview = 'policyReview',
  policyDraft = 'policyDraft',
  pendingRequests = 'pendingRequests'
  }
  export const auditDateRecords = [
  { label: 'As on Date', param: 'as_on_date' }
  ];
  export const categoryConstants = {
  categoryName: 'category_name',
  description: 'description',
  assetColor: 'asset_color',
  selectedColor: 'selected_color'
  };
  export enum AuErmRegisterConstants {
  registerName = 'register_name',
  manager = 'manager',
  startDate = 'start_date',
  endDate = 'end_date',
  registerStatus = 'register_status'
  }
  export const ermScoringFactors = [
  {
  configurable: 'Yes',
  data_type: 'ForeignKey',
  field: 'inherent_likelihood',
  field_name: commonLanguage.inherent_likelihood,
  slug: 'risk-assessment'
  },
  {
  configurable: 'Yes',
  data_type: 'ForeignKey',
  field: 'inherent_impact',
  field_name: commonLanguage.inherent_impact,
  slug: 'risk-assessment'
  }
  ];
  export const timeLogConstant = {
  year: 'year'
  };

  export const complianceManagementResponseParams = {
  management_status: '',
  management_user: '',
  observation_rating: '',
  multi_values: false
  };
  export const ermConstants = {
  ermUniverse: 'erm-universe'
  };
  export const fieldWithNoColor = ['scoring_method'];
  export const commonActionDropDown = ['bottom-right', 'left-top', 'bottom-end', 'bottom', 'left', 'bottom-left', 'bottom', 'bottom-start', 'bottom-end', 'start-top', 'left-bottom', 'start-bottom', 'left-bottom', 'end', 'top', 'top-start', 'top-left', 'top-end', 'top-right', 'start', 'right', 'end-top', 'right-top', 'end-bottom', 'right-bottom'];
  export const statusInWholeModules = ['status', 'strategy_status', 'action_status', 'issue_status', 'observation_status', 'risk_category', 'recommendation_status', 'incident_status', 'compliance_audit_status', 'compliance_request_status', 'compliance_observation_recommendation_status', 'compliance_observation_status', 'policy_management_status', 'policy_request_status', 'status_policy_request', 'status_compliance_request', 'compliance_audit_issue_status', 'management_status', 'improvement_status'];
  export const dropDownFieldWithDefault = ['issue_rating', 'recommendation_priority', 'action_priority', 'risk_identifier', 'treatment', 'risk_type', 'fraud_related', 'risk_mitigation_strategy_effectiveness', 'key_risk', 'top_risk', 'risk_appetite', 'risk_within_appetite', 'risk_mitigation_required', 'target_residual_risk', 'target_residual_risk', 'additional_department',
  'risk_mitigation_strategy_responsibility_v1', 'risk_extra_dropdown', 'risk_additional_dropdown', 'risk_new_dropdown', 'risk_auxiliary_dropdown', 'risk_subsidiary_dropdown', 'extra_dropdown_risk', 'additional_dropdown_risk', 'new_dropdown_risk', 'control_frequency', 'control_type', 'control_automation', 'control_rating', 'process', 'sub_process', 'key_control', 'ease_of_implementation',
  'ease_of_maintain', 'cost_to_implement_maintain', 'reduces_impact', 'reduces_likelihood', 'control_design_evaluation', 'strategy_category', 'strategy_priority', 'compliance_observation_recommendation_priority', 'compliance_audit_type', 'compliance_audit_rating', 'compliance_audit_occurrence', 'compliance_audit_area', 'compliance_audit_requested_by', 'compliance_additional_dropdown_v1',
  'compliance_additional_dropdown_v2', 'compliance_additional_dropdown_v3', 'compliance_additional_dropdown_v4', 'compliance_additional_multi_dropdown_v1', 'compliance_additional_multi_dropdown_v2', 'compliance_additional_multi_dropdown_v3', 'compliance_additional_multi_dropdown_v4', 'likelihood', 'impact', 'requested_by', 'source_of_audit', 'audit_additional_dropdown_v1', 'audit_additional_dropdown_v2',
  'audit_additional_dropdown_v3', 'audit_additional_dropdown_v4', 'report_rating', 'audit_additional_multi_dropdown_v1', 'audit_additional_multi_dropdown_v2', 'audit_additional_multi_dropdown_v3', 'audit_additional_multi_dropdown_v4', 'policy_request_category', 'policy_request_priority', 'policy_request_requested_by', 'policy_request_type', 'policy_management_requested_by', 'policy_management_area',
  'policy_management_rating', 'major_process', 'mega_process', 'policy_management_type', 'incident_type', 'incident_type', 'policy_dropdown_v1', 'policy_dropdown_v2', 'policy_dropdown_v3', 'policy_dropdown_v4', 'policy_multi_dropdown_v1', 'policy_multi_dropdown_v2', 'policy_multi_dropdown_v3', 'policy_multi_dropdown_v4', 'request_dropdown_v1', 'request_dropdown_v2', 'request_dropdown_v3', 'request_dropdown_v4',
  'request_multi_dropdown_v1', 'request_multi_dropdown_v2', 'request_multi_dropdown_v3', 'request_multi_dropdown_v4', 'compliance_request_type', 'compliance_request_requested_by', 'compliance_request_priority', 'multi_dropdown_v1', 'multi_dropdown_v2', 'multi_dropdown_v3', 'multi_dropdown_v4',
  'compliance_request_category', 'compliance_observation_rating', 'compliance_duration', 'risk_program_requested_by', 'method_of_risk_program', 'risk_program_dropdown_v1', 'risk_program_dropdown_v2', 'risk_program_dropdown_v3', 'risk_program_dropdown_v4', 'risk_program_multi_dropdown_v1', 'risk_program_multi_dropdown_v2', 'risk_program_multi_dropdown_v3', 'risk_program_multi_dropdown_v4', 'erm_type', 'erm-category', 'observation_type', 'compliance_audit_issue_rating', 'compliance_issue_dropdown_v1', 'compliance_issue_dropdown_v2', 'compliance_issue_multi_dropdown_v1', 'compliance_issue_multi_dropdown_v2', 'management_status', 'state', 'scoring_method'];
  export const auQuillEditorTooltips = {
  upload: 'Supported Documents : \'.pdf\', \'.txt\', \'.docx\'',
  font: 'Select a font',
  size: 'Select a font size',
  header: 'Select the text style',
  bold: 'Bold',
  italic: 'Italic',
  underline: 'Underline',
  strike: 'Strikethrough',
  color: 'Select a text color',
  background: 'Select a background color',
  script: {
  sub: 'Subscript',
  super: 'Superscript'
  },
  list: {
  ordered: 'Numbered list',
  bullet: 'Bulleted list'
  },
  indent: {
  '-1': 'Decrease indent',
  '+1': 'Increase indent'
  },
  direction: {
  'rtl': 'Text direction (right to left)',
  'ltr': 'Text direction (left to right)'
  },
  align: 'Text alignment',
  link: 'Insert a link',
  undo: 'Undo Changes',
  redo: 'Redo Changes',
  blockquote: 'Add a quote',
  // Tooltip for image upload
  image: 'Upload an image',
  // Tooltip for video upload
  video: 'Upload a video'
  };
  export const auQuillEditorUploadableDocumentFormats = ['docx', 'odt', 'pdf', 'txt'];
  export enum SearchFilter {
  name = 'name',
  members = 'members',
  order = 'order'
  }
  export enum SearchFilterNew {
    name = 'name',
    members = 'members',
    order = 'order',
    created_by = 'created_by',
    modified_by = 'modified_by'
    }
  export enum searchFilterModal {
    user_id = 'user_id',
    username = 'username',
    status = 'status',
    designation = 'designation',
    license_type = 'license_type',
    reporting_to = 'reporting_to'
  }
  export const searchFilterDynamicData = ['data_type', 'configurable', 'dynamic_data', 'field_name'];
  export const searchFilterkeyCommon = ['data_type', 'configurable', 'field_name'];
  export const searchFilterkeyRegion = ['region_id', 'name', 'region_manager', 'country'];
  export const searchFilterkeydepartment = ['department_id', 'department_manager', 'name'];
  export const searchFilterKeyObjective = ['name'];
  export const searchFilterkeyBudgetCategory = ['category', 'budget', 'country', 'entity_new', 'created_by', 'updated_by', 'created_date', 'modified_date', 'budget_balance'];
  export const searchFilterkeyUniverseModel = ['model_id', 'name',  'status', 'created_by', 'created_date'];
  export const searchFilterkeyEntity = ['entity_id', 'name', 'entity_type', 'entity_manager', 'region', 'city', 'state', 'country'];
  export const searchFilterkeyBudgetSubcategory = [...searchFilterkeyBudgetCategory, 'sub_category', 'sub_budget'];
  export const searchFilterkeyDesignations = ['name', 'order'];
  export const searchFilterkeyReminders = ['name', 'module', 'modified_date'];
  export const searchFilterKeyDataSelectByField = ['field','datatype']
  export const d3ChartConstants = {
  clicked: 'clicked'
  };
  export const statsMsgConst = {
  success: 'success'
  };
  export const departmentAdminData = [
  {
  'id': 1,
  'department_id': 'D-001',
  'name': 'Customer Department Level 1',
  'department_level': 'level1'
  },
  {
  'id': 2,
  'department_id': 'D-002',
  'name': 'Customer Department Level 2',
  'department_level': 'level2'
  },
  {
  'id': 3,
  'department_id': 'D-003',
  'name': 'Customer Department Level 3',
  'department_level': 'level3'
  },
  {
  'id': 4,
  'department_id': 'D-004',
  'name': 'Customer Department Level 4',
  'department_level': 'level4'
  }
  ];
  export const userDepartmentAdminData = [
  {
  'id': 1,
  'department_id': 'D-001',
  'name': 'User Department Level 1',
  'department_level': 'level1'
  },
  {
  'id': 2,
  'department_id': 'D-002',
  'name': 'User Department Level 2',
  'department_level': 'level2'
  },
  {
  'id': 3,
  'department_id': 'D-003',
  'name': 'User Department Level 3',
  'department_level': 'level3'
  },
  {
  'id': 4,
  'department_id': 'D-004',
  'name': 'User Department Level 4',
  'department_level': 'level4'
  }
  ];
  export const auditNotificationTemplateData = {
  'field_name': 'Audit Notification Template',
  'data_type': '-',
  'configurable': 'Yes',
  'slug': 'audit-planning',
  'field': 'audit-notification-template'
  };
  export const riskProgramNotificationTemplateData = {
  'field_name': 'Risk Program Notification Template',
  'data_type': '-',
  'configurable': 'Yes',
  'slug': 'risk-program',
  'field': 'risk-program-notification-template'
  };

  export const riskProgramTemplatesSettingsData = {
    'field_name': 'Templates',
    'data_type': '-',
    'configurable': 'Yes',
    'slug': 'risk-program',
    'field': 'templates'
  };
  export const riskUniverseTemplatesSettingsData = {
    'field_name': commonLanguage.gridtemplate,
    'data_type': '-',
    'configurable': 'Yes',
    'slug': 'risk-universe',
    'field': 'risk-grid'
  };

  export const rcmRiskTableData = {
    'field_name': 'RCM',
    'data_type': '-',
    'configurable': 'Yes',
    'slug': 'risk-universe',
    'field': 'risk-control-metrics'
    };

    export const riskCategories = {
      'field_name': riskArabicTranslation.Risk_Categories,
      'data_type': '-',
      'configurable': 'Yes',
      'slug': 'risk-universe',
      'field': 'risk-cateogries'
      };

  export const complianceNotificationTemplateData = {
    'field_name': 'Compliance Notification Template',
    'data_type': '-',
    'configurable': 'Yes',
    'slug': 'compliance-audit',
    'field': 'compliance-notification-template'
    };

  export const auditNotificationSignatureData = {
  'field_name': 'Audit Notification Signature',
  'data_type': '-',
  'configurable': 'Yes',
  'slug': 'audit-planning',
  'field': 'audit-notification-signature'
  };
  export const riskProgramNotificationSignatureData = {
  'field_name': 'Risk Program Notification Signature',
  'data_type': '-',
  'configurable': 'Yes',
  'slug': 'risk-program',
  'field': 'risk-program-notification-signature'
  };
  export const complianceNotificationSignatureData = {
    'field_name': 'Compliance Notification Signature',
    'data_type': '-',
    'configurable': 'Yes',
    'slug': 'compliance-audit',
    'field': 'compliance-notification-signature'
    };
  export enum GenerateAuditPlanConstants {
  addRiskParam = 'addRiskParam',
  addRisks = 'addRisks',
  auditSummary = 'auditSummary',
  generateFinish = 'generateFinish'
  }
  export enum GenerateAuditUniverseDeptConstants {
  addDeptParam = 'addDeptParam',
  addDept = 'addDept',
  auditDeptSummary = 'auditDeptSummary',
  generateDeptFinish = 'generateDeptFinish'
  }
  // used for browserCheck service
  export const browserData = {
  edge: 'edge',
  opera: 'opera',
  chrome: 'chrome',
  trident: 'trident',
  firefox: 'firefox',
  safari: 'safari',
  other: 'other',
  opr: 'opr',
  ie: 'ie',
  edg: 'edg',
  browserCheck: 'browserCheck'
  };
  // used for browser version in BrowserCheck
  export const browserVersion = {
  firefox: 90,
  chrome: 91,
  opera: 77,
  edge: 92,
  safari: 14
  };
  // browser aleart message
  export const browserDataAlert = {
  headText: 'Oops! It seems like Aurex doesn’t support the browser you are using.',
  subText: 'It looks like you are using an old browser that Aurex doesn’t support. Switch to one of these browsers for a better experience.',
  buttonOpt_1: 'close',
  buttonOpt_2: ' ',
  showCancelButton: false,
  icon: ''
  };
  export const objectiveAdminData = [
  {
  'name': 'Objective Level 1',
  'objective_level': 'level1'
  },
  {
  'name': 'Objective Level 2',
  'objective_level': 'level2'
  },
  {
  'name': 'Objective Level 3',
  'objective_level': 'level3'
  },
  {
  'name': 'Objective Level 4',
  'objective_level': 'level4'
  },
  {
  'name': 'Objective Level 5',
  'objective_level': 'level5'
  },
  {
  'name': 'Objective Level 6',
  'objective_level': 'level6'
  }
  ];
  export const objectiveManagementRow = {
  configurable: 'Yes',
  data_type: '-',
  field: 'objective_management',
  field_name: 'Objective Management',
  slug: 'business-strategy'
  };
  export enum ConfigurablePolicy {
  fieldName = 'field_name',
  policyType = 'policy_management_type',
  type = 'policy_type',
  policyRating = 'policy_management_rating',
  rating = 'policy_rating',
  policyStatus = 'policy_management_status',
  status = 'policy_status',
  policyArea = 'policy_management_area',
  area = 'policy_area',
  policyRequestedBy = 'policy_management_requested_by',
  requestBy = 'requested_by',
  policyManagement = 'policy-management',
  requestType = 'request_type',
  policyRequestType ='policy_request_type',
  policyRequest = 'policy-request',
  requestedBy = 'requested_by',
  policyRequestBy = 'policy_request_requested_by',
  policyPriority = 'policy_request_priority',
  priority = 'priority',
  requestStatus = 'request_status',
  policyRequestStatus = 'policy_request_status',
  category = 'category',
  policyCategory = 'type'
  }
  export const auditReportSettingsData = {
  'field_name': 'Report Settings',
  'data_type': '-',
  'configurable': 'Yes',
  'slug': 'audit-planning',
  'field': 'report_settings'
  };
  export const auditTemplatesSettingsData = {
    'field_name': 'Templates',
    'data_type': '-',
    'configurable': 'Yes',
    'slug': 'audit-planning',
    'field': 'templates'
    };

  export const complianceTemplatesSettingsData = {
    'field_name': 'Templates',
    'data_type': '-',
    'configurable': 'Yes',
    'slug': 'compliance-audit',
    'field': 'templates'
  };

  export const entityDonutConstants = {
    rating: 'Audit by Status',
    show: 'show',
    label: 'label'
  };

  export enum AuditIssueObservationWidget {
  issueObservation = 'issueObservation',
  workingPaper = 'workingPaper',
  riskProgramWorkingPaper = 'riskProgramWorkingPaper',
  complianceWorkingPaper = 'complianceWorkingPaper'
  }
  export enum AuditReportSettingsKeys {
  fieldName = 'field_name',
  firstPage = 'first_page',
  coverPage = 'cover_page',
  id ='id',
  reportFieldName = 'report_field_name'
  }
  export const auditReportSettingsInvalidFieldList = ['id', 'permission_status'];
  export const auditIssueTrackerIssueStatus = [
  { label: 'All Issues', data: 0, color: '#4e79a7' }
  ];

  export const auditIssueTrackerObservationStatus = [
    { label: 'All Observations', data: 0, color: '#4e79a7' }
    ];
  export const auditCalendarConstants = {
  multiYearAuditPlan: 'Multi Year Audit Plan',
  calendar: 'Calendar',
  auditPlans: 'Audit Plans',
  heightFactor: 54
  };
  export const multiYearAuditPlanTableConfig = {
  tableLimitOption: ['All', 5, 10, 25, 50, 100],
  pager: 10,
  tableLimit: 10
  };
  export const multiYearAuditPlanConstants = {
  all: 'All',
  audit: 'Audit',
  departments: 'Departments',
  manager: 'Manager',
  auditUniverse: 'Audit Universe',
  auditType: 'Audit Type'
  };
  export const toolTip = {
  top: 10,
  left: 20
  };
  export const complianceRcmConstants = {
  id: 'id',
  risk: 'risk',
  control: 'control',
  riskRcm: 'risk_rcm',
  controlRcm: 'control_rcm',
  assessmentRcm: 'assessment_rcm',
  show: 'show',
  category: 'category',
  label: 'label',
  custom: 'custom',
  self: '_self',
  fieldStatus: 'field_status',
  csv: 'csv',
  xls: 'xls',
  riskExport: 'risk_export',
  controlExport: 'control_export',
  export: 'Export',
  auditPlanning: 'audit-planning',
  defaultTitle: 'Risk Control Metrics',
  observation: 'observation',
  complianceObservation:'compliance-observation',
  complianceObservationrequestfor:'complianceobservation',
  recommendation: 'recommendation',
  assessment: 'assessment',
  managementResponse: 'management_response',
  observationRcm: 'observation_rcm',
  recommendationRcm: 'recommendation_rcm',
  managementResponseRcm: 'management_response_rcm',
  rcmDataRequestFor: 'compliance_audit,observation',
  rcmRequestForObservation: 'observation',
  rcmRequestForRiskAndControls: 'compliance_audit',
  riskControl: 'riskControl',
  observationId :'observation_id',
  response: 'response',
  shortTextLengthForRcmFieldsInControlPanel: 50,
  observationList : 'ObservationLIst'
  };
  export const complianceRcmTableRowData = {
    'field_name': 'Risk Control Metrics',
    'data_type': '-',
    'configurable': 'Yes',
    'slug': 'compliance-audit',
    'field': 'risk-control-metrics'
  };

  export const complianceReportSettingsData = {
    'field_name': 'Report Settings',
    'data_type': '-',
    'configurable': 'Yes',
    'slug': 'compliance-audit',
    'field': 'report_settings'
  };

  export interface AuditSuccessDraftCancelSettings {
    cancelButtonText?: string,
    customClassForCancel?: string,
    cancelButtonRouterLink?: string | any,
    enableCancelButtonRouterLink?: boolean
  }

  export interface AuditSuccessDraftConfirmSettings {
    confirmButtonText?: string,
    customClassForConfirm?: string,
    confirmButtonRouterLink?: string | any,
    enableConfirmButtonRouterLink?: boolean
  }

  export const reportUrlData  = {
  'action': 'true',
  'recommendation': 'true',
  'issue': 'true',
  'management_comments': 'true'
  // 'observation' : 'true'
  };
  export const reportComplianceUrlData  = {
  'actions': 'true',
  'recommendation': 'true',
  'management_comments': 'true',
  'observation' : 'true'
  };
  export const auditManagementPermissionCheckPaths = {
    auditUniverse: ['plans','plans-detailpage', 'generate-audit-plan', 'generate-audit-plan-with-department', 'generate-audit-plan-based-model', 'generate-audits', 'generate-plan' ,'generic-generate-plan'],
    auditOverview: ['audit-planing', 'audit-overview', 'audit-calender', 'multi-year-audit-plan', 'audit-planing', 'audit-issue-tracker', 'audit-review', 'overview', 'issue-tracker','create-audit', 'edit-audit','action_detail', 'import', 'details', 'request-response', 'approval-edit-audit', 'action', 'report-details', 'report-sign-off', 'report', 'compliance-observations', 'risk-program-observations', 'audit-incident', 'rcm', 'universe', 'calendar', 'generate-audit-universe', 'department-based-universe', 'sub_activities', 'dynamic', 'documents'],
    auditProgram: ['audit-program', 'audit-program-for', 'working-paper-details', 'edit-working-paper', 'create-working-paper'],
    auditManagementResponse: ['management-responses', 'management-response-page', 'request-response'],
    auditObservation: ['observations', 'observation-detailpage', 'audit-detail-observation-create', 'observation-edit-audit', 'observation-create-audit', 'observation-details-action', 'observation-detail-edit-observation', 'audit-assessment'],
    auditObservationRecommendation: ['recommendations', 'recommendations-detailpage','recommendation-action-details'],
    auditIssue: ['issues', 'issue-details'],
    auditProgramActivity: ['activities', 'activity-detail'],
    auditUniverseTab: ['universe', 'universe-detailpage'],
    auditTabs: ['universe', 'plans', 'calendar', 'audit-overview'],
    auditObservationAction: 'observation-details-action',
    auditActivityAction: 'activity-detail-action',
    auditIssueAction: 'issue-action-details',
    auditOverviewSlug: 'audit-planning',
    auditUniverseSlug: 'audit-universe',
    auditWorkingPaperSlug: 'working-paper',
    auditManagementResponseSlug: 'management-response',
    riskManagementResponseSlug: 'risk-program-management-response',
    auditManagementPath : 'management-responses',
    auditObservationSlug: 'audit-observation',
    auditObservationRecommendationSlug: 'observation-recommendation',
    auditIssueSlug: 'audit-issue',
    auditProgramActivitySlug: 'audit-program-activity',
    auditObservationActionSlug: 'observation-action',
    auditActivityActionSlug: 'audit-activity-action',
    auditIssueActionSlug: 'audit-issue-action',
    auditRecommendationActionSlug: 'audit-recommendation-action',
    auditIssueTrackerIssueSlug: 'audit-issue',
    auditIssueTrackerRecommendationSlug: 'observation-recommendation',
    auditIssueTrackerIssuePath: '/audit-management/audit-issue-tracker/issues',
    auditIssueTrackerRecommendationPath: '/audit-management/audit-issue-tracker/recommendations',
    auditIssueTrackerActions: '/audit-management/audit-issue-tracker/actions',
    auditIssueTrackerManagementResponse: '/audit-management/audit-issue-tracker/management-responses',
    improvementManagementResponseSlug: 'improvement-management-response',
    complianceAudit: 'compliance-audit',
    workingPaperActionSlug: 'working-paper-action',
    improvementActionSlug: 'improvement-action',
    auditIssuetrackerObservationPerm: 'audit-observation',
    auditTerritory: 'audit-territory',
    auditImprovement: 'audit-improvement',
    auditPlansSlug: 'audit-territory'
  };
  export const auditManagementPermissionData = {
  add: 'Add',
  addComment: 'Add Comment',
  admin: 'Admin',
  approver: 'Approver',
  approval: 'Approval',
  delete: 'Delete',
  deleteComment: 'Delete Comment',
  deleteDocument: 'Delete Document',
  download: 'Download',
  downloadDocument: 'Download Document',
  edit: 'Edit',
  editComment: 'Edit Comment',
  editDocument: 'Edit Document',
  export: 'Export',
  import: 'Import',
  upload: 'Upload',
  uploadDocument: 'Upload Document',
  view: 'View',
  viewComment: 'View Comment',
  parentModule: 'Management Policy',
  tag: 'Tag',
  viewAny: 'View Any',
  viewOwn: 'View Own',
  auditParentModule: 'Audit Management',
  complianceParentModule: 'Compliance Audit Management',
  canAddManagementResponse: 'Can add management response',
  canChangeManagementResponse: 'Can change management response',
  canDeleteManagementResponse: 'Can delete management response',
  canViewManagementResponse: 'Can view management response'
  };
  export const adminAuditPlanningConstants = {
  objectManagementSlug: 'object-management',
  auditManagementSlug: 'audit-management',
  slug: 'slug'
  }

  export const menuCreatorData = {
    add: 'Add',
    delete: 'Delete',
    view: 'View',
    edit: 'Edit'
  }

  export enum MenuCreatorLinkType {
    url = 'Url',
    page = 'Page',
    mainmenu = 'Main Menu'
  }

  export enum MenuActivityOpenConstants {
    edit = 'edit',
    create = 'create',
    delete = 'delete',
    browse = 'browse'
  }

  export const menuQueryParamSetvalue = {
    limit: tableConfig.pager,
    offset: 0
  }

  export const compliancePlanQueryParams = {
    requested_by: '',
    rating: '',
    audit_manager: '',
    plan: '',
    plan_status: '',
    manager: '',
    department_level1: '',
    user_department_level1: '',
    mega_process: '',
    major_process: '',
    compliance_plan_status: ''
  };

  export enum EmailConstants {
    auditNotificationEmail = 'Audit Notification Email',
    audit_slug = 'compliance-audit',
    file = 'file',
    slug ='audit-management'
  }

  export const maxSizeLimitMessage = {
    documentDetailText: `Maximum upload file size ${maxUploadSize.maxDocumentSize}`,
    documentDetailSingleText: `The maximum file size of a single file is ${maxUploadSize.maxVideoUploadSizeLimit} and the total file size limit is ${maxUploadSize.maxDocumentVideoSizeAllFile}`,
    imageDetailText: `Maximum upload file size ${maxUploadSize.maxDocumentSize}`,
    emailDocumentDetailText: `Maximum upload file size ${maxUploadSize.maxDocumentSize}`,
    dataSourceDocumentDetailtext: `Maximum upload file size ${maxUploadSize.dataSourceMaxSize}`,
    excelDocumentText: `Maximum upload file size is ${maxUploadSize.maxDocumentSize}`,
    documentDetailSingleTextFirst: 'The maximum file size of a single file is',
    documentDetailSingleTextSecond: 'and the total file size limit is'

  }


  export const controlScoringData = [
    {
    configurable: 'Yes',
    dynamic_data: {
      'field_name': 'Dynamic Rating',
      'model_field_name': null,
      'configuration': {
        'show': true,
        'mandatory': false,
        'description': false
      },
      'permission_status': true
    },
    field: 'dynamic_rating',
    field_name: 'Dynamic Rating',
    slug: 'risk-assessment-v1-details',
    data_type: 'ForeignKey'
  },
  {
    configurable: 'Yes',
    dynamic_data: {
      'field_name': 'Dynamic Rating Factors',
      'model_field_name': null,
      'configuration': {
        'show': true,
        'mandatory': false,
        'description': false
      },
      'permission_status': true
    },
    field: 'dynamic_rating_factors',
    field_name: 'Dynamic Rating Factors',
    slug: 'risk-assessment-v1-details',
    data_type: 'ForeignKey'
  }
  ]

  export const userModulePermissions = {
    add: 'Add',
    view: 'View',
    edit: 'Edit',
    delete: 'Delete',
    upload: 'Upload',
    download: 'Download',
    addComment: 'Add Comment',
    viewComment: 'View Comment',
    editComment: 'Edit Comment',
    deleteComment: 'Delete Comment',
    uploadDocument: 'Upload Document',
    downloadDocument: 'Download Document',
    editDocument: 'Edit Document',
    deleteDocument: 'Delete Document',
    export: 'Export',
    import: 'Import',
    tag: 'Tag',
    viewAny: 'View Any',
    viewOwn: 'View Own',
    admin: 'Admin',
    approval: 'Approval',
  };

  export enum GenerateAuditPlanTab {
    populateauditplan = 'populate',
    selectauditplan = 'select',
    modifyauditplan = 'modify',
    finishauditplan = 'finish',
    generateAudit = 'GenerateAudit'
    }

    export enum AuditFollowupReportFields {
      status = 'statusreport',
      performance = 'performancemonthly',
      performanceyearly = 'performanceyearly',
      }

export const auditPlanAnalysisData = {
  name: '',
  audits_count: '',
  tat: '',
  mandays: '',
  budget_hour: '',
  fiscal_year: '',
  audit_name: '',
  function: '',
  department: '',
  sort_rating: '',
  mega_proecess: '',
  man_days: ''
}

export const approvalFormConstants = {
  comment: 'comment',
  file: 'file',
  object_id: 'object_id',
  content_type: 'content_type',
  users: 'users',
  requestor_comment: 'requestor_comment',
  data: 'data',
  groupName: 'group_name',
  selectAll: 'select_all',
  mediaObjectId: 'media_object_id',
  workflow: 'workflow'
};

export const approvalIcon ={
  revoked: 'au-icon--reload',
  approved: 'au-icon--tick',
  rejected: 'au-icon--close',
  delegate: 'au-icon--back-home',
  pending: 'au-icon--approval-pending',
  'in-progress': 'au-icon--approval-in-progress',
  canceled: 'au-icon--close',
  'not-initiated': 'au-icon--reload',
  revised: 'au-icon--reload',
  requested: 'au-icon--request'
}

export const fileTypes = 'pdf, docx, doc, ppt, pptx, xls, xlsx, txt, odt, jpg, png, jpeg, csv, psv, tsv, ssv, scsv, msg, mp4, avi, mkv, mov, wmv, webm, flv, vsd, vsdx';

export const fileTypesWithoutVideo = 'pdf, docx, doc, ppt, pptx, xls, xlsx, txt, odt, jpg, png, jpeg, csv, psv, tsv, ssv, scsv, msg';

export const fileTypesForWorkflow = 'jpg, jpeg, png, mp4, avi, mkv, mov, wmv, webm, flv, vsd, vsdx';

export const fileTypesForLogo = 'jpg, jpeg, png';

export const fileTypesForReport = 'pdf, docx, doc';

export const fileTypesForStract = 'pdf, docx, doc, txt, jpg, png, jpeg';

export const fileTypesForAnnexure = 'docx, doc, xls, xlsx';

export const fileTypeForPolicyReport = 'pdf';

export const fileTypesForImport = 'xls, xlsx, csv';

export const fileTypesForExcelImport = 'xls, xlsx';

export const fileTypesForTextImport = 'csv, txt, ssv, tsv, scsv, psv';

export const fileTypesForMROverview = 'pdf, docx, doc, ppt, pptx, xls, xlsx, txt, odt, mp4, avi, mkv, mov, wmv, webm, flv, jpg, png, jpeg, csv, psv, tsv, ssv, scsv, msg';

export const fileTypesForSignOffDocument = 'docx, pptx, pdf'

export const gaugePointerTempData = [
  {
    label: '',
    data: 0,
    isTemp: true,
    color: '#E1E1E1',
    colors: '#E1E1E1'
  }
];

export const scheduleTemplateKeyInNotification = {
  key: '{schedule_template_fields}'
}

export const managementResponseWidgetConstants = {
  compliance: 'compliance',
  complianceObservationRecommendation: 'complianceObservationRecommendation',
  auditObservationRecommendation: 'auditObservationRecommendation'
}

export const scheduleTemplateNotificationExcludedKeys = ['start_date', 'end_date', 'tat', 'completion_percentage', 'budget_hour', 'remarks', 'media', 'approvers', 'reviewers','start_time', 'end_time']

export const moduleRequestForScheduleControlPanel = {
  complianceAudit: 'Compliance Audit',
  riskProgram: 'Risk Program',
  audit: 'Audit',
  bcmAudit: 'BCM Audit',
  riskUniverse: 'Risk Universe'
};

export const scheduleTemplateNotificationDateKeys = ['start_date', 'end_date', 'planned_start_date', 'planned_end_date']

export const dummyPermission = {
  'Admin': true,
  'Approver': true,
  'Add': true,
  'Add Comment': true,
  'Delete Document': true,
  'Download': true,
  'Download Document': true,
  'Edit': true,
  'Edit Comment': true,
  'Edit Document': true,
  'Export': true,
  'Import': true,
  'Delete': true,
  'Delete Comment': true,
  'View Any': true,
  'View Comment': true,
  'View Own': true,
  'Tag': true,
  'Upload Document': true
};

export const exportModalWithSocketConstants = {
  open: 'open',
  loading: 'loading',
  closed: 'closed'
};

export const kriBasedModules = ['KRI', 'Risk Appetite', 'Metrics', 'Compliance KPI', 'Strategy KPI']

export const kriModulesPermissionSlug = ['kri', 'risk-appetite', 'compliance-kpi'];

export const kriModulesPermissionSlugScore = ['kriscore', 'compliance-kpi-score', 'risk-appetite-score'];

export const scheduleTextareaFields = ['remarks', 'text_area_1', 'text_area_2', 'text_area_3', 'text_area_4', 'text_area_5'];

export const scheduleTextFields = ['text_field_1', 'text_field_2', 'text_field_3', 'text_field_4', 'text_field_5'];

export const customDropdownsSchedule = ['schedule_single_dropdown_1', 'schedule_single_dropdown_2', 'schedule_single_dropdown_3', 'schedule_single_dropdown_4', 'schedule_single_dropdown_5',
'schedule_multi_dropdown_1', 'schedule_multi_dropdown_2', 'schedule_multi_dropdown_3', 'schedule_multi_dropdown_4', 'schedule_multi_dropdown_5'];

export const userDropdownsSchedule = ['schedule_user_multi_dropdown_1', 'schedule_user_multi_dropdown_2', 'schedule_user_multi_dropdown_3', 'schedule_user_multi_dropdown_4', 'approvers', 'reviewers', 'team'];

export const approvalFields = ['approval_status', 'approved_date', 'approved_or_rejected_by'];

export const auditSubModuleSlug = {
  managementResponse: 'management-response',
  improvementManagementResponse: 'improvement-management-response',
  auditObservationAction: 'audit-observation-action',
  auditIssueAction: 'audit-issue-action',
  auditRecommendationAction: 'audit-recommendation-action',
  auditActivityAction: 'audit-activity-action',
  auditImprovementAction: 'audit-improvement-action',
  auditObservation: 'audit-observation',
  auditStartDate: 'audit-start-date',
  auditRecommendation: 'audit-recommendation',
  auditProgramActivity: 'audit-program-activity'
};

export const gaugeChartPointerNoData = [
  {
  "color": "#E1E1E1",
    "value": "20",
    "label": "20",
    "data": 20
},
{
"color": "#E1E1E1",
  "value": "40",
  "label": "40",
  "data": 20
},
{
"color": "#E1E1E1",
"value": "60",
"label": "60",
"data": 20
},
{
"color": "#E1E1E1",
"value": "80",
"label": "80",
"data": 20
},
{
"color": "#E1E1E1",
"value": "100",
"label": "100",
"data": 20
},
]

export const policyDocumentDependency = [
  {
  value: 'Uploads',
  count: 0
  },
  {
  value: 'Policies',
  count: 0
  },
  {
    value: 'Signoff',
    count: 0
    },
  ];

  export const scheduleMaskKey = {
    schedule: 'SC-#####'
  }

export const notificationControlKey = {
  both: 'both',
  email: 'email',
  notification: 'notification'
};

export const stackedChartDataV1 = {
  data: [{ 'name': '', 'data': [8, 10, 9, 10] }],
  color: ['#e9e9e9'],
  label: ['a', 'b', 'c','d'],
  tooltip: { 'a': { '-': '-' }, 'b': { '-': '-' }, 'c': { '-': '-' }, 'd': { '-': '-' }, 'e': { '-': '-' }, 'f': { '-': '-' } }
  };

  export const buttonOptions = {
  createUser: 'createUser',
  massReassignUser: 'massReassignUser',
  export:'export'
};

    export const VerticalBarData = [
      {
        name: ' ',
        data: [
          5,
          10,
          15,
          20,
          25
        ]
      },
      {
        name: ' ',
        data: [
          5,
          10,
          15,
          20,
          25
        ]
      },
      ];
      export const VerticalBarColor = ['#dbdbdb', '#e1e1e1', '#e7e7e7'];
      export const VerticalBarLabel = [
        '',
        ' ',
        '  ',
        '   ',
        '    '];

    export const formattedData = [
      {
        'Date': 'Jun, 2021',
        'Not Classified': 5,
        'High': 6,
        'Priority': 1
      },
      {
        'Date': 'Jun, 2022',
        'Not Classified': 5,
        'High': 6,
        'Priority': 1
      },
      {
        'Date': 'Jun, 2023',
        'Not Classified': 5,
        'High': 6,
        'Priority': 1
      }
    ];

    export const dashboardQualityConstant = {
      label: 'label',
      color: 'color'
      };

  export const progressBarGraph = [
    {
      'label': ' ',
      'data': 20,
      'color': '#e9e9e9',
      'identifier': ' '
      },
      {
      'label': ' ',
      'data': 15,
      'color': '#e9e9e9',
      'identifier': ' '
      },
      {
      'label': ' ',
      'data': 10,
      'color': '#e9e9e9',
      'identifier': ' '
      },
      {
      'label': ' ',
      'data': 5,
      'color': '#e9e9e9',
      'identifier': ' '
      }
    ];

    export const approvalModuleKeys = {
      auditReport: 'auditdigitalreport_approval',
      auditIssue: 'auditissue_approval',
      auditMR: 'auditmanagementresponse_approval',
      auditObservation: 'auditobservation_approval',
      auditPlan: 'auditplan_approval',
      auditPlanDetail: 'auditplandetail_approval',
      auditPlanMaster: 'auditplanmaster_approval',
      auditProgram: 'auditprogram_approval',
      auditRecommandation: 'auditrecommendation_approval',
      auditUniverse: 'audituniverse_approval',
      assesmentApproval: 'surveyv2_approval'
    }

    export const enableAuditApproval = environment.clientName === clientNames.salAccess || environment.clientName === clientNames.iyelo ? true : false;

    export const activeFont = {
      'font-size': 'medium'
    };

    export const scheduleApprovalConstant = {
      scheduleApprovalProcessing: 'Unable to complete the action as approval is in process',
      scheduleStageLocked: 'Unable to perform the action as the schedule is locked',
      auditApprovalLock: 'You cannot perform the action as the audit is under approval'
    };

    export const dynamicEntries = {
      default: 'default',
      createdBy: 'created_by',
      createdDate: 'created_date',
      modifiedDate: 'modified_date',
      updatedBy: 'updated_by',
      user: 'user',
      date: 'datetime',
      defaultFields: 'Default Fields',
      organisationFields: 'Organisation Fields',
      fieldTypes: 'Field Types'
    };
    export const dynamicFields = {
      dropdown: 'single_dropdown',
      dropdownMulti: 'multi_dropdown',
      textarea: 'textarea',
      input: 'text',
      number: 'number',
      dateField: 'datetime',
      user: 'user',
      title: 'title',
      status: 'status',
      autoNumber: 'auto_number',
      people: 'people',
      checkbox: 'checkbox',
      imageUpload: 'image_upload',
      richText: 'rich_text',
      customRender: 'custom_render',
      badgeDropdown: 'badge_dropdown',
      documentUpload: 'document_upload',
      multidateTime: 'multidatetime',
      radioButton: 'radiobutton',
      enableRangeSlider: 'enable_range_slider',
      multiDropdownCheckbox: 'multi_dropdown_checkbox',
      multiParentCheckbox: 'multi_parent_checkbox',
      badge: 'badge',
      disabled: 'static_dropdown'
    };

    export const dynamicFieldsProps = {
      displayStyleText: 'text',
      displayStyleLongText: 'longText',
      displayStyleLongBadge: 'badge'
    };

    export const toolTipConatiner = 'toolTipConatiner';

    export const dynamicCreateConstants= {
      hide: 'hide',
      createForm: 'createForm',
      editForm: 'editForm',
      sliderForm: 'sliderForm'
    };

    export const fileUploadSocketConstants = {
      open: 'open',
      closed: 'closed',
      failed: 'failed',
    };

    export const assessmentApprovalRequestFor = {
      bcm_working_paper: 'Bcm Working Paper'
    };

    export const lockStatusMRKeys = {
      observation: 'observation',
      improvement: 'improvement',
      analytics: 'analytics',
      compliance: 'compliance',
      risk_program: 'risk_program'
    }

    export const dynamicFieldConfigKeys = {
      lock_unlock: 'lock/unlock'
    }

    export const clientDomain = {
      staging: 'staging.beinex.in',
      preproduction: 'preproduction.auraa.com',
      sal_staging: 'sal-staging.auraa.com'
    }

    export const permissionGuardConstants = ['user-dashboards', 'document-archive'];
    export const mrReponseTextLockAfterSubmitFeature = [''];
    export const responseTextMinCharacterValidationRequiredClients = [clientNames.ajm];
    export const themePreference = {
      ui_preference: false
    }

    export const cardView = {
      notAdded: ' not added',
      add: 'Add',
      likelihood: 'Likelihood',
      impact: 'Impact',
      causes: 'Causes',
      vulnerability: 'Vulnerability',
      velocity: 'Velocity'
    }
