export const arxRiskTranslation = {
  Members: 'Members',
  addCause: 'Add Cause',
  addImpact: 'Add Impact',
  TimeLine: 'Timeline',
  quick_actions: 'Quick Actions',
  more_information: 'More Information',
  overview: 'Overview',
  impactingRisk: 'Impacting Risk',
  Controls: 'Controls',
  Assessments: 'Assessments',
  dueDate: 'Due date',
  notAvailable: 'Not Available',
  mitigationPlans: 'Mitigation Plans',
  field: 'Field',
  newValue: 'New Value',
  oldValue: 'Old Value',
  residualScore: 'Residual Risk Score',
  inherentScore: 'Inherent Risk Score',
  riskScore: 'Risk Score',
  moreInfo: 'More Information',
  riskRating: 'Risk Rating',
  controlRating: 'Control Rating',
  riskStageMsg: 'Risk Stage changed from ',
  riskStages: 'Risk Stage',
  riskLevel: 'Risk level',
  not_found: 'No data found',
  history: 'History',
  risk_universe: 'Risk Universe',
  print_report: 'Print Report',
  approval: 'Submit for Approval',
  delete: 'Delete',
  riskMovement: 'Risk Movement',
  currentResidual: 'Current Residual Risk',
  likelihood: 'Likelihood',
  vulnerability: 'Vulnerability',
  velocity: 'Velocity',
  impact: 'Impact',
  lockStage_control: 'Unable to update control stage as the control is locked.',
  identifiedBy: 'Created by',
  on: 'on',
  risk: 'Risk',
  risk_rating: 'Risk Rating',
  rating: 'Rating',
  score: 'Score',
  edit: 'Edit',
  manually: 'Manually',
  controlStage: 'Control Stage',
  control: 'Control',
  untitled: 'Untitled',
  controlStageMsg: 'Control Stage changed from ',
  activity_log: 'Activity Log',
  residual_check: 'The residual rating you have selected is higher than the inherent rating.',
  save_as_draft: 'Save as Draft',
  create_risk: 'Create Risk',
  current_control_rating: 'Current Control Rating',
  bowtieanalysis: 'Bow Tie Analysis',
  max_stages: 'Maximum stages reached',
  risk_assessment_criteria: 'Risk Assessment Criteria',
  control_assessment_criteria: 'Control Assessment Criteria',
  risk_taxonomy: 'Risk Taxonomy',
  definition: 'Definitions',
  risk_scoring_definitions: 'Risk Scoring Definitions',
  scoring: 'Scoring',
  evaluvate_risk: 'Evaluate Risk',
  evaluvate_control: 'Evaluate Control',
  not_support_the_video_tag: 'Your browser does not support the video tag.',
  not_support_the_audio_element: 'Your browser does not support the audio element.',
  advance_stage: 'Advance Stage',
  risk_reduction: 'Risk Reduction',
  risk_evaluvation: 'Risk Elevation',
  Analysis: 'Analysis',
  related_dependencies: 'Related Dependencies',
  next_stage: 'Next stage',
  evaluate_risk_text: 'View the key aspects of Inherent Risk Rating to discover how to evaluate risk effectively',
  evaluate_control_text: 'View the key aspects of Control Rating to discover how to evaluate control effectively',
  compliance_staging: 'Compliance Stage',
  score_card_data: 'Score compared to previous data',
  no_score_change: 'Score is same as previous data',
  overall_score: 'Overall Score',
  assessment_score: 'Assessment Score',
  risk_category: 'Risk Category',
  monitoring: 'Monitoring',
  evaluation: 'Evaluation',
  edit_residual_risk_rating_manually: 'Edit Residual Risk Rating Manually',
  add_description: 'Add Description',
  add_mitigation_plans: 'Add Mitigation Plans',
  add_KRI: 'Add KRI',
  timeline_actions: 'Timeline & Actions',
  lockStage_risk: 'Unable to update risk stage as the risk is locked.',
  of: 'of',
  Completion: 'Completion',
  sub_activities_completed: 'Sub activities completed',
  control_category: 'Control Category',
  riskOutcome: 'Risk Outcome',
  dynamically_affecting_risk: 'Is this control dynamically affecting the risk?',
  dynamically_affecting_control: 'Is this risk dynamically affecting the control?'
};

export const arxBusinessProcessTranslation = {
  businessPrs: 'Business Processes',
  assetRating: 'Asset Rating'
};

export const arxToastsMsg = {
  lockedRecord: 'Unable to perform the action as the record is locked',
  tagPermissionDenied: 'You don\'t have permission to perform this action'
};

export const arxRiskWarning = {
  withoutRatingControl: 'Configure control rating later?',
  withoutRatingRisk: 'Configure risk rating later?',
  noScoreRisk: 'This will create and tag the risk to the control without configuring the risk rating.',
  noScoreControl: 'This will create and tag the control to the risk without configuring the control rating.',
  keepEditing: 'Keep editing',
  discard: 'Discard'
};
